import React, { ReactNode, useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';

const useConfirmationDialog = (): { showConfirmationDialog(text: ReactNode | string, yesAction: () => void): void } => {
  const showDialog = useContext(ModalContext);
  const showConfirmationDialog = (text: string, yesAction: () => void): void => {
    showDialog(
      <div className='align-middle text-center p-6 bg-white rounded-lg shadow-lg w-64'>
        <span className='text-lg'>{text}</span>
        <div className='flex flex-row justify-center mt-6 space-x-3 sm:flex-col sm:space-x-0 sm:space-y-2'>
          <button className='g-orange-button rounded-lg flex-1 sm:w-full' onClick={(): void => showDialog(null)}>
            No
          </button>
          <button
            className='g-blue-button rounded-lg flex-1 sm:w-full'
            onClick={(): void => {
              showDialog(null);
              yesAction();
            }}
          >
            Sí
          </button>
        </div>
      </div>
    );
  };
  return { showConfirmationDialog };
};

export default useConfirmationDialog;
