/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<Contract>;
  contractPatientAddress?: Maybe<Contract>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  formattedAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  innerNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateNestedOneWithoutBranchesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutBranchesInput>;
  create?: Maybe<AddressCreateWithoutBranchesInput>;
};

export type AddressCreateNestedOneWithoutContractDoctorAddressInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutContractDoctorAddressInput>;
  create?: Maybe<AddressCreateWithoutContractDoctorAddressInput>;
};

export type AddressCreateNestedOneWithoutContractPatientAddressInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutContractPatientAddressInput>;
  create?: Maybe<AddressCreateWithoutContractPatientAddressInput>;
};

export type AddressCreateNestedOneWithoutEmployeesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<AddressCreateWithoutEmployeesInput>;
};

export type AddressCreateNestedOneWithoutInvoiceFilesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutInvoiceFilesInput>;
  create?: Maybe<AddressCreateWithoutInvoiceFilesInput>;
};

export type AddressCreateNestedOneWithoutPatientsInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<AddressCreateWithoutPatientsInput>;
};

export type AddressCreateOrConnectWithoutBranchesInput = {
  create: AddressCreateWithoutBranchesInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutContractDoctorAddressInput = {
  create: AddressCreateWithoutContractDoctorAddressInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutContractPatientAddressInput = {
  create: AddressCreateWithoutContractPatientAddressInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutEmployeesInput = {
  create: AddressCreateWithoutEmployeesInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutInvoiceFilesInput = {
  create: AddressCreateWithoutInvoiceFilesInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutPatientsInput = {
  create: AddressCreateWithoutPatientsInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutBranchesInput = {
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateWithoutContractDoctorAddressInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateWithoutContractPatientAddressInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateWithoutEmployeesInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateWithoutInvoiceFilesInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressCreateWithoutPatientsInput = {
  branches?: Maybe<BranchCreateNestedManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractCreateNestedOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractCreateNestedOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileCreateNestedManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressOrderByInput = {
  city?: Maybe<SortOrder>;
  country?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  innerNumber?: Maybe<SortOrder>;
  neighborhood?: Maybe<SortOrder>;
  number?: Maybe<SortOrder>;
  reference?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  street?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  zipCode?: Maybe<SortOrder>;
};

export type AddressUpdateInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateManyMutationInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateOneRequiredWithoutBranchesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutBranchesInput>;
  create?: Maybe<AddressCreateWithoutBranchesInput>;
  update?: Maybe<AddressUpdateWithoutBranchesInput>;
  upsert?: Maybe<AddressUpsertWithoutBranchesInput>;
};

export type AddressUpdateOneRequiredWithoutContractDoctorAddressInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutContractDoctorAddressInput>;
  create?: Maybe<AddressCreateWithoutContractDoctorAddressInput>;
  update?: Maybe<AddressUpdateWithoutContractDoctorAddressInput>;
  upsert?: Maybe<AddressUpsertWithoutContractDoctorAddressInput>;
};

export type AddressUpdateOneRequiredWithoutContractPatientAddressInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutContractPatientAddressInput>;
  create?: Maybe<AddressCreateWithoutContractPatientAddressInput>;
  update?: Maybe<AddressUpdateWithoutContractPatientAddressInput>;
  upsert?: Maybe<AddressUpsertWithoutContractPatientAddressInput>;
};

export type AddressUpdateOneWithoutEmployeesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<AddressCreateWithoutEmployeesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUpdateWithoutEmployeesInput>;
  upsert?: Maybe<AddressUpsertWithoutEmployeesInput>;
};

export type AddressUpdateOneWithoutInvoiceFilesInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutInvoiceFilesInput>;
  create?: Maybe<AddressCreateWithoutInvoiceFilesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUpdateWithoutInvoiceFilesInput>;
  upsert?: Maybe<AddressUpsertWithoutInvoiceFilesInput>;
};

export type AddressUpdateOneWithoutPatientsInput = {
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<AddressCreateWithoutPatientsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AddressUpdateWithoutPatientsInput>;
  upsert?: Maybe<AddressUpsertWithoutPatientsInput>;
};

export type AddressUpdateWithoutBranchesInput = {
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutContractDoctorAddressInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutContractPatientAddressInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutEmployeesInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutInvoiceFilesInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutAddressInput>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpdateWithoutPatientsInput = {
  branches?: Maybe<BranchUpdateManyWithoutAddressInput>;
  city?: Maybe<Scalars['String']>;
  contractDoctorAddress?: Maybe<ContractUpdateOneWithoutDoctorAddressInput>;
  contractPatientAddress?: Maybe<ContractUpdateOneWithoutPatientAddressInput>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutAddressInput>;
  id?: Maybe<Scalars['String']>;
  innerNumber?: Maybe<Scalars['String']>;
  invoiceFiles?: Maybe<InvoiceFileUpdateManyWithoutAddressInput>;
  neighborhood?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressUpsertWithoutBranchesInput = {
  create: AddressCreateWithoutBranchesInput;
  update: AddressUpdateWithoutBranchesInput;
};

export type AddressUpsertWithoutContractDoctorAddressInput = {
  create: AddressCreateWithoutContractDoctorAddressInput;
  update: AddressUpdateWithoutContractDoctorAddressInput;
};

export type AddressUpsertWithoutContractPatientAddressInput = {
  create: AddressCreateWithoutContractPatientAddressInput;
  update: AddressUpdateWithoutContractPatientAddressInput;
};

export type AddressUpsertWithoutEmployeesInput = {
  create: AddressCreateWithoutEmployeesInput;
  update: AddressUpdateWithoutEmployeesInput;
};

export type AddressUpsertWithoutInvoiceFilesInput = {
  create: AddressCreateWithoutInvoiceFilesInput;
  update: AddressUpdateWithoutInvoiceFilesInput;
};

export type AddressUpsertWithoutPatientsInput = {
  create: AddressCreateWithoutPatientsInput;
  update: AddressUpdateWithoutPatientsInput;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  branches?: Maybe<BranchListRelationFilter>;
  city?: Maybe<StringNullableFilter>;
  contractDoctorAddress?: Maybe<ContractWhereInput>;
  contractPatientAddress?: Maybe<ContractWhereInput>;
  country?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employees?: Maybe<EmployeeListRelationFilter>;
  id?: Maybe<StringFilter>;
  innerNumber?: Maybe<StringNullableFilter>;
  invoiceFiles?: Maybe<InvoiceFileListRelationFilter>;
  neighborhood?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  number?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<AddressWhereInput>>;
  patients?: Maybe<PatientListRelationFilter>;
  reference?: Maybe<StringNullableFilter>;
  state?: Maybe<StringNullableFilter>;
  street?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  zipCode?: Maybe<StringNullableFilter>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type Ailment = {
  __typename?: 'Ailment';
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  evolutionNote: EvolutionNote;
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  name: Scalars['String'];
  phase?: Maybe<Scalars['String']>;
  treatment: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AilmentCreateManyEvolutionNoteInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phase?: Maybe<Scalars['String']>;
  treatment: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AilmentCreateManyEvolutionNoteInputEnvelope = {
  data?: Maybe<Array<AilmentCreateManyEvolutionNoteInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AilmentCreateNestedManyWithoutEvolutionNoteInput = {
  connect?: Maybe<Array<AilmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AilmentCreateOrConnectWithoutEvolutionNoteInput>>;
  create?: Maybe<Array<AilmentCreateWithoutEvolutionNoteInput>>;
  createMany?: Maybe<AilmentCreateManyEvolutionNoteInputEnvelope>;
};

export type AilmentCreateOrConnectWithoutEvolutionNoteInput = {
  create: AilmentCreateWithoutEvolutionNoteInput;
  where: AilmentWhereUniqueInput;
};

export type AilmentCreateWithoutEvolutionNoteInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phase?: Maybe<Scalars['String']>;
  treatment: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AilmentListRelationFilter = {
  every?: Maybe<AilmentWhereInput>;
  none?: Maybe<AilmentWhereInput>;
  some?: Maybe<AilmentWhereInput>;
};

export type AilmentScalarWhereInput = {
  AND?: Maybe<Array<AilmentScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  evolutionNoteId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isPrimary?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AilmentScalarWhereInput>>;
  OR?: Maybe<Array<AilmentScalarWhereInput>>;
  phase?: Maybe<StringNullableFilter>;
  treatment?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AilmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  treatment?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AilmentUpdateManyWithoutEvolutionNoteInput = {
  connect?: Maybe<Array<AilmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AilmentCreateOrConnectWithoutEvolutionNoteInput>>;
  create?: Maybe<Array<AilmentCreateWithoutEvolutionNoteInput>>;
  createMany?: Maybe<AilmentCreateManyEvolutionNoteInputEnvelope>;
  delete?: Maybe<Array<AilmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AilmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AilmentWhereUniqueInput>>;
  set?: Maybe<Array<AilmentWhereUniqueInput>>;
  update?: Maybe<Array<AilmentUpdateWithWhereUniqueWithoutEvolutionNoteInput>>;
  updateMany?: Maybe<Array<AilmentUpdateManyWithWhereWithoutEvolutionNoteInput>>;
  upsert?: Maybe<Array<AilmentUpsertWithWhereUniqueWithoutEvolutionNoteInput>>;
};

export type AilmentUpdateManyWithWhereWithoutEvolutionNoteInput = {
  data: AilmentUpdateManyMutationInput;
  where: AilmentScalarWhereInput;
};

export type AilmentUpdateWithoutEvolutionNoteInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phase?: Maybe<Scalars['String']>;
  treatment?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AilmentUpdateWithWhereUniqueWithoutEvolutionNoteInput = {
  data: AilmentUpdateWithoutEvolutionNoteInput;
  where: AilmentWhereUniqueInput;
};

export type AilmentUpsertWithWhereUniqueWithoutEvolutionNoteInput = {
  create: AilmentCreateWithoutEvolutionNoteInput;
  update: AilmentUpdateWithoutEvolutionNoteInput;
  where: AilmentWhereUniqueInput;
};

export type AilmentWhereInput = {
  AND?: Maybe<Array<AilmentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  details?: Maybe<StringNullableFilter>;
  evolutionNote?: Maybe<EvolutionNoteWhereInput>;
  evolutionNoteId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isPrimary?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AilmentWhereInput>>;
  OR?: Maybe<Array<AilmentWhereInput>>;
  phase?: Maybe<StringNullableFilter>;
  treatment?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AilmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  announcementViews: Array<AnnouncementView>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uploader: Employee;
  uploaderId: Scalars['String'];
};

export type AnnouncementAnnouncementViewsArgs = {
  cursor?: Maybe<AnnouncementViewWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type AnnouncementCreateInput = {
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutAnnouncementInput>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutAnnouncementsInput;
};

export type AnnouncementCreateManyUploaderInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementCreateManyUploaderInputEnvelope = {
  data?: Maybe<Array<AnnouncementCreateManyUploaderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AnnouncementCreateNestedManyWithoutUploaderInput = {
  connect?: Maybe<Array<AnnouncementWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<AnnouncementCreateWithoutUploaderInput>>;
  createMany?: Maybe<AnnouncementCreateManyUploaderInputEnvelope>;
};

export type AnnouncementCreateNestedOneWithoutAnnouncementViewsInput = {
  connect?: Maybe<AnnouncementWhereUniqueInput>;
  connectOrCreate?: Maybe<AnnouncementCreateOrConnectWithoutAnnouncementViewsInput>;
  create?: Maybe<AnnouncementCreateWithoutAnnouncementViewsInput>;
};

export type AnnouncementCreateOrConnectWithoutAnnouncementViewsInput = {
  create: AnnouncementCreateWithoutAnnouncementViewsInput;
  where: AnnouncementWhereUniqueInput;
};

export type AnnouncementCreateOrConnectWithoutUploaderInput = {
  create: AnnouncementCreateWithoutUploaderInput;
  where: AnnouncementWhereUniqueInput;
};

export type AnnouncementCreateWithoutAnnouncementViewsInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutAnnouncementsInput;
};

export type AnnouncementCreateWithoutUploaderInput = {
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutAnnouncementInput>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementListRelationFilter = {
  every?: Maybe<AnnouncementWhereInput>;
  none?: Maybe<AnnouncementWhereInput>;
  some?: Maybe<AnnouncementWhereInput>;
};

export type AnnouncementOrderByInput = {
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploaderId?: Maybe<SortOrder>;
};

export type AnnouncementScalarWhereInput = {
  AND?: Maybe<Array<AnnouncementScalarWhereInput>>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AnnouncementScalarWhereInput>>;
  OR?: Maybe<Array<AnnouncementScalarWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploaderId?: Maybe<StringFilter>;
};

export type AnnouncementUpdateInput = {
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutAnnouncementInput>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutAnnouncementsInput>;
};

export type AnnouncementUpdateManyMutationInput = {
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementUpdateManyWithoutUploaderInput = {
  connect?: Maybe<Array<AnnouncementWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<AnnouncementCreateWithoutUploaderInput>>;
  createMany?: Maybe<AnnouncementCreateManyUploaderInputEnvelope>;
  delete?: Maybe<Array<AnnouncementWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AnnouncementScalarWhereInput>>;
  disconnect?: Maybe<Array<AnnouncementWhereUniqueInput>>;
  set?: Maybe<Array<AnnouncementWhereUniqueInput>>;
  update?: Maybe<Array<AnnouncementUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: Maybe<Array<AnnouncementUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: Maybe<Array<AnnouncementUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type AnnouncementUpdateManyWithWhereWithoutUploaderInput = {
  data: AnnouncementUpdateManyMutationInput;
  where: AnnouncementScalarWhereInput;
};

export type AnnouncementUpdateOneRequiredWithoutAnnouncementViewsInput = {
  connect?: Maybe<AnnouncementWhereUniqueInput>;
  connectOrCreate?: Maybe<AnnouncementCreateOrConnectWithoutAnnouncementViewsInput>;
  create?: Maybe<AnnouncementCreateWithoutAnnouncementViewsInput>;
  update?: Maybe<AnnouncementUpdateWithoutAnnouncementViewsInput>;
  upsert?: Maybe<AnnouncementUpsertWithoutAnnouncementViewsInput>;
};

export type AnnouncementUpdateWithoutAnnouncementViewsInput = {
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutAnnouncementsInput>;
};

export type AnnouncementUpdateWithoutUploaderInput = {
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutAnnouncementInput>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementUpdateWithWhereUniqueWithoutUploaderInput = {
  data: AnnouncementUpdateWithoutUploaderInput;
  where: AnnouncementWhereUniqueInput;
};

export type AnnouncementUpsertWithoutAnnouncementViewsInput = {
  create: AnnouncementCreateWithoutAnnouncementViewsInput;
  update: AnnouncementUpdateWithoutAnnouncementViewsInput;
};

export type AnnouncementUpsertWithWhereUniqueWithoutUploaderInput = {
  create: AnnouncementCreateWithoutUploaderInput;
  update: AnnouncementUpdateWithoutUploaderInput;
  where: AnnouncementWhereUniqueInput;
};

export type AnnouncementView = {
  __typename?: 'AnnouncementView';
  announcement: Announcement;
  announcementId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  employee: Employee;
  employeeId: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AnnouncementViewCreateInput = {
  announcement: AnnouncementCreateNestedOneWithoutAnnouncementViewsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAnnouncementViewsInput;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewCreateManyAnnouncementInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewCreateManyAnnouncementInputEnvelope = {
  data?: Maybe<Array<AnnouncementViewCreateManyAnnouncementInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AnnouncementViewCreateManyEmployeeInput = {
  announcementId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<AnnouncementViewCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AnnouncementViewCreateNestedManyWithoutAnnouncementInput = {
  connect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementViewCreateOrConnectWithoutAnnouncementInput>>;
  create?: Maybe<Array<AnnouncementViewCreateWithoutAnnouncementInput>>;
  createMany?: Maybe<AnnouncementViewCreateManyAnnouncementInputEnvelope>;
};

export type AnnouncementViewCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementViewCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<AnnouncementViewCreateWithoutEmployeeInput>>;
  createMany?: Maybe<AnnouncementViewCreateManyEmployeeInputEnvelope>;
};

export type AnnouncementViewCreateOrConnectWithoutAnnouncementInput = {
  create: AnnouncementViewCreateWithoutAnnouncementInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewCreateOrConnectWithoutEmployeeInput = {
  create: AnnouncementViewCreateWithoutEmployeeInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewCreateWithoutAnnouncementInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAnnouncementViewsInput;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewCreateWithoutEmployeeInput = {
  announcement: AnnouncementCreateNestedOneWithoutAnnouncementViewsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewEmployeeIdAnnouncementIdCompoundUniqueInput = {
  announcementId: Scalars['String'];
  employeeId: Scalars['String'];
};

export type AnnouncementViewListRelationFilter = {
  every?: Maybe<AnnouncementViewWhereInput>;
  none?: Maybe<AnnouncementViewWhereInput>;
  some?: Maybe<AnnouncementViewWhereInput>;
};

export type AnnouncementViewOrderByInput = {
  announcementId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AnnouncementViewScalarWhereInput = {
  AND?: Maybe<Array<AnnouncementViewScalarWhereInput>>;
  announcementId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AnnouncementViewScalarWhereInput>>;
  OR?: Maybe<Array<AnnouncementViewScalarWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AnnouncementViewUpdateInput = {
  announcement?: Maybe<AnnouncementUpdateOneRequiredWithoutAnnouncementViewsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAnnouncementViewsInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewUpdateManyWithoutAnnouncementInput = {
  connect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementViewCreateOrConnectWithoutAnnouncementInput>>;
  create?: Maybe<Array<AnnouncementViewCreateWithoutAnnouncementInput>>;
  createMany?: Maybe<AnnouncementViewCreateManyAnnouncementInputEnvelope>;
  delete?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AnnouncementViewScalarWhereInput>>;
  disconnect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  set?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  update?: Maybe<Array<AnnouncementViewUpdateWithWhereUniqueWithoutAnnouncementInput>>;
  updateMany?: Maybe<Array<AnnouncementViewUpdateManyWithWhereWithoutAnnouncementInput>>;
  upsert?: Maybe<Array<AnnouncementViewUpsertWithWhereUniqueWithoutAnnouncementInput>>;
};

export type AnnouncementViewUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AnnouncementViewCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<AnnouncementViewCreateWithoutEmployeeInput>>;
  createMany?: Maybe<AnnouncementViewCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AnnouncementViewScalarWhereInput>>;
  disconnect?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  set?: Maybe<Array<AnnouncementViewWhereUniqueInput>>;
  update?: Maybe<Array<AnnouncementViewUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<AnnouncementViewUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<AnnouncementViewUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type AnnouncementViewUpdateManyWithWhereWithoutAnnouncementInput = {
  data: AnnouncementViewUpdateManyMutationInput;
  where: AnnouncementViewScalarWhereInput;
};

export type AnnouncementViewUpdateManyWithWhereWithoutEmployeeInput = {
  data: AnnouncementViewUpdateManyMutationInput;
  where: AnnouncementViewScalarWhereInput;
};

export type AnnouncementViewUpdateWithoutAnnouncementInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAnnouncementViewsInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewUpdateWithoutEmployeeInput = {
  announcement?: Maybe<AnnouncementUpdateOneRequiredWithoutAnnouncementViewsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnnouncementViewUpdateWithWhereUniqueWithoutAnnouncementInput = {
  data: AnnouncementViewUpdateWithoutAnnouncementInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: AnnouncementViewUpdateWithoutEmployeeInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewUpsertWithWhereUniqueWithoutAnnouncementInput = {
  create: AnnouncementViewCreateWithoutAnnouncementInput;
  update: AnnouncementViewUpdateWithoutAnnouncementInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: AnnouncementViewCreateWithoutEmployeeInput;
  update: AnnouncementViewUpdateWithoutEmployeeInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type AnnouncementViewWhereInput = {
  AND?: Maybe<Array<AnnouncementViewWhereInput>>;
  announcement?: Maybe<AnnouncementWhereInput>;
  announcementId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AnnouncementViewWhereInput>>;
  OR?: Maybe<Array<AnnouncementViewWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AnnouncementViewWhereUniqueInput = {
  employeeId_announcementId?: Maybe<AnnouncementViewEmployeeIdAnnouncementIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type AnnouncementWhereInput = {
  AND?: Maybe<Array<AnnouncementWhereInput>>;
  announcementViews?: Maybe<AnnouncementViewListRelationFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<AnnouncementWhereInput>>;
  OR?: Maybe<Array<AnnouncementWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploader?: Maybe<EmployeeWhereInput>;
  uploaderId?: Maybe<StringFilter>;
};

export type AnnouncementWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  branch: Branch;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  endDate: Scalars['DateTime'];
  evolutionNotes: Array<EvolutionNote>;
  files: Array<File>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: Patient;
  paymentConcept: PaymentConcept;
  points?: Maybe<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentEvolutionNotesArgs = {
  cursor?: Maybe<EvolutionNoteWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type AppointmentFilesArgs = {
  cursor?: Maybe<FileWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type AppointmentCreateInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patientId: Scalars['String'];
  paymentConceptId: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyEmployeeInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patientId: Scalars['String'];
  paymentConceptId: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyPatientInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  paymentConceptId: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateManyPaymentConceptInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patientId: Scalars['String'];
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateManyPaymentConceptInputEnvelope = {
  data?: Maybe<Array<AppointmentCreateManyPaymentConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AppointmentCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutBranchInput>>;
  createMany?: Maybe<AppointmentCreateManyBranchInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutEmployeeInput>>;
  createMany?: Maybe<AppointmentCreateManyEmployeeInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutPatientInput>>;
  createMany?: Maybe<AppointmentCreateManyPatientInputEnvelope>;
};

export type AppointmentCreateNestedManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<AppointmentCreateManyPaymentConceptInputEnvelope>;
};

export type AppointmentCreateNestedOneWithoutEvolutionNotesInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<AppointmentCreateWithoutEvolutionNotesInput>;
};

export type AppointmentCreateNestedOneWithoutFilesInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFilesInput>;
  create?: Maybe<AppointmentCreateWithoutFilesInput>;
};

export type AppointmentCreateNestedOneWithoutPaymentInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<AppointmentCreateWithoutPaymentInput>;
};

export type AppointmentCreateOrConnectWithoutBranchInput = {
  create: AppointmentCreateWithoutBranchInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutEmployeeInput = {
  create: AppointmentCreateWithoutEmployeeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutEvolutionNotesInput = {
  create: AppointmentCreateWithoutEvolutionNotesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutFilesInput = {
  create: AppointmentCreateWithoutFilesInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutPatientInput = {
  create: AppointmentCreateWithoutPatientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutPaymentConceptInput = {
  create: AppointmentCreateWithoutPaymentConceptInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateOrConnectWithoutPaymentInput = {
  create: AppointmentCreateWithoutPaymentInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutEmployeeInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutEvolutionNotesInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutFilesInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutPatientInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutPaymentConceptInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  payment?: Maybe<PaymentCreateNestedOneWithoutAppointmentInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentCreateWithoutPaymentInput = {
  branch: BranchCreateNestedOneWithoutAppointmentsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutAppointmentsInput;
  endDate: Scalars['DateTime'];
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutAppointmentInput>;
  files?: Maybe<FileCreateNestedManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient: PatientCreateNestedOneWithoutAppointmentsInput;
  paymentConcept: PaymentConceptCreateNestedOneWithoutAppointmentsInput;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: AppointmentStatus;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentListRelationFilter = {
  every?: Maybe<AppointmentWhereInput>;
  none?: Maybe<AppointmentWhereInput>;
  some?: Maybe<AppointmentWhereInput>;
};

export type AppointmentOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  ongoingTime?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  paymentConceptId?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  reciptId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  waitingTime?: Maybe<SortOrder>;
};

export type AppointmentScalarWhereInput = {
  AND?: Maybe<Array<AppointmentScalarWhereInput>>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  endDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<AppointmentScalarWhereInput>>;
  ongoingTime?: Maybe<DateTimeNullableFilter>;
  OR?: Maybe<Array<AppointmentScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  paymentConceptId?: Maybe<StringFilter>;
  points?: Maybe<IntNullableFilter>;
  reciptId?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  waitingTime?: Maybe<DateTimeNullableFilter>;
};

export enum AppointmentStatus {
  ATTENDED = 'ATTENDED',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  MISSED = 'MISSED',
  ONGOING = 'ONGOING',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
  WAITING = 'WAITING'
}

export type AppointmentUpdateInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutBranchInput>>;
  createMany?: Maybe<AppointmentCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type AppointmentUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutEmployeeInput>>;
  createMany?: Maybe<AppointmentCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type AppointmentUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutPatientInput>>;
  createMany?: Maybe<AppointmentCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type AppointmentUpdateManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AppointmentCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<AppointmentCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<AppointmentCreateManyPaymentConceptInputEnvelope>;
  delete?: Maybe<Array<AppointmentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<AppointmentScalarWhereInput>>;
  disconnect?: Maybe<Array<AppointmentWhereUniqueInput>>;
  set?: Maybe<Array<AppointmentWhereUniqueInput>>;
  update?: Maybe<Array<AppointmentUpdateWithWhereUniqueWithoutPaymentConceptInput>>;
  updateMany?: Maybe<Array<AppointmentUpdateManyWithWhereWithoutPaymentConceptInput>>;
  upsert?: Maybe<Array<AppointmentUpsertWithWhereUniqueWithoutPaymentConceptInput>>;
};

export type AppointmentUpdateManyWithWhereWithoutBranchInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutEmployeeInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutPatientInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateManyWithWhereWithoutPaymentConceptInput = {
  data: AppointmentUpdateManyMutationInput;
  where: AppointmentScalarWhereInput;
};

export type AppointmentUpdateOneWithoutEvolutionNotesInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<AppointmentCreateWithoutEvolutionNotesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUpdateWithoutEvolutionNotesInput>;
  upsert?: Maybe<AppointmentUpsertWithoutEvolutionNotesInput>;
};

export type AppointmentUpdateOneWithoutFilesInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutFilesInput>;
  create?: Maybe<AppointmentCreateWithoutFilesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUpdateWithoutFilesInput>;
  upsert?: Maybe<AppointmentUpsertWithoutFilesInput>;
};

export type AppointmentUpdateOneWithoutPaymentInput = {
  connect?: Maybe<AppointmentWhereUniqueInput>;
  connectOrCreate?: Maybe<AppointmentCreateOrConnectWithoutPaymentInput>;
  create?: Maybe<AppointmentCreateWithoutPaymentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<AppointmentUpdateWithoutPaymentInput>;
  upsert?: Maybe<AppointmentUpsertWithoutPaymentInput>;
};

export type AppointmentUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutEvolutionNotesInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutFilesInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutPatientInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutPaymentConceptInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  payment?: Maybe<PaymentUpdateOneWithoutAppointmentInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithoutPaymentInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutAppointmentsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutAppointmentsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutAppointmentInput>;
  files?: Maybe<FileUpdateManyWithoutAppointmentInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ongoingTime?: Maybe<Scalars['DateTime']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutAppointmentsInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutAppointmentsInput>;
  points?: Maybe<Scalars['Int']>;
  reciptId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  waitingTime?: Maybe<Scalars['DateTime']>;
};

export type AppointmentUpdateWithWhereUniqueWithoutBranchInput = {
  data: AppointmentUpdateWithoutBranchInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: AppointmentUpdateWithoutEmployeeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutPatientInput = {
  data: AppointmentUpdateWithoutPatientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpdateWithWhereUniqueWithoutPaymentConceptInput = {
  data: AppointmentUpdateWithoutPaymentConceptInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithoutEvolutionNotesInput = {
  create: AppointmentCreateWithoutEvolutionNotesInput;
  update: AppointmentUpdateWithoutEvolutionNotesInput;
};

export type AppointmentUpsertWithoutFilesInput = {
  create: AppointmentCreateWithoutFilesInput;
  update: AppointmentUpdateWithoutFilesInput;
};

export type AppointmentUpsertWithoutPaymentInput = {
  create: AppointmentCreateWithoutPaymentInput;
  update: AppointmentUpdateWithoutPaymentInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutBranchInput = {
  create: AppointmentCreateWithoutBranchInput;
  update: AppointmentUpdateWithoutBranchInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: AppointmentCreateWithoutEmployeeInput;
  update: AppointmentUpdateWithoutEmployeeInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutPatientInput = {
  create: AppointmentCreateWithoutPatientInput;
  update: AppointmentUpdateWithoutPatientInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentUpsertWithWhereUniqueWithoutPaymentConceptInput = {
  create: AppointmentCreateWithoutPaymentConceptInput;
  update: AppointmentUpdateWithoutPaymentConceptInput;
  where: AppointmentWhereUniqueInput;
};

export type AppointmentWhereInput = {
  AND?: Maybe<Array<AppointmentWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  endDate?: Maybe<DateTimeFilter>;
  evolutionNotes?: Maybe<EvolutionNoteListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  id?: Maybe<StringFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<AppointmentWhereInput>>;
  ongoingTime?: Maybe<DateTimeNullableFilter>;
  OR?: Maybe<Array<AppointmentWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  payment?: Maybe<PaymentWhereInput>;
  paymentConcept?: Maybe<PaymentConceptWhereInput>;
  paymentConceptId?: Maybe<StringFilter>;
  points?: Maybe<IntNullableFilter>;
  reciptId?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  status?: Maybe<EnumAppointmentStatusFilter>;
  subject?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  waitingTime?: Maybe<DateTimeNullableFilter>;
};

export type AppointmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  employee?: Maybe<Employee>;
  token?: Maybe<Scalars['String']>;
};

export type BankingFile = {
  __typename?: 'BankingFile';
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BankingFileCreateInput = {
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutBankingFileInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BankingFileCreateNestedOneWithoutEmployeeInput = {
  connect?: Maybe<BankingFileWhereUniqueInput>;
  connectOrCreate?: Maybe<BankingFileCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<BankingFileCreateWithoutEmployeeInput>;
};

export type BankingFileCreateOrConnectWithoutEmployeeInput = {
  create: BankingFileCreateWithoutEmployeeInput;
  where: BankingFileWhereUniqueInput;
};

export type BankingFileCreateWithoutEmployeeInput = {
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BankingFileOrderByInput = {
  account?: Maybe<SortOrder>;
  bank?: Maybe<SortOrder>;
  cardNumber?: Maybe<SortOrder>;
  clabe?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BankingFileUpdateInput = {
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutBankingFileInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BankingFileUpdateManyMutationInput = {
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BankingFileUpdateOneWithoutEmployeeInput = {
  connect?: Maybe<BankingFileWhereUniqueInput>;
  connectOrCreate?: Maybe<BankingFileCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<BankingFileCreateWithoutEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BankingFileUpdateWithoutEmployeeInput>;
  upsert?: Maybe<BankingFileUpsertWithoutEmployeeInput>;
};

export type BankingFileUpdateWithoutEmployeeInput = {
  account?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  clabe?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BankingFileUpsertWithoutEmployeeInput = {
  create: BankingFileCreateWithoutEmployeeInput;
  update: BankingFileUpdateWithoutEmployeeInput;
};

export type BankingFileWhereInput = {
  account?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<BankingFileWhereInput>>;
  bank?: Maybe<StringNullableFilter>;
  cardNumber?: Maybe<StringNullableFilter>;
  clabe?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BankingFileWhereInput>>;
  OR?: Maybe<Array<BankingFileWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BankingFileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count?: Maybe<Scalars['Int']>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  image: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BlogPostCreateInput = {
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogPostOrderByInput = {
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  image?: Maybe<SortOrder>;
  subtitle?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BlogPostUpdateInput = {
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogPostUpdateManyMutationInput = {
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BlogPostWhereInput = {
  AND?: Maybe<Array<BlogPostWhereInput>>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  image?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BlogPostWhereInput>>;
  OR?: Maybe<Array<BlogPostWhereInput>>;
  subtitle?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BlogPostWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type Branch = {
  __typename?: 'Branch';
  address: Address;
  branchServices: Array<BranchService>;
  chatParticipant?: Maybe<ChatParticipant>;
  createdAt: Scalars['DateTime'];
  employees: Array<Employee>;
  goals: Array<Goal>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients: Array<Patient>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchBranchServicesArgs = {
  cursor?: Maybe<BranchServiceWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type BranchEmployeesArgs = {
  cursor?: Maybe<EmployeeWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type BranchGoalsArgs = {
  cursor?: Maybe<GoalWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type BranchPatientsArgs = {
  cursor?: Maybe<PatientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type BranchCreateInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateManyAddressInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateManyAddressInputEnvelope = {
  data?: Maybe<Array<BranchCreateManyAddressInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BranchCreateNestedManyWithoutAddressInput = {
  connect?: Maybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<BranchCreateWithoutAddressInput>>;
  createMany?: Maybe<BranchCreateManyAddressInputEnvelope>;
};

export type BranchCreateNestedManyWithoutEmployeesInput = {
  connect?: Maybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchCreateOrConnectWithoutEmployeesInput>>;
  create?: Maybe<Array<BranchCreateWithoutEmployeesInput>>;
};

export type BranchCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<BranchCreateWithoutAppointmentsInput>;
};

export type BranchCreateNestedOneWithoutBranchServicesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutBranchServicesInput>;
  create?: Maybe<BranchCreateWithoutBranchServicesInput>;
};

export type BranchCreateNestedOneWithoutCashClosingInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutCashClosingInput>;
  create?: Maybe<BranchCreateWithoutCashClosingInput>;
};

export type BranchCreateNestedOneWithoutChatParticipantInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<BranchCreateWithoutChatParticipantInput>;
};

export type BranchCreateNestedOneWithoutDaysInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutDaysInput>;
  create?: Maybe<BranchCreateWithoutDaysInput>;
};

export type BranchCreateNestedOneWithoutExpensesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutExpensesInput>;
  create?: Maybe<BranchCreateWithoutExpensesInput>;
};

export type BranchCreateNestedOneWithoutGoalsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutGoalsInput>;
  create?: Maybe<BranchCreateWithoutGoalsInput>;
};

export type BranchCreateNestedOneWithoutLockedPeriodsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutLockedPeriodsInput>;
  create?: Maybe<BranchCreateWithoutLockedPeriodsInput>;
};

export type BranchCreateNestedOneWithoutPatientsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<BranchCreateWithoutPatientsInput>;
};

export type BranchCreateNestedOneWithoutPaymentsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<BranchCreateWithoutPaymentsInput>;
};

export type BranchCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<BranchCreateWithoutPrescriptionsInput>;
};

export type BranchCreateNestedOneWithoutServicesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutServicesInput>;
  create?: Maybe<BranchCreateWithoutServicesInput>;
};

export type BranchCreateOrConnectWithoutAddressInput = {
  create: BranchCreateWithoutAddressInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutAppointmentsInput = {
  create: BranchCreateWithoutAppointmentsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutBranchServicesInput = {
  create: BranchCreateWithoutBranchServicesInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutCashClosingInput = {
  create: BranchCreateWithoutCashClosingInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutChatParticipantInput = {
  create: BranchCreateWithoutChatParticipantInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutDaysInput = {
  create: BranchCreateWithoutDaysInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutEmployeesInput = {
  create: BranchCreateWithoutEmployeesInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutExpensesInput = {
  create: BranchCreateWithoutExpensesInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutGoalsInput = {
  create: BranchCreateWithoutGoalsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutLockedPeriodsInput = {
  create: BranchCreateWithoutLockedPeriodsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutPatientsInput = {
  create: BranchCreateWithoutPatientsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutPaymentsInput = {
  create: BranchCreateWithoutPaymentsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutPrescriptionsInput = {
  create: BranchCreateWithoutPrescriptionsInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateOrConnectWithoutServicesInput = {
  create: BranchCreateWithoutServicesInput;
  where: BranchWhereUniqueInput;
};

export type BranchCreateWithoutAddressInput = {
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutAppointmentsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutBranchServicesInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutCashClosingInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutChatParticipantInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutDaysInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutEmployeesInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutExpensesInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutGoalsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutLockedPeriodsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutPatientsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutPaymentsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutPrescriptionsInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  services?: Maybe<PaymentConceptCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchCreateWithoutServicesInput = {
  address: AddressCreateNestedOneWithoutBranchesInput;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceCreateNestedManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayCreateNestedManyWithoutBranchInput>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutBranchInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutBranchInput>;
  longitude: Scalars['Float'];
  name: Scalars['String'];
  patients?: Maybe<PatientCreateNestedManyWithoutBranchInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchGoalProgress = {
  __typename?: 'BranchGoalProgress';
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  target?: Maybe<Scalars['Float']>;
};

export type BranchIncome = {
  __typename?: 'BranchIncome';
  branch?: Maybe<Branch>;
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

export type BranchListRelationFilter = {
  every?: Maybe<BranchWhereInput>;
  none?: Maybe<BranchWhereInput>;
  some?: Maybe<BranchWhereInput>;
};

export type BranchOrderByInput = {
  addressId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  holder?: Maybe<SortOrder>;
  holderRfc?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  locationUrl?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  photoHash?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  whatsAppPhoneNumber?: Maybe<SortOrder>;
};

export type BranchPatientCountBreakdown = {
  __typename?: 'BranchPatientCountBreakdown';
  canceled?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  scheduled?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
};

export type BranchScalarWhereInput = {
  addressId?: Maybe<StringFilter>;
  AND?: Maybe<Array<BranchScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  holder?: Maybe<StringNullableFilter>;
  holderRfc?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  latitude?: Maybe<FloatFilter>;
  locationUrl?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BranchScalarWhereInput>>;
  OR?: Maybe<Array<BranchScalarWhereInput>>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  photoHash?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  whatsAppPhoneNumber?: Maybe<StringNullableFilter>;
};

export type BranchService = {
  __typename?: 'BranchService';
  branch?: Maybe<Branch>;
  branchId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  frequency: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type BranchServiceBranchIdNameCompoundUniqueInput = {
  branchId: Scalars['String'];
  name: Scalars['String'];
};

export type BranchServiceCreateInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutBranchServicesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<BranchServiceCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BranchServiceCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<BranchServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchServiceCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<BranchServiceCreateWithoutBranchInput>>;
  createMany?: Maybe<BranchServiceCreateManyBranchInputEnvelope>;
};

export type BranchServiceCreateOrConnectWithoutBranchInput = {
  create: BranchServiceCreateWithoutBranchInput;
  where: BranchServiceWhereUniqueInput;
};

export type BranchServiceCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceListRelationFilter = {
  every?: Maybe<BranchServiceWhereInput>;
  none?: Maybe<BranchServiceWhereInput>;
  some?: Maybe<BranchServiceWhereInput>;
};

export type BranchServiceOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  frequency?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  payDay?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BranchServiceScalarWhereInput = {
  AND?: Maybe<Array<BranchServiceScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  frequency?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BranchServiceScalarWhereInput>>;
  OR?: Maybe<Array<BranchServiceScalarWhereInput>>;
  payDay?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BranchServiceUpdateInput = {
  branch?: Maybe<BranchUpdateOneWithoutBranchServicesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<BranchServiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchServiceCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<BranchServiceCreateWithoutBranchInput>>;
  createMany?: Maybe<BranchServiceCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<BranchServiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BranchServiceScalarWhereInput>>;
  disconnect?: Maybe<Array<BranchServiceWhereUniqueInput>>;
  set?: Maybe<Array<BranchServiceWhereUniqueInput>>;
  update?: Maybe<Array<BranchServiceUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<BranchServiceUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<BranchServiceUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type BranchServiceUpdateManyWithWhereWithoutBranchInput = {
  data: BranchServiceUpdateManyMutationInput;
  where: BranchServiceScalarWhereInput;
};

export type BranchServiceUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BranchServiceUpdateWithWhereUniqueWithoutBranchInput = {
  data: BranchServiceUpdateWithoutBranchInput;
  where: BranchServiceWhereUniqueInput;
};

export type BranchServiceUpsertWithWhereUniqueWithoutBranchInput = {
  create: BranchServiceCreateWithoutBranchInput;
  update: BranchServiceUpdateWithoutBranchInput;
  where: BranchServiceWhereUniqueInput;
};

export type BranchServiceWhereInput = {
  AND?: Maybe<Array<BranchServiceWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  frequency?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BranchServiceWhereInput>>;
  OR?: Maybe<Array<BranchServiceWhereInput>>;
  payDay?: Maybe<IntNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BranchServiceWhereUniqueInput = {
  branchId_name?: Maybe<BranchServiceBranchIdNameCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type BranchUpdateInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateManyWithoutAddressInput = {
  connect?: Maybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<BranchCreateWithoutAddressInput>>;
  createMany?: Maybe<BranchCreateManyAddressInputEnvelope>;
  delete?: Maybe<Array<BranchWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BranchScalarWhereInput>>;
  disconnect?: Maybe<Array<BranchWhereUniqueInput>>;
  set?: Maybe<Array<BranchWhereUniqueInput>>;
  update?: Maybe<Array<BranchUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<BranchUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: Maybe<Array<BranchUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type BranchUpdateManyWithoutEmployeesInput = {
  connect?: Maybe<Array<BranchWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BranchCreateOrConnectWithoutEmployeesInput>>;
  create?: Maybe<Array<BranchCreateWithoutEmployeesInput>>;
  delete?: Maybe<Array<BranchWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BranchScalarWhereInput>>;
  disconnect?: Maybe<Array<BranchWhereUniqueInput>>;
  set?: Maybe<Array<BranchWhereUniqueInput>>;
  update?: Maybe<Array<BranchUpdateWithWhereUniqueWithoutEmployeesInput>>;
  updateMany?: Maybe<Array<BranchUpdateManyWithWhereWithoutEmployeesInput>>;
  upsert?: Maybe<Array<BranchUpsertWithWhereUniqueWithoutEmployeesInput>>;
};

export type BranchUpdateManyWithWhereWithoutAddressInput = {
  data: BranchUpdateManyMutationInput;
  where: BranchScalarWhereInput;
};

export type BranchUpdateManyWithWhereWithoutEmployeesInput = {
  data: BranchUpdateManyMutationInput;
  where: BranchScalarWhereInput;
};

export type BranchUpdateOneRequiredWithoutAppointmentsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<BranchCreateWithoutAppointmentsInput>;
  update?: Maybe<BranchUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<BranchUpsertWithoutAppointmentsInput>;
};

export type BranchUpdateOneRequiredWithoutCashClosingInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutCashClosingInput>;
  create?: Maybe<BranchCreateWithoutCashClosingInput>;
  update?: Maybe<BranchUpdateWithoutCashClosingInput>;
  upsert?: Maybe<BranchUpsertWithoutCashClosingInput>;
};

export type BranchUpdateOneRequiredWithoutExpensesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutExpensesInput>;
  create?: Maybe<BranchCreateWithoutExpensesInput>;
  update?: Maybe<BranchUpdateWithoutExpensesInput>;
  upsert?: Maybe<BranchUpsertWithoutExpensesInput>;
};

export type BranchUpdateOneRequiredWithoutPatientsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<BranchCreateWithoutPatientsInput>;
  update?: Maybe<BranchUpdateWithoutPatientsInput>;
  upsert?: Maybe<BranchUpsertWithoutPatientsInput>;
};

export type BranchUpdateOneRequiredWithoutPrescriptionsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<BranchCreateWithoutPrescriptionsInput>;
  update?: Maybe<BranchUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<BranchUpsertWithoutPrescriptionsInput>;
};

export type BranchUpdateOneWithoutBranchServicesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutBranchServicesInput>;
  create?: Maybe<BranchCreateWithoutBranchServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutBranchServicesInput>;
  upsert?: Maybe<BranchUpsertWithoutBranchServicesInput>;
};

export type BranchUpdateOneWithoutChatParticipantInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<BranchCreateWithoutChatParticipantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutChatParticipantInput>;
  upsert?: Maybe<BranchUpsertWithoutChatParticipantInput>;
};

export type BranchUpdateOneWithoutDaysInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutDaysInput>;
  create?: Maybe<BranchCreateWithoutDaysInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutDaysInput>;
  upsert?: Maybe<BranchUpsertWithoutDaysInput>;
};

export type BranchUpdateOneWithoutGoalsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutGoalsInput>;
  create?: Maybe<BranchCreateWithoutGoalsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutGoalsInput>;
  upsert?: Maybe<BranchUpsertWithoutGoalsInput>;
};

export type BranchUpdateOneWithoutLockedPeriodsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutLockedPeriodsInput>;
  create?: Maybe<BranchCreateWithoutLockedPeriodsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutLockedPeriodsInput>;
  upsert?: Maybe<BranchUpsertWithoutLockedPeriodsInput>;
};

export type BranchUpdateOneWithoutPaymentsInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<BranchCreateWithoutPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutPaymentsInput>;
  upsert?: Maybe<BranchUpsertWithoutPaymentsInput>;
};

export type BranchUpdateOneWithoutServicesInput = {
  connect?: Maybe<BranchWhereUniqueInput>;
  connectOrCreate?: Maybe<BranchCreateOrConnectWithoutServicesInput>;
  create?: Maybe<BranchCreateWithoutServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BranchUpdateWithoutServicesInput>;
  upsert?: Maybe<BranchUpsertWithoutServicesInput>;
};

export type BranchUpdateWithoutAddressInput = {
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutAppointmentsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutBranchServicesInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutCashClosingInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutChatParticipantInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutDaysInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutEmployeesInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutExpensesInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutGoalsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutLockedPeriodsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutPatientsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutPaymentsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutPrescriptionsInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  services?: Maybe<PaymentConceptUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithoutServicesInput = {
  address?: Maybe<AddressUpdateOneRequiredWithoutBranchesInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutBranchInput>;
  branchServices?: Maybe<BranchServiceUpdateManyWithoutBranchInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutBranchInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutBranchInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  days?: Maybe<DayUpdateManyWithoutBranchInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutBranchesInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutBranchInput>;
  goals?: Maybe<GoalUpdateManyWithoutBranchInput>;
  holder?: Maybe<Scalars['String']>;
  holderRfc?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  locationUrl?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutBranchInput>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutBranchInput>;
  payments?: Maybe<PaymentUpdateManyWithoutBranchInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutBranchInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  whatsAppPhoneNumber?: Maybe<Scalars['String']>;
};

export type BranchUpdateWithWhereUniqueWithoutAddressInput = {
  data: BranchUpdateWithoutAddressInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpdateWithWhereUniqueWithoutEmployeesInput = {
  data: BranchUpdateWithoutEmployeesInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpsertWithoutAppointmentsInput = {
  create: BranchCreateWithoutAppointmentsInput;
  update: BranchUpdateWithoutAppointmentsInput;
};

export type BranchUpsertWithoutBranchServicesInput = {
  create: BranchCreateWithoutBranchServicesInput;
  update: BranchUpdateWithoutBranchServicesInput;
};

export type BranchUpsertWithoutCashClosingInput = {
  create: BranchCreateWithoutCashClosingInput;
  update: BranchUpdateWithoutCashClosingInput;
};

export type BranchUpsertWithoutChatParticipantInput = {
  create: BranchCreateWithoutChatParticipantInput;
  update: BranchUpdateWithoutChatParticipantInput;
};

export type BranchUpsertWithoutDaysInput = {
  create: BranchCreateWithoutDaysInput;
  update: BranchUpdateWithoutDaysInput;
};

export type BranchUpsertWithoutExpensesInput = {
  create: BranchCreateWithoutExpensesInput;
  update: BranchUpdateWithoutExpensesInput;
};

export type BranchUpsertWithoutGoalsInput = {
  create: BranchCreateWithoutGoalsInput;
  update: BranchUpdateWithoutGoalsInput;
};

export type BranchUpsertWithoutLockedPeriodsInput = {
  create: BranchCreateWithoutLockedPeriodsInput;
  update: BranchUpdateWithoutLockedPeriodsInput;
};

export type BranchUpsertWithoutPatientsInput = {
  create: BranchCreateWithoutPatientsInput;
  update: BranchUpdateWithoutPatientsInput;
};

export type BranchUpsertWithoutPaymentsInput = {
  create: BranchCreateWithoutPaymentsInput;
  update: BranchUpdateWithoutPaymentsInput;
};

export type BranchUpsertWithoutPrescriptionsInput = {
  create: BranchCreateWithoutPrescriptionsInput;
  update: BranchUpdateWithoutPrescriptionsInput;
};

export type BranchUpsertWithoutServicesInput = {
  create: BranchCreateWithoutServicesInput;
  update: BranchUpdateWithoutServicesInput;
};

export type BranchUpsertWithWhereUniqueWithoutAddressInput = {
  create: BranchCreateWithoutAddressInput;
  update: BranchUpdateWithoutAddressInput;
  where: BranchWhereUniqueInput;
};

export type BranchUpsertWithWhereUniqueWithoutEmployeesInput = {
  create: BranchCreateWithoutEmployeesInput;
  update: BranchUpdateWithoutEmployeesInput;
  where: BranchWhereUniqueInput;
};

export type BranchWhereInput = {
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringFilter>;
  AND?: Maybe<Array<BranchWhereInput>>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  branchServices?: Maybe<BranchServiceListRelationFilter>;
  CashClosing?: Maybe<CashClosingListRelationFilter>;
  chatParticipant?: Maybe<ChatParticipantWhereInput>;
  createdAt?: Maybe<DateTimeFilter>;
  days?: Maybe<DayListRelationFilter>;
  employees?: Maybe<EmployeeListRelationFilter>;
  expenses?: Maybe<ExpenseListRelationFilter>;
  goals?: Maybe<GoalListRelationFilter>;
  holder?: Maybe<StringNullableFilter>;
  holderRfc?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  latitude?: Maybe<FloatFilter>;
  locationUrl?: Maybe<StringNullableFilter>;
  lockedPeriods?: Maybe<LockedPeriodListRelationFilter>;
  longitude?: Maybe<FloatFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BranchWhereInput>>;
  OR?: Maybe<Array<BranchWhereInput>>;
  patients?: Maybe<PatientListRelationFilter>;
  payments?: Maybe<PaymentListRelationFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  photoHash?: Maybe<StringNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  services?: Maybe<PaymentConceptListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  whatsAppPhoneNumber?: Maybe<StringNullableFilter>;
};

export type BranchWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Budget = {
  __typename?: 'Budget';
  createdAt: Scalars['DateTime'];
  expireDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  items: Array<BudgetItem>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConcept>;
  patient: Patient;
  updatedAt: Scalars['DateTime'];
};

export type BudgetItemsArgs = {
  cursor?: Maybe<BudgetItemWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type BudgetCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemCreateNestedManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptCreateNestedOneWithoutUsedByBudgetsInput>;
  patient: PatientCreateNestedOneWithoutBudgetsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCreateManyPackInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCreateManyPackInputEnvelope = {
  data?: Maybe<Array<BudgetCreateManyPackInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  packId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<BudgetCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetCreateNestedManyWithoutPackInput = {
  connect?: Maybe<Array<BudgetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCreateOrConnectWithoutPackInput>>;
  create?: Maybe<Array<BudgetCreateWithoutPackInput>>;
  createMany?: Maybe<BudgetCreateManyPackInputEnvelope>;
};

export type BudgetCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<BudgetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<BudgetCreateWithoutPatientInput>>;
  createMany?: Maybe<BudgetCreateManyPatientInputEnvelope>;
};

export type BudgetCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<BudgetWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCreateOrConnectWithoutItemsInput>;
  create?: Maybe<BudgetCreateWithoutItemsInput>;
};

export type BudgetCreateOrConnectWithoutItemsInput = {
  create: BudgetCreateWithoutItemsInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetCreateOrConnectWithoutPackInput = {
  create: BudgetCreateWithoutPackInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetCreateOrConnectWithoutPatientInput = {
  create: BudgetCreateWithoutPatientInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetCreateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptCreateNestedOneWithoutUsedByBudgetsInput>;
  patient: PatientCreateNestedOneWithoutBudgetsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCreateWithoutPackInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemCreateNestedManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutBudgetsInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemCreateNestedManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptCreateNestedOneWithoutUsedByBudgetsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItem = {
  __typename?: 'BudgetItem';
  budget: Budget;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  paymentConcept: PaymentConcept;
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BudgetItemCreateInput = {
  budget: BudgetCreateNestedOneWithoutItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutBudgetItemsInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemCreateManyBudgetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConceptId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemCreateManyBudgetInputEnvelope = {
  data?: Maybe<Array<BudgetItemCreateManyBudgetInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetItemCreateManyPaymentConceptInput = {
  budgetId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemCreateManyPaymentConceptInputEnvelope = {
  data?: Maybe<Array<BudgetItemCreateManyPaymentConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BudgetItemCreateNestedManyWithoutBudgetInput = {
  connect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetItemCreateOrConnectWithoutBudgetInput>>;
  create?: Maybe<Array<BudgetItemCreateWithoutBudgetInput>>;
  createMany?: Maybe<BudgetItemCreateManyBudgetInputEnvelope>;
};

export type BudgetItemCreateNestedManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetItemCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<BudgetItemCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<BudgetItemCreateManyPaymentConceptInputEnvelope>;
};

export type BudgetItemCreateOrConnectWithoutBudgetInput = {
  create: BudgetItemCreateWithoutBudgetInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemCreateOrConnectWithoutPaymentConceptInput = {
  create: BudgetItemCreateWithoutPaymentConceptInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemCreateWithoutBudgetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutBudgetItemsInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemCreateWithoutPaymentConceptInput = {
  budget: BudgetCreateNestedOneWithoutItemsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemListRelationFilter = {
  every?: Maybe<BudgetItemWhereInput>;
  none?: Maybe<BudgetItemWhereInput>;
  some?: Maybe<BudgetItemWhereInput>;
};

export type BudgetItemOrderByInput = {
  budgetId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paymentConceptId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BudgetItemScalarWhereInput = {
  AND?: Maybe<Array<BudgetItemScalarWhereInput>>;
  budgetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BudgetItemScalarWhereInput>>;
  OR?: Maybe<Array<BudgetItemScalarWhereInput>>;
  paymentConceptId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetItemUpdateInput = {
  budget?: Maybe<BudgetUpdateOneRequiredWithoutItemsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutBudgetItemsInput>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemUpdateManyWithoutBudgetInput = {
  connect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetItemCreateOrConnectWithoutBudgetInput>>;
  create?: Maybe<Array<BudgetItemCreateWithoutBudgetInput>>;
  createMany?: Maybe<BudgetItemCreateManyBudgetInputEnvelope>;
  delete?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetItemScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  set?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  update?: Maybe<Array<BudgetItemUpdateWithWhereUniqueWithoutBudgetInput>>;
  updateMany?: Maybe<Array<BudgetItemUpdateManyWithWhereWithoutBudgetInput>>;
  upsert?: Maybe<Array<BudgetItemUpsertWithWhereUniqueWithoutBudgetInput>>;
};

export type BudgetItemUpdateManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetItemCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<BudgetItemCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<BudgetItemCreateManyPaymentConceptInputEnvelope>;
  delete?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetItemScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  set?: Maybe<Array<BudgetItemWhereUniqueInput>>;
  update?: Maybe<Array<BudgetItemUpdateWithWhereUniqueWithoutPaymentConceptInput>>;
  updateMany?: Maybe<Array<BudgetItemUpdateManyWithWhereWithoutPaymentConceptInput>>;
  upsert?: Maybe<Array<BudgetItemUpsertWithWhereUniqueWithoutPaymentConceptInput>>;
};

export type BudgetItemUpdateManyWithWhereWithoutBudgetInput = {
  data: BudgetItemUpdateManyMutationInput;
  where: BudgetItemScalarWhereInput;
};

export type BudgetItemUpdateManyWithWhereWithoutPaymentConceptInput = {
  data: BudgetItemUpdateManyMutationInput;
  where: BudgetItemScalarWhereInput;
};

export type BudgetItemUpdateWithoutBudgetInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutBudgetItemsInput>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemUpdateWithoutPaymentConceptInput = {
  budget?: Maybe<BudgetUpdateOneRequiredWithoutItemsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetItemUpdateWithWhereUniqueWithoutBudgetInput = {
  data: BudgetItemUpdateWithoutBudgetInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemUpdateWithWhereUniqueWithoutPaymentConceptInput = {
  data: BudgetItemUpdateWithoutPaymentConceptInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemUpsertWithWhereUniqueWithoutBudgetInput = {
  create: BudgetItemCreateWithoutBudgetInput;
  update: BudgetItemUpdateWithoutBudgetInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemUpsertWithWhereUniqueWithoutPaymentConceptInput = {
  create: BudgetItemCreateWithoutPaymentConceptInput;
  update: BudgetItemUpdateWithoutPaymentConceptInput;
  where: BudgetItemWhereUniqueInput;
};

export type BudgetItemWhereInput = {
  AND?: Maybe<Array<BudgetItemWhereInput>>;
  budget?: Maybe<BudgetWhereInput>;
  budgetId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BudgetItemWhereInput>>;
  OR?: Maybe<Array<BudgetItemWhereInput>>;
  paymentConcept?: Maybe<PaymentConceptWhereInput>;
  paymentConceptId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BudgetListRelationFilter = {
  every?: Maybe<BudgetWhereInput>;
  none?: Maybe<BudgetWhereInput>;
  some?: Maybe<BudgetWhereInput>;
};

export type BudgetOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  expireDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  packId?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type BudgetScalarWhereInput = {
  AND?: Maybe<Array<BudgetScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expireDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<BudgetScalarWhereInput>>;
  notes?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<BudgetScalarWhereInput>>;
  packId?: Maybe<StringNullableFilter>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemUpdateManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptUpdateOneWithoutUsedByBudgetsInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutBudgetsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetUpdateManyWithoutPackInput = {
  connect?: Maybe<Array<BudgetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCreateOrConnectWithoutPackInput>>;
  create?: Maybe<Array<BudgetCreateWithoutPackInput>>;
  createMany?: Maybe<BudgetCreateManyPackInputEnvelope>;
  delete?: Maybe<Array<BudgetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetWhereUniqueInput>>;
  set?: Maybe<Array<BudgetWhereUniqueInput>>;
  update?: Maybe<Array<BudgetUpdateWithWhereUniqueWithoutPackInput>>;
  updateMany?: Maybe<Array<BudgetUpdateManyWithWhereWithoutPackInput>>;
  upsert?: Maybe<Array<BudgetUpsertWithWhereUniqueWithoutPackInput>>;
};

export type BudgetUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<BudgetWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<BudgetCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<BudgetCreateWithoutPatientInput>>;
  createMany?: Maybe<BudgetCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<BudgetWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BudgetScalarWhereInput>>;
  disconnect?: Maybe<Array<BudgetWhereUniqueInput>>;
  set?: Maybe<Array<BudgetWhereUniqueInput>>;
  update?: Maybe<Array<BudgetUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<BudgetUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<BudgetUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type BudgetUpdateManyWithWhereWithoutPackInput = {
  data: BudgetUpdateManyMutationInput;
  where: BudgetScalarWhereInput;
};

export type BudgetUpdateManyWithWhereWithoutPatientInput = {
  data: BudgetUpdateManyMutationInput;
  where: BudgetScalarWhereInput;
};

export type BudgetUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<BudgetWhereUniqueInput>;
  connectOrCreate?: Maybe<BudgetCreateOrConnectWithoutItemsInput>;
  create?: Maybe<BudgetCreateWithoutItemsInput>;
  update?: Maybe<BudgetUpdateWithoutItemsInput>;
  upsert?: Maybe<BudgetUpsertWithoutItemsInput>;
};

export type BudgetUpdateWithoutItemsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptUpdateOneWithoutUsedByBudgetsInput>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutBudgetsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetUpdateWithoutPackInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemUpdateManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutBudgetsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<BudgetItemUpdateManyWithoutBudgetInput>;
  notes?: Maybe<Scalars['String']>;
  pack?: Maybe<PaymentConceptUpdateOneWithoutUsedByBudgetsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BudgetUpdateWithWhereUniqueWithoutPackInput = {
  data: BudgetUpdateWithoutPackInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetUpdateWithWhereUniqueWithoutPatientInput = {
  data: BudgetUpdateWithoutPatientInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetUpsertWithoutItemsInput = {
  create: BudgetCreateWithoutItemsInput;
  update: BudgetUpdateWithoutItemsInput;
};

export type BudgetUpsertWithWhereUniqueWithoutPackInput = {
  create: BudgetCreateWithoutPackInput;
  update: BudgetUpdateWithoutPackInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetUpsertWithWhereUniqueWithoutPatientInput = {
  create: BudgetCreateWithoutPatientInput;
  update: BudgetUpdateWithoutPatientInput;
  where: BudgetWhereUniqueInput;
};

export type BudgetWhereInput = {
  AND?: Maybe<Array<BudgetWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expireDate?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<BudgetItemListRelationFilter>;
  NOT?: Maybe<Array<BudgetWhereInput>>;
  notes?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<BudgetWhereInput>>;
  pack?: Maybe<PaymentConceptWhereInput>;
  packId?: Maybe<StringNullableFilter>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type BudgetWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  createdAt: Scalars['DateTime'];
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
  lookUpReference: Scalars['String'];
  patient?: Maybe<Patient>;
  registrationId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum CardBrand {
  MASTERCARD = 'mastercard',
  OTHER = 'other',
  VISA = 'visa'
}

export type CardCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastFour: Scalars['String'];
  lookUpReference: Scalars['String'];
  patient?: Maybe<PatientCreateNestedOneWithoutCardsInput>;
  RecurringPayment?: Maybe<RecurringPaymentCreateNestedManyWithoutCardInput>;
  registrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastFour: Scalars['String'];
  lookUpReference: Scalars['String'];
  registrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<CardCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CardCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CardCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<CardCreateWithoutPatientInput>>;
  createMany?: Maybe<CardCreateManyPatientInputEnvelope>;
};

export type CardCreateNestedOneWithoutRecurringPaymentInput = {
  connect?: Maybe<CardWhereUniqueInput>;
  connectOrCreate?: Maybe<CardCreateOrConnectWithoutRecurringPaymentInput>;
  create?: Maybe<CardCreateWithoutRecurringPaymentInput>;
};

export type CardCreateOrConnectWithoutPatientInput = {
  create: CardCreateWithoutPatientInput;
  where: CardWhereUniqueInput;
};

export type CardCreateOrConnectWithoutRecurringPaymentInput = {
  create: CardCreateWithoutRecurringPaymentInput;
  where: CardWhereUniqueInput;
};

export type CardCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastFour: Scalars['String'];
  lookUpReference: Scalars['String'];
  RecurringPayment?: Maybe<RecurringPaymentCreateNestedManyWithoutCardInput>;
  registrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardCreateWithoutRecurringPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastFour: Scalars['String'];
  lookUpReference: Scalars['String'];
  patient?: Maybe<PatientCreateNestedOneWithoutCardsInput>;
  registrationId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardListRelationFilter = {
  every?: Maybe<CardWhereInput>;
  none?: Maybe<CardWhereInput>;
  some?: Maybe<CardWhereInput>;
};

export type CardOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  expirationMonth?: Maybe<SortOrder>;
  expirationYear?: Maybe<SortOrder>;
  fullName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastFour?: Maybe<SortOrder>;
  lookUpReference?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  registrationId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CardScalarWhereInput = {
  AND?: Maybe<Array<CardScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationMonth?: Maybe<StringFilter>;
  expirationYear?: Maybe<StringFilter>;
  fullName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastFour?: Maybe<StringFilter>;
  lookUpReference?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CardScalarWhereInput>>;
  OR?: Maybe<Array<CardScalarWhereInput>>;
  patientId?: Maybe<StringNullableFilter>;
  registrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CardUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  lookUpReference?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneWithoutCardsInput>;
  RecurringPayment?: Maybe<RecurringPaymentUpdateManyWithoutCardInput>;
  registrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  lookUpReference?: Maybe<Scalars['String']>;
  registrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<CardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CardCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<CardCreateWithoutPatientInput>>;
  createMany?: Maybe<CardCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<CardWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CardScalarWhereInput>>;
  disconnect?: Maybe<Array<CardWhereUniqueInput>>;
  set?: Maybe<Array<CardWhereUniqueInput>>;
  update?: Maybe<Array<CardUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<CardUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<CardUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type CardUpdateManyWithWhereWithoutPatientInput = {
  data: CardUpdateManyMutationInput;
  where: CardScalarWhereInput;
};

export type CardUpdateOneRequiredWithoutRecurringPaymentInput = {
  connect?: Maybe<CardWhereUniqueInput>;
  connectOrCreate?: Maybe<CardCreateOrConnectWithoutRecurringPaymentInput>;
  create?: Maybe<CardCreateWithoutRecurringPaymentInput>;
  update?: Maybe<CardUpdateWithoutRecurringPaymentInput>;
  upsert?: Maybe<CardUpsertWithoutRecurringPaymentInput>;
};

export type CardUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  lookUpReference?: Maybe<Scalars['String']>;
  RecurringPayment?: Maybe<RecurringPaymentUpdateManyWithoutCardInput>;
  registrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardUpdateWithoutRecurringPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  expirationMonth?: Maybe<Scalars['String']>;
  expirationYear?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastFour?: Maybe<Scalars['String']>;
  lookUpReference?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneWithoutCardsInput>;
  registrationId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardUpdateWithWhereUniqueWithoutPatientInput = {
  data: CardUpdateWithoutPatientInput;
  where: CardWhereUniqueInput;
};

export type CardUpsertWithoutRecurringPaymentInput = {
  create: CardCreateWithoutRecurringPaymentInput;
  update: CardUpdateWithoutRecurringPaymentInput;
};

export type CardUpsertWithWhereUniqueWithoutPatientInput = {
  create: CardCreateWithoutPatientInput;
  update: CardUpdateWithoutPatientInput;
  where: CardWhereUniqueInput;
};

export type CardWhereInput = {
  AND?: Maybe<Array<CardWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  expirationMonth?: Maybe<StringFilter>;
  expirationYear?: Maybe<StringFilter>;
  fullName?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  lastFour?: Maybe<StringFilter>;
  lookUpReference?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CardWhereInput>>;
  OR?: Maybe<Array<CardWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringNullableFilter>;
  RecurringPayment?: Maybe<RecurringPaymentListRelationFilter>;
  registrationId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  registrationId?: Maybe<Scalars['String']>;
};

export type CashClosing = {
  __typename?: 'CashClosing';
  branch: Branch;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type CashClosingCreateInput = {
  branch: BranchCreateNestedOneWithoutCashClosingInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutCashClosingInput;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<CashClosingCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CashClosingCreateManyEmployeeInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<CashClosingCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CashClosingCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CashClosingCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<CashClosingCreateWithoutBranchInput>>;
  createMany?: Maybe<CashClosingCreateManyBranchInputEnvelope>;
};

export type CashClosingCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CashClosingCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<CashClosingCreateWithoutEmployeeInput>>;
  createMany?: Maybe<CashClosingCreateManyEmployeeInputEnvelope>;
};

export type CashClosingCreateOrConnectWithoutBranchInput = {
  create: CashClosingCreateWithoutBranchInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingCreateOrConnectWithoutEmployeeInput = {
  create: CashClosingCreateWithoutEmployeeInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutCashClosingInput;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingCreateWithoutEmployeeInput = {
  branch: BranchCreateNestedOneWithoutCashClosingInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  startAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingListRelationFilter = {
  every?: Maybe<CashClosingWhereInput>;
  none?: Maybe<CashClosingWhereInput>;
  some?: Maybe<CashClosingWhereInput>;
};

export type CashClosingOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  end?: Maybe<SortOrder>;
  endAmount?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  observations?: Maybe<SortOrder>;
  start?: Maybe<SortOrder>;
  startAmount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CashClosingScalarWhereInput = {
  AND?: Maybe<Array<CashClosingScalarWhereInput>>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  end?: Maybe<DateTimeNullableFilter>;
  endAmount?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CashClosingScalarWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<CashClosingScalarWhereInput>>;
  start?: Maybe<DateTimeFilter>;
  startAmount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CashClosingUpdateInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutCashClosingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutCashClosingInput>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  startAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  startAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CashClosingCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<CashClosingCreateWithoutBranchInput>>;
  createMany?: Maybe<CashClosingCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<CashClosingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CashClosingScalarWhereInput>>;
  disconnect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  set?: Maybe<Array<CashClosingWhereUniqueInput>>;
  update?: Maybe<Array<CashClosingUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<CashClosingUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<CashClosingUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type CashClosingUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CashClosingCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<CashClosingCreateWithoutEmployeeInput>>;
  createMany?: Maybe<CashClosingCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<CashClosingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CashClosingScalarWhereInput>>;
  disconnect?: Maybe<Array<CashClosingWhereUniqueInput>>;
  set?: Maybe<Array<CashClosingWhereUniqueInput>>;
  update?: Maybe<Array<CashClosingUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<CashClosingUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<CashClosingUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type CashClosingUpdateManyWithWhereWithoutBranchInput = {
  data: CashClosingUpdateManyMutationInput;
  where: CashClosingScalarWhereInput;
};

export type CashClosingUpdateManyWithWhereWithoutEmployeeInput = {
  data: CashClosingUpdateManyMutationInput;
  where: CashClosingScalarWhereInput;
};

export type CashClosingUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutCashClosingInput>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  startAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutCashClosingInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  endAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  startAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CashClosingUpdateWithWhereUniqueWithoutBranchInput = {
  data: CashClosingUpdateWithoutBranchInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: CashClosingUpdateWithoutEmployeeInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingUpsertWithWhereUniqueWithoutBranchInput = {
  create: CashClosingCreateWithoutBranchInput;
  update: CashClosingUpdateWithoutBranchInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: CashClosingCreateWithoutEmployeeInput;
  update: CashClosingUpdateWithoutEmployeeInput;
  where: CashClosingWhereUniqueInput;
};

export type CashClosingWhereInput = {
  AND?: Maybe<Array<CashClosingWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  end?: Maybe<DateTimeNullableFilter>;
  endAmount?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CashClosingWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<CashClosingWhereInput>>;
  start?: Maybe<DateTimeFilter>;
  startAmount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CashClosingWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChartDay = {
  __typename?: 'ChartDay';
  day?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type ChartMonth = {
  __typename?: 'ChartMonth';
  month?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Float']>;
};

export type ChartPayload = {
  __typename?: 'ChartPayload';
  year1: Array<ChartMonth>;
  year2: Array<ChartMonth>;
};

export type Chat = {
  __typename?: 'Chat';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastMessage?: Maybe<ChatMessage>;
  messages: Array<ChatMessage>;
  other?: Maybe<ChatParticipant>;
  participants: Array<ChatParticipant>;
  type: ChatType;
  unreadMessages?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ChatMessagesArgs = {
  cursor?: Maybe<ChatMessageWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ChatParticipantsArgs = {
  cursor?: Maybe<ChatParticipantWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ChatCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutChatInput>;
  participants?: Maybe<ChatParticipantCreateNestedManyWithoutChatsInput>;
  type: ChatType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatCreateNestedManyWithoutParticipantsInput = {
  connect?: Maybe<Array<ChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatCreateOrConnectWithoutParticipantsInput>>;
  create?: Maybe<Array<ChatCreateWithoutParticipantsInput>>;
};

export type ChatCreateNestedOneWithoutMessagesInput = {
  connect?: Maybe<ChatWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChatCreateWithoutMessagesInput>;
};

export type ChatCreateOrConnectWithoutMessagesInput = {
  create: ChatCreateWithoutMessagesInput;
  where: ChatWhereUniqueInput;
};

export type ChatCreateOrConnectWithoutParticipantsInput = {
  create: ChatCreateWithoutParticipantsInput;
  where: ChatWhereUniqueInput;
};

export type ChatCreateWithoutMessagesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<ChatParticipantCreateNestedManyWithoutChatsInput>;
  type: ChatType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatCreateWithoutParticipantsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutChatInput>;
  type: ChatType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatListRelationFilter = {
  every?: Maybe<ChatWhereInput>;
  none?: Maybe<ChatWhereInput>;
  some?: Maybe<ChatWhereInput>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chat: Chat;
  content: Scalars['String'];
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  from: ChatParticipant;
  id: Scalars['String'];
  isAttachment: Scalars['Boolean'];
  read: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ChatMessageCreateInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chat: ChatCreateNestedOneWithoutMessagesInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  from: ChatParticipantCreateNestedOneWithoutMessagesInput;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageCreateManyChatInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  fromId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageCreateManyChatInputEnvelope = {
  data?: Maybe<Array<ChatMessageCreateManyChatInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChatMessageCreateManyFromInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chatId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageCreateManyFromInputEnvelope = {
  data?: Maybe<Array<ChatMessageCreateManyFromInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ChatMessageCreateNestedManyWithoutChatInput = {
  connect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatMessageCreateOrConnectWithoutChatInput>>;
  create?: Maybe<Array<ChatMessageCreateWithoutChatInput>>;
  createMany?: Maybe<ChatMessageCreateManyChatInputEnvelope>;
};

export type ChatMessageCreateNestedManyWithoutFromInput = {
  connect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatMessageCreateOrConnectWithoutFromInput>>;
  create?: Maybe<Array<ChatMessageCreateWithoutFromInput>>;
  createMany?: Maybe<ChatMessageCreateManyFromInputEnvelope>;
};

export type ChatMessageCreateOrConnectWithoutChatInput = {
  create: ChatMessageCreateWithoutChatInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageCreateOrConnectWithoutFromInput = {
  create: ChatMessageCreateWithoutFromInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageCreateWithoutChatInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  from: ChatParticipantCreateNestedOneWithoutMessagesInput;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageCreateWithoutFromInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chat: ChatCreateNestedOneWithoutMessagesInput;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageListRelationFilter = {
  every?: Maybe<ChatMessageWhereInput>;
  none?: Maybe<ChatMessageWhereInput>;
  some?: Maybe<ChatMessageWhereInput>;
};

export type ChatMessageOrderByInput = {
  attachmentMimetype?: Maybe<SortOrder>;
  attachmentName?: Maybe<SortOrder>;
  chatId?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  fromId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isAttachment?: Maybe<SortOrder>;
  read?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChatMessageScalarWhereInput = {
  AND?: Maybe<Array<ChatMessageScalarWhereInput>>;
  attachmentMimetype?: Maybe<StringNullableFilter>;
  attachmentName?: Maybe<StringNullableFilter>;
  chatId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  fromId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAttachment?: Maybe<BoolFilter>;
  NOT?: Maybe<Array<ChatMessageScalarWhereInput>>;
  OR?: Maybe<Array<ChatMessageScalarWhereInput>>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChatMessageUpdateInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chat?: Maybe<ChatUpdateOneRequiredWithoutMessagesInput>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<ChatParticipantUpdateOneRequiredWithoutMessagesInput>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageUpdateManyMutationInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageUpdateManyWithoutChatInput = {
  connect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatMessageCreateOrConnectWithoutChatInput>>;
  create?: Maybe<Array<ChatMessageCreateWithoutChatInput>>;
  createMany?: Maybe<ChatMessageCreateManyChatInputEnvelope>;
  delete?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChatMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChatMessageUpdateWithWhereUniqueWithoutChatInput>>;
  updateMany?: Maybe<Array<ChatMessageUpdateManyWithWhereWithoutChatInput>>;
  upsert?: Maybe<Array<ChatMessageUpsertWithWhereUniqueWithoutChatInput>>;
};

export type ChatMessageUpdateManyWithoutFromInput = {
  connect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatMessageCreateOrConnectWithoutFromInput>>;
  create?: Maybe<Array<ChatMessageCreateWithoutFromInput>>;
  createMany?: Maybe<ChatMessageCreateManyFromInputEnvelope>;
  delete?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChatMessageScalarWhereInput>>;
  disconnect?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  set?: Maybe<Array<ChatMessageWhereUniqueInput>>;
  update?: Maybe<Array<ChatMessageUpdateWithWhereUniqueWithoutFromInput>>;
  updateMany?: Maybe<Array<ChatMessageUpdateManyWithWhereWithoutFromInput>>;
  upsert?: Maybe<Array<ChatMessageUpsertWithWhereUniqueWithoutFromInput>>;
};

export type ChatMessageUpdateManyWithWhereWithoutChatInput = {
  data: ChatMessageUpdateManyMutationInput;
  where: ChatMessageScalarWhereInput;
};

export type ChatMessageUpdateManyWithWhereWithoutFromInput = {
  data: ChatMessageUpdateManyMutationInput;
  where: ChatMessageScalarWhereInput;
};

export type ChatMessageUpdateWithoutChatInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  from?: Maybe<ChatParticipantUpdateOneRequiredWithoutMessagesInput>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageUpdateWithoutFromInput = {
  attachmentMimetype?: Maybe<Scalars['String']>;
  attachmentName?: Maybe<Scalars['String']>;
  chat?: Maybe<ChatUpdateOneRequiredWithoutMessagesInput>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isAttachment?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatMessageUpdateWithWhereUniqueWithoutChatInput = {
  data: ChatMessageUpdateWithoutChatInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageUpdateWithWhereUniqueWithoutFromInput = {
  data: ChatMessageUpdateWithoutFromInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageUpsertWithWhereUniqueWithoutChatInput = {
  create: ChatMessageCreateWithoutChatInput;
  update: ChatMessageUpdateWithoutChatInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageUpsertWithWhereUniqueWithoutFromInput = {
  create: ChatMessageCreateWithoutFromInput;
  update: ChatMessageUpdateWithoutFromInput;
  where: ChatMessageWhereUniqueInput;
};

export type ChatMessageWhereInput = {
  AND?: Maybe<Array<ChatMessageWhereInput>>;
  attachmentMimetype?: Maybe<StringNullableFilter>;
  attachmentName?: Maybe<StringNullableFilter>;
  chat?: Maybe<ChatWhereInput>;
  chatId?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  from?: Maybe<ChatParticipantWhereInput>;
  fromId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  isAttachment?: Maybe<BoolFilter>;
  NOT?: Maybe<Array<ChatMessageWhereInput>>;
  OR?: Maybe<Array<ChatMessageWhereInput>>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChatMessageWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ChatOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChatParticipant = {
  __typename?: 'ChatParticipant';
  branch?: Maybe<Branch>;
  chats: Array<Chat>;
  createdAt: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  id: Scalars['String'];
  patient?: Maybe<Patient>;
  updatedAt: Scalars['DateTime'];
};

export type ChatParticipantChatsArgs = {
  cursor?: Maybe<ChatWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ChatParticipantCreateNestedManyWithoutChatsInput = {
  connect?: Maybe<Array<ChatParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatParticipantCreateOrConnectWithoutChatsInput>>;
  create?: Maybe<Array<ChatParticipantCreateWithoutChatsInput>>;
};

export type ChatParticipantCreateNestedOneWithoutBranchInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutBranchInput>;
  create?: Maybe<ChatParticipantCreateWithoutBranchInput>;
};

export type ChatParticipantCreateNestedOneWithoutEmployeeInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<ChatParticipantCreateWithoutEmployeeInput>;
};

export type ChatParticipantCreateNestedOneWithoutMessagesInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChatParticipantCreateWithoutMessagesInput>;
};

export type ChatParticipantCreateNestedOneWithoutPatientInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutPatientInput>;
  create?: Maybe<ChatParticipantCreateWithoutPatientInput>;
};

export type ChatParticipantCreateOrConnectWithoutBranchInput = {
  create: ChatParticipantCreateWithoutBranchInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantCreateOrConnectWithoutChatsInput = {
  create: ChatParticipantCreateWithoutChatsInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantCreateOrConnectWithoutEmployeeInput = {
  create: ChatParticipantCreateWithoutEmployeeInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantCreateOrConnectWithoutMessagesInput = {
  create: ChatParticipantCreateWithoutMessagesInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantCreateOrConnectWithoutPatientInput = {
  create: ChatParticipantCreateWithoutPatientInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantCreateWithoutBranchInput = {
  chats?: Maybe<ChatCreateNestedManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutFromInput>;
  patient?: Maybe<PatientCreateNestedOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantCreateWithoutChatsInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutChatParticipantInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutFromInput>;
  patient?: Maybe<PatientCreateNestedOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantCreateWithoutEmployeeInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatCreateNestedManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutFromInput>;
  patient?: Maybe<PatientCreateNestedOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantCreateWithoutMessagesInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatCreateNestedManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientCreateNestedOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantCreateWithoutPatientInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatCreateNestedManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageCreateNestedManyWithoutFromInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantListRelationFilter = {
  every?: Maybe<ChatParticipantWhereInput>;
  none?: Maybe<ChatParticipantWhereInput>;
  some?: Maybe<ChatParticipantWhereInput>;
};

export type ChatParticipantOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ChatParticipantScalarWhereInput = {
  AND?: Maybe<Array<ChatParticipantScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ChatParticipantScalarWhereInput>>;
  OR?: Maybe<Array<ChatParticipantScalarWhereInput>>;
  patientId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChatParticipantUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateManyWithoutChatsInput = {
  connect?: Maybe<Array<ChatParticipantWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatParticipantCreateOrConnectWithoutChatsInput>>;
  create?: Maybe<Array<ChatParticipantCreateWithoutChatsInput>>;
  delete?: Maybe<Array<ChatParticipantWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChatParticipantScalarWhereInput>>;
  disconnect?: Maybe<Array<ChatParticipantWhereUniqueInput>>;
  set?: Maybe<Array<ChatParticipantWhereUniqueInput>>;
  update?: Maybe<Array<ChatParticipantUpdateWithWhereUniqueWithoutChatsInput>>;
  updateMany?: Maybe<Array<ChatParticipantUpdateManyWithWhereWithoutChatsInput>>;
  upsert?: Maybe<Array<ChatParticipantUpsertWithWhereUniqueWithoutChatsInput>>;
};

export type ChatParticipantUpdateManyWithWhereWithoutChatsInput = {
  data: ChatParticipantUpdateManyMutationInput;
  where: ChatParticipantScalarWhereInput;
};

export type ChatParticipantUpdateOneRequiredWithoutMessagesInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChatParticipantCreateWithoutMessagesInput>;
  update?: Maybe<ChatParticipantUpdateWithoutMessagesInput>;
  upsert?: Maybe<ChatParticipantUpsertWithoutMessagesInput>;
};

export type ChatParticipantUpdateOneWithoutBranchInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutBranchInput>;
  create?: Maybe<ChatParticipantCreateWithoutBranchInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChatParticipantUpdateWithoutBranchInput>;
  upsert?: Maybe<ChatParticipantUpsertWithoutBranchInput>;
};

export type ChatParticipantUpdateOneWithoutEmployeeInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<ChatParticipantCreateWithoutEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChatParticipantUpdateWithoutEmployeeInput>;
  upsert?: Maybe<ChatParticipantUpsertWithoutEmployeeInput>;
};

export type ChatParticipantUpdateOneWithoutPatientInput = {
  connect?: Maybe<ChatParticipantWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatParticipantCreateOrConnectWithoutPatientInput>;
  create?: Maybe<ChatParticipantCreateWithoutPatientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ChatParticipantUpdateWithoutPatientInput>;
  upsert?: Maybe<ChatParticipantUpsertWithoutPatientInput>;
};

export type ChatParticipantUpdateWithoutBranchInput = {
  chats?: Maybe<ChatUpdateManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutFromInput>;
  patient?: Maybe<PatientUpdateOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateWithoutChatsInput = {
  branch?: Maybe<BranchUpdateOneWithoutChatParticipantInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutFromInput>;
  patient?: Maybe<PatientUpdateOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatUpdateManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutFromInput>;
  patient?: Maybe<PatientUpdateOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateWithoutMessagesInput = {
  branch?: Maybe<BranchUpdateOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatUpdateManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneWithoutChatParticipantInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateWithoutPatientInput = {
  branch?: Maybe<BranchUpdateOneWithoutChatParticipantInput>;
  chats?: Maybe<ChatUpdateManyWithoutParticipantsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutChatParticipantInput>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutFromInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatParticipantUpdateWithWhereUniqueWithoutChatsInput = {
  data: ChatParticipantUpdateWithoutChatsInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantUpsertWithoutBranchInput = {
  create: ChatParticipantCreateWithoutBranchInput;
  update: ChatParticipantUpdateWithoutBranchInput;
};

export type ChatParticipantUpsertWithoutEmployeeInput = {
  create: ChatParticipantCreateWithoutEmployeeInput;
  update: ChatParticipantUpdateWithoutEmployeeInput;
};

export type ChatParticipantUpsertWithoutMessagesInput = {
  create: ChatParticipantCreateWithoutMessagesInput;
  update: ChatParticipantUpdateWithoutMessagesInput;
};

export type ChatParticipantUpsertWithoutPatientInput = {
  create: ChatParticipantCreateWithoutPatientInput;
  update: ChatParticipantUpdateWithoutPatientInput;
};

export type ChatParticipantUpsertWithWhereUniqueWithoutChatsInput = {
  create: ChatParticipantCreateWithoutChatsInput;
  update: ChatParticipantUpdateWithoutChatsInput;
  where: ChatParticipantWhereUniqueInput;
};

export type ChatParticipantWhereInput = {
  AND?: Maybe<Array<ChatParticipantWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  chats?: Maybe<ChatListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  messages?: Maybe<ChatMessageListRelationFilter>;
  NOT?: Maybe<Array<ChatParticipantWhereInput>>;
  OR?: Maybe<Array<ChatParticipantWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChatParticipantWhereUniqueInput = {
  branchId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type ChatScalarWhereInput = {
  AND?: Maybe<Array<ChatScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ChatScalarWhereInput>>;
  OR?: Maybe<Array<ChatScalarWhereInput>>;
  type?: Maybe<EnumChatTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum ChatType {
  BRANCH_ON_PATIENT = 'BRANCH_ON_PATIENT',
  EMPLOYEE_ON_EMPLOYEE = 'EMPLOYEE_ON_EMPLOYEE'
}

export type ChatUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutChatInput>;
  participants?: Maybe<ChatParticipantUpdateManyWithoutChatsInput>;
  type?: Maybe<ChatType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ChatType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatUpdateManyWithoutParticipantsInput = {
  connect?: Maybe<Array<ChatWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ChatCreateOrConnectWithoutParticipantsInput>>;
  create?: Maybe<Array<ChatCreateWithoutParticipantsInput>>;
  delete?: Maybe<Array<ChatWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChatScalarWhereInput>>;
  disconnect?: Maybe<Array<ChatWhereUniqueInput>>;
  set?: Maybe<Array<ChatWhereUniqueInput>>;
  update?: Maybe<Array<ChatUpdateWithWhereUniqueWithoutParticipantsInput>>;
  updateMany?: Maybe<Array<ChatUpdateManyWithWhereWithoutParticipantsInput>>;
  upsert?: Maybe<Array<ChatUpsertWithWhereUniqueWithoutParticipantsInput>>;
};

export type ChatUpdateManyWithWhereWithoutParticipantsInput = {
  data: ChatUpdateManyMutationInput;
  where: ChatScalarWhereInput;
};

export type ChatUpdateOneRequiredWithoutMessagesInput = {
  connect?: Maybe<ChatWhereUniqueInput>;
  connectOrCreate?: Maybe<ChatCreateOrConnectWithoutMessagesInput>;
  create?: Maybe<ChatCreateWithoutMessagesInput>;
  update?: Maybe<ChatUpdateWithoutMessagesInput>;
  upsert?: Maybe<ChatUpsertWithoutMessagesInput>;
};

export type ChatUpdateWithoutMessagesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<ChatParticipantUpdateManyWithoutChatsInput>;
  type?: Maybe<ChatType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatUpdateWithoutParticipantsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  messages?: Maybe<ChatMessageUpdateManyWithoutChatInput>;
  type?: Maybe<ChatType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChatUpdateWithWhereUniqueWithoutParticipantsInput = {
  data: ChatUpdateWithoutParticipantsInput;
  where: ChatWhereUniqueInput;
};

export type ChatUpsertWithoutMessagesInput = {
  create: ChatCreateWithoutMessagesInput;
  update: ChatUpdateWithoutMessagesInput;
};

export type ChatUpsertWithWhereUniqueWithoutParticipantsInput = {
  create: ChatCreateWithoutParticipantsInput;
  update: ChatUpdateWithoutParticipantsInput;
  where: ChatWhereUniqueInput;
};

export type ChatWhereInput = {
  AND?: Maybe<Array<ChatWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  messages?: Maybe<ChatMessageListRelationFilter>;
  NOT?: Maybe<Array<ChatWhereInput>>;
  OR?: Maybe<Array<ChatWhereInput>>;
  participants?: Maybe<ChatParticipantListRelationFilter>;
  type?: Maybe<EnumChatTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ChatWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Commission = {
  __typename?: 'Commission';
  amount: Scalars['Float'];
  base: Scalars['Float'];
  category: CommissionCategory;
  commissionPercentage: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  materialAmount?: Maybe<Scalars['Float']>;
  paid: Scalars['Boolean'];
  payment: Payment;
  totalAmount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export enum CommissionCategory {
  INTEGRAL = 'INTEGRAL',
  ORTHODONTICS = 'ORTHODONTICS'
}

export type CommissionCreateManyPaymentInput = {
  amount: Scalars['Float'];
  base: Scalars['Float'];
  category: CommissionCategory;
  commissionPercentage: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Float']>;
  paid: Scalars['Boolean'];
  totalAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionCreateManyPaymentInputEnvelope = {
  data?: Maybe<Array<CommissionCreateManyPaymentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CommissionCreateNestedManyWithoutPaymentInput = {
  connect?: Maybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommissionCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<CommissionCreateWithoutPaymentInput>>;
  createMany?: Maybe<CommissionCreateManyPaymentInputEnvelope>;
};

export type CommissionCreateOrConnectWithoutPaymentInput = {
  create: CommissionCreateWithoutPaymentInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionCreateWithoutPaymentInput = {
  amount: Scalars['Float'];
  base: Scalars['Float'];
  category: CommissionCategory;
  commissionPercentage: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Float']>;
  paid: Scalars['Boolean'];
  totalAmount: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionGoalProgress = {
  __typename?: 'CommissionGoalProgress';
  month?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  target?: Maybe<Scalars['Float']>;
};

export type CommissionListRelationFilter = {
  every?: Maybe<CommissionWhereInput>;
  none?: Maybe<CommissionWhereInput>;
  some?: Maybe<CommissionWhereInput>;
};

export type CommissionOrderByInput = {
  amount?: Maybe<SortOrder>;
  base?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  commissionPercentage?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  materialAmount?: Maybe<SortOrder>;
  paid?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  totalAmount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CommissionScalarWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<CommissionScalarWhereInput>>;
  base?: Maybe<FloatFilter>;
  category?: Maybe<EnumCommissionCategoryFilter>;
  commissionPercentage?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  materialAmount?: Maybe<FloatNullableFilter>;
  NOT?: Maybe<Array<CommissionScalarWhereInput>>;
  OR?: Maybe<Array<CommissionScalarWhereInput>>;
  paid?: Maybe<BoolFilter>;
  paymentId?: Maybe<StringFilter>;
  totalAmount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CommissionsReports = {
  __typename?: 'CommissionsReports';
  commissionsGoalProgressByMonth?: Maybe<Array<Maybe<CommissionGoalProgress>>>;
};

export type CommissionsReportsCommissionsGoalProgressByMonthArgs = {
  year: Scalars['Int'];
};

export type CommissionUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  base?: Maybe<Scalars['Float']>;
  category?: Maybe<CommissionCategory>;
  commissionPercentage?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Boolean']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutCommissionsInput>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  base?: Maybe<Scalars['Float']>;
  category?: Maybe<CommissionCategory>;
  commissionPercentage?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Boolean']>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionUpdateManyWithoutPaymentInput = {
  connect?: Maybe<Array<CommissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CommissionCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<CommissionCreateWithoutPaymentInput>>;
  createMany?: Maybe<CommissionCreateManyPaymentInputEnvelope>;
  delete?: Maybe<Array<CommissionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CommissionScalarWhereInput>>;
  disconnect?: Maybe<Array<CommissionWhereUniqueInput>>;
  set?: Maybe<Array<CommissionWhereUniqueInput>>;
  update?: Maybe<Array<CommissionUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<CommissionUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: Maybe<Array<CommissionUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type CommissionUpdateManyWithWhereWithoutPaymentInput = {
  data: CommissionUpdateManyMutationInput;
  where: CommissionScalarWhereInput;
};

export type CommissionUpdateWithoutPaymentInput = {
  amount?: Maybe<Scalars['Float']>;
  base?: Maybe<Scalars['Float']>;
  category?: Maybe<CommissionCategory>;
  commissionPercentage?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materialAmount?: Maybe<Scalars['Float']>;
  paid?: Maybe<Scalars['Boolean']>;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommissionUpdateWithWhereUniqueWithoutPaymentInput = {
  data: CommissionUpdateWithoutPaymentInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionUpsertWithWhereUniqueWithoutPaymentInput = {
  create: CommissionCreateWithoutPaymentInput;
  update: CommissionUpdateWithoutPaymentInput;
  where: CommissionWhereUniqueInput;
};

export type CommissionWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<CommissionWhereInput>>;
  base?: Maybe<FloatFilter>;
  category?: Maybe<EnumCommissionCategoryFilter>;
  commissionPercentage?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  materialAmount?: Maybe<FloatNullableFilter>;
  NOT?: Maybe<Array<CommissionWhereInput>>;
  OR?: Maybe<Array<CommissionWhereInput>>;
  paid?: Maybe<BoolFilter>;
  payment?: Maybe<PaymentWhereInput>;
  paymentId?: Maybe<StringFilter>;
  totalAmount?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CommissionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ComparisonField = {
  __typename?: 'ComparisonField';
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

export type ContextualNotification = {
  __typename?: 'ContextualNotification';
  announcement?: Maybe<Announcement>;
  category?: Maybe<Scalars['String']>;
  chatMessage?: Maybe<ChatMessage>;
  message?: Maybe<Scalars['String']>;
  notification?: Maybe<Notification>;
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ContextualPatientNotification = {
  __typename?: 'ContextualPatientNotification';
  category?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notification?: Maybe<PatientNotification>;
  patients?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type Contract = {
  __typename?: 'Contract';
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  doctor: Employee;
  doctorAddress: Address;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: Patient;
  patientAddress: Address;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uploader: Employee;
};

export type ContractCreateInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: EmployeeCreateNestedOneWithoutContractsAsDoctorInput;
  doctorAddress: AddressCreateNestedOneWithoutContractDoctorAddressInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: PatientCreateNestedOneWithoutContractsInput;
  patientAddress: AddressCreateNestedOneWithoutContractPatientAddressInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutContractsUploadedInput;
};

export type ContractCreateManyDoctorInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorAddressId: Scalars['String'];
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patientAddressId: Scalars['String'];
  patientId: Scalars['String'];
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploaderId: Scalars['String'];
};

export type ContractCreateManyDoctorInputEnvelope = {
  data?: Maybe<Array<ContractCreateManyDoctorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContractCreateManyPatientInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorAddressId: Scalars['String'];
  doctorId: Scalars['String'];
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patientAddressId: Scalars['String'];
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploaderId: Scalars['String'];
};

export type ContractCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<ContractCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContractCreateManyUploaderInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorAddressId: Scalars['String'];
  doctorId: Scalars['String'];
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patientAddressId: Scalars['String'];
  patientId: Scalars['String'];
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractCreateManyUploaderInputEnvelope = {
  data?: Maybe<Array<ContractCreateManyUploaderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ContractCreateNestedManyWithoutDoctorInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutDoctorInput>>;
  create?: Maybe<Array<ContractCreateWithoutDoctorInput>>;
  createMany?: Maybe<ContractCreateManyDoctorInputEnvelope>;
};

export type ContractCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<ContractCreateWithoutPatientInput>>;
  createMany?: Maybe<ContractCreateManyPatientInputEnvelope>;
};

export type ContractCreateNestedManyWithoutUploaderInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<ContractCreateWithoutUploaderInput>>;
  createMany?: Maybe<ContractCreateManyUploaderInputEnvelope>;
};

export type ContractCreateNestedOneWithoutDoctorAddressInput = {
  connect?: Maybe<ContractWhereUniqueInput>;
  connectOrCreate?: Maybe<ContractCreateOrConnectWithoutDoctorAddressInput>;
  create?: Maybe<ContractCreateWithoutDoctorAddressInput>;
};

export type ContractCreateNestedOneWithoutPatientAddressInput = {
  connect?: Maybe<ContractWhereUniqueInput>;
  connectOrCreate?: Maybe<ContractCreateOrConnectWithoutPatientAddressInput>;
  create?: Maybe<ContractCreateWithoutPatientAddressInput>;
};

export type ContractCreateOrConnectWithoutDoctorAddressInput = {
  create: ContractCreateWithoutDoctorAddressInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateOrConnectWithoutDoctorInput = {
  create: ContractCreateWithoutDoctorInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateOrConnectWithoutPatientAddressInput = {
  create: ContractCreateWithoutPatientAddressInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateOrConnectWithoutPatientInput = {
  create: ContractCreateWithoutPatientInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateOrConnectWithoutUploaderInput = {
  create: ContractCreateWithoutUploaderInput;
  where: ContractWhereUniqueInput;
};

export type ContractCreateWithoutDoctorAddressInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: EmployeeCreateNestedOneWithoutContractsAsDoctorInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: PatientCreateNestedOneWithoutContractsInput;
  patientAddress: AddressCreateNestedOneWithoutContractPatientAddressInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutContractsUploadedInput;
};

export type ContractCreateWithoutDoctorInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorAddress: AddressCreateNestedOneWithoutContractDoctorAddressInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: PatientCreateNestedOneWithoutContractsInput;
  patientAddress: AddressCreateNestedOneWithoutContractPatientAddressInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutContractsUploadedInput;
};

export type ContractCreateWithoutPatientAddressInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: EmployeeCreateNestedOneWithoutContractsAsDoctorInput;
  doctorAddress: AddressCreateNestedOneWithoutContractDoctorAddressInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: PatientCreateNestedOneWithoutContractsInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutContractsUploadedInput;
};

export type ContractCreateWithoutPatientInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: EmployeeCreateNestedOneWithoutContractsAsDoctorInput;
  doctorAddress: AddressCreateNestedOneWithoutContractDoctorAddressInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patientAddress: AddressCreateNestedOneWithoutContractPatientAddressInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader: EmployeeCreateNestedOneWithoutContractsUploadedInput;
};

export type ContractCreateWithoutUploaderInput = {
  areaSpecialty: Scalars['String'];
  branchName: Scalars['String'];
  coordinatorName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor: EmployeeCreateNestedOneWithoutContractsAsDoctorInput;
  doctorAddress: AddressCreateNestedOneWithoutContractDoctorAddressInput;
  doctorName: Scalars['String'];
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor: Scalars['Boolean'];
  legalRepresentativeName: Scalars['String'];
  paidInFull: Scalars['Boolean'];
  patient: PatientCreateNestedOneWithoutContractsInput;
  patientAddress: AddressCreateNestedOneWithoutContractPatientAddressInput;
  patientName: Scalars['String'];
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName: Scalars['String'];
  term: Scalars['Int'];
  totalAmount: Scalars['Float'];
  totalAmountInWords: Scalars['String'];
  treatmentStartDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractListRelationFilter = {
  every?: Maybe<ContractWhereInput>;
  none?: Maybe<ContractWhereInput>;
  some?: Maybe<ContractWhereInput>;
};

export type ContractOrderByInput = {
  areaSpecialty?: Maybe<SortOrder>;
  branchName?: Maybe<SortOrder>;
  coordinatorName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  doctorAddressId?: Maybe<SortOrder>;
  doctorId?: Maybe<SortOrder>;
  doctorName?: Maybe<SortOrder>;
  downpayment?: Maybe<SortOrder>;
  downpaymentInWords?: Maybe<SortOrder>;
  extraAppointmentCost?: Maybe<SortOrder>;
  extraAppointmentCostInWords?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isMinor?: Maybe<SortOrder>;
  legalRepresentativeName?: Maybe<SortOrder>;
  paidInFull?: Maybe<SortOrder>;
  patientAddressId?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  patientName?: Maybe<SortOrder>;
  payDay?: Maybe<SortOrder>;
  paymentDate?: Maybe<SortOrder>;
  serviceName?: Maybe<SortOrder>;
  term?: Maybe<SortOrder>;
  totalAmount?: Maybe<SortOrder>;
  totalAmountInWords?: Maybe<SortOrder>;
  treatmentStartDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploaderId?: Maybe<SortOrder>;
};

export type ContractScalarWhereInput = {
  AND?: Maybe<Array<ContractScalarWhereInput>>;
  areaSpecialty?: Maybe<StringFilter>;
  branchName?: Maybe<StringFilter>;
  coordinatorName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  doctorAddressId?: Maybe<StringFilter>;
  doctorId?: Maybe<StringFilter>;
  doctorName?: Maybe<StringFilter>;
  downpayment?: Maybe<FloatNullableFilter>;
  downpaymentInWords?: Maybe<StringNullableFilter>;
  extraAppointmentCost?: Maybe<FloatNullableFilter>;
  extraAppointmentCostInWords?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isMinor?: Maybe<BoolFilter>;
  legalRepresentativeName?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ContractScalarWhereInput>>;
  OR?: Maybe<Array<ContractScalarWhereInput>>;
  paidInFull?: Maybe<BoolFilter>;
  patientAddressId?: Maybe<StringFilter>;
  patientId?: Maybe<StringFilter>;
  patientName?: Maybe<StringFilter>;
  payDay?: Maybe<IntNullableFilter>;
  paymentDate?: Maybe<DateTimeNullableFilter>;
  serviceName?: Maybe<StringFilter>;
  term?: Maybe<IntFilter>;
  totalAmount?: Maybe<FloatFilter>;
  totalAmountInWords?: Maybe<StringFilter>;
  treatmentStartDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploaderId?: Maybe<StringFilter>;
};

export type ContractUpdateInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput>;
  doctorAddress?: Maybe<AddressUpdateOneRequiredWithoutContractDoctorAddressInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutContractsInput>;
  patientAddress?: Maybe<AddressUpdateOneRequiredWithoutContractPatientAddressInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutContractsUploadedInput>;
};

export type ContractUpdateManyMutationInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractUpdateManyWithoutDoctorInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutDoctorInput>>;
  create?: Maybe<Array<ContractCreateWithoutDoctorInput>>;
  createMany?: Maybe<ContractCreateManyDoctorInputEnvelope>;
  delete?: Maybe<Array<ContractWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ContractScalarWhereInput>>;
  disconnect?: Maybe<Array<ContractWhereUniqueInput>>;
  set?: Maybe<Array<ContractWhereUniqueInput>>;
  update?: Maybe<Array<ContractUpdateWithWhereUniqueWithoutDoctorInput>>;
  updateMany?: Maybe<Array<ContractUpdateManyWithWhereWithoutDoctorInput>>;
  upsert?: Maybe<Array<ContractUpsertWithWhereUniqueWithoutDoctorInput>>;
};

export type ContractUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<ContractCreateWithoutPatientInput>>;
  createMany?: Maybe<ContractCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<ContractWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ContractScalarWhereInput>>;
  disconnect?: Maybe<Array<ContractWhereUniqueInput>>;
  set?: Maybe<Array<ContractWhereUniqueInput>>;
  update?: Maybe<Array<ContractUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<ContractUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<ContractUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type ContractUpdateManyWithoutUploaderInput = {
  connect?: Maybe<Array<ContractWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ContractCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<ContractCreateWithoutUploaderInput>>;
  createMany?: Maybe<ContractCreateManyUploaderInputEnvelope>;
  delete?: Maybe<Array<ContractWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ContractScalarWhereInput>>;
  disconnect?: Maybe<Array<ContractWhereUniqueInput>>;
  set?: Maybe<Array<ContractWhereUniqueInput>>;
  update?: Maybe<Array<ContractUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: Maybe<Array<ContractUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: Maybe<Array<ContractUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type ContractUpdateManyWithWhereWithoutDoctorInput = {
  data: ContractUpdateManyMutationInput;
  where: ContractScalarWhereInput;
};

export type ContractUpdateManyWithWhereWithoutPatientInput = {
  data: ContractUpdateManyMutationInput;
  where: ContractScalarWhereInput;
};

export type ContractUpdateManyWithWhereWithoutUploaderInput = {
  data: ContractUpdateManyMutationInput;
  where: ContractScalarWhereInput;
};

export type ContractUpdateOneWithoutDoctorAddressInput = {
  connect?: Maybe<ContractWhereUniqueInput>;
  connectOrCreate?: Maybe<ContractCreateOrConnectWithoutDoctorAddressInput>;
  create?: Maybe<ContractCreateWithoutDoctorAddressInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ContractUpdateWithoutDoctorAddressInput>;
  upsert?: Maybe<ContractUpsertWithoutDoctorAddressInput>;
};

export type ContractUpdateOneWithoutPatientAddressInput = {
  connect?: Maybe<ContractWhereUniqueInput>;
  connectOrCreate?: Maybe<ContractCreateOrConnectWithoutPatientAddressInput>;
  create?: Maybe<ContractCreateWithoutPatientAddressInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ContractUpdateWithoutPatientAddressInput>;
  upsert?: Maybe<ContractUpsertWithoutPatientAddressInput>;
};

export type ContractUpdateWithoutDoctorAddressInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutContractsInput>;
  patientAddress?: Maybe<AddressUpdateOneRequiredWithoutContractPatientAddressInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutContractsUploadedInput>;
};

export type ContractUpdateWithoutDoctorInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorAddress?: Maybe<AddressUpdateOneRequiredWithoutContractDoctorAddressInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutContractsInput>;
  patientAddress?: Maybe<AddressUpdateOneRequiredWithoutContractPatientAddressInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutContractsUploadedInput>;
};

export type ContractUpdateWithoutPatientAddressInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput>;
  doctorAddress?: Maybe<AddressUpdateOneRequiredWithoutContractDoctorAddressInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutContractsInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutContractsUploadedInput>;
};

export type ContractUpdateWithoutPatientInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput>;
  doctorAddress?: Maybe<AddressUpdateOneRequiredWithoutContractDoctorAddressInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patientAddress?: Maybe<AddressUpdateOneRequiredWithoutContractPatientAddressInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneRequiredWithoutContractsUploadedInput>;
};

export type ContractUpdateWithoutUploaderInput = {
  areaSpecialty?: Maybe<Scalars['String']>;
  branchName?: Maybe<Scalars['String']>;
  coordinatorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctor?: Maybe<EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput>;
  doctorAddress?: Maybe<AddressUpdateOneRequiredWithoutContractDoctorAddressInput>;
  doctorName?: Maybe<Scalars['String']>;
  downpayment?: Maybe<Scalars['Float']>;
  downpaymentInWords?: Maybe<Scalars['String']>;
  extraAppointmentCost?: Maybe<Scalars['Float']>;
  extraAppointmentCostInWords?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isMinor?: Maybe<Scalars['Boolean']>;
  legalRepresentativeName?: Maybe<Scalars['String']>;
  paidInFull?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutContractsInput>;
  patientAddress?: Maybe<AddressUpdateOneRequiredWithoutContractPatientAddressInput>;
  patientName?: Maybe<Scalars['String']>;
  payDay?: Maybe<Scalars['Int']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  serviceName?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  totalAmountInWords?: Maybe<Scalars['String']>;
  treatmentStartDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContractUpdateWithWhereUniqueWithoutDoctorInput = {
  data: ContractUpdateWithoutDoctorInput;
  where: ContractWhereUniqueInput;
};

export type ContractUpdateWithWhereUniqueWithoutPatientInput = {
  data: ContractUpdateWithoutPatientInput;
  where: ContractWhereUniqueInput;
};

export type ContractUpdateWithWhereUniqueWithoutUploaderInput = {
  data: ContractUpdateWithoutUploaderInput;
  where: ContractWhereUniqueInput;
};

export type ContractUpsertWithoutDoctorAddressInput = {
  create: ContractCreateWithoutDoctorAddressInput;
  update: ContractUpdateWithoutDoctorAddressInput;
};

export type ContractUpsertWithoutPatientAddressInput = {
  create: ContractCreateWithoutPatientAddressInput;
  update: ContractUpdateWithoutPatientAddressInput;
};

export type ContractUpsertWithWhereUniqueWithoutDoctorInput = {
  create: ContractCreateWithoutDoctorInput;
  update: ContractUpdateWithoutDoctorInput;
  where: ContractWhereUniqueInput;
};

export type ContractUpsertWithWhereUniqueWithoutPatientInput = {
  create: ContractCreateWithoutPatientInput;
  update: ContractUpdateWithoutPatientInput;
  where: ContractWhereUniqueInput;
};

export type ContractUpsertWithWhereUniqueWithoutUploaderInput = {
  create: ContractCreateWithoutUploaderInput;
  update: ContractUpdateWithoutUploaderInput;
  where: ContractWhereUniqueInput;
};

export type ContractWhereInput = {
  AND?: Maybe<Array<ContractWhereInput>>;
  areaSpecialty?: Maybe<StringFilter>;
  branchName?: Maybe<StringFilter>;
  coordinatorName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  doctor?: Maybe<EmployeeWhereInput>;
  doctorAddress?: Maybe<AddressWhereInput>;
  doctorAddressId?: Maybe<StringFilter>;
  doctorId?: Maybe<StringFilter>;
  doctorName?: Maybe<StringFilter>;
  downpayment?: Maybe<FloatNullableFilter>;
  downpaymentInWords?: Maybe<StringNullableFilter>;
  extraAppointmentCost?: Maybe<FloatNullableFilter>;
  extraAppointmentCostInWords?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isMinor?: Maybe<BoolFilter>;
  legalRepresentativeName?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ContractWhereInput>>;
  OR?: Maybe<Array<ContractWhereInput>>;
  paidInFull?: Maybe<BoolFilter>;
  patient?: Maybe<PatientWhereInput>;
  patientAddress?: Maybe<AddressWhereInput>;
  patientAddressId?: Maybe<StringFilter>;
  patientId?: Maybe<StringFilter>;
  patientName?: Maybe<StringFilter>;
  payDay?: Maybe<IntNullableFilter>;
  paymentDate?: Maybe<DateTimeNullableFilter>;
  serviceName?: Maybe<StringFilter>;
  term?: Maybe<IntFilter>;
  totalAmount?: Maybe<FloatFilter>;
  totalAmountInWords?: Maybe<StringFilter>;
  treatmentStartDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploader?: Maybe<EmployeeWhereInput>;
  uploaderId?: Maybe<StringFilter>;
};

export type ContractWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  patient: Patient;
  paymentConcept: PaymentConcept;
  paymentConceptId: Scalars['String'];
  points: Scalars['Float'];
  redeemed: Scalars['Boolean'];
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  validFor: Scalars['String'];
};

export type CouponCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutCouponsInput;
  paymentConcept: PaymentConceptCreateNestedOneWithoutCouponInput;
  points: Scalars['Float'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor: Scalars['String'];
};

export type CouponCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConceptId: Scalars['String'];
  points: Scalars['Float'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor: Scalars['String'];
};

export type CouponCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<CouponCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CouponCreateManyPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  points: Scalars['Float'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor: Scalars['String'];
};

export type CouponCreateManyPaymentConceptInputEnvelope = {
  data?: Maybe<Array<CouponCreateManyPaymentConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CouponCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<CouponWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CouponCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<CouponCreateWithoutPatientInput>>;
  createMany?: Maybe<CouponCreateManyPatientInputEnvelope>;
};

export type CouponCreateNestedManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<CouponWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CouponCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<CouponCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<CouponCreateManyPaymentConceptInputEnvelope>;
};

export type CouponCreateOrConnectWithoutPatientInput = {
  create: CouponCreateWithoutPatientInput;
  where: CouponWhereUniqueInput;
};

export type CouponCreateOrConnectWithoutPaymentConceptInput = {
  create: CouponCreateWithoutPaymentConceptInput;
  where: CouponWhereUniqueInput;
};

export type CouponCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutCouponInput;
  points: Scalars['Float'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor: Scalars['String'];
};

export type CouponCreateWithoutPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutCouponsInput;
  points: Scalars['Float'];
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor: Scalars['String'];
};

export type CouponListRelationFilter = {
  every?: Maybe<CouponWhereInput>;
  none?: Maybe<CouponWhereInput>;
  some?: Maybe<CouponWhereInput>;
};

export type CouponOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  paymentConceptId?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  redeemed?: Maybe<SortOrder>;
  redeemedDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  validFor?: Maybe<SortOrder>;
};

export type CouponScalarWhereInput = {
  AND?: Maybe<Array<CouponScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CouponScalarWhereInput>>;
  OR?: Maybe<Array<CouponScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  paymentConceptId?: Maybe<StringFilter>;
  points?: Maybe<FloatFilter>;
  redeemed?: Maybe<BoolFilter>;
  redeemedDate?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  validFor?: Maybe<StringFilter>;
};

export type CouponUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutCouponsInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutCouponInput>;
  points?: Maybe<Scalars['Float']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor?: Maybe<Scalars['String']>;
};

export type CouponUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor?: Maybe<Scalars['String']>;
};

export type CouponUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<CouponWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CouponCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<CouponCreateWithoutPatientInput>>;
  createMany?: Maybe<CouponCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<CouponWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CouponScalarWhereInput>>;
  disconnect?: Maybe<Array<CouponWhereUniqueInput>>;
  set?: Maybe<Array<CouponWhereUniqueInput>>;
  update?: Maybe<Array<CouponUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<CouponUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<CouponUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type CouponUpdateManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<CouponWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CouponCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<CouponCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<CouponCreateManyPaymentConceptInputEnvelope>;
  delete?: Maybe<Array<CouponWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CouponScalarWhereInput>>;
  disconnect?: Maybe<Array<CouponWhereUniqueInput>>;
  set?: Maybe<Array<CouponWhereUniqueInput>>;
  update?: Maybe<Array<CouponUpdateWithWhereUniqueWithoutPaymentConceptInput>>;
  updateMany?: Maybe<Array<CouponUpdateManyWithWhereWithoutPaymentConceptInput>>;
  upsert?: Maybe<Array<CouponUpsertWithWhereUniqueWithoutPaymentConceptInput>>;
};

export type CouponUpdateManyWithWhereWithoutPatientInput = {
  data: CouponUpdateManyMutationInput;
  where: CouponScalarWhereInput;
};

export type CouponUpdateManyWithWhereWithoutPaymentConceptInput = {
  data: CouponUpdateManyMutationInput;
  where: CouponScalarWhereInput;
};

export type CouponUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutCouponInput>;
  points?: Maybe<Scalars['Float']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor?: Maybe<Scalars['String']>;
};

export type CouponUpdateWithoutPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutCouponsInput>;
  points?: Maybe<Scalars['Float']>;
  redeemed?: Maybe<Scalars['Boolean']>;
  redeemedDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validFor?: Maybe<Scalars['String']>;
};

export type CouponUpdateWithWhereUniqueWithoutPatientInput = {
  data: CouponUpdateWithoutPatientInput;
  where: CouponWhereUniqueInput;
};

export type CouponUpdateWithWhereUniqueWithoutPaymentConceptInput = {
  data: CouponUpdateWithoutPaymentConceptInput;
  where: CouponWhereUniqueInput;
};

export type CouponUpsertWithWhereUniqueWithoutPatientInput = {
  create: CouponCreateWithoutPatientInput;
  update: CouponUpdateWithoutPatientInput;
  where: CouponWhereUniqueInput;
};

export type CouponUpsertWithWhereUniqueWithoutPaymentConceptInput = {
  create: CouponCreateWithoutPaymentConceptInput;
  update: CouponUpdateWithoutPaymentConceptInput;
  where: CouponWhereUniqueInput;
};

export type CouponWhereInput = {
  AND?: Maybe<Array<CouponWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<CouponWhereInput>>;
  OR?: Maybe<Array<CouponWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  paymentConcept?: Maybe<PaymentConceptWhereInput>;
  paymentConceptId?: Maybe<StringFilter>;
  points?: Maybe<FloatFilter>;
  redeemed?: Maybe<BoolFilter>;
  redeemedDate?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  validFor?: Maybe<StringFilter>;
};

export type CouponWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Currency {
  MXN = 'MXN',
  USD = 'USD'
}

export type DashboardPayload = {
  __typename?: 'DashboardPayload';
  chartData?: Maybe<ChartPayload>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  personalGoals?: Maybe<Array<Maybe<Goal>>>;
  promotions?: Maybe<Array<Maybe<PaymentConcept>>>;
  stats: Array<StatPayload>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type Day = {
  __typename?: 'Day';
  createdAt: Scalars['DateTime'];
  day: Scalars['Int'];
  hourRanges: Array<HourRange>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DayHourRangesArgs = {
  cursor?: Maybe<HourRangeWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type DayCreateInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutDaysInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day: Scalars['Int'];
  employee: EmployeeCreateNestedOneWithoutScheduleInput;
  hourRanges?: Maybe<HourRangeCreateNestedManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  day: Scalars['Int'];
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<DayCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DayCreateManyEmployeeInput = {
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<DayCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type DayCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<DayWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DayCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<DayCreateWithoutBranchInput>>;
  createMany?: Maybe<DayCreateManyBranchInputEnvelope>;
};

export type DayCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<DayWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DayCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<DayCreateWithoutEmployeeInput>>;
  createMany?: Maybe<DayCreateManyEmployeeInputEnvelope>;
};

export type DayCreateOrConnectWithoutBranchInput = {
  create: DayCreateWithoutBranchInput;
  where: DayWhereUniqueInput;
};

export type DayCreateOrConnectWithoutEmployeeInput = {
  create: DayCreateWithoutEmployeeInput;
  where: DayWhereUniqueInput;
};

export type DayCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  day: Scalars['Int'];
  employee: EmployeeCreateNestedOneWithoutScheduleInput;
  hourRanges?: Maybe<HourRangeCreateNestedManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayCreateWithoutEmployeeInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutDaysInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day: Scalars['Int'];
  hourRanges?: Maybe<HourRangeCreateNestedManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayListRelationFilter = {
  every?: Maybe<DayWhereInput>;
  none?: Maybe<DayWhereInput>;
  some?: Maybe<DayWhereInput>;
};

export type DayOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  day?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DaysAvailability = {
  __typename?: 'DaysAvailability';
  appointments?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['DateTime']>;
};

export type DayScalarWhereInput = {
  AND?: Maybe<Array<DayScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  day?: Maybe<IntFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<DayScalarWhereInput>>;
  OR?: Maybe<Array<DayScalarWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DayUpdateInput = {
  branch?: Maybe<BranchUpdateOneWithoutDaysInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutScheduleInput>;
  hourRanges?: Maybe<HourRangeUpdateManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<DayWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DayCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<DayCreateWithoutBranchInput>>;
  createMany?: Maybe<DayCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<DayWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DayScalarWhereInput>>;
  disconnect?: Maybe<Array<DayWhereUniqueInput>>;
  set?: Maybe<Array<DayWhereUniqueInput>>;
  update?: Maybe<Array<DayUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<DayUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<DayUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type DayUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<DayWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<DayCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<DayCreateWithoutEmployeeInput>>;
  createMany?: Maybe<DayCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<DayWhereUniqueInput>>;
  deleteMany?: Maybe<Array<DayScalarWhereInput>>;
  disconnect?: Maybe<Array<DayWhereUniqueInput>>;
  set?: Maybe<Array<DayWhereUniqueInput>>;
  update?: Maybe<Array<DayUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<DayUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<DayUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type DayUpdateManyWithWhereWithoutBranchInput = {
  data: DayUpdateManyMutationInput;
  where: DayScalarWhereInput;
};

export type DayUpdateManyWithWhereWithoutEmployeeInput = {
  data: DayUpdateManyMutationInput;
  where: DayScalarWhereInput;
};

export type DayUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutScheduleInput>;
  hourRanges?: Maybe<HourRangeUpdateManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneWithoutDaysInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Int']>;
  hourRanges?: Maybe<HourRangeUpdateManyWithoutDayInput>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DayUpdateWithWhereUniqueWithoutBranchInput = {
  data: DayUpdateWithoutBranchInput;
  where: DayWhereUniqueInput;
};

export type DayUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: DayUpdateWithoutEmployeeInput;
  where: DayWhereUniqueInput;
};

export type DayUpsertWithWhereUniqueWithoutBranchInput = {
  create: DayCreateWithoutBranchInput;
  update: DayUpdateWithoutBranchInput;
  where: DayWhereUniqueInput;
};

export type DayUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: DayCreateWithoutEmployeeInput;
  update: DayUpdateWithoutEmployeeInput;
  where: DayWhereUniqueInput;
};

export type DayWhereInput = {
  AND?: Maybe<Array<DayWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  day?: Maybe<IntFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  hourRanges?: Maybe<HourRangeListRelationFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<DayWhereInput>>;
  OR?: Maybe<Array<DayWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DayWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type DoctorFile = {
  __typename?: 'DoctorFile';
  createdAt: Scalars['DateTime'];
  experience?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  medicalId: Scalars['String'];
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type DoctorFileCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutDoctorFileInput>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  medicalId: Scalars['String'];
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorFileCreateNestedOneWithoutEmployeeInput = {
  connect?: Maybe<DoctorFileWhereUniqueInput>;
  connectOrCreate?: Maybe<DoctorFileCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<DoctorFileCreateWithoutEmployeeInput>;
};

export type DoctorFileCreateOrConnectWithoutEmployeeInput = {
  create: DoctorFileCreateWithoutEmployeeInput;
  where: DoctorFileWhereUniqueInput;
};

export type DoctorFileCreateWithoutEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  medicalId: Scalars['String'];
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorFileOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  experience?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  medicalId?: Maybe<SortOrder>;
  specialty?: Maybe<SortOrder>;
  university?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DoctorFileUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutDoctorFileInput>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  medicalId?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorFileUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  medicalId?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorFileUpdateOneWithoutEmployeeInput = {
  connect?: Maybe<DoctorFileWhereUniqueInput>;
  connectOrCreate?: Maybe<DoctorFileCreateOrConnectWithoutEmployeeInput>;
  create?: Maybe<DoctorFileCreateWithoutEmployeeInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DoctorFileUpdateWithoutEmployeeInput>;
  upsert?: Maybe<DoctorFileUpsertWithoutEmployeeInput>;
};

export type DoctorFileUpdateWithoutEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  experience?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  medicalId?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DoctorFileUpsertWithoutEmployeeInput = {
  create: DoctorFileCreateWithoutEmployeeInput;
  update: DoctorFileUpdateWithoutEmployeeInput;
};

export type DoctorFileWhereInput = {
  AND?: Maybe<Array<DoctorFileWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  experience?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  medicalId?: Maybe<StringFilter>;
  NOT?: Maybe<Array<DoctorFileWhereInput>>;
  OR?: Maybe<Array<DoctorFileWhereInput>>;
  specialty?: Maybe<StringNullableFilter>;
  university?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DoctorFileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  kinship: Scalars['String'];
  name: Scalars['String'];
  patient: Patient;
  phone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EmergencyContactCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kinship: Scalars['String'];
  name: Scalars['String'];
  patient: PatientCreateNestedOneWithoutEmergencyContactInput;
  phone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyContactCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kinship: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyContactCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<EmergencyContactCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmergencyContactCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<EmergencyContactWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyContactCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EmergencyContactCreateWithoutPatientInput>>;
  createMany?: Maybe<EmergencyContactCreateManyPatientInputEnvelope>;
};

export type EmergencyContactCreateOrConnectWithoutPatientInput = {
  create: EmergencyContactCreateWithoutPatientInput;
  where: EmergencyContactWhereUniqueInput;
};

export type EmergencyContactCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kinship: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyContactListRelationFilter = {
  every?: Maybe<EmergencyContactWhereInput>;
  none?: Maybe<EmergencyContactWhereInput>;
  some?: Maybe<EmergencyContactWhereInput>;
};

export type EmergencyContactScalarWhereInput = {
  AND?: Maybe<Array<EmergencyContactScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kinship?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmergencyContactScalarWhereInput>>;
  OR?: Maybe<Array<EmergencyContactScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmergencyContactUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kinship?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyContactUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<EmergencyContactWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmergencyContactCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EmergencyContactCreateWithoutPatientInput>>;
  createMany?: Maybe<EmergencyContactCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<EmergencyContactWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmergencyContactScalarWhereInput>>;
  disconnect?: Maybe<Array<EmergencyContactWhereUniqueInput>>;
  set?: Maybe<Array<EmergencyContactWhereUniqueInput>>;
  update?: Maybe<Array<EmergencyContactUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<EmergencyContactUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<EmergencyContactUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type EmergencyContactUpdateManyWithWhereWithoutPatientInput = {
  data: EmergencyContactUpdateManyMutationInput;
  where: EmergencyContactScalarWhereInput;
};

export type EmergencyContactUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  kinship?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmergencyContactUpdateWithWhereUniqueWithoutPatientInput = {
  data: EmergencyContactUpdateWithoutPatientInput;
  where: EmergencyContactWhereUniqueInput;
};

export type EmergencyContactUpsertWithWhereUniqueWithoutPatientInput = {
  create: EmergencyContactCreateWithoutPatientInput;
  update: EmergencyContactUpdateWithoutPatientInput;
  where: EmergencyContactWhereUniqueInput;
};

export type EmergencyContactWhereInput = {
  AND?: Maybe<Array<EmergencyContactWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  kinship?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmergencyContactWhereInput>>;
  OR?: Maybe<Array<EmergencyContactWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmergencyContactWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  aboutMe?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  bankingFile?: Maybe<BankingFile>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches: Array<Branch>;
  chatParticipant?: Maybe<ChatParticipant>;
  commissionTotal?: Maybe<Scalars['Float']>;
  contractsAsDoctor: Array<Contract>;
  contractsUploaded: Array<Contract>;
  createdAt: Scalars['DateTime'];
  doctorFile?: Maybe<DoctorFile>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes: Array<EvolutionNote>;
  goals: Array<Goal>;
  id: Scalars['String'];
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<Interest>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameWithTitle?: Maybe<Scalars['String']>;
  notifications: Array<NotificationRecipient>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  ratings: Array<EmployeeRating>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule: Array<Day>;
  sex?: Maybe<Sex>;
  updatedAt: Scalars['DateTime'];
  uploadedLogs: Array<Log>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeBranchesArgs = {
  cursor?: Maybe<BranchWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeCommissionTotalArgs = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type EmployeeContractsAsDoctorArgs = {
  cursor?: Maybe<ContractWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeContractsUploadedArgs = {
  cursor?: Maybe<ContractWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeEvolutionNotesArgs = {
  cursor?: Maybe<EvolutionNoteWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeGoalsArgs = {
  cursor?: Maybe<GoalWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeNotificationsArgs = {
  cursor?: Maybe<NotificationRecipientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeRatingsArgs = {
  cursor?: Maybe<EmployeeRatingWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeScheduleArgs = {
  cursor?: Maybe<DayWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeUploadedLogsArgs = {
  cursor?: Maybe<LogWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateManyAddressInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  bankingFileId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorFileId?: Maybe<Scalars['String']>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interestId?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateManyAddressInputEnvelope = {
  data?: Maybe<Array<EmployeeCreateManyAddressInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmployeeCreateManyInterestInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  addressId?: Maybe<Scalars['String']>;
  bankingFileId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorFileId?: Maybe<Scalars['String']>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateManyInterestInputEnvelope = {
  data?: Maybe<Array<EmployeeCreateManyInterestInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmployeeCreateNestedManyWithoutAddressInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutAddressInput>>;
  createMany?: Maybe<EmployeeCreateManyAddressInputEnvelope>;
};

export type EmployeeCreateNestedManyWithoutBranchesInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutBranchesInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutBranchesInput>>;
};

export type EmployeeCreateNestedManyWithoutInterestInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutInterestInput>>;
  createMany?: Maybe<EmployeeCreateManyInterestInputEnvelope>;
};

export type EmployeeCreateNestedOneWithoutAnnouncementsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAnnouncementsInput>;
  create?: Maybe<EmployeeCreateWithoutAnnouncementsInput>;
};

export type EmployeeCreateNestedOneWithoutAnnouncementViewsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAnnouncementViewsInput>;
  create?: Maybe<EmployeeCreateWithoutAnnouncementViewsInput>;
};

export type EmployeeCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<EmployeeCreateWithoutAppointmentsInput>;
};

export type EmployeeCreateNestedOneWithoutArchivedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutArchivedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutArchivedPaymentsInput>;
};

export type EmployeeCreateNestedOneWithoutArchivedRecievedAmountsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutArchivedRecievedAmountsInput>;
  create?: Maybe<EmployeeCreateWithoutArchivedRecievedAmountsInput>;
};

export type EmployeeCreateNestedOneWithoutBankingFileInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutBankingFileInput>;
  create?: Maybe<EmployeeCreateWithoutBankingFileInput>;
};

export type EmployeeCreateNestedOneWithoutCashClosingInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCashClosingInput>;
  create?: Maybe<EmployeeCreateWithoutCashClosingInput>;
};

export type EmployeeCreateNestedOneWithoutChatParticipantInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<EmployeeCreateWithoutChatParticipantInput>;
};

export type EmployeeCreateNestedOneWithoutCollectedAmountsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCollectedAmountsInput>;
  create?: Maybe<EmployeeCreateWithoutCollectedAmountsInput>;
};

export type EmployeeCreateNestedOneWithoutContractsAsDoctorInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutContractsAsDoctorInput>;
  create?: Maybe<EmployeeCreateWithoutContractsAsDoctorInput>;
};

export type EmployeeCreateNestedOneWithoutContractsUploadedInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutContractsUploadedInput>;
  create?: Maybe<EmployeeCreateWithoutContractsUploadedInput>;
};

export type EmployeeCreateNestedOneWithoutCreatedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCreatedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutCreatedPaymentsInput>;
};

export type EmployeeCreateNestedOneWithoutDoctorFileInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutDoctorFileInput>;
  create?: Maybe<EmployeeCreateWithoutDoctorFileInput>;
};

export type EmployeeCreateNestedOneWithoutEvolutionNotesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<EmployeeCreateWithoutEvolutionNotesInput>;
};

export type EmployeeCreateNestedOneWithoutExpensesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutExpensesInput>;
  create?: Maybe<EmployeeCreateWithoutExpensesInput>;
};

export type EmployeeCreateNestedOneWithoutGoalsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutGoalsInput>;
  create?: Maybe<EmployeeCreateWithoutGoalsInput>;
};

export type EmployeeCreateNestedOneWithoutLockedPeriodsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutLockedPeriodsInput>;
  create?: Maybe<EmployeeCreateWithoutLockedPeriodsInput>;
};

export type EmployeeCreateNestedOneWithoutNotificationsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<EmployeeCreateWithoutNotificationsInput>;
};

export type EmployeeCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<EmployeeCreateWithoutPrescriptionsInput>;
};

export type EmployeeCreateNestedOneWithoutRatingsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutRatingsInput>;
  create?: Maybe<EmployeeCreateWithoutRatingsInput>;
};

export type EmployeeCreateNestedOneWithoutRecievedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutRecievedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutRecievedPaymentsInput>;
};

export type EmployeeCreateNestedOneWithoutScheduleInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutScheduleInput>;
  create?: Maybe<EmployeeCreateWithoutScheduleInput>;
};

export type EmployeeCreateNestedOneWithoutUploadedFilesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutUploadedFilesInput>;
  create?: Maybe<EmployeeCreateWithoutUploadedFilesInput>;
};

export type EmployeeCreateNestedOneWithoutUploadedLogsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutUploadedLogsInput>;
  create?: Maybe<EmployeeCreateWithoutUploadedLogsInput>;
};

export type EmployeeCreateOrConnectWithoutAddressInput = {
  create: EmployeeCreateWithoutAddressInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutAnnouncementsInput = {
  create: EmployeeCreateWithoutAnnouncementsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutAnnouncementViewsInput = {
  create: EmployeeCreateWithoutAnnouncementViewsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutAppointmentsInput = {
  create: EmployeeCreateWithoutAppointmentsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutArchivedPaymentsInput = {
  create: EmployeeCreateWithoutArchivedPaymentsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutArchivedRecievedAmountsInput = {
  create: EmployeeCreateWithoutArchivedRecievedAmountsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutBankingFileInput = {
  create: EmployeeCreateWithoutBankingFileInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutBranchesInput = {
  create: EmployeeCreateWithoutBranchesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutCashClosingInput = {
  create: EmployeeCreateWithoutCashClosingInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutChatParticipantInput = {
  create: EmployeeCreateWithoutChatParticipantInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutCollectedAmountsInput = {
  create: EmployeeCreateWithoutCollectedAmountsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutContractsAsDoctorInput = {
  create: EmployeeCreateWithoutContractsAsDoctorInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutContractsUploadedInput = {
  create: EmployeeCreateWithoutContractsUploadedInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutCreatedPaymentsInput = {
  create: EmployeeCreateWithoutCreatedPaymentsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutDoctorFileInput = {
  create: EmployeeCreateWithoutDoctorFileInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutEvolutionNotesInput = {
  create: EmployeeCreateWithoutEvolutionNotesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutExpensesInput = {
  create: EmployeeCreateWithoutExpensesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutGoalsInput = {
  create: EmployeeCreateWithoutGoalsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutInterestInput = {
  create: EmployeeCreateWithoutInterestInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutLockedPeriodsInput = {
  create: EmployeeCreateWithoutLockedPeriodsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutNotificationsInput = {
  create: EmployeeCreateWithoutNotificationsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutPrescriptionsInput = {
  create: EmployeeCreateWithoutPrescriptionsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutRatingsInput = {
  create: EmployeeCreateWithoutRatingsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutRecievedPaymentsInput = {
  create: EmployeeCreateWithoutRecievedPaymentsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutScheduleInput = {
  create: EmployeeCreateWithoutScheduleInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutUploadedFilesInput = {
  create: EmployeeCreateWithoutUploadedFilesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateOrConnectWithoutUploadedLogsInput = {
  create: EmployeeCreateWithoutUploadedLogsInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeCreateWithoutAddressInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutAnnouncementsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutAnnouncementViewsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutAppointmentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutArchivedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutArchivedRecievedAmountsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutBankingFileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutBranchesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutCashClosingInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutChatParticipantInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutCollectedAmountsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutContractsAsDoctorInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutContractsUploadedInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutCreatedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutDoctorFileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutEvolutionNotesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutExpensesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutGoalsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutInterestInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutLockedPeriodsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutNotificationsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutPrescriptionsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutRatingsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutRecievedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutScheduleInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutUploadedFilesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedLogs?: Maybe<LogCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateWithoutUploadedLogsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementCreateNestedManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewCreateNestedManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentCreateNestedManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileCreateNestedOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchCreateNestedManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingCreateNestedManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractCreateNestedManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractCreateNestedManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentCreateNestedManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileCreateNestedOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseCreateNestedManyWithoutEmployeeInput>;
  goals?: Maybe<GoalCreateNestedManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestCreateNestedOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodCreateNestedManyWithoutEmployeeInput>;
  name: Scalars['String'];
  notifications?: Maybe<NotificationRecipientCreateNestedManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentCreateNestedManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role: Role;
  schedule?: Maybe<DayCreateNestedManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileCreateNestedManyWithoutUploaderInput>;
  username: Scalars['String'];
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeListRelationFilter = {
  every?: Maybe<EmployeeWhereInput>;
  none?: Maybe<EmployeeWhereInput>;
  some?: Maybe<EmployeeWhereInput>;
};

export type EmployeeOrderByInput = {
  aboutMe?: Maybe<SortOrder>;
  active?: Maybe<SortOrder>;
  addressId?: Maybe<SortOrder>;
  bankingFileId?: Maybe<SortOrder>;
  birthDate?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  doctorFileId?: Maybe<SortOrder>;
  educationLevel?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  integralCommissionPercentage?: Maybe<SortOrder>;
  interestId?: Maybe<SortOrder>;
  jobTitle?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  orthodonticsCommissionPercentage?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  rfc?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  sex?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  vacationDays?: Maybe<SortOrder>;
};

export type EmployeeRating = {
  __typename?: 'EmployeeRating';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['String'];
  patient: Patient;
  rating: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type EmployeeRatingCreateInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutRatingsInput;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutRatingsInput;
  rating: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingCreateManyEmployeeInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  rating: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<EmployeeRatingCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmployeeRatingCreateManyPatientInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<EmployeeRatingCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EmployeeRatingCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeRatingCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<EmployeeRatingCreateWithoutEmployeeInput>>;
  createMany?: Maybe<EmployeeRatingCreateManyEmployeeInputEnvelope>;
};

export type EmployeeRatingCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeRatingCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EmployeeRatingCreateWithoutPatientInput>>;
  createMany?: Maybe<EmployeeRatingCreateManyPatientInputEnvelope>;
};

export type EmployeeRatingCreateOrConnectWithoutEmployeeInput = {
  create: EmployeeRatingCreateWithoutEmployeeInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingCreateOrConnectWithoutPatientInput = {
  create: EmployeeRatingCreateWithoutPatientInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingCreateWithoutEmployeeInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutRatingsInput;
  rating: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingCreateWithoutPatientInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutRatingsInput;
  id?: Maybe<Scalars['String']>;
  rating: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingEmployeeIdPatientIdCompoundUniqueInput = {
  employeeId: Scalars['String'];
  patientId: Scalars['String'];
};

export type EmployeeRatingListRelationFilter = {
  every?: Maybe<EmployeeRatingWhereInput>;
  none?: Maybe<EmployeeRatingWhereInput>;
  some?: Maybe<EmployeeRatingWhereInput>;
};

export type EmployeeRatingOrderByInput = {
  comment?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  rating?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EmployeeRatingScalarWhereInput = {
  AND?: Maybe<Array<EmployeeRatingScalarWhereInput>>;
  comment?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmployeeRatingScalarWhereInput>>;
  OR?: Maybe<Array<EmployeeRatingScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  rating?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmployeeRatingUpdateInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutRatingsInput>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutRatingsInput>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingUpdateManyMutationInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeRatingCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<EmployeeRatingCreateWithoutEmployeeInput>>;
  createMany?: Maybe<EmployeeRatingCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmployeeRatingScalarWhereInput>>;
  disconnect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  set?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  update?: Maybe<Array<EmployeeRatingUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<EmployeeRatingUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<EmployeeRatingUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type EmployeeRatingUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeRatingCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EmployeeRatingCreateWithoutPatientInput>>;
  createMany?: Maybe<EmployeeRatingCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmployeeRatingScalarWhereInput>>;
  disconnect?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  set?: Maybe<Array<EmployeeRatingWhereUniqueInput>>;
  update?: Maybe<Array<EmployeeRatingUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<EmployeeRatingUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<EmployeeRatingUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type EmployeeRatingUpdateManyWithWhereWithoutEmployeeInput = {
  data: EmployeeRatingUpdateManyMutationInput;
  where: EmployeeRatingScalarWhereInput;
};

export type EmployeeRatingUpdateManyWithWhereWithoutPatientInput = {
  data: EmployeeRatingUpdateManyMutationInput;
  where: EmployeeRatingScalarWhereInput;
};

export type EmployeeRatingUpdateWithoutEmployeeInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutRatingsInput>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingUpdateWithoutPatientInput = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutRatingsInput>;
  id?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmployeeRatingUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: EmployeeRatingUpdateWithoutEmployeeInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingUpdateWithWhereUniqueWithoutPatientInput = {
  data: EmployeeRatingUpdateWithoutPatientInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: EmployeeRatingCreateWithoutEmployeeInput;
  update: EmployeeRatingUpdateWithoutEmployeeInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingUpsertWithWhereUniqueWithoutPatientInput = {
  create: EmployeeRatingCreateWithoutPatientInput;
  update: EmployeeRatingUpdateWithoutPatientInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type EmployeeRatingWhereInput = {
  AND?: Maybe<Array<EmployeeRatingWhereInput>>;
  comment?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmployeeRatingWhereInput>>;
  OR?: Maybe<Array<EmployeeRatingWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  rating?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmployeeRatingWhereUniqueInput = {
  employeeId_patientId?: Maybe<EmployeeRatingEmployeeIdPatientIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type EmployeeScalarWhereInput = {
  aboutMe?: Maybe<StringNullableFilter>;
  active?: Maybe<BoolFilter>;
  addressId?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<EmployeeScalarWhereInput>>;
  bankingFileId?: Maybe<StringNullableFilter>;
  birthDate?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  doctorFileId?: Maybe<StringNullableFilter>;
  educationLevel?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  integralCommissionPercentage?: Maybe<FloatNullableFilter>;
  interestId?: Maybe<StringNullableFilter>;
  jobTitle?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmployeeScalarWhereInput>>;
  OR?: Maybe<Array<EmployeeScalarWhereInput>>;
  orthodonticsCommissionPercentage?: Maybe<FloatNullableFilter>;
  password?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  rfc?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumRoleFilter>;
  sex?: Maybe<EnumSexNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
  vacationDays?: Maybe<IntNullableFilter>;
};

export type EmployeeUpdateInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateManyMutationInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateManyWithoutAddressInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutAddressInput>>;
  createMany?: Maybe<EmployeeCreateManyAddressInputEnvelope>;
  delete?: Maybe<Array<EmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  set?: Maybe<Array<EmployeeWhereUniqueInput>>;
  update?: Maybe<Array<EmployeeUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<EmployeeUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: Maybe<Array<EmployeeUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type EmployeeUpdateManyWithoutBranchesInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutBranchesInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutBranchesInput>>;
  delete?: Maybe<Array<EmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  set?: Maybe<Array<EmployeeWhereUniqueInput>>;
  update?: Maybe<Array<EmployeeUpdateWithWhereUniqueWithoutBranchesInput>>;
  updateMany?: Maybe<Array<EmployeeUpdateManyWithWhereWithoutBranchesInput>>;
  upsert?: Maybe<Array<EmployeeUpsertWithWhereUniqueWithoutBranchesInput>>;
};

export type EmployeeUpdateManyWithoutInterestInput = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EmployeeCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<EmployeeCreateWithoutInterestInput>>;
  createMany?: Maybe<EmployeeCreateManyInterestInputEnvelope>;
  delete?: Maybe<Array<EmployeeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EmployeeScalarWhereInput>>;
  disconnect?: Maybe<Array<EmployeeWhereUniqueInput>>;
  set?: Maybe<Array<EmployeeWhereUniqueInput>>;
  update?: Maybe<Array<EmployeeUpdateWithWhereUniqueWithoutInterestInput>>;
  updateMany?: Maybe<Array<EmployeeUpdateManyWithWhereWithoutInterestInput>>;
  upsert?: Maybe<Array<EmployeeUpsertWithWhereUniqueWithoutInterestInput>>;
};

export type EmployeeUpdateManyWithWhereWithoutAddressInput = {
  data: EmployeeUpdateManyMutationInput;
  where: EmployeeScalarWhereInput;
};

export type EmployeeUpdateManyWithWhereWithoutBranchesInput = {
  data: EmployeeUpdateManyMutationInput;
  where: EmployeeScalarWhereInput;
};

export type EmployeeUpdateManyWithWhereWithoutInterestInput = {
  data: EmployeeUpdateManyMutationInput;
  where: EmployeeScalarWhereInput;
};

export type EmployeeUpdateOneRequiredWithoutAnnouncementsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAnnouncementsInput>;
  create?: Maybe<EmployeeCreateWithoutAnnouncementsInput>;
  update?: Maybe<EmployeeUpdateWithoutAnnouncementsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutAnnouncementsInput>;
};

export type EmployeeUpdateOneRequiredWithoutAnnouncementViewsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAnnouncementViewsInput>;
  create?: Maybe<EmployeeCreateWithoutAnnouncementViewsInput>;
  update?: Maybe<EmployeeUpdateWithoutAnnouncementViewsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutAnnouncementViewsInput>;
};

export type EmployeeUpdateOneRequiredWithoutAppointmentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<EmployeeCreateWithoutAppointmentsInput>;
  update?: Maybe<EmployeeUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutAppointmentsInput>;
};

export type EmployeeUpdateOneRequiredWithoutCashClosingInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCashClosingInput>;
  create?: Maybe<EmployeeCreateWithoutCashClosingInput>;
  update?: Maybe<EmployeeUpdateWithoutCashClosingInput>;
  upsert?: Maybe<EmployeeUpsertWithoutCashClosingInput>;
};

export type EmployeeUpdateOneRequiredWithoutContractsAsDoctorInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutContractsAsDoctorInput>;
  create?: Maybe<EmployeeCreateWithoutContractsAsDoctorInput>;
  update?: Maybe<EmployeeUpdateWithoutContractsAsDoctorInput>;
  upsert?: Maybe<EmployeeUpsertWithoutContractsAsDoctorInput>;
};

export type EmployeeUpdateOneRequiredWithoutContractsUploadedInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutContractsUploadedInput>;
  create?: Maybe<EmployeeCreateWithoutContractsUploadedInput>;
  update?: Maybe<EmployeeUpdateWithoutContractsUploadedInput>;
  upsert?: Maybe<EmployeeUpsertWithoutContractsUploadedInput>;
};

export type EmployeeUpdateOneRequiredWithoutEvolutionNotesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<EmployeeCreateWithoutEvolutionNotesInput>;
  update?: Maybe<EmployeeUpdateWithoutEvolutionNotesInput>;
  upsert?: Maybe<EmployeeUpsertWithoutEvolutionNotesInput>;
};

export type EmployeeUpdateOneRequiredWithoutExpensesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutExpensesInput>;
  create?: Maybe<EmployeeCreateWithoutExpensesInput>;
  update?: Maybe<EmployeeUpdateWithoutExpensesInput>;
  upsert?: Maybe<EmployeeUpsertWithoutExpensesInput>;
};

export type EmployeeUpdateOneRequiredWithoutLockedPeriodsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutLockedPeriodsInput>;
  create?: Maybe<EmployeeCreateWithoutLockedPeriodsInput>;
  update?: Maybe<EmployeeUpdateWithoutLockedPeriodsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutLockedPeriodsInput>;
};

export type EmployeeUpdateOneRequiredWithoutNotificationsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<EmployeeCreateWithoutNotificationsInput>;
  update?: Maybe<EmployeeUpdateWithoutNotificationsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutNotificationsInput>;
};

export type EmployeeUpdateOneRequiredWithoutPrescriptionsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<EmployeeCreateWithoutPrescriptionsInput>;
  update?: Maybe<EmployeeUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutPrescriptionsInput>;
};

export type EmployeeUpdateOneRequiredWithoutRatingsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutRatingsInput>;
  create?: Maybe<EmployeeCreateWithoutRatingsInput>;
  update?: Maybe<EmployeeUpdateWithoutRatingsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutRatingsInput>;
};

export type EmployeeUpdateOneRequiredWithoutScheduleInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutScheduleInput>;
  create?: Maybe<EmployeeCreateWithoutScheduleInput>;
  update?: Maybe<EmployeeUpdateWithoutScheduleInput>;
  upsert?: Maybe<EmployeeUpsertWithoutScheduleInput>;
};

export type EmployeeUpdateOneWithoutArchivedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutArchivedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutArchivedPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutArchivedPaymentsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutArchivedPaymentsInput>;
};

export type EmployeeUpdateOneWithoutArchivedRecievedAmountsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutArchivedRecievedAmountsInput>;
  create?: Maybe<EmployeeCreateWithoutArchivedRecievedAmountsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutArchivedRecievedAmountsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutArchivedRecievedAmountsInput>;
};

export type EmployeeUpdateOneWithoutBankingFileInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutBankingFileInput>;
  create?: Maybe<EmployeeCreateWithoutBankingFileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutBankingFileInput>;
  upsert?: Maybe<EmployeeUpsertWithoutBankingFileInput>;
};

export type EmployeeUpdateOneWithoutChatParticipantInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<EmployeeCreateWithoutChatParticipantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutChatParticipantInput>;
  upsert?: Maybe<EmployeeUpsertWithoutChatParticipantInput>;
};

export type EmployeeUpdateOneWithoutCollectedAmountsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCollectedAmountsInput>;
  create?: Maybe<EmployeeCreateWithoutCollectedAmountsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutCollectedAmountsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutCollectedAmountsInput>;
};

export type EmployeeUpdateOneWithoutCreatedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutCreatedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutCreatedPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutCreatedPaymentsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutCreatedPaymentsInput>;
};

export type EmployeeUpdateOneWithoutDoctorFileInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutDoctorFileInput>;
  create?: Maybe<EmployeeCreateWithoutDoctorFileInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutDoctorFileInput>;
  upsert?: Maybe<EmployeeUpsertWithoutDoctorFileInput>;
};

export type EmployeeUpdateOneWithoutGoalsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutGoalsInput>;
  create?: Maybe<EmployeeCreateWithoutGoalsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutGoalsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutGoalsInput>;
};

export type EmployeeUpdateOneWithoutRecievedPaymentsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutRecievedPaymentsInput>;
  create?: Maybe<EmployeeCreateWithoutRecievedPaymentsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutRecievedPaymentsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutRecievedPaymentsInput>;
};

export type EmployeeUpdateOneWithoutUploadedFilesInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutUploadedFilesInput>;
  create?: Maybe<EmployeeCreateWithoutUploadedFilesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutUploadedFilesInput>;
  upsert?: Maybe<EmployeeUpsertWithoutUploadedFilesInput>;
};

export type EmployeeUpdateOneWithoutUploadedLogsInput = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutUploadedLogsInput>;
  create?: Maybe<EmployeeCreateWithoutUploadedLogsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUpdateWithoutUploadedLogsInput>;
  upsert?: Maybe<EmployeeUpsertWithoutUploadedLogsInput>;
};

export type EmployeeUpdateWithoutAddressInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutAnnouncementsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutAnnouncementViewsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutAppointmentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutArchivedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutArchivedRecievedAmountsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutBankingFileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutBranchesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutCashClosingInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutChatParticipantInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutCollectedAmountsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutContractsAsDoctorInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutContractsUploadedInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutCreatedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutDoctorFileInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutEvolutionNotesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutExpensesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutGoalsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutInterestInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutLockedPeriodsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutNotificationsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutPrescriptionsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutRatingsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutRecievedPaymentsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutScheduleInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutUploadedFilesInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedLogs?: Maybe<LogUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithoutUploadedLogsInput = {
  aboutMe?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutEmployeesInput>;
  announcements?: Maybe<AnnouncementUpdateManyWithoutUploaderInput>;
  announcementViews?: Maybe<AnnouncementViewUpdateManyWithoutEmployeeInput>;
  appointments?: Maybe<AppointmentUpdateManyWithoutEmployeeInput>;
  archivedPayments?: Maybe<PaymentUpdateManyWithoutArchivedByInput>;
  archivedRecievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutArchivedByInput>;
  bankingFile?: Maybe<BankingFileUpdateOneWithoutEmployeeInput>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branches?: Maybe<BranchUpdateManyWithoutEmployeesInput>;
  CashClosing?: Maybe<CashClosingUpdateManyWithoutEmployeeInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutEmployeeInput>;
  collectedAmounts?: Maybe<RecievedAmountUpdateManyWithoutEmployeeInput>;
  contractsAsDoctor?: Maybe<ContractUpdateManyWithoutDoctorInput>;
  contractsUploaded?: Maybe<ContractUpdateManyWithoutUploaderInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdPayments?: Maybe<PaymentUpdateManyWithoutEmployeeInput>;
  doctorFile?: Maybe<DoctorFileUpdateOneWithoutEmployeeInput>;
  educationLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutEmployeeInput>;
  expenses?: Maybe<ExpenseUpdateManyWithoutEmployeeInput>;
  goals?: Maybe<GoalUpdateManyWithoutEmployeeInput>;
  id?: Maybe<Scalars['String']>;
  integralCommissionPercentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<InterestUpdateOneWithoutEmployeesInput>;
  jobTitle?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  lockedPeriods?: Maybe<LockedPeriodUpdateManyWithoutEmployeeInput>;
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<NotificationRecipientUpdateManyWithoutEmployeeInput>;
  orthodonticsCommissionPercentage?: Maybe<Scalars['Float']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutEmployeeInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutEmployeeInput>;
  recievedPayments?: Maybe<PaymentUpdateManyWithoutRecieverInput>;
  rfc?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  schedule?: Maybe<DayUpdateManyWithoutEmployeeInput>;
  sex?: Maybe<Sex>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<FileUpdateManyWithoutUploaderInput>;
  username?: Maybe<Scalars['String']>;
  vacationDays?: Maybe<Scalars['Int']>;
};

export type EmployeeUpdateWithWhereUniqueWithoutAddressInput = {
  data: EmployeeUpdateWithoutAddressInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpdateWithWhereUniqueWithoutBranchesInput = {
  data: EmployeeUpdateWithoutBranchesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpdateWithWhereUniqueWithoutInterestInput = {
  data: EmployeeUpdateWithoutInterestInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpsertWithoutAnnouncementsInput = {
  create: EmployeeCreateWithoutAnnouncementsInput;
  update: EmployeeUpdateWithoutAnnouncementsInput;
};

export type EmployeeUpsertWithoutAnnouncementViewsInput = {
  create: EmployeeCreateWithoutAnnouncementViewsInput;
  update: EmployeeUpdateWithoutAnnouncementViewsInput;
};

export type EmployeeUpsertWithoutAppointmentsInput = {
  create: EmployeeCreateWithoutAppointmentsInput;
  update: EmployeeUpdateWithoutAppointmentsInput;
};

export type EmployeeUpsertWithoutArchivedPaymentsInput = {
  create: EmployeeCreateWithoutArchivedPaymentsInput;
  update: EmployeeUpdateWithoutArchivedPaymentsInput;
};

export type EmployeeUpsertWithoutArchivedRecievedAmountsInput = {
  create: EmployeeCreateWithoutArchivedRecievedAmountsInput;
  update: EmployeeUpdateWithoutArchivedRecievedAmountsInput;
};

export type EmployeeUpsertWithoutBankingFileInput = {
  create: EmployeeCreateWithoutBankingFileInput;
  update: EmployeeUpdateWithoutBankingFileInput;
};

export type EmployeeUpsertWithoutCashClosingInput = {
  create: EmployeeCreateWithoutCashClosingInput;
  update: EmployeeUpdateWithoutCashClosingInput;
};

export type EmployeeUpsertWithoutChatParticipantInput = {
  create: EmployeeCreateWithoutChatParticipantInput;
  update: EmployeeUpdateWithoutChatParticipantInput;
};

export type EmployeeUpsertWithoutCollectedAmountsInput = {
  create: EmployeeCreateWithoutCollectedAmountsInput;
  update: EmployeeUpdateWithoutCollectedAmountsInput;
};

export type EmployeeUpsertWithoutContractsAsDoctorInput = {
  create: EmployeeCreateWithoutContractsAsDoctorInput;
  update: EmployeeUpdateWithoutContractsAsDoctorInput;
};

export type EmployeeUpsertWithoutContractsUploadedInput = {
  create: EmployeeCreateWithoutContractsUploadedInput;
  update: EmployeeUpdateWithoutContractsUploadedInput;
};

export type EmployeeUpsertWithoutCreatedPaymentsInput = {
  create: EmployeeCreateWithoutCreatedPaymentsInput;
  update: EmployeeUpdateWithoutCreatedPaymentsInput;
};

export type EmployeeUpsertWithoutDoctorFileInput = {
  create: EmployeeCreateWithoutDoctorFileInput;
  update: EmployeeUpdateWithoutDoctorFileInput;
};

export type EmployeeUpsertWithoutEvolutionNotesInput = {
  create: EmployeeCreateWithoutEvolutionNotesInput;
  update: EmployeeUpdateWithoutEvolutionNotesInput;
};

export type EmployeeUpsertWithoutExpensesInput = {
  create: EmployeeCreateWithoutExpensesInput;
  update: EmployeeUpdateWithoutExpensesInput;
};

export type EmployeeUpsertWithoutGoalsInput = {
  create: EmployeeCreateWithoutGoalsInput;
  update: EmployeeUpdateWithoutGoalsInput;
};

export type EmployeeUpsertWithoutLockedPeriodsInput = {
  create: EmployeeCreateWithoutLockedPeriodsInput;
  update: EmployeeUpdateWithoutLockedPeriodsInput;
};

export type EmployeeUpsertWithoutNotificationsInput = {
  create: EmployeeCreateWithoutNotificationsInput;
  update: EmployeeUpdateWithoutNotificationsInput;
};

export type EmployeeUpsertWithoutPrescriptionsInput = {
  create: EmployeeCreateWithoutPrescriptionsInput;
  update: EmployeeUpdateWithoutPrescriptionsInput;
};

export type EmployeeUpsertWithoutRatingsInput = {
  create: EmployeeCreateWithoutRatingsInput;
  update: EmployeeUpdateWithoutRatingsInput;
};

export type EmployeeUpsertWithoutRecievedPaymentsInput = {
  create: EmployeeCreateWithoutRecievedPaymentsInput;
  update: EmployeeUpdateWithoutRecievedPaymentsInput;
};

export type EmployeeUpsertWithoutScheduleInput = {
  create: EmployeeCreateWithoutScheduleInput;
  update: EmployeeUpdateWithoutScheduleInput;
};

export type EmployeeUpsertWithoutUploadedFilesInput = {
  create: EmployeeCreateWithoutUploadedFilesInput;
  update: EmployeeUpdateWithoutUploadedFilesInput;
};

export type EmployeeUpsertWithoutUploadedLogsInput = {
  create: EmployeeCreateWithoutUploadedLogsInput;
  update: EmployeeUpdateWithoutUploadedLogsInput;
};

export type EmployeeUpsertWithWhereUniqueWithoutAddressInput = {
  create: EmployeeCreateWithoutAddressInput;
  update: EmployeeUpdateWithoutAddressInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpsertWithWhereUniqueWithoutBranchesInput = {
  create: EmployeeCreateWithoutBranchesInput;
  update: EmployeeUpdateWithoutBranchesInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeUpsertWithWhereUniqueWithoutInterestInput = {
  create: EmployeeCreateWithoutInterestInput;
  update: EmployeeUpdateWithoutInterestInput;
  where: EmployeeWhereUniqueInput;
};

export type EmployeeWhereInput = {
  aboutMe?: Maybe<StringNullableFilter>;
  active?: Maybe<BoolFilter>;
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<EmployeeWhereInput>>;
  announcements?: Maybe<AnnouncementListRelationFilter>;
  announcementViews?: Maybe<AnnouncementViewListRelationFilter>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  archivedPayments?: Maybe<PaymentListRelationFilter>;
  archivedRecievedAmounts?: Maybe<RecievedAmountListRelationFilter>;
  bankingFile?: Maybe<BankingFileWhereInput>;
  bankingFileId?: Maybe<StringNullableFilter>;
  birthDate?: Maybe<DateTimeNullableFilter>;
  branches?: Maybe<BranchListRelationFilter>;
  CashClosing?: Maybe<CashClosingListRelationFilter>;
  chatParticipant?: Maybe<ChatParticipantWhereInput>;
  collectedAmounts?: Maybe<RecievedAmountListRelationFilter>;
  contractsAsDoctor?: Maybe<ContractListRelationFilter>;
  contractsUploaded?: Maybe<ContractListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  createdPayments?: Maybe<PaymentListRelationFilter>;
  doctorFile?: Maybe<DoctorFileWhereInput>;
  doctorFileId?: Maybe<StringNullableFilter>;
  educationLevel?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  evolutionNotes?: Maybe<EvolutionNoteListRelationFilter>;
  expenses?: Maybe<ExpenseListRelationFilter>;
  goals?: Maybe<GoalListRelationFilter>;
  id?: Maybe<StringFilter>;
  integralCommissionPercentage?: Maybe<FloatNullableFilter>;
  interest?: Maybe<InterestWhereInput>;
  interestId?: Maybe<StringNullableFilter>;
  jobTitle?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  lockedPeriods?: Maybe<LockedPeriodListRelationFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EmployeeWhereInput>>;
  notifications?: Maybe<NotificationRecipientListRelationFilter>;
  OR?: Maybe<Array<EmployeeWhereInput>>;
  orthodonticsCommissionPercentage?: Maybe<FloatNullableFilter>;
  password?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  ratings?: Maybe<EmployeeRatingListRelationFilter>;
  recievedPayments?: Maybe<PaymentListRelationFilter>;
  rfc?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumRoleFilter>;
  schedule?: Maybe<DayListRelationFilter>;
  sex?: Maybe<EnumSexNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploadedFiles?: Maybe<FileListRelationFilter>;
  uploadedLogs?: Maybe<LogListRelationFilter>;
  username?: Maybe<StringFilter>;
  vacationDays?: Maybe<IntNullableFilter>;
};

export type EmployeeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type EnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type EnumChatTypeFilter = {
  equals?: Maybe<ChatType>;
  in?: Maybe<Array<ChatType>>;
  not?: Maybe<NestedEnumChatTypeFilter>;
  notIn?: Maybe<Array<ChatType>>;
};

export type EnumCommissionCategoryFilter = {
  equals?: Maybe<CommissionCategory>;
  in?: Maybe<Array<CommissionCategory>>;
  not?: Maybe<NestedEnumCommissionCategoryFilter>;
  notIn?: Maybe<Array<CommissionCategory>>;
};

export type EnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type EnumGoalLevelFilter = {
  equals?: Maybe<GoalLevel>;
  in?: Maybe<Array<GoalLevel>>;
  not?: Maybe<NestedEnumGoalLevelFilter>;
  notIn?: Maybe<Array<GoalLevel>>;
};

export type EnumGoalTypeFilter = {
  equals?: Maybe<GoalType>;
  in?: Maybe<Array<GoalType>>;
  not?: Maybe<NestedEnumGoalTypeFilter>;
  notIn?: Maybe<Array<GoalType>>;
};

export type EnumInvoiceDocumentTypeFilter = {
  equals?: Maybe<InvoiceDocumentType>;
  in?: Maybe<Array<InvoiceDocumentType>>;
  not?: Maybe<NestedEnumInvoiceDocumentTypeFilter>;
  notIn?: Maybe<Array<InvoiceDocumentType>>;
};

export type EnumInvoicePaymentMethodKeyFilter = {
  equals?: Maybe<InvoicePaymentMethodKey>;
  in?: Maybe<Array<InvoicePaymentMethodKey>>;
  not?: Maybe<NestedEnumInvoicePaymentMethodKeyFilter>;
  notIn?: Maybe<Array<InvoicePaymentMethodKey>>;
};

export type EnumMaritalStatusFilter = {
  equals?: Maybe<MaritalStatus>;
  in?: Maybe<Array<MaritalStatus>>;
  not?: Maybe<NestedEnumMaritalStatusFilter>;
  notIn?: Maybe<Array<MaritalStatus>>;
};

export type EnumNotificationCategoryFilter = {
  equals?: Maybe<NotificationCategory>;
  in?: Maybe<Array<NotificationCategory>>;
  not?: Maybe<NestedEnumNotificationCategoryFilter>;
  notIn?: Maybe<Array<NotificationCategory>>;
};

export type EnumNotificationLevelFilter = {
  equals?: Maybe<NotificationLevel>;
  in?: Maybe<Array<NotificationLevel>>;
  not?: Maybe<NestedEnumNotificationLevelFilter>;
  notIn?: Maybe<Array<NotificationLevel>>;
};

export type EnumPatientNotificationCategoryFilter = {
  equals?: Maybe<PatientNotificationCategory>;
  in?: Maybe<Array<PatientNotificationCategory>>;
  not?: Maybe<NestedEnumPatientNotificationCategoryFilter>;
  notIn?: Maybe<Array<PatientNotificationCategory>>;
};

export type EnumPaymentConceptTypeFilter = {
  equals?: Maybe<PaymentConceptType>;
  in?: Maybe<Array<PaymentConceptType>>;
  not?: Maybe<NestedEnumPaymentConceptTypeFilter>;
  notIn?: Maybe<Array<PaymentConceptType>>;
};

export type EnumPointStoryReasonFilter = {
  equals?: Maybe<PointStoryReason>;
  in?: Maybe<Array<PointStoryReason>>;
  not?: Maybe<NestedEnumPointStoryReasonFilter>;
  notIn?: Maybe<Array<PointStoryReason>>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type EnumServiceCategoryNullableFilter = {
  equals?: Maybe<ServiceCategory>;
  in?: Maybe<Array<ServiceCategory>>;
  not?: Maybe<NestedEnumServiceCategoryNullableFilter>;
  notIn?: Maybe<Array<ServiceCategory>>;
};

export type EnumSexFilter = {
  equals?: Maybe<Sex>;
  in?: Maybe<Array<Sex>>;
  not?: Maybe<NestedEnumSexFilter>;
  notIn?: Maybe<Array<Sex>>;
};

export type EnumSexNullableFilter = {
  equals?: Maybe<Sex>;
  in?: Maybe<Array<Sex>>;
  not?: Maybe<NestedEnumSexNullableFilter>;
  notIn?: Maybe<Array<Sex>>;
};

export type EnumTreatmentNullableFilter = {
  equals?: Maybe<Treatment>;
  in?: Maybe<Array<Treatment>>;
  not?: Maybe<NestedEnumTreatmentNullableFilter>;
  notIn?: Maybe<Array<Treatment>>;
};

export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EventCreateManyInterestInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventCreateManyInterestInputEnvelope = {
  data?: Maybe<Array<EventCreateManyInterestInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EventCreateNestedManyWithoutInterestInput = {
  connect?: Maybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<EventCreateWithoutInterestInput>>;
  createMany?: Maybe<EventCreateManyInterestInputEnvelope>;
};

export type EventCreateOrConnectWithoutInterestInput = {
  create: EventCreateWithoutInterestInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutInterestInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventListRelationFilter = {
  every?: Maybe<EventWhereInput>;
  none?: Maybe<EventWhereInput>;
  some?: Maybe<EventWhereInput>;
};

export type EventScalarWhereInput = {
  AND?: Maybe<Array<EventScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  interestId?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EventScalarWhereInput>>;
  OR?: Maybe<Array<EventScalarWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EventUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventUpdateManyWithoutInterestInput = {
  connect?: Maybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<EventCreateWithoutInterestInput>>;
  createMany?: Maybe<EventCreateManyInterestInputEnvelope>;
  delete?: Maybe<Array<EventWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EventScalarWhereInput>>;
  disconnect?: Maybe<Array<EventWhereUniqueInput>>;
  set?: Maybe<Array<EventWhereUniqueInput>>;
  update?: Maybe<Array<EventUpdateWithWhereUniqueWithoutInterestInput>>;
  updateMany?: Maybe<Array<EventUpdateManyWithWhereWithoutInterestInput>>;
  upsert?: Maybe<Array<EventUpsertWithWhereUniqueWithoutInterestInput>>;
};

export type EventUpdateManyWithWhereWithoutInterestInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateWithoutInterestInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventUpdateWithWhereUniqueWithoutInterestInput = {
  data: EventUpdateWithoutInterestInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutInterestInput = {
  create: EventCreateWithoutInterestInput;
  update: EventUpdateWithoutInterestInput;
  where: EventWhereUniqueInput;
};

export type EventWhereInput = {
  AND?: Maybe<Array<EventWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  date?: Maybe<DateTimeNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  interest?: Maybe<InterestWhereInput>;
  interestId?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EvolutionNote = {
  __typename?: 'EvolutionNote';
  ailments: Array<Ailment>;
  appointment?: Maybe<Appointment>;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['String'];
  observations?: Maybe<Scalars['String']>;
  patient: Patient;
  updatedAt: Scalars['DateTime'];
};

export type EvolutionNoteAilmentsArgs = {
  cursor?: Maybe<AilmentWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type EvolutionNoteCreateInput = {
  ailments?: Maybe<AilmentCreateNestedManyWithoutEvolutionNoteInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutEvolutionNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutEvolutionNotesInput;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutEvolutionNotesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateManyAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateManyAppointmentInputEnvelope = {
  data?: Maybe<Array<EvolutionNoteCreateManyAppointmentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EvolutionNoteCreateManyEmployeeInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<EvolutionNoteCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EvolutionNoteCreateManyPatientInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<EvolutionNoteCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EvolutionNoteCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutAppointmentInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyAppointmentInputEnvelope>;
};

export type EvolutionNoteCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutEmployeeInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyEmployeeInputEnvelope>;
};

export type EvolutionNoteCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutPatientInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyPatientInputEnvelope>;
};

export type EvolutionNoteCreateOrConnectWithoutAppointmentInput = {
  create: EvolutionNoteCreateWithoutAppointmentInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteCreateOrConnectWithoutEmployeeInput = {
  create: EvolutionNoteCreateWithoutEmployeeInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteCreateOrConnectWithoutPatientInput = {
  create: EvolutionNoteCreateWithoutPatientInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteCreateWithoutAppointmentInput = {
  ailments?: Maybe<AilmentCreateNestedManyWithoutEvolutionNoteInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutEvolutionNotesInput;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutEvolutionNotesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateWithoutEmployeeInput = {
  ailments?: Maybe<AilmentCreateNestedManyWithoutEvolutionNoteInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutEvolutionNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutEvolutionNotesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteCreateWithoutPatientInput = {
  ailments?: Maybe<AilmentCreateNestedManyWithoutEvolutionNoteInput>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutEvolutionNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutEvolutionNotesInput;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteListRelationFilter = {
  every?: Maybe<EvolutionNoteWhereInput>;
  none?: Maybe<EvolutionNoteWhereInput>;
  some?: Maybe<EvolutionNoteWhereInput>;
};

export type EvolutionNoteOrderByInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  observations?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EvolutionNoteScalarWhereInput = {
  AND?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EvolutionNoteUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteUpdateManyWithoutAppointmentInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutAppointmentInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  set?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  update?: Maybe<Array<EvolutionNoteUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<EvolutionNoteUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<EvolutionNoteUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type EvolutionNoteUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutEmployeeInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  set?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  update?: Maybe<Array<EvolutionNoteUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<EvolutionNoteUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<EvolutionNoteUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type EvolutionNoteUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EvolutionNoteCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<EvolutionNoteCreateWithoutPatientInput>>;
  createMany?: Maybe<EvolutionNoteCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  deleteMany?: Maybe<Array<EvolutionNoteScalarWhereInput>>;
  disconnect?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  set?: Maybe<Array<EvolutionNoteWhereUniqueInput>>;
  update?: Maybe<Array<EvolutionNoteUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<EvolutionNoteUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<EvolutionNoteUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type EvolutionNoteUpdateManyWithWhereWithoutAppointmentInput = {
  data: EvolutionNoteUpdateManyMutationInput;
  where: EvolutionNoteScalarWhereInput;
};

export type EvolutionNoteUpdateManyWithWhereWithoutEmployeeInput = {
  data: EvolutionNoteUpdateManyMutationInput;
  where: EvolutionNoteScalarWhereInput;
};

export type EvolutionNoteUpdateManyWithWhereWithoutPatientInput = {
  data: EvolutionNoteUpdateManyMutationInput;
  where: EvolutionNoteScalarWhereInput;
};

export type EvolutionNoteUpdateWithoutAppointmentInput = {
  ailments?: Maybe<AilmentUpdateManyWithoutEvolutionNoteInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutEvolutionNotesInput>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutEvolutionNotesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteUpdateWithoutEmployeeInput = {
  ailments?: Maybe<AilmentUpdateManyWithoutEvolutionNoteInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutEvolutionNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutEvolutionNotesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteUpdateWithoutPatientInput = {
  ailments?: Maybe<AilmentUpdateManyWithoutEvolutionNoteInput>;
  appointment?: Maybe<AppointmentUpdateOneWithoutEvolutionNotesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutEvolutionNotesInput>;
  id?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EvolutionNoteUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: EvolutionNoteUpdateWithoutAppointmentInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: EvolutionNoteUpdateWithoutEmployeeInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteUpdateWithWhereUniqueWithoutPatientInput = {
  data: EvolutionNoteUpdateWithoutPatientInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: EvolutionNoteCreateWithoutAppointmentInput;
  update: EvolutionNoteUpdateWithoutAppointmentInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: EvolutionNoteCreateWithoutEmployeeInput;
  update: EvolutionNoteUpdateWithoutEmployeeInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteUpsertWithWhereUniqueWithoutPatientInput = {
  create: EvolutionNoteCreateWithoutPatientInput;
  update: EvolutionNoteUpdateWithoutPatientInput;
  where: EvolutionNoteWhereUniqueInput;
};

export type EvolutionNoteWhereInput = {
  ailments?: Maybe<AilmentListRelationFilter>;
  AND?: Maybe<Array<EvolutionNoteWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<EvolutionNoteWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<EvolutionNoteWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EvolutionNoteWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Expense = {
  __typename?: 'Expense';
  amount: Scalars['Float'];
  branch: Branch;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ExpenseCreateInput = {
  amount: Scalars['Float'];
  branch: BranchCreateNestedOneWithoutExpensesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutExpensesInput;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseCreateManyBranchInput = {
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<ExpenseCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExpenseCreateManyEmployeeInput = {
  amount: Scalars['Float'];
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<ExpenseCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ExpenseCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExpenseCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<ExpenseCreateWithoutBranchInput>>;
  createMany?: Maybe<ExpenseCreateManyBranchInputEnvelope>;
};

export type ExpenseCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExpenseCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<ExpenseCreateWithoutEmployeeInput>>;
  createMany?: Maybe<ExpenseCreateManyEmployeeInputEnvelope>;
};

export type ExpenseCreateOrConnectWithoutBranchInput = {
  create: ExpenseCreateWithoutBranchInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateOrConnectWithoutEmployeeInput = {
  create: ExpenseCreateWithoutEmployeeInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateWithoutBranchInput = {
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutExpensesInput;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseCreateWithoutEmployeeInput = {
  amount: Scalars['Float'];
  branch: BranchCreateNestedOneWithoutExpensesInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseListRelationFilter = {
  every?: Maybe<ExpenseWhereInput>;
  none?: Maybe<ExpenseWhereInput>;
  some?: Maybe<ExpenseWhereInput>;
};

export type ExpenseOrderByInput = {
  amount?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  notes?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ExpenseScalarWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<ExpenseScalarWhereInput>>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ExpenseScalarWhereInput>>;
  notes?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<ExpenseScalarWhereInput>>;
  reason?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExpenseUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExpenseCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<ExpenseCreateWithoutBranchInput>>;
  createMany?: Maybe<ExpenseCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  set?: Maybe<Array<ExpenseWhereUniqueInput>>;
  update?: Maybe<Array<ExpenseUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<ExpenseUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<ExpenseUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type ExpenseUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ExpenseCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<ExpenseCreateWithoutEmployeeInput>>;
  createMany?: Maybe<ExpenseCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: Maybe<Array<ExpenseWhereUniqueInput>>;
  set?: Maybe<Array<ExpenseWhereUniqueInput>>;
  update?: Maybe<Array<ExpenseUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<ExpenseUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<ExpenseUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type ExpenseUpdateManyWithWhereWithoutBranchInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithWhereWithoutEmployeeInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateWithoutBranchInput = {
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutExpensesInput>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseUpdateWithoutEmployeeInput = {
  amount?: Maybe<Scalars['Float']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutExpensesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExpenseUpdateWithWhereUniqueWithoutBranchInput = {
  data: ExpenseUpdateWithoutBranchInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: ExpenseUpdateWithoutEmployeeInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpsertWithWhereUniqueWithoutBranchInput = {
  create: ExpenseCreateWithoutBranchInput;
  update: ExpenseUpdateWithoutBranchInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: ExpenseCreateWithoutEmployeeInput;
  update: ExpenseUpdateWithoutEmployeeInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<ExpenseWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<ExpenseWhereInput>>;
  notes?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<ExpenseWhereInput>>;
  reason?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ExpenseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FeeBreakdown = {
  __typename?: 'FeeBreakdown';
  amount?: Maybe<Scalars['Float']>;
  amountWithFee?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  filenameUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patient: Patient;
  updatedAt: Scalars['DateTime'];
  uploader?: Maybe<Employee>;
};

export type FileCreateInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patient: PatientCreateNestedOneWithoutFilesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeCreateNestedOneWithoutUploadedFilesInput>;
};

export type FileCreateManyAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patientId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploaderId?: Maybe<Scalars['String']>;
};

export type FileCreateManyAppointmentInputEnvelope = {
  data?: Maybe<Array<FileCreateManyAppointmentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileCreateManyPatientInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploaderId?: Maybe<Scalars['String']>;
};

export type FileCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<FileCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileCreateManyUploaderInput = {
  appointmentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patientId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileCreateManyUploaderInputEnvelope = {
  data?: Maybe<Array<FileCreateManyUploaderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type FileCreateNestedManyWithoutAppointmentInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FileCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FileCreateManyAppointmentInputEnvelope>;
};

export type FileCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<FileCreateWithoutPatientInput>>;
  createMany?: Maybe<FileCreateManyPatientInputEnvelope>;
};

export type FileCreateNestedManyWithoutUploaderInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<FileCreateWithoutUploaderInput>>;
  createMany?: Maybe<FileCreateManyUploaderInputEnvelope>;
};

export type FileCreateOrConnectWithoutAppointmentInput = {
  create: FileCreateWithoutAppointmentInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutPatientInput = {
  create: FileCreateWithoutPatientInput;
  where: FileWhereUniqueInput;
};

export type FileCreateOrConnectWithoutUploaderInput = {
  create: FileCreateWithoutUploaderInput;
  where: FileWhereUniqueInput;
};

export type FileCreateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patient: PatientCreateNestedOneWithoutFilesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeCreateNestedOneWithoutUploadedFilesInput>;
};

export type FileCreateWithoutPatientInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeCreateNestedOneWithoutUploadedFilesInput>;
};

export type FileCreateWithoutUploaderInput = {
  appointment?: Maybe<AppointmentCreateNestedOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  patient: PatientCreateNestedOneWithoutFilesInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileListRelationFilter = {
  every?: Maybe<FileWhereInput>;
  none?: Maybe<FileWhereInput>;
  some?: Maybe<FileWhereInput>;
};

export type FileOrderByInput = {
  appointmentId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  encoding?: Maybe<SortOrder>;
  filename?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mimetype?: Maybe<SortOrder>;
  path?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploaderId?: Maybe<SortOrder>;
};

export type FileScalarWhereInput = {
  AND?: Maybe<Array<FileScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  encoding?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<FileScalarWhereInput>>;
  OR?: Maybe<Array<FileScalarWhereInput>>;
  path?: Maybe<StringFilter>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploaderId?: Maybe<StringNullableFilter>;
};

export type FileUpdateInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutFilesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneWithoutUploadedFilesInput>;
};

export type FileUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateManyWithoutAppointmentInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutAppointmentInput>>;
  create?: Maybe<Array<FileCreateWithoutAppointmentInput>>;
  createMany?: Maybe<FileCreateManyAppointmentInputEnvelope>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutAppointmentInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutAppointmentInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutAppointmentInput>>;
};

export type FileUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<FileCreateWithoutPatientInput>>;
  createMany?: Maybe<FileCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type FileUpdateManyWithoutUploaderInput = {
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<FileCreateWithoutUploaderInput>>;
  createMany?: Maybe<FileCreateManyUploaderInputEnvelope>;
  delete?: Maybe<Array<FileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<FileScalarWhereInput>>;
  disconnect?: Maybe<Array<FileWhereUniqueInput>>;
  set?: Maybe<Array<FileWhereUniqueInput>>;
  update?: Maybe<Array<FileUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: Maybe<Array<FileUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: Maybe<Array<FileUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type FileUpdateManyWithWhereWithoutAppointmentInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutPatientInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateManyWithWhereWithoutUploaderInput = {
  data: FileUpdateManyMutationInput;
  where: FileScalarWhereInput;
};

export type FileUpdateWithoutAppointmentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutFilesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneWithoutUploadedFilesInput>;
};

export type FileUpdateWithoutPatientInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneWithoutUploadedFilesInput>;
};

export type FileUpdateWithoutUploaderInput = {
  appointment?: Maybe<AppointmentUpdateOneWithoutFilesInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutFilesInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileUpdateWithWhereUniqueWithoutAppointmentInput = {
  data: FileUpdateWithoutAppointmentInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutPatientInput = {
  data: FileUpdateWithoutPatientInput;
  where: FileWhereUniqueInput;
};

export type FileUpdateWithWhereUniqueWithoutUploaderInput = {
  data: FileUpdateWithoutUploaderInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutAppointmentInput = {
  create: FileCreateWithoutAppointmentInput;
  update: FileUpdateWithoutAppointmentInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutPatientInput = {
  create: FileCreateWithoutPatientInput;
  update: FileUpdateWithoutPatientInput;
  where: FileWhereUniqueInput;
};

export type FileUpsertWithWhereUniqueWithoutUploaderInput = {
  create: FileCreateWithoutUploaderInput;
  update: FileUpdateWithoutUploaderInput;
  where: FileWhereUniqueInput;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  encoding?: Maybe<StringNullableFilter>;
  filename?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  mimetype?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  path?: Maybe<StringFilter>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploader?: Maybe<EmployeeWhereInput>;
  uploaderId?: Maybe<StringNullableFilter>;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type GameSkin = {
  __typename?: 'GameSkin';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  owners: Array<Patient>;
  unlockCoins: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type GameSkinOwnersArgs = {
  cursor?: Maybe<PatientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type GameSkinCreateNestedManyWithoutOwnersInput = {
  connect?: Maybe<Array<GameSkinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GameSkinCreateOrConnectWithoutOwnersInput>>;
  create?: Maybe<Array<GameSkinCreateWithoutOwnersInput>>;
};

export type GameSkinCreateOrConnectWithoutOwnersInput = {
  create: GameSkinCreateWithoutOwnersInput;
  where: GameSkinWhereUniqueInput;
};

export type GameSkinCreateWithoutOwnersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  unlockCoins: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameSkinListRelationFilter = {
  every?: Maybe<GameSkinWhereInput>;
  none?: Maybe<GameSkinWhereInput>;
  some?: Maybe<GameSkinWhereInput>;
};

export type GameSkinOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  unlockCoins?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GameSkinScalarWhereInput = {
  AND?: Maybe<Array<GameSkinScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<GameSkinScalarWhereInput>>;
  OR?: Maybe<Array<GameSkinScalarWhereInput>>;
  unlockCoins?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GameSkinUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unlockCoins?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameSkinUpdateManyWithoutOwnersInput = {
  connect?: Maybe<Array<GameSkinWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GameSkinCreateOrConnectWithoutOwnersInput>>;
  create?: Maybe<Array<GameSkinCreateWithoutOwnersInput>>;
  delete?: Maybe<Array<GameSkinWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GameSkinScalarWhereInput>>;
  disconnect?: Maybe<Array<GameSkinWhereUniqueInput>>;
  set?: Maybe<Array<GameSkinWhereUniqueInput>>;
  update?: Maybe<Array<GameSkinUpdateWithWhereUniqueWithoutOwnersInput>>;
  updateMany?: Maybe<Array<GameSkinUpdateManyWithWhereWithoutOwnersInput>>;
  upsert?: Maybe<Array<GameSkinUpsertWithWhereUniqueWithoutOwnersInput>>;
};

export type GameSkinUpdateManyWithWhereWithoutOwnersInput = {
  data: GameSkinUpdateManyMutationInput;
  where: GameSkinScalarWhereInput;
};

export type GameSkinUpdateWithoutOwnersInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unlockCoins?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GameSkinUpdateWithWhereUniqueWithoutOwnersInput = {
  data: GameSkinUpdateWithoutOwnersInput;
  where: GameSkinWhereUniqueInput;
};

export type GameSkinUpsertWithWhereUniqueWithoutOwnersInput = {
  create: GameSkinCreateWithoutOwnersInput;
  update: GameSkinUpdateWithoutOwnersInput;
  where: GameSkinWhereUniqueInput;
};

export type GameSkinWhereInput = {
  AND?: Maybe<Array<GameSkinWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<GameSkinWhereInput>>;
  OR?: Maybe<Array<GameSkinWhereInput>>;
  owners?: Maybe<PatientListRelationFilter>;
  unlockCoins?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GameSkinWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GlobalIncomeByMetricPayload = {
  __typename?: 'GlobalIncomeByMetricPayload';
  category?: Maybe<Scalars['String']>;
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

export type Goal = {
  __typename?: 'Goal';
  branch?: Maybe<Branch>;
  createdAt: Scalars['DateTime'];
  due: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  id: Scalars['String'];
  level: GoalLevel;
  progress: Scalars['Float'];
  target: Scalars['Float'];
  type: GoalType;
  updatedAt: Scalars['DateTime'];
};

export type GoalCreateInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutGoalsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  due: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutGoalsInput>;
  id?: Maybe<Scalars['String']>;
  level: GoalLevel;
  target: Scalars['Float'];
  type: GoalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  due: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  level: GoalLevel;
  target: Scalars['Float'];
  type: GoalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<GoalCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GoalCreateManyEmployeeInput = {
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  due: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  level: GoalLevel;
  target: Scalars['Float'];
  type: GoalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<GoalCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GoalCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<GoalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GoalCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<GoalCreateWithoutBranchInput>>;
  createMany?: Maybe<GoalCreateManyBranchInputEnvelope>;
};

export type GoalCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<GoalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GoalCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<GoalCreateWithoutEmployeeInput>>;
  createMany?: Maybe<GoalCreateManyEmployeeInputEnvelope>;
};

export type GoalCreateOrConnectWithoutBranchInput = {
  create: GoalCreateWithoutBranchInput;
  where: GoalWhereUniqueInput;
};

export type GoalCreateOrConnectWithoutEmployeeInput = {
  create: GoalCreateWithoutEmployeeInput;
  where: GoalWhereUniqueInput;
};

export type GoalCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  due: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutGoalsInput>;
  id?: Maybe<Scalars['String']>;
  level: GoalLevel;
  target: Scalars['Float'];
  type: GoalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalCreateWithoutEmployeeInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutGoalsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  due: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  level: GoalLevel;
  target: Scalars['Float'];
  type: GoalType;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum GoalLevel {
  BRANCH = 'BRANCH',
  GLOBAL = 'GLOBAL',
  PERSONAL = 'PERSONAL'
}

export type GoalListRelationFilter = {
  every?: Maybe<GoalWhereInput>;
  none?: Maybe<GoalWhereInput>;
  some?: Maybe<GoalWhereInput>;
};

export type GoalOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  due?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  level?: Maybe<SortOrder>;
  target?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type GoalProgressOfBranch = {
  __typename?: 'GoalProgressOfBranch';
  percentage?: Maybe<Scalars['Float']>;
  progress?: Maybe<Scalars['Float']>;
  target?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type GoalScalarWhereInput = {
  AND?: Maybe<Array<GoalScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  due?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  level?: Maybe<EnumGoalLevelFilter>;
  NOT?: Maybe<Array<GoalScalarWhereInput>>;
  OR?: Maybe<Array<GoalScalarWhereInput>>;
  target?: Maybe<FloatFilter>;
  type?: Maybe<EnumGoalTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GoalsReports = {
  __typename?: 'GoalsReports';
  goalProgressByBranch?: Maybe<Array<Maybe<BranchGoalProgress>>>;
  goalProgressOfBranch?: Maybe<Array<Maybe<GoalProgressOfBranch>>>;
};

export type GoalsReportsGoalProgressByBranchArgs = {
  goalType: GoalType;
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type GoalsReportsGoalProgressOfBranchArgs = {
  branchId: Scalars['String'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export enum GoalType {
  COMMISSIONS = 'COMMISSIONS',
  INTEGRAL = 'INTEGRAL',
  INTEGRAL_COMMISSIONS = 'INTEGRAL_COMMISSIONS',
  ORTHODONTICS = 'ORTHODONTICS',
  ORTHODONTICS_COMMISSIONS = 'ORTHODONTICS_COMMISSIONS',
  SPECIALTY = 'SPECIALTY'
}

export type GoalUpdateInput = {
  branch?: Maybe<BranchUpdateOneWithoutGoalsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  due?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutGoalsInput>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<GoalLevel>;
  target?: Maybe<Scalars['Float']>;
  type?: Maybe<GoalType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  due?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<GoalLevel>;
  target?: Maybe<Scalars['Float']>;
  type?: Maybe<GoalType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<GoalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GoalCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<GoalCreateWithoutBranchInput>>;
  createMany?: Maybe<GoalCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<GoalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GoalScalarWhereInput>>;
  disconnect?: Maybe<Array<GoalWhereUniqueInput>>;
  set?: Maybe<Array<GoalWhereUniqueInput>>;
  update?: Maybe<Array<GoalUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<GoalUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<GoalUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type GoalUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<GoalWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<GoalCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<GoalCreateWithoutEmployeeInput>>;
  createMany?: Maybe<GoalCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<GoalWhereUniqueInput>>;
  deleteMany?: Maybe<Array<GoalScalarWhereInput>>;
  disconnect?: Maybe<Array<GoalWhereUniqueInput>>;
  set?: Maybe<Array<GoalWhereUniqueInput>>;
  update?: Maybe<Array<GoalUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<GoalUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<GoalUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type GoalUpdateManyWithWhereWithoutBranchInput = {
  data: GoalUpdateManyMutationInput;
  where: GoalScalarWhereInput;
};

export type GoalUpdateManyWithWhereWithoutEmployeeInput = {
  data: GoalUpdateManyMutationInput;
  where: GoalScalarWhereInput;
};

export type GoalUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  due?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutGoalsInput>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<GoalLevel>;
  target?: Maybe<Scalars['Float']>;
  type?: Maybe<GoalType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneWithoutGoalsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  due?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  level?: Maybe<GoalLevel>;
  target?: Maybe<Scalars['Float']>;
  type?: Maybe<GoalType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GoalUpdateWithWhereUniqueWithoutBranchInput = {
  data: GoalUpdateWithoutBranchInput;
  where: GoalWhereUniqueInput;
};

export type GoalUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: GoalUpdateWithoutEmployeeInput;
  where: GoalWhereUniqueInput;
};

export type GoalUpsertWithWhereUniqueWithoutBranchInput = {
  create: GoalCreateWithoutBranchInput;
  update: GoalUpdateWithoutBranchInput;
  where: GoalWhereUniqueInput;
};

export type GoalUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: GoalCreateWithoutEmployeeInput;
  update: GoalUpdateWithoutEmployeeInput;
  where: GoalWhereUniqueInput;
};

export type GoalWhereInput = {
  AND?: Maybe<Array<GoalWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  due?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  level?: Maybe<EnumGoalLevelFilter>;
  NOT?: Maybe<Array<GoalWhereInput>>;
  OR?: Maybe<Array<GoalWhereInput>>;
  target?: Maybe<FloatFilter>;
  type?: Maybe<EnumGoalTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GoalWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type HourRange = {
  __typename?: 'HourRange';
  createdAt: Scalars['DateTime'];
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  id: Scalars['String'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type HourRangeCreateManyDayInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endHour: Scalars['Int'];
  endMinute?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMinute?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HourRangeCreateManyDayInputEnvelope = {
  data?: Maybe<Array<HourRangeCreateManyDayInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type HourRangeCreateNestedManyWithoutDayInput = {
  connect?: Maybe<Array<HourRangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HourRangeCreateOrConnectWithoutDayInput>>;
  create?: Maybe<Array<HourRangeCreateWithoutDayInput>>;
  createMany?: Maybe<HourRangeCreateManyDayInputEnvelope>;
};

export type HourRangeCreateOrConnectWithoutDayInput = {
  create: HourRangeCreateWithoutDayInput;
  where: HourRangeWhereUniqueInput;
};

export type HourRangeCreateWithoutDayInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endHour: Scalars['Int'];
  endMinute?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  startHour: Scalars['Int'];
  startMinute?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HourRangeListRelationFilter = {
  every?: Maybe<HourRangeWhereInput>;
  none?: Maybe<HourRangeWhereInput>;
  some?: Maybe<HourRangeWhereInput>;
};

export type HourRangeScalarWhereInput = {
  AND?: Maybe<Array<HourRangeScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  dayId?: Maybe<StringFilter>;
  endHour?: Maybe<IntFilter>;
  endMinute?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<HourRangeScalarWhereInput>>;
  OR?: Maybe<Array<HourRangeScalarWhereInput>>;
  startHour?: Maybe<IntFilter>;
  startMinute?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type HourRangeUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['Int']>;
  endMinute?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  startHour?: Maybe<Scalars['Int']>;
  startMinute?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HourRangeUpdateManyWithoutDayInput = {
  connect?: Maybe<Array<HourRangeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<HourRangeCreateOrConnectWithoutDayInput>>;
  create?: Maybe<Array<HourRangeCreateWithoutDayInput>>;
  createMany?: Maybe<HourRangeCreateManyDayInputEnvelope>;
  delete?: Maybe<Array<HourRangeWhereUniqueInput>>;
  deleteMany?: Maybe<Array<HourRangeScalarWhereInput>>;
  disconnect?: Maybe<Array<HourRangeWhereUniqueInput>>;
  set?: Maybe<Array<HourRangeWhereUniqueInput>>;
  update?: Maybe<Array<HourRangeUpdateWithWhereUniqueWithoutDayInput>>;
  updateMany?: Maybe<Array<HourRangeUpdateManyWithWhereWithoutDayInput>>;
  upsert?: Maybe<Array<HourRangeUpsertWithWhereUniqueWithoutDayInput>>;
};

export type HourRangeUpdateManyWithWhereWithoutDayInput = {
  data: HourRangeUpdateManyMutationInput;
  where: HourRangeScalarWhereInput;
};

export type HourRangeUpdateWithoutDayInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endHour?: Maybe<Scalars['Int']>;
  endMinute?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  startHour?: Maybe<Scalars['Int']>;
  startMinute?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HourRangeUpdateWithWhereUniqueWithoutDayInput = {
  data: HourRangeUpdateWithoutDayInput;
  where: HourRangeWhereUniqueInput;
};

export type HourRangeUpsertWithWhereUniqueWithoutDayInput = {
  create: HourRangeCreateWithoutDayInput;
  update: HourRangeUpdateWithoutDayInput;
  where: HourRangeWhereUniqueInput;
};

export type HourRangeWhereInput = {
  AND?: Maybe<Array<HourRangeWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  day?: Maybe<DayWhereInput>;
  dayId?: Maybe<StringFilter>;
  endHour?: Maybe<IntFilter>;
  endMinute?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<HourRangeWhereInput>>;
  OR?: Maybe<Array<HourRangeWhereInput>>;
  startHour?: Maybe<IntFilter>;
  startMinute?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type HourRangeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IncomeBranchesInput = {
  branches?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncomeReports = {
  __typename?: 'IncomeReports';
  incomeByPaymentMethod?: Maybe<Array<Maybe<PaymentMethodIncome>>>;
  incomeByTreatment?: Maybe<Array<Maybe<TreatmentIncome>>>;
  paymentsOfDay?: Maybe<Array<Maybe<Payment>>>;
};

export type IncomeReportsIncomeByPaymentMethodArgs = {
  branchId: Scalars['String'];
  end: Scalars['DateTime'];
  lastEnd?: Maybe<Scalars['DateTime']>;
  lastStart?: Maybe<Scalars['DateTime']>;
  onlyReportable?: Maybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type IncomeReportsIncomeByTreatmentArgs = {
  branchId: Scalars['String'];
  end: Scalars['DateTime'];
  lastEnd?: Maybe<Scalars['DateTime']>;
  lastStart?: Maybe<Scalars['DateTime']>;
  start: Scalars['DateTime'];
};

export type IncomeReportsPaymentsOfDayArgs = {
  branchId: Scalars['String'];
  day: Scalars['Int'];
  patientId: Scalars['String'];
  skip?: Maybe<Scalars['Int']>;
  take: Scalars['Int'];
};

export type InfluxAndSchedulePayload = {
  __typename?: 'InfluxAndSchedulePayload';
  hours?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Interest = {
  __typename?: 'Interest';
  createdAt: Scalars['DateTime'];
  events: Array<Event>;
  id: Scalars['String'];
  likes?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type InterestEventsArgs = {
  cursor?: Maybe<EventWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InterestCreateNestedOneWithoutEmployeesInput = {
  connect?: Maybe<InterestWhereUniqueInput>;
  connectOrCreate?: Maybe<InterestCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<InterestCreateWithoutEmployeesInput>;
};

export type InterestCreateNestedOneWithoutPatientsInput = {
  connect?: Maybe<InterestWhereUniqueInput>;
  connectOrCreate?: Maybe<InterestCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<InterestCreateWithoutPatientsInput>;
};

export type InterestCreateOrConnectWithoutEmployeesInput = {
  create: InterestCreateWithoutEmployeesInput;
  where: InterestWhereUniqueInput;
};

export type InterestCreateOrConnectWithoutPatientsInput = {
  create: InterestCreateWithoutPatientsInput;
  where: InterestWhereUniqueInput;
};

export type InterestCreateWithoutEmployeesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  events?: Maybe<EventCreateNestedManyWithoutInterestInput>;
  id?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientCreateNestedManyWithoutInterestInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterestCreateWithoutPatientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutInterestInput>;
  events?: Maybe<EventCreateNestedManyWithoutInterestInput>;
  id?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterestUpdateOneWithoutEmployeesInput = {
  connect?: Maybe<InterestWhereUniqueInput>;
  connectOrCreate?: Maybe<InterestCreateOrConnectWithoutEmployeesInput>;
  create?: Maybe<InterestCreateWithoutEmployeesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InterestUpdateWithoutEmployeesInput>;
  upsert?: Maybe<InterestUpsertWithoutEmployeesInput>;
};

export type InterestUpdateOneWithoutPatientsInput = {
  connect?: Maybe<InterestWhereUniqueInput>;
  connectOrCreate?: Maybe<InterestCreateOrConnectWithoutPatientsInput>;
  create?: Maybe<InterestCreateWithoutPatientsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InterestUpdateWithoutPatientsInput>;
  upsert?: Maybe<InterestUpsertWithoutPatientsInput>;
};

export type InterestUpdateWithoutEmployeesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  events?: Maybe<EventUpdateManyWithoutInterestInput>;
  id?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  patients?: Maybe<PatientUpdateManyWithoutInterestInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterestUpdateWithoutPatientsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employees?: Maybe<EmployeeUpdateManyWithoutInterestInput>;
  events?: Maybe<EventUpdateManyWithoutInterestInput>;
  id?: Maybe<Scalars['String']>;
  likes?: Maybe<Scalars['String']>;
  music?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterestUpsertWithoutEmployeesInput = {
  create: InterestCreateWithoutEmployeesInput;
  update: InterestUpdateWithoutEmployeesInput;
};

export type InterestUpsertWithoutPatientsInput = {
  create: InterestCreateWithoutPatientsInput;
  update: InterestUpdateWithoutPatientsInput;
};

export type InterestWhereInput = {
  AND?: Maybe<Array<InterestWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  employees?: Maybe<EmployeeListRelationFilter>;
  events?: Maybe<EventListRelationFilter>;
  id?: Maybe<StringFilter>;
  likes?: Maybe<StringNullableFilter>;
  music?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<InterestWhereInput>>;
  OR?: Maybe<Array<InterestWhereInput>>;
  patients?: Maybe<PatientListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InterestWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  concepts: Array<InvoiceConcept>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  documentType: InvoiceDocumentType;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  patient: Patient;
  patientId: Scalars['String'];
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey: InvoicePaymentMethodKey;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceConceptsArgs = {
  cursor?: Maybe<InvoiceConceptWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InvoiceConcept = {
  __typename?: 'InvoiceConcept';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  invoice: Invoice;
  invoiceId: Scalars['String'];
  productServiceKey?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  retainedTaxes: Array<RetainedTax>;
  transferredTaxes: Array<TransferredTax>;
  unitKey: Scalars['String'];
  unitValue: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceConceptRetainedTaxesArgs = {
  cursor?: Maybe<RetainedTaxWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InvoiceConceptTransferredTaxesArgs = {
  cursor?: Maybe<TransferredTaxWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InvoiceConceptCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  invoice: InvoiceCreateNestedOneWithoutConceptsInput;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  retainedTaxes?: Maybe<RetainedTaxCreateNestedManyWithoutInvoiceConceptInput>;
  transferredTaxes?: Maybe<TransferredTaxCreateNestedManyWithoutInvoiceConceptInput>;
  unitKey: Scalars['String'];
  unitValue: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptCreateManyInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  unitKey: Scalars['String'];
  unitValue: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptCreateManyInvoiceInputEnvelope = {
  data?: Maybe<Array<InvoiceConceptCreateManyInvoiceInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceConceptCreateNestedManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoiceConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceConceptCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceConceptCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceConceptCreateManyInvoiceInputEnvelope>;
};

export type InvoiceConceptCreateOrConnectWithoutInvoiceInput = {
  create: InvoiceConceptCreateWithoutInvoiceInput;
  where: InvoiceConceptWhereUniqueInput;
};

export type InvoiceConceptCreateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  retainedTaxes?: Maybe<RetainedTaxCreateNestedManyWithoutInvoiceConceptInput>;
  transferredTaxes?: Maybe<TransferredTaxCreateNestedManyWithoutInvoiceConceptInput>;
  unitKey: Scalars['String'];
  unitValue: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptListRelationFilter = {
  every?: Maybe<InvoiceConceptWhereInput>;
  none?: Maybe<InvoiceConceptWhereInput>;
  some?: Maybe<InvoiceConceptWhereInput>;
};

export type InvoiceConceptOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceId?: Maybe<SortOrder>;
  productServiceKey?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  unitKey?: Maybe<SortOrder>;
  unitValue?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceConceptScalarWhereInput = {
  AND?: Maybe<Array<InvoiceConceptScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceId?: Maybe<StringFilter>;
  NOT?: Maybe<Array<InvoiceConceptScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceConceptScalarWhereInput>>;
  productServiceKey?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  unitKey?: Maybe<StringFilter>;
  unitValue?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceConceptUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutConceptsInput>;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  retainedTaxes?: Maybe<RetainedTaxUpdateManyWithoutInvoiceConceptInput>;
  transferredTaxes?: Maybe<TransferredTaxUpdateManyWithoutInvoiceConceptInput>;
  unitKey?: Maybe<Scalars['String']>;
  unitValue?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitKey?: Maybe<Scalars['String']>;
  unitValue?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptUpdateManyWithoutInvoiceInput = {
  connect?: Maybe<Array<InvoiceConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceConceptCreateOrConnectWithoutInvoiceInput>>;
  create?: Maybe<Array<InvoiceConceptCreateWithoutInvoiceInput>>;
  createMany?: Maybe<InvoiceConceptCreateManyInvoiceInputEnvelope>;
  delete?: Maybe<Array<InvoiceConceptWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceConceptScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceConceptWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceConceptWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceConceptUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<InvoiceConceptUpdateManyWithWhereWithoutInvoiceInput>>;
  upsert?: Maybe<Array<InvoiceConceptUpsertWithWhereUniqueWithoutInvoiceInput>>;
};

export type InvoiceConceptUpdateManyWithWhereWithoutInvoiceInput = {
  data: InvoiceConceptUpdateManyMutationInput;
  where: InvoiceConceptScalarWhereInput;
};

export type InvoiceConceptUpdateWithoutInvoiceInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  productServiceKey?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  retainedTaxes?: Maybe<RetainedTaxUpdateManyWithoutInvoiceConceptInput>;
  transferredTaxes?: Maybe<TransferredTaxUpdateManyWithoutInvoiceConceptInput>;
  unitKey?: Maybe<Scalars['String']>;
  unitValue?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceConceptUpdateWithWhereUniqueWithoutInvoiceInput = {
  data: InvoiceConceptUpdateWithoutInvoiceInput;
  where: InvoiceConceptWhereUniqueInput;
};

export type InvoiceConceptUpsertWithWhereUniqueWithoutInvoiceInput = {
  create: InvoiceConceptCreateWithoutInvoiceInput;
  update: InvoiceConceptUpdateWithoutInvoiceInput;
  where: InvoiceConceptWhereUniqueInput;
};

export type InvoiceConceptWhereInput = {
  AND?: Maybe<Array<InvoiceConceptWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoiceId?: Maybe<StringFilter>;
  NOT?: Maybe<Array<InvoiceConceptWhereInput>>;
  OR?: Maybe<Array<InvoiceConceptWhereInput>>;
  productServiceKey?: Maybe<StringNullableFilter>;
  quantity?: Maybe<IntFilter>;
  retainedTaxes?: Maybe<RetainedTaxListRelationFilter>;
  transferredTaxes?: Maybe<TransferredTaxListRelationFilter>;
  unitKey?: Maybe<StringFilter>;
  unitValue?: Maybe<FloatFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceConceptWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceCreateInput = {
  concepts?: Maybe<InvoiceConceptCreateNestedManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType: InvoiceDocumentType;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutInvoicesInput;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey: InvoicePaymentMethodKey;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType: InvoiceDocumentType;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey: InvoicePaymentMethodKey;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<InvoiceCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutPatientInput>>;
  createMany?: Maybe<InvoiceCreateManyPatientInputEnvelope>;
};

export type InvoiceCreateNestedOneWithoutConceptsInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutConceptsInput>;
  create?: Maybe<InvoiceCreateWithoutConceptsInput>;
};

export type InvoiceCreateOrConnectWithoutConceptsInput = {
  create: InvoiceCreateWithoutConceptsInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutPatientInput = {
  create: InvoiceCreateWithoutPatientInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateWithoutConceptsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType: InvoiceDocumentType;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutInvoicesInput;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey: InvoicePaymentMethodKey;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceCreateWithoutPatientInput = {
  concepts?: Maybe<InvoiceConceptCreateNestedManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType: InvoiceDocumentType;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey: InvoicePaymentMethodKey;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum InvoiceDocumentType {
  ARRENDAMIENTO = 'arrendamiento',
  CARTA_PORTE = 'carta_porte',
  DONATIVOS = 'donativos',
  FACTURA = 'factura',
  FACTURA_HOTEL = 'factura_hotel',
  HONORARIOS = 'honorarios',
  NOTA_CARGO = 'nota_cargo',
  NOTA_CREDITO = 'nota_credito',
  NOTA_DEVOLUCION = 'nota_devolucion'
}

export type InvoiceFile = {
  __typename?: 'InvoiceFile';
  address?: Maybe<Address>;
  contactName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: Patient;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type InvoiceFileCreateInput = {
  address?: Maybe<AddressCreateNestedOneWithoutInvoiceFilesInput>;
  contactName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutInvoiceFileInput;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileCreateManyAddressInput = {
  contactName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileCreateManyAddressInputEnvelope = {
  data?: Maybe<Array<InvoiceFileCreateManyAddressInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceFileCreateNestedManyWithoutAddressInput = {
  connect?: Maybe<Array<InvoiceFileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceFileCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<InvoiceFileCreateWithoutAddressInput>>;
  createMany?: Maybe<InvoiceFileCreateManyAddressInputEnvelope>;
};

export type InvoiceFileCreateNestedOneWithoutPatientInput = {
  connect?: Maybe<InvoiceFileWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceFileCreateOrConnectWithoutPatientInput>;
  create?: Maybe<InvoiceFileCreateWithoutPatientInput>;
};

export type InvoiceFileCreateOrConnectWithoutAddressInput = {
  create: InvoiceFileCreateWithoutAddressInput;
  where: InvoiceFileWhereUniqueInput;
};

export type InvoiceFileCreateOrConnectWithoutPatientInput = {
  create: InvoiceFileCreateWithoutPatientInput;
  where: InvoiceFileWhereUniqueInput;
};

export type InvoiceFileCreateWithoutAddressInput = {
  contactName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutInvoiceFileInput;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileCreateWithoutPatientInput = {
  address?: Maybe<AddressCreateNestedOneWithoutInvoiceFilesInput>;
  contactName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileListRelationFilter = {
  every?: Maybe<InvoiceFileWhereInput>;
  none?: Maybe<InvoiceFileWhereInput>;
  some?: Maybe<InvoiceFileWhereInput>;
};

export type InvoiceFileOrderByInput = {
  addressId?: Maybe<SortOrder>;
  contactName?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  externalUid?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isr?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  numRegIdTrib?: Maybe<SortOrder>;
  observations?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  retention?: Maybe<SortOrder>;
  rfc?: Maybe<SortOrder>;
  taxRegime?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type InvoiceFileScalarWhereInput = {
  addressId?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<InvoiceFileScalarWhereInput>>;
  contactName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  externalUid?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isr?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<InvoiceFileScalarWhereInput>>;
  numRegIdTrib?: Maybe<StringNullableFilter>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<InvoiceFileScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  retention?: Maybe<FloatNullableFilter>;
  rfc?: Maybe<StringNullableFilter>;
  taxRegime?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceFileUpdateInput = {
  address?: Maybe<AddressUpdateOneWithoutInvoiceFilesInput>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutInvoiceFileInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileUpdateManyMutationInput = {
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileUpdateManyWithoutAddressInput = {
  connect?: Maybe<Array<InvoiceFileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceFileCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<InvoiceFileCreateWithoutAddressInput>>;
  createMany?: Maybe<InvoiceFileCreateManyAddressInputEnvelope>;
  delete?: Maybe<Array<InvoiceFileWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceFileScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceFileWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceFileWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceFileUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<InvoiceFileUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: Maybe<Array<InvoiceFileUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type InvoiceFileUpdateManyWithWhereWithoutAddressInput = {
  data: InvoiceFileUpdateManyMutationInput;
  where: InvoiceFileScalarWhereInput;
};

export type InvoiceFileUpdateOneWithoutPatientInput = {
  connect?: Maybe<InvoiceFileWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceFileCreateOrConnectWithoutPatientInput>;
  create?: Maybe<InvoiceFileCreateWithoutPatientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InvoiceFileUpdateWithoutPatientInput>;
  upsert?: Maybe<InvoiceFileUpsertWithoutPatientInput>;
};

export type InvoiceFileUpdateWithoutAddressInput = {
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutInvoiceFileInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileUpdateWithoutPatientInput = {
  address?: Maybe<AddressUpdateOneWithoutInvoiceFilesInput>;
  contactName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isr?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numRegIdTrib?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  retention?: Maybe<Scalars['Float']>;
  rfc?: Maybe<Scalars['String']>;
  taxRegime?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceFileUpdateWithWhereUniqueWithoutAddressInput = {
  data: InvoiceFileUpdateWithoutAddressInput;
  where: InvoiceFileWhereUniqueInput;
};

export type InvoiceFileUpsertWithoutPatientInput = {
  create: InvoiceFileCreateWithoutPatientInput;
  update: InvoiceFileUpdateWithoutPatientInput;
};

export type InvoiceFileUpsertWithWhereUniqueWithoutAddressInput = {
  create: InvoiceFileCreateWithoutAddressInput;
  update: InvoiceFileUpdateWithoutAddressInput;
  where: InvoiceFileWhereUniqueInput;
};

export type InvoiceFileWhereInput = {
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<InvoiceFileWhereInput>>;
  contactName?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  externalUid?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isr?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<InvoiceFileWhereInput>>;
  numRegIdTrib?: Maybe<StringNullableFilter>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<InvoiceFileWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  retention?: Maybe<FloatNullableFilter>;
  rfc?: Maybe<StringNullableFilter>;
  taxRegime?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceFileWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
};

export type InvoiceOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  documentType?: Maybe<SortOrder>;
  emitterRfc?: Maybe<SortOrder>;
  externalUid?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  invoiceUseKey?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  paymentConditions?: Maybe<SortOrder>;
  paymentFormKey?: Maybe<SortOrder>;
  paymentMethodKey?: Maybe<SortOrder>;
  satUuid?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum InvoicePaymentMethodKey {
  PPD = 'PPD',
  PUE = 'PUE'
}

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<InvoiceScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  documentType?: Maybe<EnumInvoiceDocumentTypeFilter>;
  emitterRfc?: Maybe<StringNullableFilter>;
  externalUid?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceUseKey?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  paymentConditions?: Maybe<StringNullableFilter>;
  paymentFormKey?: Maybe<StringNullableFilter>;
  paymentMethodKey?: Maybe<EnumInvoicePaymentMethodKeyFilter>;
  satUuid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceUpdateInput = {
  concepts?: Maybe<InvoiceConceptUpdateManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType?: Maybe<InvoiceDocumentType>;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutInvoicesInput>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey?: Maybe<InvoicePaymentMethodKey>;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType?: Maybe<InvoiceDocumentType>;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey?: Maybe<InvoicePaymentMethodKey>;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<InvoiceCreateWithoutPatientInput>>;
  createMany?: Maybe<InvoiceCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type InvoiceUpdateManyWithWhereWithoutPatientInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateOneRequiredWithoutConceptsInput = {
  connect?: Maybe<InvoiceWhereUniqueInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutConceptsInput>;
  create?: Maybe<InvoiceCreateWithoutConceptsInput>;
  update?: Maybe<InvoiceUpdateWithoutConceptsInput>;
  upsert?: Maybe<InvoiceUpsertWithoutConceptsInput>;
};

export type InvoiceUpdateWithoutConceptsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType?: Maybe<InvoiceDocumentType>;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutInvoicesInput>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey?: Maybe<InvoicePaymentMethodKey>;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithoutPatientInput = {
  concepts?: Maybe<InvoiceConceptUpdateManyWithoutInvoiceInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  documentType?: Maybe<InvoiceDocumentType>;
  emitterRfc?: Maybe<Scalars['String']>;
  externalUid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invoiceUseKey?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  paymentConditions?: Maybe<Scalars['String']>;
  paymentFormKey?: Maybe<Scalars['String']>;
  paymentMethodKey?: Maybe<InvoicePaymentMethodKey>;
  satUuid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceUpdateWithWhereUniqueWithoutPatientInput = {
  data: InvoiceUpdateWithoutPatientInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithoutConceptsInput = {
  create: InvoiceCreateWithoutConceptsInput;
  update: InvoiceUpdateWithoutConceptsInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutPatientInput = {
  create: InvoiceCreateWithoutPatientInput;
  update: InvoiceUpdateWithoutPatientInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  concepts?: Maybe<InvoiceConceptListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  currency?: Maybe<EnumCurrencyFilter>;
  documentType?: Maybe<EnumInvoiceDocumentTypeFilter>;
  emitterRfc?: Maybe<StringNullableFilter>;
  externalUid?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  invoiceUseKey?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  paymentConditions?: Maybe<StringNullableFilter>;
  paymentFormKey?: Maybe<StringNullableFilter>;
  paymentMethodKey?: Maybe<EnumInvoicePaymentMethodKeyFilter>;
  satUuid?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
};

export type Laboratory = {
  __typename?: 'Laboratory';
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  materials: Array<PaymentConcept>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LaboratoryMaterialsArgs = {
  cursor?: Maybe<PaymentConceptWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type LaboratoryCreateInput = {
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materials?: Maybe<PaymentConceptCreateNestedManyWithoutLaboratoryInput>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaboratoryCreateNestedOneWithoutMaterialsInput = {
  connect?: Maybe<LaboratoryWhereUniqueInput>;
  connectOrCreate?: Maybe<LaboratoryCreateOrConnectWithoutMaterialsInput>;
  create?: Maybe<LaboratoryCreateWithoutMaterialsInput>;
};

export type LaboratoryCreateOrConnectWithoutMaterialsInput = {
  create: LaboratoryCreateWithoutMaterialsInput;
  where: LaboratoryWhereUniqueInput;
};

export type LaboratoryCreateWithoutMaterialsInput = {
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaboratoryOrderByInput = {
  city?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LaboratoryUpdateInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  materials?: Maybe<PaymentConceptUpdateManyWithoutLaboratoryInput>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaboratoryUpdateManyMutationInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaboratoryUpdateOneWithoutMaterialsInput = {
  connect?: Maybe<LaboratoryWhereUniqueInput>;
  connectOrCreate?: Maybe<LaboratoryCreateOrConnectWithoutMaterialsInput>;
  create?: Maybe<LaboratoryCreateWithoutMaterialsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<LaboratoryUpdateWithoutMaterialsInput>;
  upsert?: Maybe<LaboratoryUpsertWithoutMaterialsInput>;
};

export type LaboratoryUpdateWithoutMaterialsInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LaboratoryUpsertWithoutMaterialsInput = {
  create: LaboratoryCreateWithoutMaterialsInput;
  update: LaboratoryUpdateWithoutMaterialsInput;
};

export type LaboratoryWhereInput = {
  AND?: Maybe<Array<LaboratoryWhereInput>>;
  city?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  materials?: Maybe<PaymentConceptListRelationFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LaboratoryWhereInput>>;
  OR?: Maybe<Array<LaboratoryWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LaboratoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LockedPeriod = {
  __typename?: 'LockedPeriod';
  branch?: Maybe<Branch>;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  endDate: Scalars['DateTime'];
  id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LockedPeriodCreateInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutLockedPeriodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutLockedPeriodsInput;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<LockedPeriodCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LockedPeriodCreateManyEmployeeInput = {
  branchId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<LockedPeriodCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LockedPeriodCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedPeriodCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<LockedPeriodCreateWithoutBranchInput>>;
  createMany?: Maybe<LockedPeriodCreateManyBranchInputEnvelope>;
};

export type LockedPeriodCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedPeriodCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<LockedPeriodCreateWithoutEmployeeInput>>;
  createMany?: Maybe<LockedPeriodCreateManyEmployeeInputEnvelope>;
};

export type LockedPeriodCreateOrConnectWithoutBranchInput = {
  create: LockedPeriodCreateWithoutBranchInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodCreateOrConnectWithoutEmployeeInput = {
  create: LockedPeriodCreateWithoutEmployeeInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutLockedPeriodsInput;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodCreateWithoutEmployeeInput = {
  branch?: Maybe<BranchCreateNestedOneWithoutLockedPeriodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodListRelationFilter = {
  every?: Maybe<LockedPeriodWhereInput>;
  none?: Maybe<LockedPeriodWhereInput>;
  some?: Maybe<LockedPeriodWhereInput>;
};

export type LockedPeriodOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LockedPeriodScalarWhereInput = {
  AND?: Maybe<Array<LockedPeriodScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  endDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LockedPeriodScalarWhereInput>>;
  OR?: Maybe<Array<LockedPeriodScalarWhereInput>>;
  reason?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LockedPeriodUpdateInput = {
  branch?: Maybe<BranchUpdateOneWithoutLockedPeriodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutLockedPeriodsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedPeriodCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<LockedPeriodCreateWithoutBranchInput>>;
  createMany?: Maybe<LockedPeriodCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LockedPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  set?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  update?: Maybe<Array<LockedPeriodUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<LockedPeriodUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<LockedPeriodUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type LockedPeriodUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LockedPeriodCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<LockedPeriodCreateWithoutEmployeeInput>>;
  createMany?: Maybe<LockedPeriodCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LockedPeriodScalarWhereInput>>;
  disconnect?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  set?: Maybe<Array<LockedPeriodWhereUniqueInput>>;
  update?: Maybe<Array<LockedPeriodUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<LockedPeriodUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<LockedPeriodUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type LockedPeriodUpdateManyWithWhereWithoutBranchInput = {
  data: LockedPeriodUpdateManyMutationInput;
  where: LockedPeriodScalarWhereInput;
};

export type LockedPeriodUpdateManyWithWhereWithoutEmployeeInput = {
  data: LockedPeriodUpdateManyMutationInput;
  where: LockedPeriodScalarWhereInput;
};

export type LockedPeriodUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutLockedPeriodsInput>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneWithoutLockedPeriodsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LockedPeriodUpdateWithWhereUniqueWithoutBranchInput = {
  data: LockedPeriodUpdateWithoutBranchInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: LockedPeriodUpdateWithoutEmployeeInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodUpsertWithWhereUniqueWithoutBranchInput = {
  create: LockedPeriodCreateWithoutBranchInput;
  update: LockedPeriodUpdateWithoutBranchInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: LockedPeriodCreateWithoutEmployeeInput;
  update: LockedPeriodUpdateWithoutEmployeeInput;
  where: LockedPeriodWhereUniqueInput;
};

export type LockedPeriodWhereInput = {
  AND?: Maybe<Array<LockedPeriodWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  endDate?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LockedPeriodWhereInput>>;
  OR?: Maybe<Array<LockedPeriodWhereInput>>;
  reason?: Maybe<StringNullableFilter>;
  startDate?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LockedPeriodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Log = {
  __typename?: 'Log';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  patient: Patient;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uploader?: Maybe<Employee>;
};

export type LogCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutLogsInput;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeCreateNestedOneWithoutUploadedLogsInput>;
};

export type LogCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploaderId?: Maybe<Scalars['String']>;
};

export type LogCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<LogCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LogCreateManyUploaderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LogCreateManyUploaderInputEnvelope = {
  data?: Maybe<Array<LogCreateManyUploaderInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type LogCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<LogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LogCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<LogCreateWithoutPatientInput>>;
  createMany?: Maybe<LogCreateManyPatientInputEnvelope>;
};

export type LogCreateNestedManyWithoutUploaderInput = {
  connect?: Maybe<Array<LogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LogCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<LogCreateWithoutUploaderInput>>;
  createMany?: Maybe<LogCreateManyUploaderInputEnvelope>;
};

export type LogCreateOrConnectWithoutPatientInput = {
  create: LogCreateWithoutPatientInput;
  where: LogWhereUniqueInput;
};

export type LogCreateOrConnectWithoutUploaderInput = {
  create: LogCreateWithoutUploaderInput;
  where: LogWhereUniqueInput;
};

export type LogCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeCreateNestedOneWithoutUploadedLogsInput>;
};

export type LogCreateWithoutUploaderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutLogsInput;
  text: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LogListRelationFilter = {
  every?: Maybe<LogWhereInput>;
  none?: Maybe<LogWhereInput>;
  some?: Maybe<LogWhereInput>;
};

export type LogOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uploaderId?: Maybe<SortOrder>;
};

export type LogScalarWhereInput = {
  AND?: Maybe<Array<LogScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LogScalarWhereInput>>;
  OR?: Maybe<Array<LogScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  title?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploaderId?: Maybe<StringNullableFilter>;
};

export type LogUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutLogsInput>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneWithoutUploadedLogsInput>;
};

export type LogUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LogUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<LogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LogCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<LogCreateWithoutPatientInput>>;
  createMany?: Maybe<LogCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<LogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LogScalarWhereInput>>;
  disconnect?: Maybe<Array<LogWhereUniqueInput>>;
  set?: Maybe<Array<LogWhereUniqueInput>>;
  update?: Maybe<Array<LogUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<LogUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<LogUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type LogUpdateManyWithoutUploaderInput = {
  connect?: Maybe<Array<LogWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<LogCreateOrConnectWithoutUploaderInput>>;
  create?: Maybe<Array<LogCreateWithoutUploaderInput>>;
  createMany?: Maybe<LogCreateManyUploaderInputEnvelope>;
  delete?: Maybe<Array<LogWhereUniqueInput>>;
  deleteMany?: Maybe<Array<LogScalarWhereInput>>;
  disconnect?: Maybe<Array<LogWhereUniqueInput>>;
  set?: Maybe<Array<LogWhereUniqueInput>>;
  update?: Maybe<Array<LogUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: Maybe<Array<LogUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: Maybe<Array<LogUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type LogUpdateManyWithWhereWithoutPatientInput = {
  data: LogUpdateManyMutationInput;
  where: LogScalarWhereInput;
};

export type LogUpdateManyWithWhereWithoutUploaderInput = {
  data: LogUpdateManyMutationInput;
  where: LogScalarWhereInput;
};

export type LogUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uploader?: Maybe<EmployeeUpdateOneWithoutUploadedLogsInput>;
};

export type LogUpdateWithoutUploaderInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutLogsInput>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LogUpdateWithWhereUniqueWithoutPatientInput = {
  data: LogUpdateWithoutPatientInput;
  where: LogWhereUniqueInput;
};

export type LogUpdateWithWhereUniqueWithoutUploaderInput = {
  data: LogUpdateWithoutUploaderInput;
  where: LogWhereUniqueInput;
};

export type LogUpsertWithWhereUniqueWithoutPatientInput = {
  create: LogCreateWithoutPatientInput;
  update: LogUpdateWithoutPatientInput;
  where: LogWhereUniqueInput;
};

export type LogUpsertWithWhereUniqueWithoutUploaderInput = {
  create: LogCreateWithoutUploaderInput;
  update: LogUpdateWithoutUploaderInput;
  where: LogWhereUniqueInput;
};

export type LogWhereInput = {
  AND?: Maybe<Array<LogWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<LogWhereInput>>;
  OR?: Maybe<Array<LogWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  title?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uploader?: Maybe<EmployeeWhereInput>;
  uploaderId?: Maybe<StringNullableFilter>;
};

export type LogWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MakePaymentResult = {
  __typename?: 'MakePaymentResult';
  recievedAmounts?: Maybe<Array<Maybe<RecievedAmount>>>;
  status?: Maybe<Scalars['String']>;
  totalPaid?: Maybe<Scalars['Float']>;
};

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  SINGLE = 'SINGLE',
  UNKNOWN = 'UNKNOWN',
  WIDOWED = 'WIDOWED'
}

export type MedicalHistory = {
  __typename?: 'MedicalHistory';
  allergyMedication?: Maybe<Scalars['String']>;
  anesthesiaReaction?: Maybe<Scalars['String']>;
  arthritisMedication?: Maybe<Scalars['String']>;
  bloodDisorders?: Maybe<Scalars['Boolean']>;
  bloodType?: Maybe<Scalars['String']>;
  contraceptives?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  diabetesMedication?: Maybe<Scalars['String']>;
  diet?: Maybe<Scalars['Boolean']>;
  diseasesObservations?: Maybe<Scalars['String']>;
  drinkingHabit?: Maybe<Scalars['Boolean']>;
  drugAllergies?: Maybe<Scalars['String']>;
  epilepsy?: Maybe<Scalars['Boolean']>;
  fainting?: Maybe<Scalars['Boolean']>;
  headache?: Maybe<Scalars['Boolean']>;
  healingTime?: Maybe<Scalars['String']>;
  heartDisease?: Maybe<Scalars['Boolean']>;
  heartMedication?: Maybe<Scalars['String']>;
  hemorrage?: Maybe<Scalars['Boolean']>;
  highBloodPresure?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  lastExam?: Maybe<Scalars['String']>;
  liverDisease?: Maybe<Scalars['Boolean']>;
  liverMedication?: Maybe<Scalars['String']>;
  lowBloodPresure?: Maybe<Scalars['Boolean']>;
  lungDisorders?: Maybe<Scalars['Boolean']>;
  nerveMedication?: Maybe<Scalars['String']>;
  otherHabits?: Maybe<Scalars['String']>;
  patient: Patient;
  pregnancy?: Maybe<Scalars['Boolean']>;
  radiation?: Maybe<Scalars['Boolean']>;
  respiratoryProblems?: Maybe<Scalars['Boolean']>;
  sleepingMedication?: Maybe<Scalars['String']>;
  smokingHabit?: Maybe<Scalars['Boolean']>;
  stds?: Maybe<Scalars['Boolean']>;
  swollenFeet?: Maybe<Scalars['Boolean']>;
  torax?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  useThyroidHormones?: Maybe<Scalars['Boolean']>;
};

export type MedicalHistoryCreateNestedOneWithoutPatientInput = {
  connect?: Maybe<MedicalHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MedicalHistoryCreateOrConnectWithoutPatientInput>;
  create?: Maybe<MedicalHistoryCreateWithoutPatientInput>;
};

export type MedicalHistoryCreateOrConnectWithoutPatientInput = {
  create: MedicalHistoryCreateWithoutPatientInput;
  where: MedicalHistoryWhereUniqueInput;
};

export type MedicalHistoryCreateWithoutPatientInput = {
  allergyMedication?: Maybe<Scalars['String']>;
  anesthesiaReaction?: Maybe<Scalars['String']>;
  arthritisMedication?: Maybe<Scalars['String']>;
  bloodDisorders?: Maybe<Scalars['Boolean']>;
  bloodType?: Maybe<Scalars['String']>;
  contraceptives?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diabetesMedication?: Maybe<Scalars['String']>;
  diet?: Maybe<Scalars['Boolean']>;
  diseasesObservations?: Maybe<Scalars['String']>;
  drinkingHabit?: Maybe<Scalars['Boolean']>;
  drugAllergies?: Maybe<Scalars['String']>;
  epilepsy?: Maybe<Scalars['Boolean']>;
  fainting?: Maybe<Scalars['Boolean']>;
  headache?: Maybe<Scalars['Boolean']>;
  healingTime?: Maybe<Scalars['String']>;
  heartDisease?: Maybe<Scalars['Boolean']>;
  heartMedication?: Maybe<Scalars['String']>;
  hemorrage?: Maybe<Scalars['Boolean']>;
  highBloodPresure?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  lastExam?: Maybe<Scalars['String']>;
  liverDisease?: Maybe<Scalars['Boolean']>;
  liverMedication?: Maybe<Scalars['String']>;
  lowBloodPresure?: Maybe<Scalars['Boolean']>;
  lungDisorders?: Maybe<Scalars['Boolean']>;
  nerveMedication?: Maybe<Scalars['String']>;
  otherHabits?: Maybe<Scalars['String']>;
  pregnancy?: Maybe<Scalars['Boolean']>;
  radiation?: Maybe<Scalars['Boolean']>;
  respiratoryProblems?: Maybe<Scalars['Boolean']>;
  sleepingMedication?: Maybe<Scalars['String']>;
  smokingHabit?: Maybe<Scalars['Boolean']>;
  stds?: Maybe<Scalars['Boolean']>;
  swollenFeet?: Maybe<Scalars['Boolean']>;
  torax?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  useThyroidHormones?: Maybe<Scalars['Boolean']>;
};

export type MedicalHistoryUpdateOneWithoutPatientInput = {
  connect?: Maybe<MedicalHistoryWhereUniqueInput>;
  connectOrCreate?: Maybe<MedicalHistoryCreateOrConnectWithoutPatientInput>;
  create?: Maybe<MedicalHistoryCreateWithoutPatientInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MedicalHistoryUpdateWithoutPatientInput>;
  upsert?: Maybe<MedicalHistoryUpsertWithoutPatientInput>;
};

export type MedicalHistoryUpdateWithoutPatientInput = {
  allergyMedication?: Maybe<Scalars['String']>;
  anesthesiaReaction?: Maybe<Scalars['String']>;
  arthritisMedication?: Maybe<Scalars['String']>;
  bloodDisorders?: Maybe<Scalars['Boolean']>;
  bloodType?: Maybe<Scalars['String']>;
  contraceptives?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  diabetesMedication?: Maybe<Scalars['String']>;
  diet?: Maybe<Scalars['Boolean']>;
  diseasesObservations?: Maybe<Scalars['String']>;
  drinkingHabit?: Maybe<Scalars['Boolean']>;
  drugAllergies?: Maybe<Scalars['String']>;
  epilepsy?: Maybe<Scalars['Boolean']>;
  fainting?: Maybe<Scalars['Boolean']>;
  headache?: Maybe<Scalars['Boolean']>;
  healingTime?: Maybe<Scalars['String']>;
  heartDisease?: Maybe<Scalars['Boolean']>;
  heartMedication?: Maybe<Scalars['String']>;
  hemorrage?: Maybe<Scalars['Boolean']>;
  highBloodPresure?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  lastExam?: Maybe<Scalars['String']>;
  liverDisease?: Maybe<Scalars['Boolean']>;
  liverMedication?: Maybe<Scalars['String']>;
  lowBloodPresure?: Maybe<Scalars['Boolean']>;
  lungDisorders?: Maybe<Scalars['Boolean']>;
  nerveMedication?: Maybe<Scalars['String']>;
  otherHabits?: Maybe<Scalars['String']>;
  pregnancy?: Maybe<Scalars['Boolean']>;
  radiation?: Maybe<Scalars['Boolean']>;
  respiratoryProblems?: Maybe<Scalars['Boolean']>;
  sleepingMedication?: Maybe<Scalars['String']>;
  smokingHabit?: Maybe<Scalars['Boolean']>;
  stds?: Maybe<Scalars['Boolean']>;
  swollenFeet?: Maybe<Scalars['Boolean']>;
  torax?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  useThyroidHormones?: Maybe<Scalars['Boolean']>;
};

export type MedicalHistoryUpsertWithoutPatientInput = {
  create: MedicalHistoryCreateWithoutPatientInput;
  update: MedicalHistoryUpdateWithoutPatientInput;
};

export type MedicalHistoryWhereInput = {
  allergyMedication?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<MedicalHistoryWhereInput>>;
  anesthesiaReaction?: Maybe<StringNullableFilter>;
  arthritisMedication?: Maybe<StringNullableFilter>;
  bloodDisorders?: Maybe<BoolNullableFilter>;
  bloodType?: Maybe<StringNullableFilter>;
  contraceptives?: Maybe<BoolNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  diabetesMedication?: Maybe<StringNullableFilter>;
  diet?: Maybe<BoolNullableFilter>;
  diseasesObservations?: Maybe<StringNullableFilter>;
  drinkingHabit?: Maybe<BoolNullableFilter>;
  drugAllergies?: Maybe<StringNullableFilter>;
  epilepsy?: Maybe<BoolNullableFilter>;
  fainting?: Maybe<BoolNullableFilter>;
  headache?: Maybe<BoolNullableFilter>;
  healingTime?: Maybe<StringNullableFilter>;
  heartDisease?: Maybe<BoolNullableFilter>;
  heartMedication?: Maybe<StringNullableFilter>;
  hemorrage?: Maybe<BoolNullableFilter>;
  highBloodPresure?: Maybe<BoolNullableFilter>;
  id?: Maybe<StringFilter>;
  lastExam?: Maybe<StringNullableFilter>;
  liverDisease?: Maybe<BoolNullableFilter>;
  liverMedication?: Maybe<StringNullableFilter>;
  lowBloodPresure?: Maybe<BoolNullableFilter>;
  lungDisorders?: Maybe<BoolNullableFilter>;
  nerveMedication?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<MedicalHistoryWhereInput>>;
  OR?: Maybe<Array<MedicalHistoryWhereInput>>;
  otherHabits?: Maybe<StringNullableFilter>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  pregnancy?: Maybe<BoolNullableFilter>;
  radiation?: Maybe<BoolNullableFilter>;
  respiratoryProblems?: Maybe<BoolNullableFilter>;
  sleepingMedication?: Maybe<StringNullableFilter>;
  smokingHabit?: Maybe<BoolNullableFilter>;
  stds?: Maybe<BoolNullableFilter>;
  swollenFeet?: Maybe<BoolNullableFilter>;
  torax?: Maybe<BoolNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  useThyroidHormones?: Maybe<BoolNullableFilter>;
};

export type MedicalHistoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type MessagesSeenPayload = {
  __typename?: 'MessagesSeenPayload';
  branchId?: Maybe<Scalars['String']>;
  patientId?: Maybe<Scalars['String']>;
};

export type ModelCounter = {
  __typename?: 'ModelCounter';
  announcement?: Maybe<Scalars['Int']>;
  appointment?: Maybe<Scalars['Int']>;
  cashClosing?: Maybe<Scalars['Int']>;
  chatMessage?: Maybe<Scalars['Int']>;
  commission?: Maybe<Scalars['Int']>;
  employee?: Maybe<Scalars['Int']>;
  evolutionNote?: Maybe<Scalars['Int']>;
  expense?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Scalars['Int']>;
  log?: Maybe<Scalars['Int']>;
  notification?: Maybe<Scalars['Int']>;
  patient?: Maybe<Scalars['Int']>;
  payment?: Maybe<Scalars['Int']>;
  paymentConcept?: Maybe<Scalars['Int']>;
  prescription?: Maybe<Scalars['Int']>;
  recurringPayment?: Maybe<Scalars['Int']>;
};

export type ModelCounterAnnouncementArgs = {
  where?: Maybe<AnnouncementWhereInput>;
};

export type ModelCounterAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
};

export type ModelCounterCashClosingArgs = {
  where?: Maybe<CashClosingWhereInput>;
};

export type ModelCounterChatMessageArgs = {
  where?: Maybe<ChatMessageWhereInput>;
};

export type ModelCounterCommissionArgs = {
  where?: Maybe<CommissionWhereInput>;
};

export type ModelCounterEmployeeArgs = {
  where?: Maybe<EmployeeWhereInput>;
};

export type ModelCounterEvolutionNoteArgs = {
  where?: Maybe<EvolutionNoteWhereInput>;
};

export type ModelCounterExpenseArgs = {
  where?: Maybe<ExpenseWhereInput>;
};

export type ModelCounterFileArgs = {
  where?: Maybe<FileWhereInput>;
};

export type ModelCounterInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};

export type ModelCounterLogArgs = {
  where?: Maybe<LogWhereInput>;
};

export type ModelCounterNotificationArgs = {
  where?: Maybe<NotificationWhereInput>;
};

export type ModelCounterPatientArgs = {
  where?: Maybe<PatientWhereInput>;
};

export type ModelCounterPaymentArgs = {
  where?: Maybe<PaymentWhereInput>;
};

export type ModelCounterPaymentConceptArgs = {
  where?: Maybe<PaymentConceptWhereInput>;
};

export type ModelCounterPrescriptionArgs = {
  where?: Maybe<PrescriptionWhereInput>;
};

export type ModelCounterRecurringPaymentArgs = {
  where?: Maybe<RecurringPaymentWhereInput>;
};

export type MonthAppointmentStatus = {
  __typename?: 'MonthAppointmentStatus';
  attended?: Maybe<Scalars['Float']>;
  canceled?: Maybe<Scalars['Float']>;
  missed?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addPoints?: Maybe<Scalars['Int']>;
  /** Archives a payment and its recieved amounts */
  archivePayment?: Maybe<Payment>;
  /** Archives one recieved amount */
  archiveReceivedAmount?: Maybe<Payment>;
  /** Archives recieved amounts */
  archiveReceivedAmounts?: Maybe<Payment>;
  cancelRecurringPayment?: Maybe<RecurringPayment>;
  changePatientDefaultPaymentMethod?: Maybe<PatientPaymentMethod>;
  changePatientPassword?: Maybe<PatientAuthPayload>;
  /** Creates a Payment and attempts to pay it immediatly. Useful for Ayzer App eCommerce. */
  createAndMakePayment?: Maybe<MakePaymentResult>;
  createOneAddress: Address;
  createOneAnnouncement: Announcement;
  createOneAnnouncementView: AnnouncementView;
  createOneAppointment: Appointment;
  createOneBankingFile: BankingFile;
  createOneBlogPost: BlogPost;
  createOneBranch: Branch;
  createOneBranchService: BranchService;
  createOneBudget: Budget;
  createOneBudgetItem: BudgetItem;
  createOneCard: Card;
  createOneCashClosing: CashClosing;
  createOneChat: Chat;
  createOneChatMessage: ChatMessage;
  createOneContract: Contract;
  createOneCoupon: Coupon;
  createOneDay: Day;
  createOneDoctorFile: DoctorFile;
  createOneEmergencyContact: EmergencyContact;
  createOneEmployee: Employee;
  createOneEmployeeRating: EmployeeRating;
  createOneEvolutionNote: EvolutionNote;
  createOneExpense: Expense;
  createOneFile: File;
  createOneGoal: Goal;
  createOneInvoice: Invoice;
  createOneInvoiceConcept: InvoiceConcept;
  createOneInvoiceFile: InvoiceFile;
  createOneLaboratory: Laboratory;
  createOneLockedPeriod: LockedPeriod;
  createOneLog: Log;
  createOneNotification: Notification;
  createOnePatient: Patient;
  createOnePatientNotification: PatientNotification;
  createOnePatientPaymentMethod?: Maybe<PatientPaymentMethod>;
  createOnePayment: Payment;
  createOnePaymentCard: PaymentCard;
  createOnePaymentConcept: PaymentConcept;
  createOnePaymentItem: PaymentItem;
  createOnePaymentMethod: PaymentMethod;
  createOnePointStory: PointStory;
  createOnePrescription: Prescription;
  createOneRecievedAmount: RecievedAmount;
  createOneRecurringPayment: RecurringPayment;
  createOneSpecialtyDoctor: SpecialtyDoctor;
  createOneUserFeedback: UserFeedback;
  deleteManyAddress: AffectedRowsOutput;
  deleteManyAilment: AffectedRowsOutput;
  deleteManyAnnouncement: AffectedRowsOutput;
  deleteManyAnnouncementView: AffectedRowsOutput;
  deleteManyAppointment: AffectedRowsOutput;
  deleteManyBankingFile: AffectedRowsOutput;
  deleteManyBlogPost: AffectedRowsOutput;
  deleteManyBranch: AffectedRowsOutput;
  deleteManyBranchService: AffectedRowsOutput;
  deleteManyBudget: AffectedRowsOutput;
  deleteManyBudgetItem: AffectedRowsOutput;
  deleteManyCard: AffectedRowsOutput;
  deleteManyChatParticipant: AffectedRowsOutput;
  deleteManyContract: AffectedRowsOutput;
  deleteManyDay: AffectedRowsOutput;
  deleteManyDoctorFile: AffectedRowsOutput;
  deleteManyEmployee: AffectedRowsOutput;
  deleteManyEmployeeRating: AffectedRowsOutput;
  deleteManyEvolutionNote: AffectedRowsOutput;
  deleteManyFile: AffectedRowsOutput;
  deleteManyGoal: AffectedRowsOutput;
  deleteManyInvoice: AffectedRowsOutput;
  deleteManyInvoiceConcept: AffectedRowsOutput;
  deleteManyInvoiceFile: AffectedRowsOutput;
  deleteManyLaboratory: AffectedRowsOutput;
  deleteManyLog: AffectedRowsOutput;
  deleteManyNotification: AffectedRowsOutput;
  deleteManyPatient: AffectedRowsOutput;
  deleteManyPatientNotification: AffectedRowsOutput;
  deleteManyPayment: AffectedRowsOutput;
  deleteManyPaymentCard: AffectedRowsOutput;
  deleteManyPaymentConcept: AffectedRowsOutput;
  deleteManyPaymentItem: AffectedRowsOutput;
  deleteManyPaymentMethod: AffectedRowsOutput;
  deleteManyPointStory: AffectedRowsOutput;
  deleteManyRecievedAmount: AffectedRowsOutput;
  deleteManyRecurringPayment: AffectedRowsOutput;
  deleteManySpecialtyDoctor: AffectedRowsOutput;
  deleteManyUserFeedback: AffectedRowsOutput;
  deleteOneAddress?: Maybe<Address>;
  deleteOneAnnouncement?: Maybe<Announcement>;
  deleteOneAnnouncementView?: Maybe<AnnouncementView>;
  deleteOneAppointment?: Maybe<Appointment>;
  deleteOneBankingFile?: Maybe<BankingFile>;
  deleteOneBlogPost?: Maybe<BlogPost>;
  deleteOneBranch?: Maybe<Branch>;
  deleteOneBranchService?: Maybe<BranchService>;
  deleteOneBudget?: Maybe<Budget>;
  deleteOneBudgetItem?: Maybe<BudgetItem>;
  deleteOneCard?: Maybe<Card>;
  deleteOneChat?: Maybe<Chat>;
  deleteOneChatMessage?: Maybe<ChatMessage>;
  deleteOneContract?: Maybe<Contract>;
  deleteOneDay?: Maybe<Day>;
  deleteOneDoctorFile?: Maybe<DoctorFile>;
  deleteOneEmployee?: Maybe<Employee>;
  deleteOneEmployeeRating?: Maybe<EmployeeRating>;
  deleteOneEvolutionNote?: Maybe<EvolutionNote>;
  deleteOneExpense?: Maybe<Expense>;
  deleteOneFile?: Maybe<File>;
  deleteOneGoal?: Maybe<Goal>;
  deleteOneInvoice?: Maybe<Invoice>;
  deleteOneInvoiceConcept?: Maybe<InvoiceConcept>;
  deleteOneInvoiceFile?: Maybe<InvoiceFile>;
  deleteOneLaboratory?: Maybe<Laboratory>;
  deleteOneLockedPeriod?: Maybe<LockedPeriod>;
  deleteOneLog?: Maybe<Log>;
  deleteOneNotification?: Maybe<Notification>;
  deleteOnePatient?: Maybe<Patient>;
  deleteOnePatientNotification?: Maybe<PatientNotification>;
  deleteOnePatientPaymentMethod?: Maybe<PatientPaymentMethod>;
  deleteOnePayment?: Maybe<Payment>;
  deleteOnePaymentCard?: Maybe<PaymentCard>;
  deleteOnePaymentConcept?: Maybe<PaymentConcept>;
  deleteOnePaymentItem?: Maybe<PaymentItem>;
  deleteOnePaymentMethod?: Maybe<PaymentMethod>;
  deleteOnePointStory?: Maybe<PointStory>;
  deleteOnePrescription?: Maybe<Prescription>;
  deleteOneRecievedAmount?: Maybe<RecievedAmount>;
  deleteOneRecurringPayment?: Maybe<RecurringPayment>;
  deleteOneSpecialtyDoctor?: Maybe<SpecialtyDoctor>;
  deleteOneUserFeedback?: Maybe<UserFeedback>;
  login?: Maybe<AuthPayload>;
  loginPatient?: Maybe<PatientAuthPayload>;
  /** Field to perform online payments from the patients app. */
  makePayment?: Maybe<MakePaymentResult>;
  /** Field used to set messages as read in a employee-patient chat */
  readMessages?: Maybe<BatchPayload>;
  recurringPayment?: Maybe<RecurringPayment>;
  registrationCRD?: Maybe<Card>;
  signUp?: Maybe<AuthPayload>;
  signUpPatient?: Maybe<PatientAuthPayload>;
  updateManyAddress: AffectedRowsOutput;
  updateManyAnnouncement: AffectedRowsOutput;
  updateManyAnnouncementView: AffectedRowsOutput;
  updateManyBankingFile: AffectedRowsOutput;
  updateManyBlogPost: AffectedRowsOutput;
  updateManyBranch: AffectedRowsOutput;
  updateManyBranchService: AffectedRowsOutput;
  updateManyBudget: AffectedRowsOutput;
  updateManyBudgetItem: AffectedRowsOutput;
  updateManyCard: AffectedRowsOutput;
  updateManyChatMessage: AffectedRowsOutput;
  updateManyCommission: AffectedRowsOutput;
  updateManyDay: AffectedRowsOutput;
  updateManyDoctorFile: AffectedRowsOutput;
  updateManyEmployee: AffectedRowsOutput;
  updateManyEvolutionNote: AffectedRowsOutput;
  updateManyFile: AffectedRowsOutput;
  updateManyGoal: AffectedRowsOutput;
  updateManyInvoice: AffectedRowsOutput;
  updateManyInvoiceConcept: AffectedRowsOutput;
  updateManyInvoiceFile: AffectedRowsOutput;
  updateManyLaboratory: AffectedRowsOutput;
  updateManyLog: AffectedRowsOutput;
  updateManyNotification: AffectedRowsOutput;
  updateManyPatient: AffectedRowsOutput;
  updateManyPatientNotification: AffectedRowsOutput;
  updateManyPatientNotificationRecipient: AffectedRowsOutput;
  updateManyPayment: AffectedRowsOutput;
  updateManyPaymentCard: AffectedRowsOutput;
  updateManyPaymentConcept: AffectedRowsOutput;
  updateManyPaymentItem: AffectedRowsOutput;
  updateManyPaymentMethod: AffectedRowsOutput;
  updateManyPointStory: AffectedRowsOutput;
  updateManyRecievedAmount: AffectedRowsOutput;
  updateManyRecurringPayment: AffectedRowsOutput;
  updateManySpecialtyDoctor: AffectedRowsOutput;
  updateManyUserFeedback: AffectedRowsOutput;
  updateOneAddress?: Maybe<Address>;
  updateOneAnnouncement?: Maybe<Announcement>;
  updateOneAnnouncementView?: Maybe<AnnouncementView>;
  updateOneAppointment?: Maybe<Appointment>;
  updateOneBankingFile?: Maybe<BankingFile>;
  updateOneBlogPost?: Maybe<BlogPost>;
  updateOneBranch?: Maybe<Branch>;
  updateOneBranchService?: Maybe<BranchService>;
  updateOneBudget?: Maybe<Budget>;
  updateOneBudgetItem?: Maybe<BudgetItem>;
  updateOneCard?: Maybe<Card>;
  updateOneCashClosing?: Maybe<CashClosing>;
  updateOneChat?: Maybe<Chat>;
  updateOneChatMessage?: Maybe<ChatMessage>;
  updateOneCommission?: Maybe<Commission>;
  updateOneContract?: Maybe<Contract>;
  updateOneCoupon?: Maybe<Coupon>;
  updateOneDay?: Maybe<Day>;
  updateOneDoctorFile?: Maybe<DoctorFile>;
  updateOneEmployee?: Maybe<Employee>;
  updateOneEmployeeRating?: Maybe<EmployeeRating>;
  updateOneFile?: Maybe<File>;
  updateOneGoal?: Maybe<Goal>;
  updateOneInvoice?: Maybe<Invoice>;
  updateOneInvoiceConcept?: Maybe<InvoiceConcept>;
  updateOneInvoiceFile?: Maybe<InvoiceFile>;
  updateOneLaboratory?: Maybe<Laboratory>;
  updateOneLockedPeriod?: Maybe<LockedPeriod>;
  updateOneLog?: Maybe<Log>;
  updateOneNotification?: Maybe<Notification>;
  updateOnePatient?: Maybe<Patient>;
  updateOnePatientNotification?: Maybe<PatientNotification>;
  updateOnePayment?: Maybe<Payment>;
  updateOnePaymentCard?: Maybe<PaymentCard>;
  updateOnePaymentConcept?: Maybe<PaymentConcept>;
  updateOnePaymentItem?: Maybe<PaymentItem>;
  updateOnePaymentMethod?: Maybe<PaymentMethod>;
  updateOnePrescription?: Maybe<Prescription>;
  updateOneRecievedAmount?: Maybe<RecievedAmount>;
  updateOneRecurringPayment?: Maybe<RecurringPayment>;
  updateOneSpecialtyDoctor?: Maybe<SpecialtyDoctor>;
  updateOneUserFeedback?: Maybe<UserFeedback>;
  uploadFile?: Maybe<UploadFilePayload>;
  verify?: Maybe<VerifyPayload>;
};

export type MutationAddPointsArgs = {
  points: Scalars['Int'];
  reason: PointStoryReason;
  where: PatientWhereUniqueInput;
};

export type MutationArchivePaymentArgs = {
  paymentId: Scalars['String'];
};

export type MutationArchiveReceivedAmountArgs = {
  amountId: Scalars['String'];
};

export type MutationArchiveReceivedAmountsArgs = {
  paymentId: Scalars['String'];
};

export type MutationCancelRecurringPaymentArgs = {
  confirmationNumber: Scalars['String'];
  convenienceFeeRefundAmount: Scalars['Float'];
  refundAmount: Scalars['Float'];
};

export type MutationChangePatientDefaultPaymentMethodArgs = {
  patientId: Scalars['String'];
  patientPaymentMethodId: Scalars['String'];
};

export type MutationChangePatientPasswordArgs = {
  password: Scalars['String'];
  patient: PatientWhereUniqueInput;
};

export type MutationCreateAndMakePaymentArgs = {
  patientId: Scalars['String'];
  paymentInput: PaymentCreateInput;
  paymentMethodId?: Maybe<Scalars['String']>;
};

export type MutationCreateOneAddressArgs = {
  data: AddressCreateInput;
};

export type MutationCreateOneAnnouncementArgs = {
  data: AnnouncementCreateInput;
};

export type MutationCreateOneAnnouncementViewArgs = {
  data: AnnouncementViewCreateInput;
};

export type MutationCreateOneAppointmentArgs = {
  data: AppointmentCreateInput;
};

export type MutationCreateOneBankingFileArgs = {
  data: BankingFileCreateInput;
};

export type MutationCreateOneBlogPostArgs = {
  data: BlogPostCreateInput;
};

export type MutationCreateOneBranchArgs = {
  data: BranchCreateInput;
};

export type MutationCreateOneBranchServiceArgs = {
  data: BranchServiceCreateInput;
};

export type MutationCreateOneBudgetArgs = {
  data: BudgetCreateInput;
};

export type MutationCreateOneBudgetItemArgs = {
  data: BudgetItemCreateInput;
};

export type MutationCreateOneCardArgs = {
  data: CardCreateInput;
};

export type MutationCreateOneCashClosingArgs = {
  data: CashClosingCreateInput;
};

export type MutationCreateOneChatArgs = {
  data: ChatCreateInput;
};

export type MutationCreateOneChatMessageArgs = {
  data: ChatMessageCreateInput;
};

export type MutationCreateOneContractArgs = {
  data: ContractCreateInput;
};

export type MutationCreateOneCouponArgs = {
  data: CouponCreateInput;
};

export type MutationCreateOneDayArgs = {
  data: DayCreateInput;
};

export type MutationCreateOneDoctorFileArgs = {
  data: DoctorFileCreateInput;
};

export type MutationCreateOneEmergencyContactArgs = {
  data: EmergencyContactCreateInput;
};

export type MutationCreateOneEmployeeArgs = {
  data: EmployeeCreateInput;
};

export type MutationCreateOneEmployeeRatingArgs = {
  data: EmployeeRatingCreateInput;
};

export type MutationCreateOneEvolutionNoteArgs = {
  data: EvolutionNoteCreateInput;
};

export type MutationCreateOneExpenseArgs = {
  data: ExpenseCreateInput;
};

export type MutationCreateOneFileArgs = {
  data: FileCreateInput;
};

export type MutationCreateOneGoalArgs = {
  data: GoalCreateInput;
};

export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};

export type MutationCreateOneInvoiceConceptArgs = {
  data: InvoiceConceptCreateInput;
};

export type MutationCreateOneInvoiceFileArgs = {
  data: InvoiceFileCreateInput;
};

export type MutationCreateOneLaboratoryArgs = {
  data: LaboratoryCreateInput;
};

export type MutationCreateOneLockedPeriodArgs = {
  data: LockedPeriodCreateInput;
};

export type MutationCreateOneLogArgs = {
  data: LogCreateInput;
};

export type MutationCreateOneNotificationArgs = {
  data: NotificationCreateInput;
};

export type MutationCreateOnePatientArgs = {
  data: PatientCreateInput;
};

export type MutationCreateOnePatientNotificationArgs = {
  data: PatientNotificationCreateInput;
};

export type MutationCreateOnePatientPaymentMethodArgs = {
  data: PatientPaymentMethodCreateInput;
};

export type MutationCreateOnePaymentArgs = {
  data: PaymentCreateInput;
};

export type MutationCreateOnePaymentCardArgs = {
  data: PaymentCardCreateInput;
};

export type MutationCreateOnePaymentConceptArgs = {
  data: PaymentConceptCreateInput;
};

export type MutationCreateOnePaymentItemArgs = {
  data: PaymentItemCreateInput;
};

export type MutationCreateOnePaymentMethodArgs = {
  data: PaymentMethodCreateInput;
};

export type MutationCreateOnePointStoryArgs = {
  data: PointStoryCreateInput;
};

export type MutationCreateOnePrescriptionArgs = {
  data: PrescriptionCreateInput;
};

export type MutationCreateOneRecievedAmountArgs = {
  data: RecievedAmountCreateInput;
};

export type MutationCreateOneRecurringPaymentArgs = {
  data: RecurringPaymentCreateInput;
};

export type MutationCreateOneSpecialtyDoctorArgs = {
  data: SpecialtyDoctorCreateInput;
};

export type MutationCreateOneUserFeedbackArgs = {
  data: UserFeedbackCreateInput;
};

export type MutationDeleteManyAddressArgs = {
  where?: Maybe<AddressWhereInput>;
};

export type MutationDeleteManyAilmentArgs = {
  where?: Maybe<AilmentWhereInput>;
};

export type MutationDeleteManyAnnouncementArgs = {
  where?: Maybe<AnnouncementWhereInput>;
};

export type MutationDeleteManyAnnouncementViewArgs = {
  where?: Maybe<AnnouncementViewWhereInput>;
};

export type MutationDeleteManyAppointmentArgs = {
  where?: Maybe<AppointmentWhereInput>;
};

export type MutationDeleteManyBankingFileArgs = {
  where?: Maybe<BankingFileWhereInput>;
};

export type MutationDeleteManyBlogPostArgs = {
  where?: Maybe<BlogPostWhereInput>;
};

export type MutationDeleteManyBranchArgs = {
  where?: Maybe<BranchWhereInput>;
};

export type MutationDeleteManyBranchServiceArgs = {
  where?: Maybe<BranchServiceWhereInput>;
};

export type MutationDeleteManyBudgetArgs = {
  where?: Maybe<BudgetWhereInput>;
};

export type MutationDeleteManyBudgetItemArgs = {
  where?: Maybe<BudgetItemWhereInput>;
};

export type MutationDeleteManyCardArgs = {
  where?: Maybe<CardWhereInput>;
};

export type MutationDeleteManyChatParticipantArgs = {
  where?: Maybe<ChatParticipantWhereInput>;
};

export type MutationDeleteManyContractArgs = {
  where?: Maybe<ContractWhereInput>;
};

export type MutationDeleteManyDayArgs = {
  where?: Maybe<DayWhereInput>;
};

export type MutationDeleteManyDoctorFileArgs = {
  where?: Maybe<DoctorFileWhereInput>;
};

export type MutationDeleteManyEmployeeArgs = {
  where?: Maybe<EmployeeWhereInput>;
};

export type MutationDeleteManyEmployeeRatingArgs = {
  where?: Maybe<EmployeeRatingWhereInput>;
};

export type MutationDeleteManyEvolutionNoteArgs = {
  where?: Maybe<EvolutionNoteWhereInput>;
};

export type MutationDeleteManyFileArgs = {
  where?: Maybe<FileWhereInput>;
};

export type MutationDeleteManyGoalArgs = {
  where?: Maybe<GoalWhereInput>;
};

export type MutationDeleteManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
};

export type MutationDeleteManyInvoiceConceptArgs = {
  where?: Maybe<InvoiceConceptWhereInput>;
};

export type MutationDeleteManyInvoiceFileArgs = {
  where?: Maybe<InvoiceFileWhereInput>;
};

export type MutationDeleteManyLaboratoryArgs = {
  where?: Maybe<LaboratoryWhereInput>;
};

export type MutationDeleteManyLogArgs = {
  where?: Maybe<LogWhereInput>;
};

export type MutationDeleteManyNotificationArgs = {
  where?: Maybe<NotificationWhereInput>;
};

export type MutationDeleteManyPatientArgs = {
  where?: Maybe<PatientWhereInput>;
};

export type MutationDeleteManyPatientNotificationArgs = {
  where?: Maybe<PatientNotificationWhereInput>;
};

export type MutationDeleteManyPaymentArgs = {
  where?: Maybe<PaymentWhereInput>;
};

export type MutationDeleteManyPaymentCardArgs = {
  where?: Maybe<PaymentCardWhereInput>;
};

export type MutationDeleteManyPaymentConceptArgs = {
  where?: Maybe<PaymentConceptWhereInput>;
};

export type MutationDeleteManyPaymentItemArgs = {
  where?: Maybe<PaymentItemWhereInput>;
};

export type MutationDeleteManyPaymentMethodArgs = {
  where?: Maybe<PaymentMethodWhereInput>;
};

export type MutationDeleteManyPointStoryArgs = {
  where?: Maybe<PointStoryWhereInput>;
};

export type MutationDeleteManyRecievedAmountArgs = {
  where?: Maybe<RecievedAmountWhereInput>;
};

export type MutationDeleteManyRecurringPaymentArgs = {
  where?: Maybe<RecurringPaymentWhereInput>;
};

export type MutationDeleteManySpecialtyDoctorArgs = {
  where?: Maybe<SpecialtyDoctorWhereInput>;
};

export type MutationDeleteManyUserFeedbackArgs = {
  where?: Maybe<UserFeedbackWhereInput>;
};

export type MutationDeleteOneAddressArgs = {
  where: AddressWhereUniqueInput;
};

export type MutationDeleteOneAnnouncementArgs = {
  where: AnnouncementWhereUniqueInput;
};

export type MutationDeleteOneAnnouncementViewArgs = {
  where: AnnouncementViewWhereUniqueInput;
};

export type MutationDeleteOneAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type MutationDeleteOneBankingFileArgs = {
  where: BankingFileWhereUniqueInput;
};

export type MutationDeleteOneBlogPostArgs = {
  where: BlogPostWhereUniqueInput;
};

export type MutationDeleteOneBranchArgs = {
  where: BranchWhereUniqueInput;
};

export type MutationDeleteOneBranchServiceArgs = {
  where: BranchServiceWhereUniqueInput;
};

export type MutationDeleteOneBudgetArgs = {
  where: BudgetWhereUniqueInput;
};

export type MutationDeleteOneBudgetItemArgs = {
  where: BudgetItemWhereUniqueInput;
};

export type MutationDeleteOneCardArgs = {
  where: CardWhereUniqueInput;
};

export type MutationDeleteOneChatArgs = {
  where: ChatWhereUniqueInput;
};

export type MutationDeleteOneChatMessageArgs = {
  where: ChatMessageWhereUniqueInput;
};

export type MutationDeleteOneContractArgs = {
  where: ContractWhereUniqueInput;
};

export type MutationDeleteOneDayArgs = {
  where: DayWhereUniqueInput;
};

export type MutationDeleteOneDoctorFileArgs = {
  where: DoctorFileWhereUniqueInput;
};

export type MutationDeleteOneEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};

export type MutationDeleteOneEmployeeRatingArgs = {
  where: EmployeeRatingWhereUniqueInput;
};

export type MutationDeleteOneEvolutionNoteArgs = {
  where: EvolutionNoteWhereUniqueInput;
};

export type MutationDeleteOneExpenseArgs = {
  where: ExpenseWhereUniqueInput;
};

export type MutationDeleteOneFileArgs = {
  where: FileWhereUniqueInput;
};

export type MutationDeleteOneGoalArgs = {
  where: GoalWhereUniqueInput;
};

export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};

export type MutationDeleteOneInvoiceConceptArgs = {
  where: InvoiceConceptWhereUniqueInput;
};

export type MutationDeleteOneInvoiceFileArgs = {
  where: InvoiceFileWhereUniqueInput;
};

export type MutationDeleteOneLaboratoryArgs = {
  where: LaboratoryWhereUniqueInput;
};

export type MutationDeleteOneLockedPeriodArgs = {
  where: LockedPeriodWhereUniqueInput;
};

export type MutationDeleteOneLogArgs = {
  where: LogWhereUniqueInput;
};

export type MutationDeleteOneNotificationArgs = {
  where: NotificationWhereUniqueInput;
};

export type MutationDeleteOnePatientArgs = {
  where: PatientWhereUniqueInput;
};

export type MutationDeleteOnePatientNotificationArgs = {
  where: PatientNotificationWhereUniqueInput;
};

export type MutationDeleteOnePatientPaymentMethodArgs = {
  id: Scalars['String'];
  patientId: Scalars['String'];
};

export type MutationDeleteOnePaymentArgs = {
  where: PaymentWhereUniqueInput;
};

export type MutationDeleteOnePaymentCardArgs = {
  where: PaymentCardWhereUniqueInput;
};

export type MutationDeleteOnePaymentConceptArgs = {
  where: PaymentConceptWhereUniqueInput;
};

export type MutationDeleteOnePaymentItemArgs = {
  where: PaymentItemWhereUniqueInput;
};

export type MutationDeleteOnePaymentMethodArgs = {
  where: PaymentMethodWhereUniqueInput;
};

export type MutationDeleteOnePointStoryArgs = {
  where: PointStoryWhereUniqueInput;
};

export type MutationDeleteOnePrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};

export type MutationDeleteOneRecievedAmountArgs = {
  where: RecievedAmountWhereUniqueInput;
};

export type MutationDeleteOneRecurringPaymentArgs = {
  where: RecurringPaymentWhereUniqueInput;
};

export type MutationDeleteOneSpecialtyDoctorArgs = {
  where: SpecialtyDoctorWhereUniqueInput;
};

export type MutationDeleteOneUserFeedbackArgs = {
  where: UserFeedbackWhereUniqueInput;
};

export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationLoginPatientArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationMakePaymentArgs = {
  patientId: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['String']>;
  payments: Array<Scalars['String']>;
};

export type MutationReadMessagesArgs = {
  chat: ChatWhereUniqueInput;
  sender: ChatParticipantWhereUniqueInput;
};

export type MutationRecurringPaymentArgs = {
  card: CardWhereUniqueInput;
  durationInMonths: Scalars['Int'];
  patientId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
};

export type MutationRegistrationCrdArgs = {
  cardNumber: Scalars['String'];
  expirationMonth: Scalars['String'];
  expirationYear: Scalars['String'];
  fullName: Scalars['String'];
  lookUpReference: Scalars['String'];
};

export type MutationSignUpArgs = {
  data: EmployeeCreateInput;
};

export type MutationSignUpPatientArgs = {
  data: PatientCreateInput;
};

export type MutationUpdateManyAddressArgs = {
  data: AddressUpdateManyMutationInput;
  where?: Maybe<AddressWhereInput>;
};

export type MutationUpdateManyAnnouncementArgs = {
  data: AnnouncementUpdateManyMutationInput;
  where?: Maybe<AnnouncementWhereInput>;
};

export type MutationUpdateManyAnnouncementViewArgs = {
  data: AnnouncementViewUpdateManyMutationInput;
  where?: Maybe<AnnouncementViewWhereInput>;
};

export type MutationUpdateManyBankingFileArgs = {
  data: BankingFileUpdateManyMutationInput;
  where?: Maybe<BankingFileWhereInput>;
};

export type MutationUpdateManyBlogPostArgs = {
  data: BlogPostUpdateManyMutationInput;
  where?: Maybe<BlogPostWhereInput>;
};

export type MutationUpdateManyBranchArgs = {
  data: BranchUpdateManyMutationInput;
  where?: Maybe<BranchWhereInput>;
};

export type MutationUpdateManyBranchServiceArgs = {
  data: BranchServiceUpdateManyMutationInput;
  where?: Maybe<BranchServiceWhereInput>;
};

export type MutationUpdateManyBudgetArgs = {
  data: BudgetUpdateManyMutationInput;
  where?: Maybe<BudgetWhereInput>;
};

export type MutationUpdateManyBudgetItemArgs = {
  data: BudgetItemUpdateManyMutationInput;
  where?: Maybe<BudgetItemWhereInput>;
};

export type MutationUpdateManyCardArgs = {
  data: CardUpdateManyMutationInput;
  where?: Maybe<CardWhereInput>;
};

export type MutationUpdateManyChatMessageArgs = {
  data: ChatMessageUpdateManyMutationInput;
  where?: Maybe<ChatMessageWhereInput>;
};

export type MutationUpdateManyCommissionArgs = {
  data: CommissionUpdateManyMutationInput;
  where?: Maybe<CommissionWhereInput>;
};

export type MutationUpdateManyDayArgs = {
  data: DayUpdateManyMutationInput;
  where?: Maybe<DayWhereInput>;
};

export type MutationUpdateManyDoctorFileArgs = {
  data: DoctorFileUpdateManyMutationInput;
  where?: Maybe<DoctorFileWhereInput>;
};

export type MutationUpdateManyEmployeeArgs = {
  data: EmployeeUpdateManyMutationInput;
  where?: Maybe<EmployeeWhereInput>;
};

export type MutationUpdateManyEvolutionNoteArgs = {
  data: EvolutionNoteUpdateManyMutationInput;
  where?: Maybe<EvolutionNoteWhereInput>;
};

export type MutationUpdateManyFileArgs = {
  data: FileUpdateManyMutationInput;
  where?: Maybe<FileWhereInput>;
};

export type MutationUpdateManyGoalArgs = {
  data: GoalUpdateManyMutationInput;
  where?: Maybe<GoalWhereInput>;
};

export type MutationUpdateManyInvoiceArgs = {
  data: InvoiceUpdateManyMutationInput;
  where?: Maybe<InvoiceWhereInput>;
};

export type MutationUpdateManyInvoiceConceptArgs = {
  data: InvoiceConceptUpdateManyMutationInput;
  where?: Maybe<InvoiceConceptWhereInput>;
};

export type MutationUpdateManyInvoiceFileArgs = {
  data: InvoiceFileUpdateManyMutationInput;
  where?: Maybe<InvoiceFileWhereInput>;
};

export type MutationUpdateManyLaboratoryArgs = {
  data: LaboratoryUpdateManyMutationInput;
  where?: Maybe<LaboratoryWhereInput>;
};

export type MutationUpdateManyLogArgs = {
  data: LogUpdateManyMutationInput;
  where?: Maybe<LogWhereInput>;
};

export type MutationUpdateManyNotificationArgs = {
  data: NotificationUpdateManyMutationInput;
  where?: Maybe<NotificationWhereInput>;
};

export type MutationUpdateManyPatientArgs = {
  data: PatientUpdateManyMutationInput;
  where?: Maybe<PatientWhereInput>;
};

export type MutationUpdateManyPatientNotificationArgs = {
  data: PatientNotificationUpdateManyMutationInput;
  where?: Maybe<PatientNotificationWhereInput>;
};

export type MutationUpdateManyPatientNotificationRecipientArgs = {
  data: PatientNotificationRecipientUpdateManyMutationInput;
  where?: Maybe<PatientNotificationRecipientWhereInput>;
};

export type MutationUpdateManyPaymentArgs = {
  data: PaymentUpdateManyMutationInput;
  where?: Maybe<PaymentWhereInput>;
};

export type MutationUpdateManyPaymentCardArgs = {
  data: PaymentCardUpdateManyMutationInput;
  where?: Maybe<PaymentCardWhereInput>;
};

export type MutationUpdateManyPaymentConceptArgs = {
  data: PaymentConceptUpdateManyMutationInput;
  where?: Maybe<PaymentConceptWhereInput>;
};

export type MutationUpdateManyPaymentItemArgs = {
  data: PaymentItemUpdateManyMutationInput;
  where?: Maybe<PaymentItemWhereInput>;
};

export type MutationUpdateManyPaymentMethodArgs = {
  data: PaymentMethodUpdateManyMutationInput;
  where?: Maybe<PaymentMethodWhereInput>;
};

export type MutationUpdateManyPointStoryArgs = {
  data: PointStoryUpdateManyMutationInput;
  where?: Maybe<PointStoryWhereInput>;
};

export type MutationUpdateManyRecievedAmountArgs = {
  data: RecievedAmountUpdateManyMutationInput;
  where?: Maybe<RecievedAmountWhereInput>;
};

export type MutationUpdateManyRecurringPaymentArgs = {
  data: RecurringPaymentUpdateManyMutationInput;
  where?: Maybe<RecurringPaymentWhereInput>;
};

export type MutationUpdateManySpecialtyDoctorArgs = {
  data: SpecialtyDoctorUpdateManyMutationInput;
  where?: Maybe<SpecialtyDoctorWhereInput>;
};

export type MutationUpdateManyUserFeedbackArgs = {
  data: UserFeedbackUpdateManyMutationInput;
  where?: Maybe<UserFeedbackWhereInput>;
};

export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};

export type MutationUpdateOneAnnouncementArgs = {
  data: AnnouncementUpdateInput;
  where: AnnouncementWhereUniqueInput;
};

export type MutationUpdateOneAnnouncementViewArgs = {
  data: AnnouncementViewUpdateInput;
  where: AnnouncementViewWhereUniqueInput;
};

export type MutationUpdateOneAppointmentArgs = {
  data: AppointmentUpdateInput;
  where: AppointmentWhereUniqueInput;
};

export type MutationUpdateOneBankingFileArgs = {
  data: BankingFileUpdateInput;
  where: BankingFileWhereUniqueInput;
};

export type MutationUpdateOneBlogPostArgs = {
  data: BlogPostUpdateInput;
  where: BlogPostWhereUniqueInput;
};

export type MutationUpdateOneBranchArgs = {
  data: BranchUpdateInput;
  where: BranchWhereUniqueInput;
};

export type MutationUpdateOneBranchServiceArgs = {
  data: BranchServiceUpdateInput;
  where: BranchServiceWhereUniqueInput;
};

export type MutationUpdateOneBudgetArgs = {
  data: BudgetUpdateInput;
  where: BudgetWhereUniqueInput;
};

export type MutationUpdateOneBudgetItemArgs = {
  data: BudgetItemUpdateInput;
  where: BudgetItemWhereUniqueInput;
};

export type MutationUpdateOneCardArgs = {
  data: CardUpdateInput;
  where: CardWhereUniqueInput;
};

export type MutationUpdateOneCashClosingArgs = {
  data: CashClosingUpdateInput;
  where: CashClosingWhereUniqueInput;
};

export type MutationUpdateOneChatArgs = {
  data: ChatUpdateInput;
  where: ChatWhereUniqueInput;
};

export type MutationUpdateOneChatMessageArgs = {
  data: ChatMessageUpdateInput;
  where: ChatMessageWhereUniqueInput;
};

export type MutationUpdateOneCommissionArgs = {
  data: CommissionUpdateInput;
  where: CommissionWhereUniqueInput;
};

export type MutationUpdateOneContractArgs = {
  data: ContractUpdateInput;
  where: ContractWhereUniqueInput;
};

export type MutationUpdateOneCouponArgs = {
  data: CouponUpdateInput;
  where: CouponWhereUniqueInput;
};

export type MutationUpdateOneDayArgs = {
  data: DayUpdateInput;
  where: DayWhereUniqueInput;
};

export type MutationUpdateOneDoctorFileArgs = {
  data: DoctorFileUpdateInput;
  where: DoctorFileWhereUniqueInput;
};

export type MutationUpdateOneEmployeeArgs = {
  data: EmployeeUpdateInput;
  where: EmployeeWhereUniqueInput;
};

export type MutationUpdateOneEmployeeRatingArgs = {
  data: EmployeeRatingUpdateInput;
  where: EmployeeRatingWhereUniqueInput;
};

export type MutationUpdateOneFileArgs = {
  data: FileUpdateInput;
  where: FileWhereUniqueInput;
};

export type MutationUpdateOneGoalArgs = {
  data: GoalUpdateInput;
  where: GoalWhereUniqueInput;
};

export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};

export type MutationUpdateOneInvoiceConceptArgs = {
  data: InvoiceConceptUpdateInput;
  where: InvoiceConceptWhereUniqueInput;
};

export type MutationUpdateOneInvoiceFileArgs = {
  data: InvoiceFileUpdateInput;
  where: InvoiceFileWhereUniqueInput;
};

export type MutationUpdateOneLaboratoryArgs = {
  data: LaboratoryUpdateInput;
  where: LaboratoryWhereUniqueInput;
};

export type MutationUpdateOneLockedPeriodArgs = {
  data: LockedPeriodUpdateInput;
  where: LockedPeriodWhereUniqueInput;
};

export type MutationUpdateOneLogArgs = {
  data: LogUpdateInput;
  where: LogWhereUniqueInput;
};

export type MutationUpdateOneNotificationArgs = {
  data: NotificationUpdateInput;
  where: NotificationWhereUniqueInput;
};

export type MutationUpdateOnePatientArgs = {
  data: PatientUpdateInput;
  where: PatientWhereUniqueInput;
};

export type MutationUpdateOnePatientNotificationArgs = {
  data: PatientNotificationUpdateInput;
  where: PatientNotificationWhereUniqueInput;
};

export type MutationUpdateOnePaymentArgs = {
  data: PaymentUpdateInput;
  where: PaymentWhereUniqueInput;
};

export type MutationUpdateOnePaymentCardArgs = {
  data: PaymentCardUpdateInput;
  where: PaymentCardWhereUniqueInput;
};

export type MutationUpdateOnePaymentConceptArgs = {
  data: PaymentConceptUpdateInput;
  where: PaymentConceptWhereUniqueInput;
};

export type MutationUpdateOnePaymentItemArgs = {
  data: PaymentItemUpdateInput;
  where: PaymentItemWhereUniqueInput;
};

export type MutationUpdateOnePaymentMethodArgs = {
  data: PaymentMethodUpdateInput;
  where: PaymentMethodWhereUniqueInput;
};

export type MutationUpdateOnePrescriptionArgs = {
  data: PrescriptionUpdateInput;
  where: PrescriptionWhereUniqueInput;
};

export type MutationUpdateOneRecievedAmountArgs = {
  data: RecievedAmountUpdateInput;
  where: RecievedAmountWhereUniqueInput;
};

export type MutationUpdateOneRecurringPaymentArgs = {
  data: RecurringPaymentUpdateInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type MutationUpdateOneSpecialtyDoctorArgs = {
  data: SpecialtyDoctorUpdateInput;
  where: SpecialtyDoctorWhereUniqueInput;
};

export type MutationUpdateOneUserFeedbackArgs = {
  data: UserFeedbackUpdateInput;
  where: UserFeedbackWhereUniqueInput;
};

export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type MutationVerifyArgs = {
  token: Scalars['String'];
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAppointmentStatusFilter = {
  equals?: Maybe<AppointmentStatus>;
  in?: Maybe<Array<AppointmentStatus>>;
  not?: Maybe<NestedEnumAppointmentStatusFilter>;
  notIn?: Maybe<Array<AppointmentStatus>>;
};

export type NestedEnumChatTypeFilter = {
  equals?: Maybe<ChatType>;
  in?: Maybe<Array<ChatType>>;
  not?: Maybe<NestedEnumChatTypeFilter>;
  notIn?: Maybe<Array<ChatType>>;
};

export type NestedEnumCommissionCategoryFilter = {
  equals?: Maybe<CommissionCategory>;
  in?: Maybe<Array<CommissionCategory>>;
  not?: Maybe<NestedEnumCommissionCategoryFilter>;
  notIn?: Maybe<Array<CommissionCategory>>;
};

export type NestedEnumCurrencyFilter = {
  equals?: Maybe<Currency>;
  in?: Maybe<Array<Currency>>;
  not?: Maybe<NestedEnumCurrencyFilter>;
  notIn?: Maybe<Array<Currency>>;
};

export type NestedEnumGoalLevelFilter = {
  equals?: Maybe<GoalLevel>;
  in?: Maybe<Array<GoalLevel>>;
  not?: Maybe<NestedEnumGoalLevelFilter>;
  notIn?: Maybe<Array<GoalLevel>>;
};

export type NestedEnumGoalTypeFilter = {
  equals?: Maybe<GoalType>;
  in?: Maybe<Array<GoalType>>;
  not?: Maybe<NestedEnumGoalTypeFilter>;
  notIn?: Maybe<Array<GoalType>>;
};

export type NestedEnumInvoiceDocumentTypeFilter = {
  equals?: Maybe<InvoiceDocumentType>;
  in?: Maybe<Array<InvoiceDocumentType>>;
  not?: Maybe<NestedEnumInvoiceDocumentTypeFilter>;
  notIn?: Maybe<Array<InvoiceDocumentType>>;
};

export type NestedEnumInvoicePaymentMethodKeyFilter = {
  equals?: Maybe<InvoicePaymentMethodKey>;
  in?: Maybe<Array<InvoicePaymentMethodKey>>;
  not?: Maybe<NestedEnumInvoicePaymentMethodKeyFilter>;
  notIn?: Maybe<Array<InvoicePaymentMethodKey>>;
};

export type NestedEnumMaritalStatusFilter = {
  equals?: Maybe<MaritalStatus>;
  in?: Maybe<Array<MaritalStatus>>;
  not?: Maybe<NestedEnumMaritalStatusFilter>;
  notIn?: Maybe<Array<MaritalStatus>>;
};

export type NestedEnumNotificationCategoryFilter = {
  equals?: Maybe<NotificationCategory>;
  in?: Maybe<Array<NotificationCategory>>;
  not?: Maybe<NestedEnumNotificationCategoryFilter>;
  notIn?: Maybe<Array<NotificationCategory>>;
};

export type NestedEnumNotificationLevelFilter = {
  equals?: Maybe<NotificationLevel>;
  in?: Maybe<Array<NotificationLevel>>;
  not?: Maybe<NestedEnumNotificationLevelFilter>;
  notIn?: Maybe<Array<NotificationLevel>>;
};

export type NestedEnumPatientNotificationCategoryFilter = {
  equals?: Maybe<PatientNotificationCategory>;
  in?: Maybe<Array<PatientNotificationCategory>>;
  not?: Maybe<NestedEnumPatientNotificationCategoryFilter>;
  notIn?: Maybe<Array<PatientNotificationCategory>>;
};

export type NestedEnumPaymentConceptTypeFilter = {
  equals?: Maybe<PaymentConceptType>;
  in?: Maybe<Array<PaymentConceptType>>;
  not?: Maybe<NestedEnumPaymentConceptTypeFilter>;
  notIn?: Maybe<Array<PaymentConceptType>>;
};

export type NestedEnumPointStoryReasonFilter = {
  equals?: Maybe<PointStoryReason>;
  in?: Maybe<Array<PointStoryReason>>;
  not?: Maybe<NestedEnumPointStoryReasonFilter>;
  notIn?: Maybe<Array<PointStoryReason>>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
  notIn?: Maybe<Array<Role>>;
};

export type NestedEnumServiceCategoryNullableFilter = {
  equals?: Maybe<ServiceCategory>;
  in?: Maybe<Array<ServiceCategory>>;
  not?: Maybe<NestedEnumServiceCategoryNullableFilter>;
  notIn?: Maybe<Array<ServiceCategory>>;
};

export type NestedEnumSexFilter = {
  equals?: Maybe<Sex>;
  in?: Maybe<Array<Sex>>;
  not?: Maybe<NestedEnumSexFilter>;
  notIn?: Maybe<Array<Sex>>;
};

export type NestedEnumSexNullableFilter = {
  equals?: Maybe<Sex>;
  in?: Maybe<Array<Sex>>;
  not?: Maybe<NestedEnumSexNullableFilter>;
  notIn?: Maybe<Array<Sex>>;
};

export type NestedEnumTreatmentNullableFilter = {
  equals?: Maybe<Treatment>;
  in?: Maybe<Array<Treatment>>;
  not?: Maybe<NestedEnumTreatmentNullableFilter>;
  notIn?: Maybe<Array<Treatment>>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type NewColocations = {
  __typename?: 'NewColocations';
  chartData?: Maybe<Array<Maybe<ChartDay>>>;
  colocationsMonthToDate?: Maybe<ComparisonField>;
  colocationsYearToDate?: Maybe<Scalars['Int']>;
};

export type NewPatients = {
  __typename?: 'NewPatients';
  breakdown?: Maybe<BranchPatientCountBreakdown>;
  current?: Maybe<Scalars['Float']>;
  previous?: Maybe<Scalars['Float']>;
};

export type Notification = {
  __typename?: 'Notification';
  category: NotificationCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  /** Gets read status conveniently */
  read?: Maybe<Scalars['Boolean']>;
  recipients: Array<NotificationRecipient>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: NotificationLevel;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type NotificationRecipientsArgs = {
  cursor?: Maybe<NotificationRecipientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum NotificationCategory {
  APPOINTMENT_CANCELED = 'APPOINTMENT_CANCELED',
  APPOINTMENT_INCOMING = 'APPOINTMENT_INCOMING',
  BILL_DUE_SOON = 'BILL_DUE_SOON',
  DEFAULT = 'DEFAULT',
  NEW_ANNOUNCEMENT = 'NEW_ANNOUNCEMENT',
  NEW_MESSAGE = 'NEW_MESSAGE'
}

export type NotificationCreateInput = {
  category: NotificationCategory;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recipients?: Maybe<NotificationRecipientCreateNestedManyWithoutNotificationInput>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: NotificationLevel;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type NotificationCreateNestedOneWithoutRecipientsInput = {
  connect?: Maybe<NotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<NotificationCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<NotificationCreateWithoutRecipientsInput>;
};

export type NotificationCreateOrConnectWithoutRecipientsInput = {
  create: NotificationCreateWithoutRecipientsInput;
  where: NotificationWhereUniqueInput;
};

export type NotificationCreateWithoutRecipientsInput = {
  category: NotificationCategory;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: NotificationLevel;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export enum NotificationLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARN = 'WARN'
}

export type NotificationOrderByInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  subtitle?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  url?: Maybe<SortOrder>;
};

export type NotificationRecipient = {
  __typename?: 'NotificationRecipient';
  createdAt: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['String'];
  notification: Notification;
  read: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationRecipientCreateManyEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notificationId: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<NotificationRecipientCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type NotificationRecipientCreateManyNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientCreateManyNotificationInputEnvelope = {
  data?: Maybe<Array<NotificationRecipientCreateManyNotificationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type NotificationRecipientCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationRecipientCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<NotificationRecipientCreateWithoutEmployeeInput>>;
  createMany?: Maybe<NotificationRecipientCreateManyEmployeeInputEnvelope>;
};

export type NotificationRecipientCreateNestedManyWithoutNotificationInput = {
  connect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationRecipientCreateOrConnectWithoutNotificationInput>>;
  create?: Maybe<Array<NotificationRecipientCreateWithoutNotificationInput>>;
  createMany?: Maybe<NotificationRecipientCreateManyNotificationInputEnvelope>;
};

export type NotificationRecipientCreateOrConnectWithoutEmployeeInput = {
  create: NotificationRecipientCreateWithoutEmployeeInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientCreateOrConnectWithoutNotificationInput = {
  create: NotificationRecipientCreateWithoutNotificationInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientCreateWithoutEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notification: NotificationCreateNestedOneWithoutRecipientsInput;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientCreateWithoutNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutNotificationsInput;
  id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientEmployeeIdNotificationIdCompoundUniqueInput = {
  employeeId: Scalars['String'];
  notificationId: Scalars['String'];
};

export type NotificationRecipientListRelationFilter = {
  every?: Maybe<NotificationRecipientWhereInput>;
  none?: Maybe<NotificationRecipientWhereInput>;
  some?: Maybe<NotificationRecipientWhereInput>;
};

export type NotificationRecipientScalarWhereInput = {
  AND?: Maybe<Array<NotificationRecipientScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<NotificationRecipientScalarWhereInput>>;
  notificationId?: Maybe<StringFilter>;
  OR?: Maybe<Array<NotificationRecipientScalarWhereInput>>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NotificationRecipientUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationRecipientCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<NotificationRecipientCreateWithoutEmployeeInput>>;
  createMany?: Maybe<NotificationRecipientCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<NotificationRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  set?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  update?: Maybe<Array<NotificationRecipientUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<NotificationRecipientUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<NotificationRecipientUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type NotificationRecipientUpdateManyWithoutNotificationInput = {
  connect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<NotificationRecipientCreateOrConnectWithoutNotificationInput>>;
  create?: Maybe<Array<NotificationRecipientCreateWithoutNotificationInput>>;
  createMany?: Maybe<NotificationRecipientCreateManyNotificationInputEnvelope>;
  delete?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<NotificationRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  set?: Maybe<Array<NotificationRecipientWhereUniqueInput>>;
  update?: Maybe<Array<NotificationRecipientUpdateWithWhereUniqueWithoutNotificationInput>>;
  updateMany?: Maybe<Array<NotificationRecipientUpdateManyWithWhereWithoutNotificationInput>>;
  upsert?: Maybe<Array<NotificationRecipientUpsertWithWhereUniqueWithoutNotificationInput>>;
};

export type NotificationRecipientUpdateManyWithWhereWithoutEmployeeInput = {
  data: NotificationRecipientUpdateManyMutationInput;
  where: NotificationRecipientScalarWhereInput;
};

export type NotificationRecipientUpdateManyWithWhereWithoutNotificationInput = {
  data: NotificationRecipientUpdateManyMutationInput;
  where: NotificationRecipientScalarWhereInput;
};

export type NotificationRecipientUpdateWithoutEmployeeInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationUpdateOneRequiredWithoutRecipientsInput>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientUpdateWithoutNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutNotificationsInput>;
  id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationRecipientUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: NotificationRecipientUpdateWithoutEmployeeInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientUpdateWithWhereUniqueWithoutNotificationInput = {
  data: NotificationRecipientUpdateWithoutNotificationInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: NotificationRecipientCreateWithoutEmployeeInput;
  update: NotificationRecipientUpdateWithoutEmployeeInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientUpsertWithWhereUniqueWithoutNotificationInput = {
  create: NotificationRecipientCreateWithoutNotificationInput;
  update: NotificationRecipientUpdateWithoutNotificationInput;
  where: NotificationRecipientWhereUniqueInput;
};

export type NotificationRecipientWhereInput = {
  AND?: Maybe<Array<NotificationRecipientWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<NotificationRecipientWhereInput>>;
  notification?: Maybe<NotificationWhereInput>;
  notificationId?: Maybe<StringFilter>;
  OR?: Maybe<Array<NotificationRecipientWhereInput>>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NotificationRecipientWhereUniqueInput = {
  employeeId_notificationId?: Maybe<NotificationRecipientEmployeeIdNotificationIdCompoundUniqueInput>;
  id?: Maybe<Scalars['String']>;
};

export type NotificationUpdateInput = {
  category?: Maybe<NotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  recipients?: Maybe<NotificationRecipientUpdateManyWithoutNotificationInput>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationLevel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type NotificationUpdateManyMutationInput = {
  category?: Maybe<NotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationLevel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type NotificationUpdateOneRequiredWithoutRecipientsInput = {
  connect?: Maybe<NotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<NotificationCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<NotificationCreateWithoutRecipientsInput>;
  update?: Maybe<NotificationUpdateWithoutRecipientsInput>;
  upsert?: Maybe<NotificationUpsertWithoutRecipientsInput>;
};

export type NotificationUpdateWithoutRecipientsInput = {
  category?: Maybe<NotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<NotificationLevel>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type NotificationUpsertWithoutRecipientsInput = {
  create: NotificationCreateWithoutRecipientsInput;
  update: NotificationUpdateWithoutRecipientsInput;
};

export type NotificationWhereInput = {
  AND?: Maybe<Array<NotificationWhereInput>>;
  category?: Maybe<EnumNotificationCategoryFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  message?: Maybe<StringFilter>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  recipients?: Maybe<NotificationRecipientListRelationFilter>;
  subtitle?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  type?: Maybe<EnumNotificationLevelFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  url?: Maybe<StringNullableFilter>;
};

export type NotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Patient = {
  __typename?: 'Patient';
  active: Scalars['Boolean'];
  address?: Maybe<Address>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments: Array<Appointment>;
  archived: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: Branch;
  budgets: Array<Budget>;
  chatParticipant?: Maybe<ChatParticipant>;
  consentedToPush: Scalars['Boolean'];
  contracts: Array<Contract>;
  coupons: Array<Coupon>;
  createdAt: Scalars['DateTime'];
  defaultPaymentMethod?: Maybe<PatientPaymentMethod>;
  email?: Maybe<Scalars['String']>;
  emergencyContact: Array<EmergencyContact>;
  evolutionNotes: Array<EvolutionNote>;
  facebookName?: Maybe<Scalars['String']>;
  files: Array<File>;
  gameCoins: Scalars['Int'];
  hasChangedUsername: Scalars['Boolean'];
  id: Scalars['String'];
  interest?: Maybe<Interest>;
  invoiceFile?: Maybe<InvoiceFile>;
  isLead: Scalars['Boolean'];
  landline?: Maybe<Scalars['String']>;
  lastAppointment?: Maybe<Appointment>;
  lastRecievedAmount?: Maybe<RecievedAmount>;
  logs: Array<Log>;
  maritalStatus: MaritalStatus;
  medicalHistory?: Maybe<MedicalHistory>;
  name: Scalars['String'];
  nextAppointment?: Maybe<Appointment>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications: Array<PatientNotificationRecipient>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard: Array<PaymentCard>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  photoUrlMedium?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  /** Shows a summary of a patient points */
  pointsOverview: PointsOverview;
  pointStories: Array<PointStory>;
  possibleGameCoins: Scalars['Int'];
  ratings: Array<EmployeeRating>;
  recordId?: Maybe<Scalars['String']>;
  sex: Sex;
  skins: Array<GameSkin>;
  stripeId?: Maybe<Scalars['String']>;
  /** Returns the sum of the amountDue of the Payments whose dueDate are in the past and not completed. */
  totalDue: TotalDueBreakdown;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentInfo?: Maybe<TreatmentInfo>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type PatientAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientBudgetsArgs = {
  cursor?: Maybe<BudgetWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientContractsArgs = {
  cursor?: Maybe<ContractWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientCouponsArgs = {
  cursor?: Maybe<CouponWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientEmergencyContactArgs = {
  cursor?: Maybe<EmergencyContactWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientEvolutionNotesArgs = {
  cursor?: Maybe<EvolutionNoteWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientFilesArgs = {
  cursor?: Maybe<FileWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientLogsArgs = {
  cursor?: Maybe<LogWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientNotificationsArgs = {
  cursor?: Maybe<PatientNotificationRecipientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientPaymentCardArgs = {
  cursor?: Maybe<PaymentCardWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientPointStoriesArgs = {
  cursor?: Maybe<PointStoryWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientRatingsArgs = {
  cursor?: Maybe<EmployeeRatingWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientSkinsArgs = {
  cursor?: Maybe<GameSkinWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PatientAcquisition = {
  __typename?: 'PatientAcquisition';
  amount: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
};

export type PatientAuthPayload = {
  __typename?: 'PatientAuthPayload';
  patient?: Maybe<Patient>;
  token?: Maybe<Scalars['String']>;
};

export type PatientCount = {
  __typename?: 'PatientCount';
  byTreatment?: Maybe<Array<Maybe<PatientCountByTreatment>>>;
  down?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  up?: Maybe<Scalars['Int']>;
};

export type PatientCountByTreatment = {
  __typename?: 'PatientCountByTreatment';
  count?: Maybe<Scalars['Int']>;
  treatment?: Maybe<Scalars['String']>;
};

export type PatientCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateManyAddressInput = {
  active?: Maybe<Scalars['Boolean']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branchId: Scalars['String'];
  consentedToPush?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interestId?: Maybe<Scalars['String']>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  recordId?: Maybe<Scalars['String']>;
  sex: Sex;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateManyAddressInputEnvelope = {
  data?: Maybe<Array<PatientCreateManyAddressInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PatientCreateManyBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  addressId?: Maybe<Scalars['String']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interestId?: Maybe<Scalars['String']>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  recordId?: Maybe<Scalars['String']>;
  sex: Sex;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<PatientCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PatientCreateManyInterestInput = {
  active?: Maybe<Scalars['Boolean']>;
  addressId?: Maybe<Scalars['String']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branchId: Scalars['String'];
  consentedToPush?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  recordId?: Maybe<Scalars['String']>;
  sex: Sex;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateManyInterestInputEnvelope = {
  data?: Maybe<Array<PatientCreateManyInterestInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PatientCreateNestedManyWithoutAddressInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<PatientCreateWithoutAddressInput>>;
  createMany?: Maybe<PatientCreateManyAddressInputEnvelope>;
};

export type PatientCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PatientCreateWithoutBranchInput>>;
  createMany?: Maybe<PatientCreateManyBranchInputEnvelope>;
};

export type PatientCreateNestedManyWithoutInterestInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<PatientCreateWithoutInterestInput>>;
  createMany?: Maybe<PatientCreateManyInterestInputEnvelope>;
};

export type PatientCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<PatientCreateWithoutAppointmentsInput>;
};

export type PatientCreateNestedOneWithoutBudgetsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutBudgetsInput>;
  create?: Maybe<PatientCreateWithoutBudgetsInput>;
};

export type PatientCreateNestedOneWithoutCardsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutCardsInput>;
  create?: Maybe<PatientCreateWithoutCardsInput>;
};

export type PatientCreateNestedOneWithoutChatParticipantInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<PatientCreateWithoutChatParticipantInput>;
};

export type PatientCreateNestedOneWithoutContractsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutContractsInput>;
  create?: Maybe<PatientCreateWithoutContractsInput>;
};

export type PatientCreateNestedOneWithoutCouponsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutCouponsInput>;
  create?: Maybe<PatientCreateWithoutCouponsInput>;
};

export type PatientCreateNestedOneWithoutEmergencyContactInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutEmergencyContactInput>;
  create?: Maybe<PatientCreateWithoutEmergencyContactInput>;
};

export type PatientCreateNestedOneWithoutEvolutionNotesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<PatientCreateWithoutEvolutionNotesInput>;
};

export type PatientCreateNestedOneWithoutFilesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutFilesInput>;
  create?: Maybe<PatientCreateWithoutFilesInput>;
};

export type PatientCreateNestedOneWithoutInvoiceFileInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutInvoiceFileInput>;
  create?: Maybe<PatientCreateWithoutInvoiceFileInput>;
};

export type PatientCreateNestedOneWithoutInvoicesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<PatientCreateWithoutInvoicesInput>;
};

export type PatientCreateNestedOneWithoutLogsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutLogsInput>;
  create?: Maybe<PatientCreateWithoutLogsInput>;
};

export type PatientCreateNestedOneWithoutNotificationsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<PatientCreateWithoutNotificationsInput>;
};

export type PatientCreateNestedOneWithoutPaymentCardInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPaymentCardInput>;
  create?: Maybe<PatientCreateWithoutPaymentCardInput>;
};

export type PatientCreateNestedOneWithoutPaymentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<PatientCreateWithoutPaymentsInput>;
};

export type PatientCreateNestedOneWithoutPointStoriesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPointStoriesInput>;
  create?: Maybe<PatientCreateWithoutPointStoriesInput>;
};

export type PatientCreateNestedOneWithoutPrescriptionsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<PatientCreateWithoutPrescriptionsInput>;
};

export type PatientCreateNestedOneWithoutRatingsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutRatingsInput>;
  create?: Maybe<PatientCreateWithoutRatingsInput>;
};

export type PatientCreateNestedOneWithoutRecurringPaymentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutRecurringPaymentsInput>;
  create?: Maybe<PatientCreateWithoutRecurringPaymentsInput>;
};

export type PatientCreateOrConnectWithoutAddressInput = {
  create: PatientCreateWithoutAddressInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutAppointmentsInput = {
  create: PatientCreateWithoutAppointmentsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutBranchInput = {
  create: PatientCreateWithoutBranchInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutBudgetsInput = {
  create: PatientCreateWithoutBudgetsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutCardsInput = {
  create: PatientCreateWithoutCardsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutChatParticipantInput = {
  create: PatientCreateWithoutChatParticipantInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutContractsInput = {
  create: PatientCreateWithoutContractsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutCouponsInput = {
  create: PatientCreateWithoutCouponsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutEmergencyContactInput = {
  create: PatientCreateWithoutEmergencyContactInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutEvolutionNotesInput = {
  create: PatientCreateWithoutEvolutionNotesInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutFilesInput = {
  create: PatientCreateWithoutFilesInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutInterestInput = {
  create: PatientCreateWithoutInterestInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutInvoiceFileInput = {
  create: PatientCreateWithoutInvoiceFileInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutInvoicesInput = {
  create: PatientCreateWithoutInvoicesInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutLogsInput = {
  create: PatientCreateWithoutLogsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutNotificationsInput = {
  create: PatientCreateWithoutNotificationsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutPaymentCardInput = {
  create: PatientCreateWithoutPaymentCardInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutPaymentsInput = {
  create: PatientCreateWithoutPaymentsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutPointStoriesInput = {
  create: PatientCreateWithoutPointStoriesInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutPrescriptionsInput = {
  create: PatientCreateWithoutPrescriptionsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutRatingsInput = {
  create: PatientCreateWithoutRatingsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateOrConnectWithoutRecurringPaymentsInput = {
  create: PatientCreateWithoutRecurringPaymentsInput;
  where: PatientWhereUniqueInput;
};

export type PatientCreateWithoutAddressInput = {
  active?: Maybe<Scalars['Boolean']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutAppointmentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutBudgetsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutCardsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutChatParticipantInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutContractsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutCouponsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutEmergencyContactInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutEvolutionNotesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutFilesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutInterestInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutInvoiceFileInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutInvoicesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutLogsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutNotificationsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutPaymentCardInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutPaymentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutPointStoriesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutPrescriptionsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutRatingsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentCreateNestedManyWithoutPatientInput>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientCreateWithoutRecurringPaymentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressCreateNestedOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch: BranchCreateNestedOneWithoutPatientsInput;
  budgets?: Maybe<BudgetCreateNestedManyWithoutPatientInput>;
  cards?: Maybe<CardCreateNestedManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantCreateNestedOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractCreateNestedManyWithoutPatientInput>;
  coupons?: Maybe<CouponCreateNestedManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactCreateNestedManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteCreateNestedManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileCreateNestedManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestCreateNestedOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileCreateNestedOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogCreateNestedManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryCreateNestedOneWithoutPatientInput>;
  name: Scalars['String'];
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  paymentCard?: Maybe<PaymentCardCreateNestedManyWithoutPatientInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityCreateNestedManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryCreateNestedManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionCreateNestedManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingCreateNestedManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  sex: Sex;
  skins?: Maybe<GameSkinCreateNestedManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type PatientListRelationFilter = {
  every?: Maybe<PatientWhereInput>;
  none?: Maybe<PatientWhereInput>;
  some?: Maybe<PatientWhereInput>;
};

export enum PatientMessageReader {
  BRANCH = 'branch',
  PATIENT = 'patient'
}

export type PatientNotification = {
  __typename?: 'PatientNotification';
  category: PatientNotificationCategory;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message: Scalars['String'];
  /** Gets read status conveniently */
  read?: Maybe<Scalars['Boolean']>;
  recipients: Array<PatientNotificationRecipient>;
  updatedAt: Scalars['DateTime'];
};

export type PatientNotificationRecipientsArgs = {
  cursor?: Maybe<PatientNotificationRecipientWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum PatientNotificationCategory {
  APPOINTMENT_INCOMING = 'APPOINTMENT_INCOMING',
  DEFAULT = 'DEFAULT',
  NEW_APPOINTMENT = 'NEW_APPOINTMENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_POINTS = 'NEW_POINTS'
}

export type PatientNotificationCreateInput = {
  category?: Maybe<PatientNotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  recipients?: Maybe<PatientNotificationRecipientCreateNestedManyWithoutNotificationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationCreateNestedOneWithoutRecipientsInput = {
  connect?: Maybe<PatientNotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientNotificationCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<PatientNotificationCreateWithoutRecipientsInput>;
};

export type PatientNotificationCreateOrConnectWithoutRecipientsInput = {
  create: PatientNotificationCreateWithoutRecipientsInput;
  where: PatientNotificationWhereUniqueInput;
};

export type PatientNotificationCreateWithoutRecipientsInput = {
  category?: Maybe<PatientNotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationOrderByInput = {
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  message?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PatientNotificationRecipient = {
  __typename?: 'PatientNotificationRecipient';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  notification: PatientNotification;
  patient: Patient;
  /** Gets read status conveniently */
  read?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};

export type PatientNotificationRecipientReadArgs = {
  patientId: Scalars['String'];
};

export type PatientNotificationRecipientCreateManyNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientCreateManyNotificationInputEnvelope = {
  data?: Maybe<Array<PatientNotificationRecipientCreateManyNotificationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PatientNotificationRecipientCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notificationId: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PatientNotificationRecipientCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PatientNotificationRecipientCreateNestedManyWithoutNotificationInput = {
  connect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientNotificationRecipientCreateOrConnectWithoutNotificationInput>>;
  create?: Maybe<Array<PatientNotificationRecipientCreateWithoutNotificationInput>>;
  createMany?: Maybe<PatientNotificationRecipientCreateManyNotificationInputEnvelope>;
};

export type PatientNotificationRecipientCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientNotificationRecipientCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PatientNotificationRecipientCreateWithoutPatientInput>>;
  createMany?: Maybe<PatientNotificationRecipientCreateManyPatientInputEnvelope>;
};

export type PatientNotificationRecipientCreateOrConnectWithoutNotificationInput = {
  create: PatientNotificationRecipientCreateWithoutNotificationInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientCreateOrConnectWithoutPatientInput = {
  create: PatientNotificationRecipientCreateWithoutPatientInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientCreateWithoutNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutNotificationsInput;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notification: PatientNotificationCreateNestedOneWithoutRecipientsInput;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientListRelationFilter = {
  every?: Maybe<PatientNotificationRecipientWhereInput>;
  none?: Maybe<PatientNotificationRecipientWhereInput>;
  some?: Maybe<PatientNotificationRecipientWhereInput>;
};

export type PatientNotificationRecipientPatientIdNotificationIdCompoundUniqueInput = {
  notificationId: Scalars['String'];
  patientId: Scalars['String'];
};

export type PatientNotificationRecipientScalarWhereInput = {
  AND?: Maybe<Array<PatientNotificationRecipientScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PatientNotificationRecipientScalarWhereInput>>;
  notificationId?: Maybe<StringFilter>;
  OR?: Maybe<Array<PatientNotificationRecipientScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PatientNotificationRecipientUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientUpdateManyWithoutNotificationInput = {
  connect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientNotificationRecipientCreateOrConnectWithoutNotificationInput>>;
  create?: Maybe<Array<PatientNotificationRecipientCreateWithoutNotificationInput>>;
  createMany?: Maybe<PatientNotificationRecipientCreateManyNotificationInputEnvelope>;
  delete?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PatientNotificationRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  set?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  update?: Maybe<Array<PatientNotificationRecipientUpdateWithWhereUniqueWithoutNotificationInput>>;
  updateMany?: Maybe<Array<PatientNotificationRecipientUpdateManyWithWhereWithoutNotificationInput>>;
  upsert?: Maybe<Array<PatientNotificationRecipientUpsertWithWhereUniqueWithoutNotificationInput>>;
};

export type PatientNotificationRecipientUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientNotificationRecipientCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PatientNotificationRecipientCreateWithoutPatientInput>>;
  createMany?: Maybe<PatientNotificationRecipientCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PatientNotificationRecipientScalarWhereInput>>;
  disconnect?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  set?: Maybe<Array<PatientNotificationRecipientWhereUniqueInput>>;
  update?: Maybe<Array<PatientNotificationRecipientUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PatientNotificationRecipientUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PatientNotificationRecipientUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PatientNotificationRecipientUpdateManyWithWhereWithoutNotificationInput = {
  data: PatientNotificationRecipientUpdateManyMutationInput;
  where: PatientNotificationRecipientScalarWhereInput;
};

export type PatientNotificationRecipientUpdateManyWithWhereWithoutPatientInput = {
  data: PatientNotificationRecipientUpdateManyMutationInput;
  where: PatientNotificationRecipientScalarWhereInput;
};

export type PatientNotificationRecipientUpdateWithoutNotificationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutNotificationsInput>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  notification?: Maybe<PatientNotificationUpdateOneRequiredWithoutRecipientsInput>;
  read?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationRecipientUpdateWithWhereUniqueWithoutNotificationInput = {
  data: PatientNotificationRecipientUpdateWithoutNotificationInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientUpdateWithWhereUniqueWithoutPatientInput = {
  data: PatientNotificationRecipientUpdateWithoutPatientInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientUpsertWithWhereUniqueWithoutNotificationInput = {
  create: PatientNotificationRecipientCreateWithoutNotificationInput;
  update: PatientNotificationRecipientUpdateWithoutNotificationInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientUpsertWithWhereUniqueWithoutPatientInput = {
  create: PatientNotificationRecipientCreateWithoutPatientInput;
  update: PatientNotificationRecipientUpdateWithoutPatientInput;
  where: PatientNotificationRecipientWhereUniqueInput;
};

export type PatientNotificationRecipientWhereInput = {
  AND?: Maybe<Array<PatientNotificationRecipientWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PatientNotificationRecipientWhereInput>>;
  notification?: Maybe<PatientNotificationWhereInput>;
  notificationId?: Maybe<StringFilter>;
  OR?: Maybe<Array<PatientNotificationRecipientWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  read?: Maybe<BoolFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PatientNotificationRecipientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  patientId_notificationId?: Maybe<PatientNotificationRecipientPatientIdNotificationIdCompoundUniqueInput>;
};

export type PatientNotificationUpdateInput = {
  category?: Maybe<PatientNotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  recipients?: Maybe<PatientNotificationRecipientUpdateManyWithoutNotificationInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationUpdateManyMutationInput = {
  category?: Maybe<PatientNotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationUpdateOneRequiredWithoutRecipientsInput = {
  connect?: Maybe<PatientNotificationWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientNotificationCreateOrConnectWithoutRecipientsInput>;
  create?: Maybe<PatientNotificationCreateWithoutRecipientsInput>;
  update?: Maybe<PatientNotificationUpdateWithoutRecipientsInput>;
  upsert?: Maybe<PatientNotificationUpsertWithoutRecipientsInput>;
};

export type PatientNotificationUpdateWithoutRecipientsInput = {
  category?: Maybe<PatientNotificationCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PatientNotificationUpsertWithoutRecipientsInput = {
  create: PatientNotificationCreateWithoutRecipientsInput;
  update: PatientNotificationUpdateWithoutRecipientsInput;
};

export type PatientNotificationWhereInput = {
  AND?: Maybe<Array<PatientNotificationWhereInput>>;
  category?: Maybe<EnumPatientNotificationCategoryFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  message?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PatientNotificationWhereInput>>;
  OR?: Maybe<Array<PatientNotificationWhereInput>>;
  recipients?: Maybe<PatientNotificationRecipientListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PatientNotificationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PatientOrderByInput = {
  active?: Maybe<SortOrder>;
  addressId?: Maybe<SortOrder>;
  advertisingMedia?: Maybe<SortOrder>;
  archived?: Maybe<SortOrder>;
  birthDate?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  consentedToPush?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  defaultPaymentMethodId?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  facebookName?: Maybe<SortOrder>;
  gameCoins?: Maybe<SortOrder>;
  hasChangedUsername?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  interestId?: Maybe<SortOrder>;
  isLead?: Maybe<SortOrder>;
  landline?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  maritalStatus?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  nextAskForPush?: Maybe<SortOrder>;
  occupation?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  phoneNumber?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  photoHash?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  possibleGameCoins?: Maybe<SortOrder>;
  recordId?: Maybe<SortOrder>;
  sex?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  treatment?: Maybe<SortOrder>;
  treatmentEnd?: Maybe<SortOrder>;
  treatmentStart?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
};

export type PatientPaymentMethod = {
  __typename?: 'PatientPaymentMethod';
  brand: Scalars['String'];
  cardHolder: Scalars['String'];
  created: Scalars['Int'];
  expireMonth: Scalars['Int'];
  expireYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type PatientPaymentMethodCreateInput = {
  cardHolder: Scalars['String'];
  cvc: Scalars['String'];
  expireMonth: Scalars['Int'];
  expireYear: Scalars['Int'];
  number: Scalars['String'];
  patientId: Scalars['String'];
};

export type PatientScalarWhereInput = {
  active?: Maybe<BoolFilter>;
  addressId?: Maybe<StringNullableFilter>;
  advertisingMedia?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<PatientScalarWhereInput>>;
  archived?: Maybe<BoolFilter>;
  birthDate?: Maybe<DateTimeNullableFilter>;
  branchId?: Maybe<StringFilter>;
  consentedToPush?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultPaymentMethodId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  facebookName?: Maybe<StringNullableFilter>;
  gameCoins?: Maybe<IntFilter>;
  hasChangedUsername?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  interestId?: Maybe<StringNullableFilter>;
  isLead?: Maybe<BoolFilter>;
  landline?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  maritalStatus?: Maybe<EnumMaritalStatusFilter>;
  name?: Maybe<StringFilter>;
  nextAskForPush?: Maybe<DateTimeNullableFilter>;
  NOT?: Maybe<Array<PatientScalarWhereInput>>;
  occupation?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<PatientScalarWhereInput>>;
  password?: Maybe<StringFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  photoHash?: Maybe<StringNullableFilter>;
  points?: Maybe<IntFilter>;
  possibleGameCoins?: Maybe<IntFilter>;
  recordId?: Maybe<StringNullableFilter>;
  sex?: Maybe<EnumSexFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  treatment?: Maybe<EnumTreatmentNullableFilter>;
  treatmentEnd?: Maybe<DateTimeNullableFilter>;
  treatmentStart?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
};

export type PatientsReports = {
  __typename?: 'PatientsReports';
  appointedMonthly?: Maybe<ComparisonField>;
  appointmentStatus?: Maybe<Array<Maybe<MonthAppointmentStatus>>>;
  attendedMonthly?: Maybe<ComparisonField>;
  influxAndSchedule?: Maybe<InfluxAndSchedulePayload>;
  newPatients?: Maybe<NewPatients>;
  patientAcquisition?: Maybe<Array<Maybe<PatientAcquisition>>>;
  patientCount?: Maybe<PatientCount>;
  patientsTrackingReport?: Maybe<Array<Patient>>;
  waitingTimes?: Maybe<WaitingTimeChartPayload>;
};

export type PatientsReportsAppointedMonthlyArgs = {
  branch?: Maybe<BranchWhereInput>;
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type PatientsReportsAppointmentStatusArgs = {
  branchIds: Array<Scalars['String']>;
  year: Scalars['Int'];
};

export type PatientsReportsAttendedMonthlyArgs = {
  branch?: Maybe<BranchWhereInput>;
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type PatientsReportsInfluxAndScheduleArgs = {
  branchId: Scalars['String'];
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type PatientsReportsNewPatientsArgs = {
  branch: BranchWhereInput;
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
  treatment: Treatment;
};

export type PatientsReportsPatientAcquisitionArgs = {
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type PatientsReportsPatientCountArgs = {
  branch?: Maybe<BranchWhereInput>;
  end?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type PatientsReportsPatientsTrackingReportArgs = {
  branchId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  patientId?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTime']>;
  take?: Maybe<Scalars['Int']>;
  treatment?: Maybe<Treatment>;
};

export type PatientsReportsWaitingTimesArgs = {
  branchIds: Array<Scalars['String']>;
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type PatientUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  recordId?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateManyWithoutAddressInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutAddressInput>>;
  create?: Maybe<Array<PatientCreateWithoutAddressInput>>;
  createMany?: Maybe<PatientCreateManyAddressInputEnvelope>;
  delete?: Maybe<Array<PatientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PatientScalarWhereInput>>;
  disconnect?: Maybe<Array<PatientWhereUniqueInput>>;
  set?: Maybe<Array<PatientWhereUniqueInput>>;
  update?: Maybe<Array<PatientUpdateWithWhereUniqueWithoutAddressInput>>;
  updateMany?: Maybe<Array<PatientUpdateManyWithWhereWithoutAddressInput>>;
  upsert?: Maybe<Array<PatientUpsertWithWhereUniqueWithoutAddressInput>>;
};

export type PatientUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PatientCreateWithoutBranchInput>>;
  createMany?: Maybe<PatientCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<PatientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PatientScalarWhereInput>>;
  disconnect?: Maybe<Array<PatientWhereUniqueInput>>;
  set?: Maybe<Array<PatientWhereUniqueInput>>;
  update?: Maybe<Array<PatientUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<PatientUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<PatientUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type PatientUpdateManyWithoutInterestInput = {
  connect?: Maybe<Array<PatientWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PatientCreateOrConnectWithoutInterestInput>>;
  create?: Maybe<Array<PatientCreateWithoutInterestInput>>;
  createMany?: Maybe<PatientCreateManyInterestInputEnvelope>;
  delete?: Maybe<Array<PatientWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PatientScalarWhereInput>>;
  disconnect?: Maybe<Array<PatientWhereUniqueInput>>;
  set?: Maybe<Array<PatientWhereUniqueInput>>;
  update?: Maybe<Array<PatientUpdateWithWhereUniqueWithoutInterestInput>>;
  updateMany?: Maybe<Array<PatientUpdateManyWithWhereWithoutInterestInput>>;
  upsert?: Maybe<Array<PatientUpsertWithWhereUniqueWithoutInterestInput>>;
};

export type PatientUpdateManyWithWhereWithoutAddressInput = {
  data: PatientUpdateManyMutationInput;
  where: PatientScalarWhereInput;
};

export type PatientUpdateManyWithWhereWithoutBranchInput = {
  data: PatientUpdateManyMutationInput;
  where: PatientScalarWhereInput;
};

export type PatientUpdateManyWithWhereWithoutInterestInput = {
  data: PatientUpdateManyMutationInput;
  where: PatientScalarWhereInput;
};

export type PatientUpdateOneRequiredWithoutAppointmentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<PatientCreateWithoutAppointmentsInput>;
  update?: Maybe<PatientUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<PatientUpsertWithoutAppointmentsInput>;
};

export type PatientUpdateOneRequiredWithoutBudgetsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutBudgetsInput>;
  create?: Maybe<PatientCreateWithoutBudgetsInput>;
  update?: Maybe<PatientUpdateWithoutBudgetsInput>;
  upsert?: Maybe<PatientUpsertWithoutBudgetsInput>;
};

export type PatientUpdateOneRequiredWithoutContractsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutContractsInput>;
  create?: Maybe<PatientCreateWithoutContractsInput>;
  update?: Maybe<PatientUpdateWithoutContractsInput>;
  upsert?: Maybe<PatientUpsertWithoutContractsInput>;
};

export type PatientUpdateOneRequiredWithoutCouponsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutCouponsInput>;
  create?: Maybe<PatientCreateWithoutCouponsInput>;
  update?: Maybe<PatientUpdateWithoutCouponsInput>;
  upsert?: Maybe<PatientUpsertWithoutCouponsInput>;
};

export type PatientUpdateOneRequiredWithoutEvolutionNotesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutEvolutionNotesInput>;
  create?: Maybe<PatientCreateWithoutEvolutionNotesInput>;
  update?: Maybe<PatientUpdateWithoutEvolutionNotesInput>;
  upsert?: Maybe<PatientUpsertWithoutEvolutionNotesInput>;
};

export type PatientUpdateOneRequiredWithoutFilesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutFilesInput>;
  create?: Maybe<PatientCreateWithoutFilesInput>;
  update?: Maybe<PatientUpdateWithoutFilesInput>;
  upsert?: Maybe<PatientUpsertWithoutFilesInput>;
};

export type PatientUpdateOneRequiredWithoutInvoiceFileInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutInvoiceFileInput>;
  create?: Maybe<PatientCreateWithoutInvoiceFileInput>;
  update?: Maybe<PatientUpdateWithoutInvoiceFileInput>;
  upsert?: Maybe<PatientUpsertWithoutInvoiceFileInput>;
};

export type PatientUpdateOneRequiredWithoutInvoicesInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutInvoicesInput>;
  create?: Maybe<PatientCreateWithoutInvoicesInput>;
  update?: Maybe<PatientUpdateWithoutInvoicesInput>;
  upsert?: Maybe<PatientUpsertWithoutInvoicesInput>;
};

export type PatientUpdateOneRequiredWithoutLogsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutLogsInput>;
  create?: Maybe<PatientCreateWithoutLogsInput>;
  update?: Maybe<PatientUpdateWithoutLogsInput>;
  upsert?: Maybe<PatientUpsertWithoutLogsInput>;
};

export type PatientUpdateOneRequiredWithoutNotificationsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutNotificationsInput>;
  create?: Maybe<PatientCreateWithoutNotificationsInput>;
  update?: Maybe<PatientUpdateWithoutNotificationsInput>;
  upsert?: Maybe<PatientUpsertWithoutNotificationsInput>;
};

export type PatientUpdateOneRequiredWithoutPaymentCardInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPaymentCardInput>;
  create?: Maybe<PatientCreateWithoutPaymentCardInput>;
  update?: Maybe<PatientUpdateWithoutPaymentCardInput>;
  upsert?: Maybe<PatientUpsertWithoutPaymentCardInput>;
};

export type PatientUpdateOneRequiredWithoutPaymentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPaymentsInput>;
  create?: Maybe<PatientCreateWithoutPaymentsInput>;
  update?: Maybe<PatientUpdateWithoutPaymentsInput>;
  upsert?: Maybe<PatientUpsertWithoutPaymentsInput>;
};

export type PatientUpdateOneRequiredWithoutPrescriptionsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutPrescriptionsInput>;
  create?: Maybe<PatientCreateWithoutPrescriptionsInput>;
  update?: Maybe<PatientUpdateWithoutPrescriptionsInput>;
  upsert?: Maybe<PatientUpsertWithoutPrescriptionsInput>;
};

export type PatientUpdateOneRequiredWithoutRatingsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutRatingsInput>;
  create?: Maybe<PatientCreateWithoutRatingsInput>;
  update?: Maybe<PatientUpdateWithoutRatingsInput>;
  upsert?: Maybe<PatientUpsertWithoutRatingsInput>;
};

export type PatientUpdateOneRequiredWithoutRecurringPaymentsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutRecurringPaymentsInput>;
  create?: Maybe<PatientCreateWithoutRecurringPaymentsInput>;
  update?: Maybe<PatientUpdateWithoutRecurringPaymentsInput>;
  upsert?: Maybe<PatientUpsertWithoutRecurringPaymentsInput>;
};

export type PatientUpdateOneWithoutCardsInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutCardsInput>;
  create?: Maybe<PatientCreateWithoutCardsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PatientUpdateWithoutCardsInput>;
  upsert?: Maybe<PatientUpsertWithoutCardsInput>;
};

export type PatientUpdateOneWithoutChatParticipantInput = {
  connect?: Maybe<PatientWhereUniqueInput>;
  connectOrCreate?: Maybe<PatientCreateOrConnectWithoutChatParticipantInput>;
  create?: Maybe<PatientCreateWithoutChatParticipantInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PatientUpdateWithoutChatParticipantInput>;
  upsert?: Maybe<PatientUpsertWithoutChatParticipantInput>;
};

export type PatientUpdateWithoutAddressInput = {
  active?: Maybe<Scalars['Boolean']>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutAppointmentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutBudgetsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutCardsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutChatParticipantInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutContractsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutCouponsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutEvolutionNotesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutFilesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutInterestInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutInvoiceFileInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutInvoicesInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutLogsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutNotificationsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutPaymentCardInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutPaymentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutPrescriptionsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutRatingsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  recurringPayments?: Maybe<RecurringPaymentUpdateManyWithoutPatientInput>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithoutRecurringPaymentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressUpdateOneWithoutPatientsInput>;
  advertisingMedia?: Maybe<Scalars['String']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPatientInput>;
  archived?: Maybe<Scalars['Boolean']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneRequiredWithoutPatientsInput>;
  budgets?: Maybe<BudgetUpdateManyWithoutPatientInput>;
  cards?: Maybe<CardUpdateManyWithoutPatientInput>;
  chatParticipant?: Maybe<ChatParticipantUpdateOneWithoutPatientInput>;
  consentedToPush?: Maybe<Scalars['Boolean']>;
  contracts?: Maybe<ContractUpdateManyWithoutPatientInput>;
  coupons?: Maybe<CouponUpdateManyWithoutPatientInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultPaymentMethodId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<EmergencyContactUpdateManyWithoutPatientInput>;
  evolutionNotes?: Maybe<EvolutionNoteUpdateManyWithoutPatientInput>;
  facebookName?: Maybe<Scalars['String']>;
  files?: Maybe<FileUpdateManyWithoutPatientInput>;
  gameCoins?: Maybe<Scalars['Int']>;
  hasChangedUsername?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interest?: Maybe<InterestUpdateOneWithoutPatientsInput>;
  invoiceFile?: Maybe<InvoiceFileUpdateOneWithoutPatientInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutPatientInput>;
  isLead?: Maybe<Scalars['Boolean']>;
  landline?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  logs?: Maybe<LogUpdateManyWithoutPatientInput>;
  maritalStatus?: Maybe<MaritalStatus>;
  medicalHistory?: Maybe<MedicalHistoryUpdateOneWithoutPatientInput>;
  name?: Maybe<Scalars['String']>;
  nextAskForPush?: Maybe<Scalars['DateTime']>;
  notifications?: Maybe<PatientNotificationRecipientUpdateManyWithoutPatientInput>;
  occupation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  paymentCard?: Maybe<PaymentCardUpdateManyWithoutPatientInput>;
  payments?: Maybe<PaymentUpdateManyWithoutPatientInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  photoHash?: Maybe<Scalars['String']>;
  physicalActivities?: Maybe<PhysicalActivityUpdateManyWithoutPatientInput>;
  points?: Maybe<Scalars['Int']>;
  pointStories?: Maybe<PointStoryUpdateManyWithoutPatientInput>;
  possibleGameCoins?: Maybe<Scalars['Int']>;
  prescriptions?: Maybe<PrescriptionUpdateManyWithoutPatientInput>;
  ratings?: Maybe<EmployeeRatingUpdateManyWithoutPatientInput>;
  recordId?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  skins?: Maybe<GameSkinUpdateManyWithoutOwnersInput>;
  stripeId?: Maybe<Scalars['String']>;
  treatment?: Maybe<Treatment>;
  treatmentEnd?: Maybe<Scalars['DateTime']>;
  treatmentStart?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

export type PatientUpdateWithWhereUniqueWithoutAddressInput = {
  data: PatientUpdateWithoutAddressInput;
  where: PatientWhereUniqueInput;
};

export type PatientUpdateWithWhereUniqueWithoutBranchInput = {
  data: PatientUpdateWithoutBranchInput;
  where: PatientWhereUniqueInput;
};

export type PatientUpdateWithWhereUniqueWithoutInterestInput = {
  data: PatientUpdateWithoutInterestInput;
  where: PatientWhereUniqueInput;
};

export type PatientUpsertWithoutAppointmentsInput = {
  create: PatientCreateWithoutAppointmentsInput;
  update: PatientUpdateWithoutAppointmentsInput;
};

export type PatientUpsertWithoutBudgetsInput = {
  create: PatientCreateWithoutBudgetsInput;
  update: PatientUpdateWithoutBudgetsInput;
};

export type PatientUpsertWithoutCardsInput = {
  create: PatientCreateWithoutCardsInput;
  update: PatientUpdateWithoutCardsInput;
};

export type PatientUpsertWithoutChatParticipantInput = {
  create: PatientCreateWithoutChatParticipantInput;
  update: PatientUpdateWithoutChatParticipantInput;
};

export type PatientUpsertWithoutContractsInput = {
  create: PatientCreateWithoutContractsInput;
  update: PatientUpdateWithoutContractsInput;
};

export type PatientUpsertWithoutCouponsInput = {
  create: PatientCreateWithoutCouponsInput;
  update: PatientUpdateWithoutCouponsInput;
};

export type PatientUpsertWithoutEvolutionNotesInput = {
  create: PatientCreateWithoutEvolutionNotesInput;
  update: PatientUpdateWithoutEvolutionNotesInput;
};

export type PatientUpsertWithoutFilesInput = {
  create: PatientCreateWithoutFilesInput;
  update: PatientUpdateWithoutFilesInput;
};

export type PatientUpsertWithoutInvoiceFileInput = {
  create: PatientCreateWithoutInvoiceFileInput;
  update: PatientUpdateWithoutInvoiceFileInput;
};

export type PatientUpsertWithoutInvoicesInput = {
  create: PatientCreateWithoutInvoicesInput;
  update: PatientUpdateWithoutInvoicesInput;
};

export type PatientUpsertWithoutLogsInput = {
  create: PatientCreateWithoutLogsInput;
  update: PatientUpdateWithoutLogsInput;
};

export type PatientUpsertWithoutNotificationsInput = {
  create: PatientCreateWithoutNotificationsInput;
  update: PatientUpdateWithoutNotificationsInput;
};

export type PatientUpsertWithoutPaymentCardInput = {
  create: PatientCreateWithoutPaymentCardInput;
  update: PatientUpdateWithoutPaymentCardInput;
};

export type PatientUpsertWithoutPaymentsInput = {
  create: PatientCreateWithoutPaymentsInput;
  update: PatientUpdateWithoutPaymentsInput;
};

export type PatientUpsertWithoutPrescriptionsInput = {
  create: PatientCreateWithoutPrescriptionsInput;
  update: PatientUpdateWithoutPrescriptionsInput;
};

export type PatientUpsertWithoutRatingsInput = {
  create: PatientCreateWithoutRatingsInput;
  update: PatientUpdateWithoutRatingsInput;
};

export type PatientUpsertWithoutRecurringPaymentsInput = {
  create: PatientCreateWithoutRecurringPaymentsInput;
  update: PatientUpdateWithoutRecurringPaymentsInput;
};

export type PatientUpsertWithWhereUniqueWithoutAddressInput = {
  create: PatientCreateWithoutAddressInput;
  update: PatientUpdateWithoutAddressInput;
  where: PatientWhereUniqueInput;
};

export type PatientUpsertWithWhereUniqueWithoutBranchInput = {
  create: PatientCreateWithoutBranchInput;
  update: PatientUpdateWithoutBranchInput;
  where: PatientWhereUniqueInput;
};

export type PatientUpsertWithWhereUniqueWithoutInterestInput = {
  create: PatientCreateWithoutInterestInput;
  update: PatientUpdateWithoutInterestInput;
  where: PatientWhereUniqueInput;
};

export type PatientWhereInput = {
  active?: Maybe<BoolFilter>;
  address?: Maybe<AddressWhereInput>;
  addressId?: Maybe<StringNullableFilter>;
  advertisingMedia?: Maybe<StringNullableFilter>;
  AND?: Maybe<Array<PatientWhereInput>>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  archived?: Maybe<BoolFilter>;
  birthDate?: Maybe<DateTimeNullableFilter>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringFilter>;
  budgets?: Maybe<BudgetListRelationFilter>;
  cards?: Maybe<CardListRelationFilter>;
  chatParticipant?: Maybe<ChatParticipantWhereInput>;
  consentedToPush?: Maybe<BoolFilter>;
  contracts?: Maybe<ContractListRelationFilter>;
  coupons?: Maybe<CouponListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  defaultPaymentMethodId?: Maybe<StringNullableFilter>;
  email?: Maybe<StringNullableFilter>;
  emergencyContact?: Maybe<EmergencyContactListRelationFilter>;
  evolutionNotes?: Maybe<EvolutionNoteListRelationFilter>;
  facebookName?: Maybe<StringNullableFilter>;
  files?: Maybe<FileListRelationFilter>;
  gameCoins?: Maybe<IntFilter>;
  hasChangedUsername?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  interest?: Maybe<InterestWhereInput>;
  interestId?: Maybe<StringNullableFilter>;
  invoiceFile?: Maybe<InvoiceFileWhereInput>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  isLead?: Maybe<BoolFilter>;
  landline?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  logs?: Maybe<LogListRelationFilter>;
  maritalStatus?: Maybe<EnumMaritalStatusFilter>;
  medicalHistory?: Maybe<MedicalHistoryWhereInput>;
  name?: Maybe<StringFilter>;
  nextAskForPush?: Maybe<DateTimeNullableFilter>;
  NOT?: Maybe<Array<PatientWhereInput>>;
  notifications?: Maybe<PatientNotificationRecipientListRelationFilter>;
  occupation?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<PatientWhereInput>>;
  password?: Maybe<StringFilter>;
  paymentCard?: Maybe<PaymentCardListRelationFilter>;
  payments?: Maybe<PaymentListRelationFilter>;
  phoneNumber?: Maybe<StringNullableFilter>;
  photo?: Maybe<StringNullableFilter>;
  photoHash?: Maybe<StringNullableFilter>;
  physicalActivities?: Maybe<PhysicalActivityListRelationFilter>;
  points?: Maybe<IntFilter>;
  pointStories?: Maybe<PointStoryListRelationFilter>;
  possibleGameCoins?: Maybe<IntFilter>;
  prescriptions?: Maybe<PrescriptionListRelationFilter>;
  ratings?: Maybe<EmployeeRatingListRelationFilter>;
  recordId?: Maybe<StringNullableFilter>;
  recurringPayments?: Maybe<RecurringPaymentListRelationFilter>;
  sex?: Maybe<EnumSexFilter>;
  skins?: Maybe<GameSkinListRelationFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  treatment?: Maybe<EnumTreatmentNullableFilter>;
  treatmentEnd?: Maybe<DateTimeNullableFilter>;
  treatmentStart?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  username?: Maybe<StringFilter>;
};

export type PatientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  amountDue: Scalars['Float'];
  amountDueCurrency: Currency;
  amountDueWithInterest?: Maybe<Scalars['Float']>;
  archived: Scalars['Boolean'];
  archivedBy?: Maybe<Employee>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<Branch>;
  commissions: Array<Commission>;
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  dueDate: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  id: Scalars['String'];
  interest?: Maybe<Scalars['Float']>;
  items: Array<PaymentItem>;
  madeFromEcommerce: Scalars['Boolean'];
  material?: Maybe<PaymentConcept>;
  patient: Patient;
  recievedAmountCurrency: Currency;
  recievedAmounts: Array<RecievedAmount>;
  reciever?: Maybe<Employee>;
  remaining?: Maybe<Scalars['Float']>;
  totalRecieved?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentCommissionsArgs = {
  cursor?: Maybe<CommissionWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaymentItemsArgs = {
  cursor?: Maybe<PaymentItemWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaymentRecievedAmountsArgs = {
  cursor?: Maybe<RecievedAmountWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  ccv: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  id: Scalars['String'];
  patient: Patient;
  updatedAt: Scalars['DateTime'];
};

export type PaymentCardCreateInput = {
  cardmodel: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  ccv: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentCardInput;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardCreateManyPatientInput = {
  cardmodel: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  ccv: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PaymentCardCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCardCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PaymentCardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCardCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PaymentCardCreateWithoutPatientInput>>;
  createMany?: Maybe<PaymentCardCreateManyPatientInputEnvelope>;
};

export type PaymentCardCreateOrConnectWithoutPatientInput = {
  create: PaymentCardCreateWithoutPatientInput;
  where: PaymentCardWhereUniqueInput;
};

export type PaymentCardCreateWithoutPatientInput = {
  cardmodel: Scalars['String'];
  cardName: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  ccv: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardListRelationFilter = {
  every?: Maybe<PaymentCardWhereInput>;
  none?: Maybe<PaymentCardWhereInput>;
  some?: Maybe<PaymentCardWhereInput>;
};

export type PaymentCardOrderByInput = {
  cardmodel?: Maybe<SortOrder>;
  cardName?: Maybe<SortOrder>;
  cardNumber?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  ccv?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  expiryMonth?: Maybe<SortOrder>;
  expiryYear?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PaymentCardScalarWhereInput = {
  AND?: Maybe<Array<PaymentCardScalarWhereInput>>;
  cardmodel?: Maybe<StringFilter>;
  cardName?: Maybe<StringFilter>;
  cardNumber?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  ccv?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiryMonth?: Maybe<IntFilter>;
  expiryYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentCardScalarWhereInput>>;
  OR?: Maybe<Array<PaymentCardScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentCardUpdateInput = {
  cardmodel?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  ccv?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentCardInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardUpdateManyMutationInput = {
  cardmodel?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  ccv?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PaymentCardWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCardCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PaymentCardCreateWithoutPatientInput>>;
  createMany?: Maybe<PaymentCardCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PaymentCardWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentCardScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentCardWhereUniqueInput>>;
  set?: Maybe<Array<PaymentCardWhereUniqueInput>>;
  update?: Maybe<Array<PaymentCardUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PaymentCardUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PaymentCardUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PaymentCardUpdateManyWithWhereWithoutPatientInput = {
  data: PaymentCardUpdateManyMutationInput;
  where: PaymentCardScalarWhereInput;
};

export type PaymentCardUpdateWithoutPatientInput = {
  cardmodel?: Maybe<Scalars['String']>;
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  ccv?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCardUpdateWithWhereUniqueWithoutPatientInput = {
  data: PaymentCardUpdateWithoutPatientInput;
  where: PaymentCardWhereUniqueInput;
};

export type PaymentCardUpsertWithWhereUniqueWithoutPatientInput = {
  create: PaymentCardCreateWithoutPatientInput;
  update: PaymentCardUpdateWithoutPatientInput;
  where: PaymentCardWhereUniqueInput;
};

export type PaymentCardWhereInput = {
  AND?: Maybe<Array<PaymentCardWhereInput>>;
  cardmodel?: Maybe<StringFilter>;
  cardName?: Maybe<StringFilter>;
  cardNumber?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  ccv?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  expiryMonth?: Maybe<IntFilter>;
  expiryYear?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentCardWhereInput>>;
  OR?: Maybe<Array<PaymentCardWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentCardWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PaymentConcept = {
  __typename?: 'PaymentConcept';
  active: Scalars['Boolean'];
  category?: Maybe<ServiceCategory>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDownpayment: Scalars['Boolean'];
  isEcommerceConcept: Scalars['Boolean'];
  laboratory?: Maybe<Laboratory>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency: Currency;
  priceWithVat?: Maybe<Scalars['Float']>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctor>;
  type: PaymentConceptType;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usedByItems: Array<PaymentItem>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUsedByItemsArgs = {
  cursor?: Maybe<PaymentItemWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaymentConceptCreateInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateManyBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratoryId?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctorId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<PaymentConceptCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentConceptCreateManyLaboratoryInput = {
  active?: Maybe<Scalars['Boolean']>;
  branchId?: Maybe<Scalars['String']>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctorId?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateManyLaboratoryInputEnvelope = {
  data?: Maybe<Array<PaymentConceptCreateManyLaboratoryInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentConceptCreateManySpecialtyDoctorInput = {
  active?: Maybe<Scalars['Boolean']>;
  branchId?: Maybe<Scalars['String']>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratoryId?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateManySpecialtyDoctorInputEnvelope = {
  data?: Maybe<Array<PaymentConceptCreateManySpecialtyDoctorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentConceptCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutBranchInput>>;
  createMany?: Maybe<PaymentConceptCreateManyBranchInputEnvelope>;
};

export type PaymentConceptCreateNestedManyWithoutLaboratoryInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutLaboratoryInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutLaboratoryInput>>;
  createMany?: Maybe<PaymentConceptCreateManyLaboratoryInputEnvelope>;
};

export type PaymentConceptCreateNestedManyWithoutSpecialtyDoctorInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutSpecialtyDoctorInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutSpecialtyDoctorInput>>;
  createMany?: Maybe<PaymentConceptCreateManySpecialtyDoctorInputEnvelope>;
};

export type PaymentConceptCreateNestedOneWithoutAppointmentsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<PaymentConceptCreateWithoutAppointmentsInput>;
};

export type PaymentConceptCreateNestedOneWithoutBudgetItemsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutBudgetItemsInput>;
  create?: Maybe<PaymentConceptCreateWithoutBudgetItemsInput>;
};

export type PaymentConceptCreateNestedOneWithoutCouponInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutCouponInput>;
  create?: Maybe<PaymentConceptCreateWithoutCouponInput>;
};

export type PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutPaymentsAsMaterialsInput>;
  create?: Maybe<PaymentConceptCreateWithoutPaymentsAsMaterialsInput>;
};

export type PaymentConceptCreateNestedOneWithoutUsedByBudgetsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutUsedByBudgetsInput>;
  create?: Maybe<PaymentConceptCreateWithoutUsedByBudgetsInput>;
};

export type PaymentConceptCreateNestedOneWithoutUsedByItemsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutUsedByItemsInput>;
  create?: Maybe<PaymentConceptCreateWithoutUsedByItemsInput>;
};

export type PaymentConceptCreateOrConnectWithoutAppointmentsInput = {
  create: PaymentConceptCreateWithoutAppointmentsInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutBranchInput = {
  create: PaymentConceptCreateWithoutBranchInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutBudgetItemsInput = {
  create: PaymentConceptCreateWithoutBudgetItemsInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutCouponInput = {
  create: PaymentConceptCreateWithoutCouponInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutLaboratoryInput = {
  create: PaymentConceptCreateWithoutLaboratoryInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutPaymentsAsMaterialsInput = {
  create: PaymentConceptCreateWithoutPaymentsAsMaterialsInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutSpecialtyDoctorInput = {
  create: PaymentConceptCreateWithoutSpecialtyDoctorInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutUsedByBudgetsInput = {
  create: PaymentConceptCreateWithoutUsedByBudgetsInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateOrConnectWithoutUsedByItemsInput = {
  create: PaymentConceptCreateWithoutUsedByItemsInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptCreateWithoutAppointmentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutBudgetItemsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutCouponInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutLaboratoryInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutPaymentsAsMaterialsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutSpecialtyDoctorInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutUsedByBudgetsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByItems?: Maybe<PaymentItemCreateNestedManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptCreateWithoutUsedByItemsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentCreateNestedManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchCreateNestedOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemCreateNestedManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponCreateNestedManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryCreateNestedOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentsAsMaterials?: Maybe<PaymentCreateNestedManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorCreateNestedOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetCreateNestedManyWithoutPackInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptListRelationFilter = {
  every?: Maybe<PaymentConceptWhereInput>;
  none?: Maybe<PaymentConceptWhereInput>;
  some?: Maybe<PaymentConceptWhereInput>;
};

export type PaymentConceptOrderByInput = {
  active?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isDownpayment?: Maybe<SortOrder>;
  isEcommerceConcept?: Maybe<SortOrder>;
  laboratoryId?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  photo?: Maybe<SortOrder>;
  price?: Maybe<SortOrder>;
  priceCurrency?: Maybe<SortOrder>;
  productServiceKey?: Maybe<SortOrder>;
  specialtyDoctorId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  unitKey?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
};

export type PaymentConceptScalarWhereInput = {
  active?: Maybe<BoolFilter>;
  AND?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  branchId?: Maybe<StringNullableFilter>;
  category?: Maybe<EnumServiceCategoryNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDownpayment?: Maybe<BoolFilter>;
  isEcommerceConcept?: Maybe<BoolFilter>;
  laboratoryId?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  OR?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  photo?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  priceCurrency?: Maybe<EnumCurrencyFilter>;
  productServiceKey?: Maybe<StringNullableFilter>;
  specialtyDoctorId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumPaymentConceptTypeFilter>;
  unitKey?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  vat?: Maybe<FloatNullableFilter>;
};

export enum PaymentConceptType {
  COUPON = 'COUPON',
  LABORATORY = 'LABORATORY',
  PACK = 'PACK',
  PRODUCT = 'PRODUCT',
  PROMOTION = 'PROMOTION',
  SERVICE = 'SERVICE',
  SPECIALIST_SERVICE = 'SPECIALIST_SERVICE',
  WARRANTY = 'WARRANTY'
}

export type PaymentConceptUpdateInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateManyMutationInput = {
  active?: Maybe<Scalars['Boolean']>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutBranchInput>>;
  createMany?: Maybe<PaymentConceptCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  set?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  update?: Maybe<Array<PaymentConceptUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<PaymentConceptUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<PaymentConceptUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type PaymentConceptUpdateManyWithoutLaboratoryInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutLaboratoryInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutLaboratoryInput>>;
  createMany?: Maybe<PaymentConceptCreateManyLaboratoryInputEnvelope>;
  delete?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  set?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  update?: Maybe<Array<PaymentConceptUpdateWithWhereUniqueWithoutLaboratoryInput>>;
  updateMany?: Maybe<Array<PaymentConceptUpdateManyWithWhereWithoutLaboratoryInput>>;
  upsert?: Maybe<Array<PaymentConceptUpsertWithWhereUniqueWithoutLaboratoryInput>>;
};

export type PaymentConceptUpdateManyWithoutSpecialtyDoctorInput = {
  connect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentConceptCreateOrConnectWithoutSpecialtyDoctorInput>>;
  create?: Maybe<Array<PaymentConceptCreateWithoutSpecialtyDoctorInput>>;
  createMany?: Maybe<PaymentConceptCreateManySpecialtyDoctorInputEnvelope>;
  delete?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentConceptScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  set?: Maybe<Array<PaymentConceptWhereUniqueInput>>;
  update?: Maybe<Array<PaymentConceptUpdateWithWhereUniqueWithoutSpecialtyDoctorInput>>;
  updateMany?: Maybe<Array<PaymentConceptUpdateManyWithWhereWithoutSpecialtyDoctorInput>>;
  upsert?: Maybe<Array<PaymentConceptUpsertWithWhereUniqueWithoutSpecialtyDoctorInput>>;
};

export type PaymentConceptUpdateManyWithWhereWithoutBranchInput = {
  data: PaymentConceptUpdateManyMutationInput;
  where: PaymentConceptScalarWhereInput;
};

export type PaymentConceptUpdateManyWithWhereWithoutLaboratoryInput = {
  data: PaymentConceptUpdateManyMutationInput;
  where: PaymentConceptScalarWhereInput;
};

export type PaymentConceptUpdateManyWithWhereWithoutSpecialtyDoctorInput = {
  data: PaymentConceptUpdateManyMutationInput;
  where: PaymentConceptScalarWhereInput;
};

export type PaymentConceptUpdateOneRequiredWithoutAppointmentsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutAppointmentsInput>;
  create?: Maybe<PaymentConceptCreateWithoutAppointmentsInput>;
  update?: Maybe<PaymentConceptUpdateWithoutAppointmentsInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutAppointmentsInput>;
};

export type PaymentConceptUpdateOneRequiredWithoutBudgetItemsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutBudgetItemsInput>;
  create?: Maybe<PaymentConceptCreateWithoutBudgetItemsInput>;
  update?: Maybe<PaymentConceptUpdateWithoutBudgetItemsInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutBudgetItemsInput>;
};

export type PaymentConceptUpdateOneRequiredWithoutCouponInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutCouponInput>;
  create?: Maybe<PaymentConceptCreateWithoutCouponInput>;
  update?: Maybe<PaymentConceptUpdateWithoutCouponInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutCouponInput>;
};

export type PaymentConceptUpdateOneRequiredWithoutUsedByItemsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutUsedByItemsInput>;
  create?: Maybe<PaymentConceptCreateWithoutUsedByItemsInput>;
  update?: Maybe<PaymentConceptUpdateWithoutUsedByItemsInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutUsedByItemsInput>;
};

export type PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutPaymentsAsMaterialsInput>;
  create?: Maybe<PaymentConceptCreateWithoutPaymentsAsMaterialsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentConceptUpdateWithoutPaymentsAsMaterialsInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutPaymentsAsMaterialsInput>;
};

export type PaymentConceptUpdateOneWithoutUsedByBudgetsInput = {
  connect?: Maybe<PaymentConceptWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentConceptCreateOrConnectWithoutUsedByBudgetsInput>;
  create?: Maybe<PaymentConceptCreateWithoutUsedByBudgetsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentConceptUpdateWithoutUsedByBudgetsInput>;
  upsert?: Maybe<PaymentConceptUpsertWithoutUsedByBudgetsInput>;
};

export type PaymentConceptUpdateWithoutAppointmentsInput = {
  active?: Maybe<Scalars['Boolean']>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutBranchInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutBudgetItemsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutCouponInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutLaboratoryInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutPaymentsAsMaterialsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutSpecialtyDoctorInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutUsedByBudgetsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByItems?: Maybe<PaymentItemUpdateManyWithoutPaymentConceptInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithoutUsedByItemsInput = {
  active?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<AppointmentUpdateManyWithoutPaymentConceptInput>;
  branch?: Maybe<BranchUpdateOneWithoutServicesInput>;
  budgetItems?: Maybe<BudgetItemUpdateManyWithoutPaymentConceptInput>;
  category?: Maybe<ServiceCategory>;
  Coupon?: Maybe<CouponUpdateManyWithoutPaymentConceptInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDownpayment?: Maybe<Scalars['Boolean']>;
  isEcommerceConcept?: Maybe<Scalars['Boolean']>;
  laboratory?: Maybe<LaboratoryUpdateOneWithoutMaterialsInput>;
  legacyId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentsAsMaterials?: Maybe<PaymentUpdateManyWithoutMaterialInput>;
  photo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Currency>;
  productServiceKey?: Maybe<Scalars['String']>;
  specialtyDoctor?: Maybe<SpecialtyDoctorUpdateOneWithoutServicesInput>;
  type?: Maybe<PaymentConceptType>;
  unitKey?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedByBudgets?: Maybe<BudgetUpdateManyWithoutPackInput>;
  vat?: Maybe<Scalars['Float']>;
};

export type PaymentConceptUpdateWithWhereUniqueWithoutBranchInput = {
  data: PaymentConceptUpdateWithoutBranchInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptUpdateWithWhereUniqueWithoutLaboratoryInput = {
  data: PaymentConceptUpdateWithoutLaboratoryInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptUpdateWithWhereUniqueWithoutSpecialtyDoctorInput = {
  data: PaymentConceptUpdateWithoutSpecialtyDoctorInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptUpsertWithoutAppointmentsInput = {
  create: PaymentConceptCreateWithoutAppointmentsInput;
  update: PaymentConceptUpdateWithoutAppointmentsInput;
};

export type PaymentConceptUpsertWithoutBudgetItemsInput = {
  create: PaymentConceptCreateWithoutBudgetItemsInput;
  update: PaymentConceptUpdateWithoutBudgetItemsInput;
};

export type PaymentConceptUpsertWithoutCouponInput = {
  create: PaymentConceptCreateWithoutCouponInput;
  update: PaymentConceptUpdateWithoutCouponInput;
};

export type PaymentConceptUpsertWithoutPaymentsAsMaterialsInput = {
  create: PaymentConceptCreateWithoutPaymentsAsMaterialsInput;
  update: PaymentConceptUpdateWithoutPaymentsAsMaterialsInput;
};

export type PaymentConceptUpsertWithoutUsedByBudgetsInput = {
  create: PaymentConceptCreateWithoutUsedByBudgetsInput;
  update: PaymentConceptUpdateWithoutUsedByBudgetsInput;
};

export type PaymentConceptUpsertWithoutUsedByItemsInput = {
  create: PaymentConceptCreateWithoutUsedByItemsInput;
  update: PaymentConceptUpdateWithoutUsedByItemsInput;
};

export type PaymentConceptUpsertWithWhereUniqueWithoutBranchInput = {
  create: PaymentConceptCreateWithoutBranchInput;
  update: PaymentConceptUpdateWithoutBranchInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptUpsertWithWhereUniqueWithoutLaboratoryInput = {
  create: PaymentConceptCreateWithoutLaboratoryInput;
  update: PaymentConceptUpdateWithoutLaboratoryInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptUpsertWithWhereUniqueWithoutSpecialtyDoctorInput = {
  create: PaymentConceptCreateWithoutSpecialtyDoctorInput;
  update: PaymentConceptUpdateWithoutSpecialtyDoctorInput;
  where: PaymentConceptWhereUniqueInput;
};

export type PaymentConceptWhereInput = {
  active?: Maybe<BoolFilter>;
  AND?: Maybe<Array<PaymentConceptWhereInput>>;
  appointments?: Maybe<AppointmentListRelationFilter>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  budgetItems?: Maybe<BudgetItemListRelationFilter>;
  category?: Maybe<EnumServiceCategoryNullableFilter>;
  Coupon?: Maybe<CouponListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  isDownpayment?: Maybe<BoolFilter>;
  isEcommerceConcept?: Maybe<BoolFilter>;
  laboratory?: Maybe<LaboratoryWhereInput>;
  laboratoryId?: Maybe<StringNullableFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentConceptWhereInput>>;
  OR?: Maybe<Array<PaymentConceptWhereInput>>;
  paymentsAsMaterials?: Maybe<PaymentListRelationFilter>;
  photo?: Maybe<StringNullableFilter>;
  price?: Maybe<FloatFilter>;
  priceCurrency?: Maybe<EnumCurrencyFilter>;
  productServiceKey?: Maybe<StringNullableFilter>;
  specialtyDoctor?: Maybe<SpecialtyDoctorWhereInput>;
  specialtyDoctorId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumPaymentConceptTypeFilter>;
  unitKey?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  usedByBudgets?: Maybe<BudgetListRelationFilter>;
  usedByItems?: Maybe<PaymentItemListRelationFilter>;
  vat?: Maybe<FloatNullableFilter>;
};

export type PaymentConceptWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
};

export type PaymentCreateInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyArchivedByInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branchId?: Maybe<Scalars['String']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  recievedAmountCurrency?: Maybe<Currency>;
  recieverId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyArchivedByInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyArchivedByInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyBranchInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  recievedAmountCurrency?: Maybe<Currency>;
  recieverId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyEmployeeInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branchId?: Maybe<Scalars['String']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  recievedAmountCurrency?: Maybe<Currency>;
  recieverId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyMaterialInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branchId?: Maybe<Scalars['String']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  recievedAmountCurrency?: Maybe<Currency>;
  recieverId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyMaterialInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyMaterialInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyPatientInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branchId?: Maybe<Scalars['String']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  recievedAmountCurrency?: Maybe<Currency>;
  recieverId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateManyRecieverInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointmentId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branchId?: Maybe<Scalars['String']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  materialId?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  recievedAmountCurrency?: Maybe<Currency>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateManyRecieverInputEnvelope = {
  data?: Maybe<Array<PaymentCreateManyRecieverInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentCreateNestedManyWithoutArchivedByInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutArchivedByInput>>;
  create?: Maybe<Array<PaymentCreateWithoutArchivedByInput>>;
  createMany?: Maybe<PaymentCreateManyArchivedByInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PaymentCreateWithoutBranchInput>>;
  createMany?: Maybe<PaymentCreateManyBranchInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PaymentCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PaymentCreateManyEmployeeInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutMaterialInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutMaterialInput>>;
  create?: Maybe<Array<PaymentCreateWithoutMaterialInput>>;
  createMany?: Maybe<PaymentCreateManyMaterialInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PaymentCreateWithoutPatientInput>>;
  createMany?: Maybe<PaymentCreateManyPatientInputEnvelope>;
};

export type PaymentCreateNestedManyWithoutRecieverInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutRecieverInput>>;
  create?: Maybe<Array<PaymentCreateWithoutRecieverInput>>;
  createMany?: Maybe<PaymentCreateManyRecieverInputEnvelope>;
};

export type PaymentCreateNestedOneWithoutAppointmentInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutAppointmentInput>;
  create?: Maybe<PaymentCreateWithoutAppointmentInput>;
};

export type PaymentCreateNestedOneWithoutItemsInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutItemsInput>;
  create?: Maybe<PaymentCreateWithoutItemsInput>;
};

export type PaymentCreateNestedOneWithoutRecievedAmountsInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutRecievedAmountsInput>;
  create?: Maybe<PaymentCreateWithoutRecievedAmountsInput>;
};

export type PaymentCreateOrConnectWithoutAppointmentInput = {
  create: PaymentCreateWithoutAppointmentInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutArchivedByInput = {
  create: PaymentCreateWithoutArchivedByInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutBranchInput = {
  create: PaymentCreateWithoutBranchInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutCommissionsInput = {
  create: PaymentCreateWithoutCommissionsInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutEmployeeInput = {
  create: PaymentCreateWithoutEmployeeInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutItemsInput = {
  create: PaymentCreateWithoutItemsInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutMaterialInput = {
  create: PaymentCreateWithoutMaterialInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutPatientInput = {
  create: PaymentCreateWithoutPatientInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutRecievedAmountsInput = {
  create: PaymentCreateWithoutRecievedAmountsInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateOrConnectWithoutRecieverInput = {
  create: PaymentCreateWithoutRecieverInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentCreateWithoutAppointmentInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutArchivedByInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutBranchInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutCommissionsInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutEmployeeInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutItemsInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutMaterialInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutPatientInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutRecievedAmountsInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  reciever?: Maybe<EmployeeCreateNestedOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentCreateWithoutRecieverInput = {
  amountDue: Scalars['Float'];
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentCreateNestedOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchCreateNestedOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionCreateNestedManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate: Scalars['DateTime'];
  employee?: Maybe<EmployeeCreateNestedOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemCreateNestedManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptCreateNestedOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPaymentsInput;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItem = {
  __typename?: 'PaymentItem';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  payment: Payment;
  paymentConcept: PaymentConcept;
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentItemCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutItemsInput;
  paymentConcept: PaymentConceptCreateNestedOneWithoutUsedByItemsInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemCreateManyPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemCreateManyPaymentConceptInputEnvelope = {
  data?: Maybe<Array<PaymentItemCreateManyPaymentConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentItemCreateManyPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConceptId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemCreateManyPaymentInputEnvelope = {
  data?: Maybe<Array<PaymentItemCreateManyPaymentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PaymentItemCreateNestedManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentItemCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<PaymentItemCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<PaymentItemCreateManyPaymentConceptInputEnvelope>;
};

export type PaymentItemCreateNestedManyWithoutPaymentInput = {
  connect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentItemCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentItemCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentItemCreateManyPaymentInputEnvelope>;
};

export type PaymentItemCreateOrConnectWithoutPaymentConceptInput = {
  create: PaymentItemCreateWithoutPaymentConceptInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemCreateOrConnectWithoutPaymentInput = {
  create: PaymentItemCreateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemCreateWithoutPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutItemsInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemCreateWithoutPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept: PaymentConceptCreateNestedOneWithoutUsedByItemsInput;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemListRelationFilter = {
  every?: Maybe<PaymentItemWhereInput>;
  none?: Maybe<PaymentItemWhereInput>;
  some?: Maybe<PaymentItemWhereInput>;
};

export type PaymentItemOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paymentConceptId?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PaymentItemScalarWhereInput = {
  AND?: Maybe<Array<PaymentItemScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentItemScalarWhereInput>>;
  OR?: Maybe<Array<PaymentItemScalarWhereInput>>;
  paymentConceptId?: Maybe<StringFilter>;
  paymentId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentItemUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutItemsInput>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutUsedByItemsInput>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemUpdateManyWithoutPaymentConceptInput = {
  connect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentItemCreateOrConnectWithoutPaymentConceptInput>>;
  create?: Maybe<Array<PaymentItemCreateWithoutPaymentConceptInput>>;
  createMany?: Maybe<PaymentItemCreateManyPaymentConceptInputEnvelope>;
  delete?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  set?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  update?: Maybe<Array<PaymentItemUpdateWithWhereUniqueWithoutPaymentConceptInput>>;
  updateMany?: Maybe<Array<PaymentItemUpdateManyWithWhereWithoutPaymentConceptInput>>;
  upsert?: Maybe<Array<PaymentItemUpsertWithWhereUniqueWithoutPaymentConceptInput>>;
};

export type PaymentItemUpdateManyWithoutPaymentInput = {
  connect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentItemCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<PaymentItemCreateWithoutPaymentInput>>;
  createMany?: Maybe<PaymentItemCreateManyPaymentInputEnvelope>;
  delete?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentItemScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  set?: Maybe<Array<PaymentItemWhereUniqueInput>>;
  update?: Maybe<Array<PaymentItemUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<PaymentItemUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: Maybe<Array<PaymentItemUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type PaymentItemUpdateManyWithWhereWithoutPaymentConceptInput = {
  data: PaymentItemUpdateManyMutationInput;
  where: PaymentItemScalarWhereInput;
};

export type PaymentItemUpdateManyWithWhereWithoutPaymentInput = {
  data: PaymentItemUpdateManyMutationInput;
  where: PaymentItemScalarWhereInput;
};

export type PaymentItemUpdateWithoutPaymentConceptInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutItemsInput>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemUpdateWithoutPaymentInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  paymentConcept?: Maybe<PaymentConceptUpdateOneRequiredWithoutUsedByItemsInput>;
  quantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentItemUpdateWithWhereUniqueWithoutPaymentConceptInput = {
  data: PaymentItemUpdateWithoutPaymentConceptInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpdateWithWhereUniqueWithoutPaymentInput = {
  data: PaymentItemUpdateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpsertWithWhereUniqueWithoutPaymentConceptInput = {
  create: PaymentItemCreateWithoutPaymentConceptInput;
  update: PaymentItemUpdateWithoutPaymentConceptInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemUpsertWithWhereUniqueWithoutPaymentInput = {
  create: PaymentItemCreateWithoutPaymentInput;
  update: PaymentItemUpdateWithoutPaymentInput;
  where: PaymentItemWhereUniqueInput;
};

export type PaymentItemWhereInput = {
  AND?: Maybe<Array<PaymentItemWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentItemWhereInput>>;
  OR?: Maybe<Array<PaymentItemWhereInput>>;
  payment?: Maybe<PaymentWhereInput>;
  paymentConcept?: Maybe<PaymentConceptWhereInput>;
  paymentConceptId?: Maybe<StringFilter>;
  paymentId?: Maybe<StringFilter>;
  quantity?: Maybe<IntFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentItemWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PaymentListRelationFilter = {
  every?: Maybe<PaymentWhereInput>;
  none?: Maybe<PaymentWhereInput>;
  some?: Maybe<PaymentWhereInput>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isReportable: Scalars['Boolean'];
  name: Scalars['String'];
  recievedAmounts: Array<RecievedAmount>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentMethodRecievedAmountsArgs = {
  cursor?: Maybe<RecievedAmountWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaymentMethodCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isReportable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  recievedAmounts?: Maybe<RecievedAmountCreateNestedManyWithoutPaymentMethodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodCreateNestedOneWithoutRecievedAmountsInput = {
  connect?: Maybe<PaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentMethodCreateOrConnectWithoutRecievedAmountsInput>;
  create?: Maybe<PaymentMethodCreateWithoutRecievedAmountsInput>;
};

export type PaymentMethodCreateOrConnectWithoutRecievedAmountsInput = {
  create: PaymentMethodCreateWithoutRecievedAmountsInput;
  where: PaymentMethodWhereUniqueInput;
};

export type PaymentMethodCreateWithoutRecievedAmountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isReportable?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodIncome = {
  __typename?: 'PaymentMethodIncome';
  income?: Maybe<ComparisonField>;
  method?: Maybe<Scalars['String']>;
};

export type PaymentMethodOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  isReportable?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PaymentMethodTotal = {
  __typename?: 'PaymentMethodTotal';
  amount: Scalars['Float'];
  method: Scalars['String'];
};

export type PaymentMethodUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isReportable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentMethodInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isReportable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodUpdateOneWithoutRecievedAmountsInput = {
  connect?: Maybe<PaymentMethodWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentMethodCreateOrConnectWithoutRecievedAmountsInput>;
  create?: Maybe<PaymentMethodCreateWithoutRecievedAmountsInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentMethodUpdateWithoutRecievedAmountsInput>;
  upsert?: Maybe<PaymentMethodUpsertWithoutRecievedAmountsInput>;
};

export type PaymentMethodUpdateWithoutRecievedAmountsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isReportable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentMethodUpsertWithoutRecievedAmountsInput = {
  create: PaymentMethodCreateWithoutRecievedAmountsInput;
  update: PaymentMethodUpdateWithoutRecievedAmountsInput;
};

export type PaymentMethodWhereInput = {
  AND?: Maybe<Array<PaymentMethodWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  isReportable?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PaymentMethodWhereInput>>;
  OR?: Maybe<Array<PaymentMethodWhereInput>>;
  recievedAmounts?: Maybe<RecievedAmountListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentMethodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PaymentOrderByInput = {
  amountDue?: Maybe<SortOrder>;
  amountDueCurrency?: Maybe<SortOrder>;
  appointmentId?: Maybe<SortOrder>;
  archived?: Maybe<SortOrder>;
  archivedById?: Maybe<SortOrder>;
  archiveTime?: Maybe<SortOrder>;
  branchId?: Maybe<SortOrder>;
  commissionPaid?: Maybe<SortOrder>;
  completed?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dueDate?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  legacyId?: Maybe<SortOrder>;
  madeFromEcommerce?: Maybe<SortOrder>;
  materialId?: Maybe<SortOrder>;
  observations?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  recievedAmountCurrency?: Maybe<SortOrder>;
  recieverId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PaymentScalarWhereInput = {
  amountDue?: Maybe<FloatFilter>;
  amountDueCurrency?: Maybe<EnumCurrencyFilter>;
  AND?: Maybe<Array<PaymentScalarWhereInput>>;
  appointmentId?: Maybe<StringNullableFilter>;
  archived?: Maybe<BoolFilter>;
  archivedById?: Maybe<StringNullableFilter>;
  archiveTime?: Maybe<DateTimeNullableFilter>;
  branchId?: Maybe<StringNullableFilter>;
  commissionPaid?: Maybe<BoolFilter>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  madeFromEcommerce?: Maybe<BoolFilter>;
  materialId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<PaymentScalarWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<PaymentScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  recievedAmountCurrency?: Maybe<EnumCurrencyFilter>;
  recieverId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentStatusPayload = {
  __typename?: 'PaymentStatusPayload';
  authorizationCode?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  customReference?: Maybe<Scalars['String']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  grossAmount?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['String']>;
  paymentPostDate?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentTimestamp?: Maybe<Scalars['String']>;
};

export type PaymentUpdateInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateManyMutationInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  recievedAmountCurrency?: Maybe<Currency>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateManyWithoutArchivedByInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutArchivedByInput>>;
  create?: Maybe<Array<PaymentCreateWithoutArchivedByInput>>;
  createMany?: Maybe<PaymentCreateManyArchivedByInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutArchivedByInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutArchivedByInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutArchivedByInput>>;
};

export type PaymentUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PaymentCreateWithoutBranchInput>>;
  createMany?: Maybe<PaymentCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type PaymentUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PaymentCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PaymentCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PaymentUpdateManyWithoutMaterialInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutMaterialInput>>;
  create?: Maybe<Array<PaymentCreateWithoutMaterialInput>>;
  createMany?: Maybe<PaymentCreateManyMaterialInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutMaterialInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutMaterialInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutMaterialInput>>;
};

export type PaymentUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PaymentCreateWithoutPatientInput>>;
  createMany?: Maybe<PaymentCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PaymentUpdateManyWithoutRecieverInput = {
  connect?: Maybe<Array<PaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PaymentCreateOrConnectWithoutRecieverInput>>;
  create?: Maybe<Array<PaymentCreateWithoutRecieverInput>>;
  createMany?: Maybe<PaymentCreateManyRecieverInputEnvelope>;
  delete?: Maybe<Array<PaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<PaymentWhereUniqueInput>>;
  set?: Maybe<Array<PaymentWhereUniqueInput>>;
  update?: Maybe<Array<PaymentUpdateWithWhereUniqueWithoutRecieverInput>>;
  updateMany?: Maybe<Array<PaymentUpdateManyWithWhereWithoutRecieverInput>>;
  upsert?: Maybe<Array<PaymentUpsertWithWhereUniqueWithoutRecieverInput>>;
};

export type PaymentUpdateManyWithWhereWithoutArchivedByInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutBranchInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutEmployeeInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutMaterialInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutPatientInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateManyWithWhereWithoutRecieverInput = {
  data: PaymentUpdateManyMutationInput;
  where: PaymentScalarWhereInput;
};

export type PaymentUpdateOneRequiredWithoutCommissionsInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutCommissionsInput>;
  create?: Maybe<PaymentCreateWithoutCommissionsInput>;
  update?: Maybe<PaymentUpdateWithoutCommissionsInput>;
  upsert?: Maybe<PaymentUpsertWithoutCommissionsInput>;
};

export type PaymentUpdateOneRequiredWithoutItemsInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutItemsInput>;
  create?: Maybe<PaymentCreateWithoutItemsInput>;
  update?: Maybe<PaymentUpdateWithoutItemsInput>;
  upsert?: Maybe<PaymentUpsertWithoutItemsInput>;
};

export type PaymentUpdateOneRequiredWithoutRecievedAmountsInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutRecievedAmountsInput>;
  create?: Maybe<PaymentCreateWithoutRecievedAmountsInput>;
  update?: Maybe<PaymentUpdateWithoutRecievedAmountsInput>;
  upsert?: Maybe<PaymentUpsertWithoutRecievedAmountsInput>;
};

export type PaymentUpdateOneWithoutAppointmentInput = {
  connect?: Maybe<PaymentWhereUniqueInput>;
  connectOrCreate?: Maybe<PaymentCreateOrConnectWithoutAppointmentInput>;
  create?: Maybe<PaymentCreateWithoutAppointmentInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PaymentUpdateWithoutAppointmentInput>;
  upsert?: Maybe<PaymentUpsertWithoutAppointmentInput>;
};

export type PaymentUpdateWithoutAppointmentInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutArchivedByInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutBranchInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutCommissionsInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutEmployeeInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutItemsInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutMaterialInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutPatientInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutRecievedAmountsInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  reciever?: Maybe<EmployeeUpdateOneWithoutRecievedPaymentsInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithoutRecieverInput = {
  amountDue?: Maybe<Scalars['Float']>;
  amountDueCurrency?: Maybe<Currency>;
  appointment?: Maybe<AppointmentUpdateOneWithoutPaymentInput>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedPaymentsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  branch?: Maybe<BranchUpdateOneWithoutPaymentsInput>;
  commissionPaid?: Maybe<Scalars['Boolean']>;
  commissions?: Maybe<CommissionUpdateManyWithoutPaymentInput>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCreatedPaymentsInput>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<PaymentItemUpdateManyWithoutPaymentInput>;
  legacyId?: Maybe<Scalars['String']>;
  madeFromEcommerce?: Maybe<Scalars['Boolean']>;
  material?: Maybe<PaymentConceptUpdateOneWithoutPaymentsAsMaterialsInput>;
  observations?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPaymentsInput>;
  recievedAmountCurrency?: Maybe<Currency>;
  recievedAmounts?: Maybe<RecievedAmountUpdateManyWithoutPaymentInput>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PaymentUpdateWithWhereUniqueWithoutArchivedByInput = {
  data: PaymentUpdateWithoutArchivedByInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutBranchInput = {
  data: PaymentUpdateWithoutBranchInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PaymentUpdateWithoutEmployeeInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutMaterialInput = {
  data: PaymentUpdateWithoutMaterialInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutPatientInput = {
  data: PaymentUpdateWithoutPatientInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpdateWithWhereUniqueWithoutRecieverInput = {
  data: PaymentUpdateWithoutRecieverInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithoutAppointmentInput = {
  create: PaymentCreateWithoutAppointmentInput;
  update: PaymentUpdateWithoutAppointmentInput;
};

export type PaymentUpsertWithoutCommissionsInput = {
  create: PaymentCreateWithoutCommissionsInput;
  update: PaymentUpdateWithoutCommissionsInput;
};

export type PaymentUpsertWithoutItemsInput = {
  create: PaymentCreateWithoutItemsInput;
  update: PaymentUpdateWithoutItemsInput;
};

export type PaymentUpsertWithoutRecievedAmountsInput = {
  create: PaymentCreateWithoutRecievedAmountsInput;
  update: PaymentUpdateWithoutRecievedAmountsInput;
};

export type PaymentUpsertWithWhereUniqueWithoutArchivedByInput = {
  create: PaymentCreateWithoutArchivedByInput;
  update: PaymentUpdateWithoutArchivedByInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutBranchInput = {
  create: PaymentCreateWithoutBranchInput;
  update: PaymentUpdateWithoutBranchInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PaymentCreateWithoutEmployeeInput;
  update: PaymentUpdateWithoutEmployeeInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutMaterialInput = {
  create: PaymentCreateWithoutMaterialInput;
  update: PaymentUpdateWithoutMaterialInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutPatientInput = {
  create: PaymentCreateWithoutPatientInput;
  update: PaymentUpdateWithoutPatientInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentUpsertWithWhereUniqueWithoutRecieverInput = {
  create: PaymentCreateWithoutRecieverInput;
  update: PaymentUpdateWithoutRecieverInput;
  where: PaymentWhereUniqueInput;
};

export type PaymentWhereInput = {
  amountDue?: Maybe<FloatFilter>;
  amountDueCurrency?: Maybe<EnumCurrencyFilter>;
  AND?: Maybe<Array<PaymentWhereInput>>;
  appointment?: Maybe<AppointmentWhereInput>;
  appointmentId?: Maybe<StringNullableFilter>;
  archived?: Maybe<BoolFilter>;
  archivedBy?: Maybe<EmployeeWhereInput>;
  archivedById?: Maybe<StringNullableFilter>;
  archiveTime?: Maybe<DateTimeNullableFilter>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringNullableFilter>;
  commissionPaid?: Maybe<BoolFilter>;
  commissions?: Maybe<CommissionListRelationFilter>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dueDate?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  items?: Maybe<PaymentItemListRelationFilter>;
  legacyId?: Maybe<StringNullableFilter>;
  madeFromEcommerce?: Maybe<BoolFilter>;
  material?: Maybe<PaymentConceptWhereInput>;
  materialId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<PaymentWhereInput>>;
  observations?: Maybe<StringNullableFilter>;
  OR?: Maybe<Array<PaymentWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  recievedAmountCurrency?: Maybe<EnumCurrencyFilter>;
  recievedAmounts?: Maybe<RecievedAmountListRelationFilter>;
  reciever?: Maybe<EmployeeWhereInput>;
  recieverId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['String']>;
};

export type PhysicalActivityCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type PhysicalActivityCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PhysicalActivityCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PhysicalActivityCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PhysicalActivityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhysicalActivityCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PhysicalActivityCreateWithoutPatientInput>>;
  createMany?: Maybe<PhysicalActivityCreateManyPatientInputEnvelope>;
};

export type PhysicalActivityCreateOrConnectWithoutPatientInput = {
  create: PhysicalActivityCreateWithoutPatientInput;
  where: PhysicalActivityWhereUniqueInput;
};

export type PhysicalActivityCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type PhysicalActivityListRelationFilter = {
  every?: Maybe<PhysicalActivityWhereInput>;
  none?: Maybe<PhysicalActivityWhereInput>;
  some?: Maybe<PhysicalActivityWhereInput>;
};

export type PhysicalActivityScalarWhereInput = {
  AND?: Maybe<Array<PhysicalActivityScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PhysicalActivityScalarWhereInput>>;
  OR?: Maybe<Array<PhysicalActivityScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
};

export type PhysicalActivityUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type PhysicalActivityUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PhysicalActivityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PhysicalActivityCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PhysicalActivityCreateWithoutPatientInput>>;
  createMany?: Maybe<PhysicalActivityCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PhysicalActivityWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PhysicalActivityScalarWhereInput>>;
  disconnect?: Maybe<Array<PhysicalActivityWhereUniqueInput>>;
  set?: Maybe<Array<PhysicalActivityWhereUniqueInput>>;
  update?: Maybe<Array<PhysicalActivityUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PhysicalActivityUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PhysicalActivityUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PhysicalActivityUpdateManyWithWhereWithoutPatientInput = {
  data: PhysicalActivityUpdateManyMutationInput;
  where: PhysicalActivityScalarWhereInput;
};

export type PhysicalActivityUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export type PhysicalActivityUpdateWithWhereUniqueWithoutPatientInput = {
  data: PhysicalActivityUpdateWithoutPatientInput;
  where: PhysicalActivityWhereUniqueInput;
};

export type PhysicalActivityUpsertWithWhereUniqueWithoutPatientInput = {
  create: PhysicalActivityCreateWithoutPatientInput;
  update: PhysicalActivityUpdateWithoutPatientInput;
  where: PhysicalActivityWhereUniqueInput;
};

export type PhysicalActivityWhereInput = {
  AND?: Maybe<Array<PhysicalActivityWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PhysicalActivityWhereInput>>;
  OR?: Maybe<Array<PhysicalActivityWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  value?: Maybe<StringFilter>;
};

export type PhysicalActivityWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PointsOverview = {
  __typename?: 'PointsOverview';
  appointmentsAttended: Scalars['Int'];
  appointmentsMissed: Scalars['Int'];
  monthPointStories?: Maybe<Array<PointStory>>;
  streak: Scalars['Int'];
  totalPoints: Scalars['Int'];
};

export type PointStory = {
  __typename?: 'PointStory';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  patient: Patient;
  points: Scalars['Int'];
  reason: PointStoryReason;
  updatedAt: Scalars['DateTime'];
};

export type PointStoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPointStoriesInput;
  points: Scalars['Int'];
  reason?: Maybe<PointStoryReason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PointStoryCreateManyPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  reason?: Maybe<PointStoryReason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PointStoryCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PointStoryCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PointStoryCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PointStoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PointStoryCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PointStoryCreateWithoutPatientInput>>;
  createMany?: Maybe<PointStoryCreateManyPatientInputEnvelope>;
};

export type PointStoryCreateOrConnectWithoutPatientInput = {
  create: PointStoryCreateWithoutPatientInput;
  where: PointStoryWhereUniqueInput;
};

export type PointStoryCreateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  points: Scalars['Int'];
  reason?: Maybe<PointStoryReason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PointStoryListRelationFilter = {
  every?: Maybe<PointStoryWhereInput>;
  none?: Maybe<PointStoryWhereInput>;
  some?: Maybe<PointStoryWhereInput>;
};

export type PointStoryOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  reason?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PointStoryReason {
  ARRIVED_EARLY = 'ARRIVED_EARLY',
  ATTENDED_APPOINTMENT = 'ATTENDED_APPOINTMENT',
  CANCELED_APPOINTMENT = 'CANCELED_APPOINTMENT',
  CONRIFMED_APPOINTMENT = 'CONRIFMED_APPOINTMENT',
  DEFAULT = 'DEFAULT',
  GAME_BONUS = 'GAME_BONUS',
  MISSED_APPOINTMENT = 'MISSED_APPOINTMENT'
}

export type PointStoryScalarWhereInput = {
  AND?: Maybe<Array<PointStoryScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PointStoryScalarWhereInput>>;
  OR?: Maybe<Array<PointStoryScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  points?: Maybe<IntFilter>;
  reason?: Maybe<EnumPointStoryReasonFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PointStoryUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  reason?: Maybe<PointStoryReason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PointStoryUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PointStoryWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PointStoryCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PointStoryCreateWithoutPatientInput>>;
  createMany?: Maybe<PointStoryCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PointStoryWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PointStoryScalarWhereInput>>;
  disconnect?: Maybe<Array<PointStoryWhereUniqueInput>>;
  set?: Maybe<Array<PointStoryWhereUniqueInput>>;
  update?: Maybe<Array<PointStoryUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PointStoryUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PointStoryUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PointStoryUpdateManyWithWhereWithoutPatientInput = {
  data: PointStoryUpdateManyMutationInput;
  where: PointStoryScalarWhereInput;
};

export type PointStoryUpdateWithoutPatientInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
  reason?: Maybe<PointStoryReason>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PointStoryUpdateWithWhereUniqueWithoutPatientInput = {
  data: PointStoryUpdateWithoutPatientInput;
  where: PointStoryWhereUniqueInput;
};

export type PointStoryUpsertWithWhereUniqueWithoutPatientInput = {
  create: PointStoryCreateWithoutPatientInput;
  update: PointStoryUpdateWithoutPatientInput;
  where: PointStoryWhereUniqueInput;
};

export type PointStoryWhereInput = {
  AND?: Maybe<Array<PointStoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PointStoryWhereInput>>;
  OR?: Maybe<Array<PointStoryWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  points?: Maybe<IntFilter>;
  reason?: Maybe<EnumPointStoryReasonFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PointStoryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Prescription = {
  __typename?: 'Prescription';
  branch: Branch;
  createdAt: Scalars['DateTime'];
  employee: Employee;
  id: Scalars['String'];
  patient: Patient;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PrescriptionCreateInput = {
  branch: BranchCreateNestedOneWithoutPrescriptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutPrescriptionsInput;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPrescriptionsInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyBranchInputEnvelope = {
  data?: Maybe<Array<PrescriptionCreateManyBranchInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyEmployeeInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<PrescriptionCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateManyPatientInput = {
  branchId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<PrescriptionCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PrescriptionCreateNestedManyWithoutBranchInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutBranchInput>>;
  createMany?: Maybe<PrescriptionCreateManyBranchInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PrescriptionCreateManyEmployeeInputEnvelope>;
};

export type PrescriptionCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPatientInput>>;
  createMany?: Maybe<PrescriptionCreateManyPatientInputEnvelope>;
};

export type PrescriptionCreateOrConnectWithoutBranchInput = {
  create: PrescriptionCreateWithoutBranchInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutEmployeeInput = {
  create: PrescriptionCreateWithoutEmployeeInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateOrConnectWithoutPatientInput = {
  create: PrescriptionCreateWithoutPatientInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionCreateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutPrescriptionsInput;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPrescriptionsInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutEmployeeInput = {
  branch: BranchCreateNestedOneWithoutPrescriptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutPrescriptionsInput;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionCreateWithoutPatientInput = {
  branch: BranchCreateNestedOneWithoutPrescriptionsInput;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee: EmployeeCreateNestedOneWithoutPrescriptionsInput;
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionListRelationFilter = {
  every?: Maybe<PrescriptionWhereInput>;
  none?: Maybe<PrescriptionWhereInput>;
  some?: Maybe<PrescriptionWhereInput>;
};

export type PrescriptionOrderByInput = {
  branchId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PrescriptionScalarWhereInput = {
  AND?: Maybe<Array<PrescriptionScalarWhereInput>>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PrescriptionScalarWhereInput>>;
  OR?: Maybe<Array<PrescriptionScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PrescriptionUpdateInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutPrescriptionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutPrescriptionsInput>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPrescriptionsInput>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateManyWithoutBranchInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutBranchInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutBranchInput>>;
  createMany?: Maybe<PrescriptionCreateManyBranchInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutBranchInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutBranchInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutBranchInput>>;
};

export type PrescriptionUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutEmployeeInput>>;
  createMany?: Maybe<PrescriptionCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type PrescriptionUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrescriptionCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<PrescriptionCreateWithoutPatientInput>>;
  createMany?: Maybe<PrescriptionCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PrescriptionScalarWhereInput>>;
  disconnect?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  set?: Maybe<Array<PrescriptionWhereUniqueInput>>;
  update?: Maybe<Array<PrescriptionUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<PrescriptionUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<PrescriptionUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type PrescriptionUpdateManyWithWhereWithoutBranchInput = {
  data: PrescriptionUpdateManyMutationInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutEmployeeInput = {
  data: PrescriptionUpdateManyMutationInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateManyWithWhereWithoutPatientInput = {
  data: PrescriptionUpdateManyMutationInput;
  where: PrescriptionScalarWhereInput;
};

export type PrescriptionUpdateWithoutBranchInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutPrescriptionsInput>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPrescriptionsInput>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutEmployeeInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutPrescriptionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutPrescriptionsInput>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithoutPatientInput = {
  branch?: Maybe<BranchUpdateOneRequiredWithoutPrescriptionsInput>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneRequiredWithoutPrescriptionsInput>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PrescriptionUpdateWithWhereUniqueWithoutBranchInput = {
  data: PrescriptionUpdateWithoutBranchInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: PrescriptionUpdateWithoutEmployeeInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpdateWithWhereUniqueWithoutPatientInput = {
  data: PrescriptionUpdateWithoutPatientInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutBranchInput = {
  create: PrescriptionCreateWithoutBranchInput;
  update: PrescriptionUpdateWithoutBranchInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: PrescriptionCreateWithoutEmployeeInput;
  update: PrescriptionUpdateWithoutEmployeeInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionUpsertWithWhereUniqueWithoutPatientInput = {
  create: PrescriptionCreateWithoutPatientInput;
  update: PrescriptionUpdateWithoutPatientInput;
  where: PrescriptionWhereUniqueInput;
};

export type PrescriptionWhereInput = {
  AND?: Maybe<Array<PrescriptionWhereInput>>;
  branch?: Maybe<BranchWhereInput>;
  branchId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<PrescriptionWhereInput>>;
  OR?: Maybe<Array<PrescriptionWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PrescriptionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<Address>;
  addresses: Array<Address>;
  announcement?: Maybe<Announcement>;
  announcements: Array<Announcement>;
  announcementView?: Maybe<AnnouncementView>;
  announcementViews: Array<AnnouncementView>;
  appointment?: Maybe<Appointment>;
  appointments: Array<Appointment>;
  bankingFile?: Maybe<BankingFile>;
  bankingFiles: Array<BankingFile>;
  blogPost?: Maybe<BlogPost>;
  blogPosts: Array<BlogPost>;
  branch?: Maybe<Branch>;
  branches: Array<Branch>;
  branchService?: Maybe<BranchService>;
  branchServices: Array<BranchService>;
  budget?: Maybe<Budget>;
  budgetItem?: Maybe<BudgetItem>;
  budgetItems: Array<BudgetItem>;
  budgets: Array<Budget>;
  card?: Maybe<Card>;
  cards: Array<Card>;
  cashClosing?: Maybe<CashClosing>;
  cashClosings: Array<CashClosing>;
  chat?: Maybe<Chat>;
  chatMessage?: Maybe<ChatMessage>;
  chatMessages: Array<ChatMessage>;
  chatParticipant?: Maybe<ChatParticipant>;
  chatParticipants: Array<ChatParticipant>;
  chats: Array<Chat>;
  commissions: Array<Commission>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  count?: Maybe<ModelCounter>;
  countPatientsByBirthdate?: Maybe<Scalars['Int']>;
  countPatientsTrackingReport?: Maybe<Scalars['Int']>;
  coupon?: Maybe<Coupon>;
  coupons: Array<Coupon>;
  dashboard?: Maybe<DashboardPayload>;
  day?: Maybe<Day>;
  days: Array<Day>;
  daysAvailability?: Maybe<Array<Maybe<DaysAvailability>>>;
  doctorFile?: Maybe<DoctorFile>;
  doctorFiles: Array<DoctorFile>;
  employee?: Maybe<Employee>;
  employeeRating?: Maybe<EmployeeRating>;
  employeeRatings: Array<EmployeeRating>;
  employees: Array<Employee>;
  evolutionNote?: Maybe<EvolutionNote>;
  evolutionNotes: Array<EvolutionNote>;
  expense?: Maybe<Expense>;
  expenses: Array<Expense>;
  /** Shows a breakdown of the fee that would be taken from a payment of the given amount. */
  feeBreakdown?: Maybe<FeeBreakdown>;
  file?: Maybe<File>;
  files: Array<File>;
  gameSkin?: Maybe<GameSkin>;
  gameSkins: Array<GameSkin>;
  globalIncomeByMetric?: Maybe<Array<Maybe<GlobalIncomeByMetricPayload>>>;
  goal?: Maybe<Goal>;
  goals: Array<Goal>;
  income?: Maybe<Array<Maybe<BranchIncome>>>;
  invoice?: Maybe<Invoice>;
  invoiceConcept?: Maybe<InvoiceConcept>;
  invoiceConcepts: Array<InvoiceConcept>;
  invoiceFile?: Maybe<InvoiceFile>;
  invoiceFiles: Array<InvoiceFile>;
  invoices: Array<Invoice>;
  laboratories: Array<Laboratory>;
  laboratory?: Maybe<Laboratory>;
  lockedPeriod?: Maybe<LockedPeriod>;
  lockedPeriods: Array<LockedPeriod>;
  log?: Maybe<Log>;
  logs: Array<Log>;
  /** Gets the chats of the current user */
  myChats?: Maybe<Array<Maybe<Chat>>>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  patient?: Maybe<Patient>;
  patientNotification?: Maybe<PatientNotification>;
  patientNotifications: Array<PatientNotification>;
  patientPaymentMethods?: Maybe<Array<Maybe<PatientPaymentMethod>>>;
  patients: Array<Patient>;
  patientsByBirthdate?: Maybe<Array<Maybe<Patient>>>;
  payment?: Maybe<Payment>;
  paymentCard?: Maybe<PaymentCard>;
  paymentCards: Array<PaymentCard>;
  paymentConcept?: Maybe<PaymentConcept>;
  paymentConcepts: Array<PaymentConcept>;
  paymentItem?: Maybe<PaymentItem>;
  paymentItems: Array<PaymentItem>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethods: Array<PaymentMethod>;
  payments: Array<Payment>;
  paymentStatus?: Maybe<PaymentStatusPayload>;
  pointStories: Array<PointStory>;
  pointStory?: Maybe<PointStory>;
  prescription?: Maybe<Prescription>;
  prescriptions: Array<Prescription>;
  recievedAmount?: Maybe<RecievedAmount>;
  recievedAmounts: Array<RecievedAmount>;
  recurringPayment?: Maybe<RecurringPayment>;
  recurringPayments: Array<RecurringPayment>;
  reports?: Maybe<Reports>;
  signedsUrl?: Maybe<SignedsUrlPayload>;
  signedUrl?: Maybe<SignedUrlPayload>;
  specialtyDoctor?: Maybe<SpecialtyDoctor>;
  specialtyDoctors: Array<SpecialtyDoctor>;
  totalsByPaymentMethod?: Maybe<Array<Maybe<PaymentMethodTotal>>>;
  userFeedback?: Maybe<UserFeedback>;
  userFeedbacks: Array<UserFeedback>;
};

export type QueryAddressArgs = {
  where: AddressWhereUniqueInput;
};

export type QueryAddressesArgs = {
  cursor?: Maybe<AddressWhereUniqueInput>;
  orderBy?: Maybe<Array<AddressOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AddressWhereInput>;
};

export type QueryAnnouncementArgs = {
  where: AnnouncementWhereUniqueInput;
};

export type QueryAnnouncementsArgs = {
  cursor?: Maybe<AnnouncementWhereUniqueInput>;
  orderBy?: Maybe<Array<AnnouncementOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AnnouncementWhereInput>;
};

export type QueryAnnouncementViewArgs = {
  where: AnnouncementViewWhereUniqueInput;
};

export type QueryAnnouncementViewsArgs = {
  cursor?: Maybe<AnnouncementViewWhereUniqueInput>;
  orderBy?: Maybe<Array<AnnouncementViewOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AnnouncementViewWhereInput>;
};

export type QueryAppointmentArgs = {
  where: AppointmentWhereUniqueInput;
};

export type QueryAppointmentsArgs = {
  cursor?: Maybe<AppointmentWhereUniqueInput>;
  orderBy?: Maybe<Array<AppointmentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<AppointmentWhereInput>;
};

export type QueryBankingFileArgs = {
  where: BankingFileWhereUniqueInput;
};

export type QueryBankingFilesArgs = {
  cursor?: Maybe<BankingFileWhereUniqueInput>;
  orderBy?: Maybe<Array<BankingFileOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BankingFileWhereInput>;
};

export type QueryBlogPostArgs = {
  where: BlogPostWhereUniqueInput;
};

export type QueryBlogPostsArgs = {
  cursor?: Maybe<BlogPostWhereUniqueInput>;
  orderBy?: Maybe<Array<BlogPostOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BlogPostWhereInput>;
};

export type QueryBranchArgs = {
  where: BranchWhereUniqueInput;
};

export type QueryBranchesArgs = {
  cursor?: Maybe<BranchWhereUniqueInput>;
  orderBy?: Maybe<Array<BranchOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BranchWhereInput>;
};

export type QueryBranchServiceArgs = {
  where: BranchServiceWhereUniqueInput;
};

export type QueryBranchServicesArgs = {
  cursor?: Maybe<BranchServiceWhereUniqueInput>;
  orderBy?: Maybe<Array<BranchServiceOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BranchServiceWhereInput>;
};

export type QueryBudgetArgs = {
  where: BudgetWhereUniqueInput;
};

export type QueryBudgetItemArgs = {
  where: BudgetItemWhereUniqueInput;
};

export type QueryBudgetItemsArgs = {
  cursor?: Maybe<BudgetItemWhereUniqueInput>;
  orderBy?: Maybe<Array<BudgetItemOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetItemWhereInput>;
};

export type QueryBudgetsArgs = {
  cursor?: Maybe<BudgetWhereUniqueInput>;
  orderBy?: Maybe<Array<BudgetOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<BudgetWhereInput>;
};

export type QueryCardArgs = {
  where: CardWhereUniqueInput;
};

export type QueryCardsArgs = {
  cursor?: Maybe<CardWhereUniqueInput>;
  orderBy?: Maybe<Array<CardOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CardWhereInput>;
};

export type QueryCashClosingArgs = {
  where: CashClosingWhereUniqueInput;
};

export type QueryCashClosingsArgs = {
  cursor?: Maybe<CashClosingWhereUniqueInput>;
  orderBy?: Maybe<Array<CashClosingOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CashClosingWhereInput>;
};

export type QueryChatArgs = {
  where: ChatWhereUniqueInput;
};

export type QueryChatMessageArgs = {
  where: ChatMessageWhereUniqueInput;
};

export type QueryChatMessagesArgs = {
  cursor?: Maybe<ChatMessageWhereUniqueInput>;
  orderBy?: Maybe<Array<ChatMessageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChatMessageWhereInput>;
};

export type QueryChatParticipantArgs = {
  where: ChatParticipantWhereUniqueInput;
};

export type QueryChatParticipantsArgs = {
  cursor?: Maybe<ChatParticipantWhereUniqueInput>;
  orderBy?: Maybe<Array<ChatParticipantOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChatParticipantWhereInput>;
};

export type QueryChatsArgs = {
  cursor?: Maybe<ChatWhereUniqueInput>;
  orderBy?: Maybe<Array<ChatOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ChatWhereInput>;
};

export type QueryCommissionsArgs = {
  cursor?: Maybe<CommissionWhereUniqueInput>;
  orderBy?: Maybe<Array<CommissionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommissionWhereInput>;
};

export type QueryContractArgs = {
  where: ContractWhereUniqueInput;
};

export type QueryContractsArgs = {
  cursor?: Maybe<ContractWhereUniqueInput>;
  orderBy?: Maybe<Array<ContractOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ContractWhereInput>;
};

export type QueryCountPatientsByBirthdateArgs = {
  branchId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  patientName?: Maybe<Scalars['String']>;
};

export type QueryCountPatientsTrackingReportArgs = {
  branchId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  patientId?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTime']>;
  take?: Maybe<Scalars['Int']>;
  treatment?: Maybe<Treatment>;
};

export type QueryCouponArgs = {
  where: CouponWhereUniqueInput;
};

export type QueryCouponsArgs = {
  cursor?: Maybe<CouponWhereUniqueInput>;
  orderBy?: Maybe<Array<CouponOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CouponWhereInput>;
};

export type QueryDashboardArgs = {
  branchIds: Array<Scalars['String']>;
  goalBranchId: Scalars['String'];
  metric: StatsMetric;
  year1?: Scalars['Int'];
  year2?: Scalars['Int'];
};

export type QueryDayArgs = {
  where: DayWhereUniqueInput;
};

export type QueryDaysArgs = {
  cursor?: Maybe<DayWhereUniqueInput>;
  orderBy?: Maybe<Array<DayOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DayWhereInput>;
};

export type QueryDaysAvailabilityArgs = {
  branchId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryDoctorFileArgs = {
  where: DoctorFileWhereUniqueInput;
};

export type QueryDoctorFilesArgs = {
  cursor?: Maybe<DoctorFileWhereUniqueInput>;
  orderBy?: Maybe<Array<DoctorFileOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DoctorFileWhereInput>;
};

export type QueryEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};

export type QueryEmployeeRatingArgs = {
  where: EmployeeRatingWhereUniqueInput;
};

export type QueryEmployeeRatingsArgs = {
  cursor?: Maybe<EmployeeRatingWhereUniqueInput>;
  orderBy?: Maybe<Array<EmployeeRatingOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmployeeRatingWhereInput>;
};

export type QueryEmployeesArgs = {
  cursor?: Maybe<EmployeeWhereUniqueInput>;
  orderBy?: Maybe<Array<EmployeeOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmployeeWhereInput>;
};

export type QueryEvolutionNoteArgs = {
  where: EvolutionNoteWhereUniqueInput;
};

export type QueryEvolutionNotesArgs = {
  cursor?: Maybe<EvolutionNoteWhereUniqueInput>;
  orderBy?: Maybe<Array<EvolutionNoteOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EvolutionNoteWhereInput>;
};

export type QueryExpenseArgs = {
  where: ExpenseWhereUniqueInput;
};

export type QueryExpensesArgs = {
  cursor?: Maybe<ExpenseWhereUniqueInput>;
  orderBy?: Maybe<Array<ExpenseOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ExpenseWhereInput>;
};

export type QueryFeeBreakdownArgs = {
  amount: Scalars['Float'];
};

export type QueryFileArgs = {
  where: FileWhereUniqueInput;
};

export type QueryFilesArgs = {
  cursor?: Maybe<FileWhereUniqueInput>;
  orderBy?: Maybe<Array<FileOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<FileWhereInput>;
};

export type QueryGameSkinArgs = {
  where: GameSkinWhereUniqueInput;
};

export type QueryGameSkinsArgs = {
  cursor?: Maybe<GameSkinWhereUniqueInput>;
  orderBy?: Maybe<Array<GameSkinOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GameSkinWhereInput>;
};

export type QueryGlobalIncomeByMetricArgs = {
  end: Scalars['DateTime'];
  lastEnd: Scalars['DateTime'];
  lastStart: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type QueryGoalArgs = {
  where: GoalWhereUniqueInput;
};

export type QueryGoalsArgs = {
  cursor?: Maybe<GoalWhereUniqueInput>;
  orderBy?: Maybe<Array<GoalOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<GoalWhereInput>;
};

export type QueryIncomeArgs = {
  branchIds: Array<Scalars['String']>;
  end: Scalars['DateTime'];
  lastEnd: Scalars['DateTime'];
  lastStart: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};

export type QueryInvoiceConceptArgs = {
  where: InvoiceConceptWhereUniqueInput;
};

export type QueryInvoiceConceptsArgs = {
  cursor?: Maybe<InvoiceConceptWhereUniqueInput>;
  orderBy?: Maybe<Array<InvoiceConceptOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceConceptWhereInput>;
};

export type QueryInvoiceFileArgs = {
  where: InvoiceFileWhereUniqueInput;
};

export type QueryInvoiceFilesArgs = {
  cursor?: Maybe<InvoiceFileWhereUniqueInput>;
  orderBy?: Maybe<Array<InvoiceFileOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceFileWhereInput>;
};

export type QueryInvoicesArgs = {
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  orderBy?: Maybe<Array<InvoiceOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<InvoiceWhereInput>;
};

export type QueryLaboratoriesArgs = {
  cursor?: Maybe<LaboratoryWhereUniqueInput>;
  orderBy?: Maybe<Array<LaboratoryOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LaboratoryWhereInput>;
};

export type QueryLaboratoryArgs = {
  where: LaboratoryWhereUniqueInput;
};

export type QueryLockedPeriodArgs = {
  where: LockedPeriodWhereUniqueInput;
};

export type QueryLockedPeriodsArgs = {
  cursor?: Maybe<LockedPeriodWhereUniqueInput>;
  orderBy?: Maybe<Array<LockedPeriodOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LockedPeriodWhereInput>;
};

export type QueryLogArgs = {
  where: LogWhereUniqueInput;
};

export type QueryLogsArgs = {
  cursor?: Maybe<LogWhereUniqueInput>;
  orderBy?: Maybe<Array<LogOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LogWhereInput>;
};

export type QueryMyChatsArgs = {
  chatType: ChatType;
  searchTerm?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryNotificationArgs = {
  where: NotificationWhereUniqueInput;
};

export type QueryNotificationsArgs = {
  cursor?: Maybe<NotificationWhereUniqueInput>;
  orderBy?: Maybe<Array<NotificationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NotificationWhereInput>;
};

export type QueryPatientArgs = {
  where: PatientWhereUniqueInput;
};

export type QueryPatientNotificationArgs = {
  where: PatientNotificationWhereUniqueInput;
};

export type QueryPatientNotificationsArgs = {
  cursor?: Maybe<PatientNotificationWhereUniqueInput>;
  orderBy?: Maybe<Array<PatientNotificationOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PatientNotificationWhereInput>;
};

export type QueryPatientPaymentMethodsArgs = {
  patientId: Scalars['String'];
};

export type QueryPatientsArgs = {
  cursor?: Maybe<PatientWhereUniqueInput>;
  orderBy?: Maybe<Array<PatientOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PatientWhereInput>;
};

export type QueryPatientsByBirthdateArgs = {
  branchId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  patientName?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput;
};

export type QueryPaymentCardArgs = {
  where: PaymentCardWhereUniqueInput;
};

export type QueryPaymentCardsArgs = {
  cursor?: Maybe<PaymentCardWhereUniqueInput>;
  orderBy?: Maybe<Array<PaymentCardOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentCardWhereInput>;
};

export type QueryPaymentConceptArgs = {
  where: PaymentConceptWhereUniqueInput;
};

export type QueryPaymentConceptsArgs = {
  cursor?: Maybe<PaymentConceptWhereUniqueInput>;
  orderBy?: Maybe<Array<PaymentConceptOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentConceptWhereInput>;
};

export type QueryPaymentItemArgs = {
  where: PaymentItemWhereUniqueInput;
};

export type QueryPaymentItemsArgs = {
  cursor?: Maybe<PaymentItemWhereUniqueInput>;
  orderBy?: Maybe<Array<PaymentItemOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentItemWhereInput>;
};

export type QueryPaymentMethodArgs = {
  where: PaymentMethodWhereUniqueInput;
};

export type QueryPaymentMethodsArgs = {
  cursor?: Maybe<PaymentMethodWhereUniqueInput>;
  orderBy?: Maybe<Array<PaymentMethodOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentMethodWhereInput>;
};

export type QueryPaymentsArgs = {
  cursor?: Maybe<PaymentWhereUniqueInput>;
  orderBy?: Maybe<Array<PaymentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PaymentWhereInput>;
};

export type QueryPaymentStatusArgs = {
  confirmationNumber: Scalars['String'];
  recurringPaymentId: Scalars['String'];
};

export type QueryPointStoriesArgs = {
  cursor?: Maybe<PointStoryWhereUniqueInput>;
  orderBy?: Maybe<Array<PointStoryOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PointStoryWhereInput>;
};

export type QueryPointStoryArgs = {
  where: PointStoryWhereUniqueInput;
};

export type QueryPrescriptionArgs = {
  where: PrescriptionWhereUniqueInput;
};

export type QueryPrescriptionsArgs = {
  cursor?: Maybe<PrescriptionWhereUniqueInput>;
  orderBy?: Maybe<Array<PrescriptionOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PrescriptionWhereInput>;
};

export type QueryRecievedAmountArgs = {
  where: RecievedAmountWhereUniqueInput;
};

export type QueryRecievedAmountsArgs = {
  cursor?: Maybe<RecievedAmountWhereUniqueInput>;
  orderBy?: Maybe<Array<RecievedAmountOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RecievedAmountWhereInput>;
};

export type QueryRecurringPaymentArgs = {
  where: RecurringPaymentWhereUniqueInput;
};

export type QueryRecurringPaymentsArgs = {
  cursor?: Maybe<RecurringPaymentWhereUniqueInput>;
  orderBy?: Maybe<Array<RecurringPaymentOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<RecurringPaymentWhereInput>;
};

export type QuerySignedsUrlArgs = {
  filenames: Array<Scalars['String']>;
};

export type QuerySignedUrlArgs = {
  filename: Scalars['String'];
};

export type QuerySpecialtyDoctorArgs = {
  where: SpecialtyDoctorWhereUniqueInput;
};

export type QuerySpecialtyDoctorsArgs = {
  cursor?: Maybe<SpecialtyDoctorWhereUniqueInput>;
  orderBy?: Maybe<Array<SpecialtyDoctorOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<SpecialtyDoctorWhereInput>;
};

export type QueryTotalsByPaymentMethodArgs = {
  branchId?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type QueryUserFeedbackArgs = {
  where: UserFeedbackWhereUniqueInput;
};

export type QueryUserFeedbacksArgs = {
  cursor?: Maybe<UserFeedbackWhereUniqueInput>;
  orderBy?: Maybe<Array<UserFeedbackOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserFeedbackWhereInput>;
};

export type RecievedAmount = {
  __typename?: 'RecievedAmount';
  amount: Scalars['Float'];
  archived: Scalars['Boolean'];
  archivedBy?: Maybe<Employee>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  employee?: Maybe<Employee>;
  fee?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  payment: Payment;
  paymentMethod?: Maybe<PaymentMethod>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethod?: Maybe<PatientPaymentMethod>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type RecievedAmountCreateInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutRecievedAmountsInput;
  paymentMethod?: Maybe<PaymentMethodCreateNestedOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateManyArchivedByInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentId: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['String']>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateManyArchivedByInputEnvelope = {
  data?: Maybe<Array<RecievedAmountCreateManyArchivedByInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecievedAmountCreateManyEmployeeInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentId: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['String']>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateManyEmployeeInputEnvelope = {
  data?: Maybe<Array<RecievedAmountCreateManyEmployeeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecievedAmountCreateManyPaymentInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateManyPaymentInputEnvelope = {
  data?: Maybe<Array<RecievedAmountCreateManyPaymentInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecievedAmountCreateManyPaymentMethodInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedById?: Maybe<Scalars['String']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employeeId?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentId: Scalars['String'];
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateManyPaymentMethodInputEnvelope = {
  data?: Maybe<Array<RecievedAmountCreateManyPaymentMethodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecievedAmountCreateNestedManyWithoutArchivedByInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutArchivedByInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutArchivedByInput>>;
  createMany?: Maybe<RecievedAmountCreateManyArchivedByInputEnvelope>;
};

export type RecievedAmountCreateNestedManyWithoutEmployeeInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutEmployeeInput>>;
  createMany?: Maybe<RecievedAmountCreateManyEmployeeInputEnvelope>;
};

export type RecievedAmountCreateNestedManyWithoutPaymentInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutPaymentInput>>;
  createMany?: Maybe<RecievedAmountCreateManyPaymentInputEnvelope>;
};

export type RecievedAmountCreateNestedManyWithoutPaymentMethodInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutPaymentMethodInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutPaymentMethodInput>>;
  createMany?: Maybe<RecievedAmountCreateManyPaymentMethodInputEnvelope>;
};

export type RecievedAmountCreateOrConnectWithoutArchivedByInput = {
  create: RecievedAmountCreateWithoutArchivedByInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountCreateOrConnectWithoutEmployeeInput = {
  create: RecievedAmountCreateWithoutEmployeeInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountCreateOrConnectWithoutPaymentInput = {
  create: RecievedAmountCreateWithoutPaymentInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountCreateOrConnectWithoutPaymentMethodInput = {
  create: RecievedAmountCreateWithoutPaymentMethodInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountCreateWithoutArchivedByInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutRecievedAmountsInput;
  paymentMethod?: Maybe<PaymentMethodCreateNestedOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateWithoutEmployeeInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutRecievedAmountsInput;
  paymentMethod?: Maybe<PaymentMethodCreateNestedOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateWithoutPaymentInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodCreateNestedOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountCreateWithoutPaymentMethodInput = {
  amount: Scalars['Float'];
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeCreateNestedOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeCreateNestedOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment: PaymentCreateNestedOneWithoutRecievedAmountsInput;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountListRelationFilter = {
  every?: Maybe<RecievedAmountWhereInput>;
  none?: Maybe<RecievedAmountWhereInput>;
  some?: Maybe<RecievedAmountWhereInput>;
};

export type RecievedAmountOrderByInput = {
  amount?: Maybe<SortOrder>;
  archived?: Maybe<SortOrder>;
  archivedById?: Maybe<SortOrder>;
  archiveTime?: Maybe<SortOrder>;
  change?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  employeeId?: Maybe<SortOrder>;
  fee?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  paymentId?: Maybe<SortOrder>;
  paymentMethodId?: Maybe<SortOrder>;
  pointsSpent?: Maybe<SortOrder>;
  stripeId?: Maybe<SortOrder>;
  stripePaymentMethodId?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RecievedAmountScalarWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  archived?: Maybe<BoolFilter>;
  archivedById?: Maybe<StringNullableFilter>;
  archiveTime?: Maybe<DateTimeNullableFilter>;
  change?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employeeId?: Maybe<StringNullableFilter>;
  fee?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  OR?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  paymentId?: Maybe<StringFilter>;
  paymentMethodId?: Maybe<StringNullableFilter>;
  pointsSpent?: Maybe<FloatNullableFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecievedAmountUpdateInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutRecievedAmountsInput>;
  paymentMethod?: Maybe<PaymentMethodUpdateOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateManyMutationInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateManyWithoutArchivedByInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutArchivedByInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutArchivedByInput>>;
  createMany?: Maybe<RecievedAmountCreateManyArchivedByInputEnvelope>;
  delete?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  disconnect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  set?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  update?: Maybe<Array<RecievedAmountUpdateWithWhereUniqueWithoutArchivedByInput>>;
  updateMany?: Maybe<Array<RecievedAmountUpdateManyWithWhereWithoutArchivedByInput>>;
  upsert?: Maybe<Array<RecievedAmountUpsertWithWhereUniqueWithoutArchivedByInput>>;
};

export type RecievedAmountUpdateManyWithoutEmployeeInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutEmployeeInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutEmployeeInput>>;
  createMany?: Maybe<RecievedAmountCreateManyEmployeeInputEnvelope>;
  delete?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  disconnect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  set?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  update?: Maybe<Array<RecievedAmountUpdateWithWhereUniqueWithoutEmployeeInput>>;
  updateMany?: Maybe<Array<RecievedAmountUpdateManyWithWhereWithoutEmployeeInput>>;
  upsert?: Maybe<Array<RecievedAmountUpsertWithWhereUniqueWithoutEmployeeInput>>;
};

export type RecievedAmountUpdateManyWithoutPaymentInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutPaymentInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutPaymentInput>>;
  createMany?: Maybe<RecievedAmountCreateManyPaymentInputEnvelope>;
  delete?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  disconnect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  set?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  update?: Maybe<Array<RecievedAmountUpdateWithWhereUniqueWithoutPaymentInput>>;
  updateMany?: Maybe<Array<RecievedAmountUpdateManyWithWhereWithoutPaymentInput>>;
  upsert?: Maybe<Array<RecievedAmountUpsertWithWhereUniqueWithoutPaymentInput>>;
};

export type RecievedAmountUpdateManyWithoutPaymentMethodInput = {
  connect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecievedAmountCreateOrConnectWithoutPaymentMethodInput>>;
  create?: Maybe<Array<RecievedAmountCreateWithoutPaymentMethodInput>>;
  createMany?: Maybe<RecievedAmountCreateManyPaymentMethodInputEnvelope>;
  delete?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecievedAmountScalarWhereInput>>;
  disconnect?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  set?: Maybe<Array<RecievedAmountWhereUniqueInput>>;
  update?: Maybe<Array<RecievedAmountUpdateWithWhereUniqueWithoutPaymentMethodInput>>;
  updateMany?: Maybe<Array<RecievedAmountUpdateManyWithWhereWithoutPaymentMethodInput>>;
  upsert?: Maybe<Array<RecievedAmountUpsertWithWhereUniqueWithoutPaymentMethodInput>>;
};

export type RecievedAmountUpdateManyWithWhereWithoutArchivedByInput = {
  data: RecievedAmountUpdateManyMutationInput;
  where: RecievedAmountScalarWhereInput;
};

export type RecievedAmountUpdateManyWithWhereWithoutEmployeeInput = {
  data: RecievedAmountUpdateManyMutationInput;
  where: RecievedAmountScalarWhereInput;
};

export type RecievedAmountUpdateManyWithWhereWithoutPaymentInput = {
  data: RecievedAmountUpdateManyMutationInput;
  where: RecievedAmountScalarWhereInput;
};

export type RecievedAmountUpdateManyWithWhereWithoutPaymentMethodInput = {
  data: RecievedAmountUpdateManyMutationInput;
  where: RecievedAmountScalarWhereInput;
};

export type RecievedAmountUpdateWithoutArchivedByInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutRecievedAmountsInput>;
  paymentMethod?: Maybe<PaymentMethodUpdateOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateWithoutEmployeeInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutRecievedAmountsInput>;
  paymentMethod?: Maybe<PaymentMethodUpdateOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateWithoutPaymentInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodUpdateOneWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateWithoutPaymentMethodInput = {
  amount?: Maybe<Scalars['Float']>;
  archived?: Maybe<Scalars['Boolean']>;
  archivedBy?: Maybe<EmployeeUpdateOneWithoutArchivedRecievedAmountsInput>;
  archiveTime?: Maybe<Scalars['DateTime']>;
  change?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  employee?: Maybe<EmployeeUpdateOneWithoutCollectedAmountsInput>;
  fee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  payment?: Maybe<PaymentUpdateOneRequiredWithoutRecievedAmountsInput>;
  pointsSpent?: Maybe<Scalars['Float']>;
  stripeId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecievedAmountUpdateWithWhereUniqueWithoutArchivedByInput = {
  data: RecievedAmountUpdateWithoutArchivedByInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpdateWithWhereUniqueWithoutEmployeeInput = {
  data: RecievedAmountUpdateWithoutEmployeeInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpdateWithWhereUniqueWithoutPaymentInput = {
  data: RecievedAmountUpdateWithoutPaymentInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpdateWithWhereUniqueWithoutPaymentMethodInput = {
  data: RecievedAmountUpdateWithoutPaymentMethodInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpsertWithWhereUniqueWithoutArchivedByInput = {
  create: RecievedAmountCreateWithoutArchivedByInput;
  update: RecievedAmountUpdateWithoutArchivedByInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpsertWithWhereUniqueWithoutEmployeeInput = {
  create: RecievedAmountCreateWithoutEmployeeInput;
  update: RecievedAmountUpdateWithoutEmployeeInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpsertWithWhereUniqueWithoutPaymentInput = {
  create: RecievedAmountCreateWithoutPaymentInput;
  update: RecievedAmountUpdateWithoutPaymentInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountUpsertWithWhereUniqueWithoutPaymentMethodInput = {
  create: RecievedAmountCreateWithoutPaymentMethodInput;
  update: RecievedAmountUpdateWithoutPaymentMethodInput;
  where: RecievedAmountWhereUniqueInput;
};

export type RecievedAmountWhereInput = {
  amount?: Maybe<FloatFilter>;
  AND?: Maybe<Array<RecievedAmountWhereInput>>;
  archived?: Maybe<BoolFilter>;
  archivedBy?: Maybe<EmployeeWhereInput>;
  archivedById?: Maybe<StringNullableFilter>;
  archiveTime?: Maybe<DateTimeNullableFilter>;
  change?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  employee?: Maybe<EmployeeWhereInput>;
  employeeId?: Maybe<StringNullableFilter>;
  fee?: Maybe<FloatNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<RecievedAmountWhereInput>>;
  OR?: Maybe<Array<RecievedAmountWhereInput>>;
  payment?: Maybe<PaymentWhereInput>;
  paymentId?: Maybe<StringFilter>;
  paymentMethod?: Maybe<PaymentMethodWhereInput>;
  paymentMethodId?: Maybe<StringNullableFilter>;
  pointsSpent?: Maybe<FloatNullableFilter>;
  stripeId?: Maybe<StringNullableFilter>;
  stripePaymentMethodId?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecievedAmountWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type RecurringPayment = {
  __typename?: 'RecurringPayment';
  authorizationCode: Scalars['String'];
  canceled: Scalars['Boolean'];
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  card: Card;
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt: Scalars['DateTime'];
  durationInMonths?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  patient: Patient;
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RecurringPaymentCreateInput = {
  authorizationCode: Scalars['String'];
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  card: CardCreateNestedOneWithoutRecurringPaymentInput;
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutRecurringPaymentsInput;
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentCreateManyCardInput = {
  authorizationCode: Scalars['String'];
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patientId: Scalars['String'];
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentCreateManyCardInputEnvelope = {
  data?: Maybe<Array<RecurringPaymentCreateManyCardInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecurringPaymentCreateManyPatientInput = {
  authorizationCode: Scalars['String'];
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  cardId: Scalars['String'];
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentCreateManyPatientInputEnvelope = {
  data?: Maybe<Array<RecurringPaymentCreateManyPatientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RecurringPaymentCreateNestedManyWithoutCardInput = {
  connect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecurringPaymentCreateOrConnectWithoutCardInput>>;
  create?: Maybe<Array<RecurringPaymentCreateWithoutCardInput>>;
  createMany?: Maybe<RecurringPaymentCreateManyCardInputEnvelope>;
};

export type RecurringPaymentCreateNestedManyWithoutPatientInput = {
  connect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecurringPaymentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<RecurringPaymentCreateWithoutPatientInput>>;
  createMany?: Maybe<RecurringPaymentCreateManyPatientInputEnvelope>;
};

export type RecurringPaymentCreateOrConnectWithoutCardInput = {
  create: RecurringPaymentCreateWithoutCardInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentCreateOrConnectWithoutPatientInput = {
  create: RecurringPaymentCreateWithoutPatientInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentCreateWithoutCardInput = {
  authorizationCode: Scalars['String'];
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patient: PatientCreateNestedOneWithoutRecurringPaymentsInput;
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentCreateWithoutPatientInput = {
  authorizationCode: Scalars['String'];
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  card: CardCreateNestedOneWithoutRecurringPaymentInput;
  cardType: Scalars['String'];
  confirmationNumber: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  paymentAmount: Scalars['Float'];
  reference: Scalars['String'];
  settlementSubmissionDate: Scalars['DateTime'];
  totalAmount: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentListRelationFilter = {
  every?: Maybe<RecurringPaymentWhereInput>;
  none?: Maybe<RecurringPaymentWhereInput>;
  some?: Maybe<RecurringPaymentWhereInput>;
};

export type RecurringPaymentOrderByInput = {
  authorizationCode?: Maybe<SortOrder>;
  canceled?: Maybe<SortOrder>;
  canceledConfirmationNumber?: Maybe<SortOrder>;
  canceledRefundAmount?: Maybe<SortOrder>;
  canceledSettlementSubmissionDate?: Maybe<SortOrder>;
  cardId?: Maybe<SortOrder>;
  cardType?: Maybe<SortOrder>;
  confirmationNumber?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  durationInMonths?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  patientId?: Maybe<SortOrder>;
  paymentAmount?: Maybe<SortOrder>;
  reference?: Maybe<SortOrder>;
  settlementSubmissionDate?: Maybe<SortOrder>;
  totalAmount?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RecurringPaymentScalarWhereInput = {
  AND?: Maybe<Array<RecurringPaymentScalarWhereInput>>;
  authorizationCode?: Maybe<StringFilter>;
  canceled?: Maybe<BoolFilter>;
  canceledConfirmationNumber?: Maybe<StringNullableFilter>;
  canceledRefundAmount?: Maybe<StringNullableFilter>;
  canceledSettlementSubmissionDate?: Maybe<DateTimeNullableFilter>;
  cardId?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  confirmationNumber?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  durationInMonths?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<RecurringPaymentScalarWhereInput>>;
  OR?: Maybe<Array<RecurringPaymentScalarWhereInput>>;
  patientId?: Maybe<StringFilter>;
  paymentAmount?: Maybe<FloatFilter>;
  reference?: Maybe<StringFilter>;
  settlementSubmissionDate?: Maybe<DateTimeFilter>;
  totalAmount?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecurringPaymentUpdateInput = {
  authorizationCode?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  card?: Maybe<CardUpdateOneRequiredWithoutRecurringPaymentInput>;
  cardType?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutRecurringPaymentsInput>;
  paymentAmount?: Maybe<Scalars['Float']>;
  reference?: Maybe<Scalars['String']>;
  settlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentUpdateManyMutationInput = {
  authorizationCode?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  cardType?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  reference?: Maybe<Scalars['String']>;
  settlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentUpdateManyWithoutCardInput = {
  connect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecurringPaymentCreateOrConnectWithoutCardInput>>;
  create?: Maybe<Array<RecurringPaymentCreateWithoutCardInput>>;
  createMany?: Maybe<RecurringPaymentCreateManyCardInputEnvelope>;
  delete?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecurringPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  set?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  update?: Maybe<Array<RecurringPaymentUpdateWithWhereUniqueWithoutCardInput>>;
  updateMany?: Maybe<Array<RecurringPaymentUpdateManyWithWhereWithoutCardInput>>;
  upsert?: Maybe<Array<RecurringPaymentUpsertWithWhereUniqueWithoutCardInput>>;
};

export type RecurringPaymentUpdateManyWithoutPatientInput = {
  connect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RecurringPaymentCreateOrConnectWithoutPatientInput>>;
  create?: Maybe<Array<RecurringPaymentCreateWithoutPatientInput>>;
  createMany?: Maybe<RecurringPaymentCreateManyPatientInputEnvelope>;
  delete?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RecurringPaymentScalarWhereInput>>;
  disconnect?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  set?: Maybe<Array<RecurringPaymentWhereUniqueInput>>;
  update?: Maybe<Array<RecurringPaymentUpdateWithWhereUniqueWithoutPatientInput>>;
  updateMany?: Maybe<Array<RecurringPaymentUpdateManyWithWhereWithoutPatientInput>>;
  upsert?: Maybe<Array<RecurringPaymentUpsertWithWhereUniqueWithoutPatientInput>>;
};

export type RecurringPaymentUpdateManyWithWhereWithoutCardInput = {
  data: RecurringPaymentUpdateManyMutationInput;
  where: RecurringPaymentScalarWhereInput;
};

export type RecurringPaymentUpdateManyWithWhereWithoutPatientInput = {
  data: RecurringPaymentUpdateManyMutationInput;
  where: RecurringPaymentScalarWhereInput;
};

export type RecurringPaymentUpdateWithoutCardInput = {
  authorizationCode?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  cardType?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  patient?: Maybe<PatientUpdateOneRequiredWithoutRecurringPaymentsInput>;
  paymentAmount?: Maybe<Scalars['Float']>;
  reference?: Maybe<Scalars['String']>;
  settlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentUpdateWithoutPatientInput = {
  authorizationCode?: Maybe<Scalars['String']>;
  canceled?: Maybe<Scalars['Boolean']>;
  canceledConfirmationNumber?: Maybe<Scalars['String']>;
  canceledRefundAmount?: Maybe<Scalars['String']>;
  canceledSettlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  card?: Maybe<CardUpdateOneRequiredWithoutRecurringPaymentInput>;
  cardType?: Maybe<Scalars['String']>;
  confirmationNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  durationInMonths?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  reference?: Maybe<Scalars['String']>;
  settlementSubmissionDate?: Maybe<Scalars['DateTime']>;
  totalAmount?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RecurringPaymentUpdateWithWhereUniqueWithoutCardInput = {
  data: RecurringPaymentUpdateWithoutCardInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentUpdateWithWhereUniqueWithoutPatientInput = {
  data: RecurringPaymentUpdateWithoutPatientInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentUpsertWithWhereUniqueWithoutCardInput = {
  create: RecurringPaymentCreateWithoutCardInput;
  update: RecurringPaymentUpdateWithoutCardInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentUpsertWithWhereUniqueWithoutPatientInput = {
  create: RecurringPaymentCreateWithoutPatientInput;
  update: RecurringPaymentUpdateWithoutPatientInput;
  where: RecurringPaymentWhereUniqueInput;
};

export type RecurringPaymentWhereInput = {
  AND?: Maybe<Array<RecurringPaymentWhereInput>>;
  authorizationCode?: Maybe<StringFilter>;
  canceled?: Maybe<BoolFilter>;
  canceledConfirmationNumber?: Maybe<StringNullableFilter>;
  canceledRefundAmount?: Maybe<StringNullableFilter>;
  canceledSettlementSubmissionDate?: Maybe<DateTimeNullableFilter>;
  card?: Maybe<CardWhereInput>;
  cardId?: Maybe<StringFilter>;
  cardType?: Maybe<StringFilter>;
  confirmationNumber?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  durationInMonths?: Maybe<IntNullableFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<RecurringPaymentWhereInput>>;
  OR?: Maybe<Array<RecurringPaymentWhereInput>>;
  patient?: Maybe<PatientWhereInput>;
  patientId?: Maybe<StringFilter>;
  paymentAmount?: Maybe<FloatFilter>;
  reference?: Maybe<StringFilter>;
  settlementSubmissionDate?: Maybe<DateTimeFilter>;
  totalAmount?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecurringPaymentWhereUniqueInput = {
  confirmationNumber?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type RegistrationCrdPayload = {
  __typename?: 'RegistrationCRDPayload';
  registrationId?: Maybe<Scalars['String']>;
  returnCode?: Maybe<Scalars['String']>;
  timeoutLimit?: Maybe<Scalars['String']>;
};

export type Reports = {
  __typename?: 'Reports';
  commissions?: Maybe<CommissionsReports>;
  goals?: Maybe<GoalsReports>;
  income?: Maybe<IncomeReports>;
  patients?: Maybe<PatientsReports>;
  treatments?: Maybe<TreatmentsReports>;
};

export type RetainedTax = {
  __typename?: 'RetainedTax';
  base: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  factorType: Scalars['String'];
  id: Scalars['String'];
  invoiceConcept?: Maybe<InvoiceConcept>;
  invoiceConceptId?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RetainedTaxCreateManyInvoiceConceptInput = {
  base: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RetainedTaxCreateManyInvoiceConceptInputEnvelope = {
  data?: Maybe<Array<RetainedTaxCreateManyInvoiceConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RetainedTaxCreateNestedManyWithoutInvoiceConceptInput = {
  connect?: Maybe<Array<RetainedTaxWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RetainedTaxCreateOrConnectWithoutInvoiceConceptInput>>;
  create?: Maybe<Array<RetainedTaxCreateWithoutInvoiceConceptInput>>;
  createMany?: Maybe<RetainedTaxCreateManyInvoiceConceptInputEnvelope>;
};

export type RetainedTaxCreateOrConnectWithoutInvoiceConceptInput = {
  create: RetainedTaxCreateWithoutInvoiceConceptInput;
  where: RetainedTaxWhereUniqueInput;
};

export type RetainedTaxCreateWithoutInvoiceConceptInput = {
  base: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RetainedTaxListRelationFilter = {
  every?: Maybe<RetainedTaxWhereInput>;
  none?: Maybe<RetainedTaxWhereInput>;
  some?: Maybe<RetainedTaxWhereInput>;
};

export type RetainedTaxScalarWhereInput = {
  AND?: Maybe<Array<RetainedTaxScalarWhereInput>>;
  base?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  factorType?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceConceptId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<RetainedTaxScalarWhereInput>>;
  OR?: Maybe<Array<RetainedTaxScalarWhereInput>>;
  rateOrFee?: Maybe<FloatFilter>;
  tax?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RetainedTaxUpdateManyMutationInput = {
  base?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rateOrFee?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RetainedTaxUpdateManyWithoutInvoiceConceptInput = {
  connect?: Maybe<Array<RetainedTaxWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RetainedTaxCreateOrConnectWithoutInvoiceConceptInput>>;
  create?: Maybe<Array<RetainedTaxCreateWithoutInvoiceConceptInput>>;
  createMany?: Maybe<RetainedTaxCreateManyInvoiceConceptInputEnvelope>;
  delete?: Maybe<Array<RetainedTaxWhereUniqueInput>>;
  deleteMany?: Maybe<Array<RetainedTaxScalarWhereInput>>;
  disconnect?: Maybe<Array<RetainedTaxWhereUniqueInput>>;
  set?: Maybe<Array<RetainedTaxWhereUniqueInput>>;
  update?: Maybe<Array<RetainedTaxUpdateWithWhereUniqueWithoutInvoiceConceptInput>>;
  updateMany?: Maybe<Array<RetainedTaxUpdateManyWithWhereWithoutInvoiceConceptInput>>;
  upsert?: Maybe<Array<RetainedTaxUpsertWithWhereUniqueWithoutInvoiceConceptInput>>;
};

export type RetainedTaxUpdateManyWithWhereWithoutInvoiceConceptInput = {
  data: RetainedTaxUpdateManyMutationInput;
  where: RetainedTaxScalarWhereInput;
};

export type RetainedTaxUpdateWithoutInvoiceConceptInput = {
  base?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rateOrFee?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RetainedTaxUpdateWithWhereUniqueWithoutInvoiceConceptInput = {
  data: RetainedTaxUpdateWithoutInvoiceConceptInput;
  where: RetainedTaxWhereUniqueInput;
};

export type RetainedTaxUpsertWithWhereUniqueWithoutInvoiceConceptInput = {
  create: RetainedTaxCreateWithoutInvoiceConceptInput;
  update: RetainedTaxUpdateWithoutInvoiceConceptInput;
  where: RetainedTaxWhereUniqueInput;
};

export type RetainedTaxWhereInput = {
  AND?: Maybe<Array<RetainedTaxWhereInput>>;
  base?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  factorType?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceConcept?: Maybe<InvoiceConceptWhereInput>;
  invoiceConceptId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<RetainedTaxWhereInput>>;
  OR?: Maybe<Array<RetainedTaxWhereInput>>;
  rateOrFee?: Maybe<FloatFilter>;
  tax?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RetainedTaxWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Role {
  ADMIN = 'ADMIN',
  COACH = 'COACH',
  COORDINATOR = 'COORDINATOR',
  DENTAL_ASSISTANT = 'DENTAL_ASSISTANT',
  DOCTOR = 'DOCTOR',
  RECEPTIONIST = 'RECEPTIONIST'
}

export enum ServiceCategory {
  CREDIT = 'CREDIT',
  INTEGRAL = 'INTEGRAL',
  ORTHODONTICS = 'ORTHODONTICS',
  RX = 'RX',
  SALE = 'SALE',
  SPECIALTY = 'SPECIALTY'
}

export enum Sex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY'
}

export type SignedsUrlPayload = {
  __typename?: 'SignedsUrlPayload';
  signedsUrl?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SignedUrlPayload = {
  __typename?: 'SignedUrlPayload';
  url?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type SpecialtyDoctor = {
  __typename?: 'SpecialtyDoctor';
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  rfc: Scalars['String'];
  services: Array<PaymentConcept>;
  specialty: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SpecialtyDoctorServicesArgs = {
  cursor?: Maybe<PaymentConceptWhereUniqueInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SpecialtyDoctorCreateInput = {
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rfc: Scalars['String'];
  services?: Maybe<PaymentConceptCreateNestedManyWithoutSpecialtyDoctorInput>;
  specialty: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialtyDoctorCreateNestedOneWithoutServicesInput = {
  connect?: Maybe<SpecialtyDoctorWhereUniqueInput>;
  connectOrCreate?: Maybe<SpecialtyDoctorCreateOrConnectWithoutServicesInput>;
  create?: Maybe<SpecialtyDoctorCreateWithoutServicesInput>;
};

export type SpecialtyDoctorCreateOrConnectWithoutServicesInput = {
  create: SpecialtyDoctorCreateWithoutServicesInput;
  where: SpecialtyDoctorWhereUniqueInput;
};

export type SpecialtyDoctorCreateWithoutServicesInput = {
  city: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rfc: Scalars['String'];
  specialty: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialtyDoctorOrderByInput = {
  city?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  rfc?: Maybe<SortOrder>;
  specialty?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type SpecialtyDoctorUpdateInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  services?: Maybe<PaymentConceptUpdateManyWithoutSpecialtyDoctorInput>;
  specialty?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialtyDoctorUpdateManyMutationInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialtyDoctorUpdateOneWithoutServicesInput = {
  connect?: Maybe<SpecialtyDoctorWhereUniqueInput>;
  connectOrCreate?: Maybe<SpecialtyDoctorCreateOrConnectWithoutServicesInput>;
  create?: Maybe<SpecialtyDoctorCreateWithoutServicesInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SpecialtyDoctorUpdateWithoutServicesInput>;
  upsert?: Maybe<SpecialtyDoctorUpsertWithoutServicesInput>;
};

export type SpecialtyDoctorUpdateWithoutServicesInput = {
  city?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SpecialtyDoctorUpsertWithoutServicesInput = {
  create: SpecialtyDoctorCreateWithoutServicesInput;
  update: SpecialtyDoctorUpdateWithoutServicesInput;
};

export type SpecialtyDoctorWhereInput = {
  AND?: Maybe<Array<SpecialtyDoctorWhereInput>>;
  city?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  NOT?: Maybe<Array<SpecialtyDoctorWhereInput>>;
  OR?: Maybe<Array<SpecialtyDoctorWhereInput>>;
  rfc?: Maybe<StringFilter>;
  services?: Maybe<PaymentConceptListRelationFilter>;
  specialty?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SpecialtyDoctorWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rfc?: Maybe<Scalars['String']>;
};

export type StatPayload = {
  __typename?: 'StatPayload';
  isCurrency?: Maybe<Scalars['Boolean']>;
  lastValue: Scalars['Float'];
  name: Scalars['String'];
  value: Scalars['Float'];
};

export enum StatsMetric {
  AJUSTES_ACTIVOS_LIQUIDADOS = 'AJUSTES_ACTIVOS_LIQUIDADOS',
  AJUSTES_FALTANTES_LIQUIDADOS = 'AJUSTES_FALTANTES_LIQUIDADOS',
  ANTICIPOS = 'ANTICIPOS',
  INGRESOS = 'INGRESOS'
}

export type StringFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateChatMessage?: Maybe<ChatMessage>;
  onCreateNotification?: Maybe<ContextualNotification>;
  onCreatePatientNotification?: Maybe<ContextualPatientNotification>;
  /** This field is used to notify a user that the messages of a chat have been seen */
  onMessagesSeen?: Maybe<Chat>;
};

export type TotalDueBreakdown = {
  __typename?: 'TotalDueBreakdown';
  amount: Scalars['Float'];
  amountWithFee: Scalars['Float'];
  fee: Scalars['Float'];
  payments: Array<Payment>;
};

export type TotalServicesByCategory = {
  __typename?: 'TotalServicesByCategory';
  year1?: Maybe<Array<Maybe<ChartMonth>>>;
  year2?: Maybe<Array<Maybe<ChartMonth>>>;
};

export type TransferredTax = {
  __typename?: 'TransferredTax';
  base: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  factorType: Scalars['String'];
  id: Scalars['String'];
  invoiceConcept?: Maybe<InvoiceConcept>;
  invoiceConceptId?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TransferredTaxCreateManyInvoiceConceptInput = {
  base: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransferredTaxCreateManyInvoiceConceptInputEnvelope = {
  data?: Maybe<Array<TransferredTaxCreateManyInvoiceConceptInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TransferredTaxCreateNestedManyWithoutInvoiceConceptInput = {
  connect?: Maybe<Array<TransferredTaxWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransferredTaxCreateOrConnectWithoutInvoiceConceptInput>>;
  create?: Maybe<Array<TransferredTaxCreateWithoutInvoiceConceptInput>>;
  createMany?: Maybe<TransferredTaxCreateManyInvoiceConceptInputEnvelope>;
};

export type TransferredTaxCreateOrConnectWithoutInvoiceConceptInput = {
  create: TransferredTaxCreateWithoutInvoiceConceptInput;
  where: TransferredTaxWhereUniqueInput;
};

export type TransferredTaxCreateWithoutInvoiceConceptInput = {
  base: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  rateOrFee: Scalars['Float'];
  tax: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransferredTaxListRelationFilter = {
  every?: Maybe<TransferredTaxWhereInput>;
  none?: Maybe<TransferredTaxWhereInput>;
  some?: Maybe<TransferredTaxWhereInput>;
};

export type TransferredTaxScalarWhereInput = {
  AND?: Maybe<Array<TransferredTaxScalarWhereInput>>;
  base?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  factorType?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceConceptId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<TransferredTaxScalarWhereInput>>;
  OR?: Maybe<Array<TransferredTaxScalarWhereInput>>;
  rateOrFee?: Maybe<FloatFilter>;
  tax?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransferredTaxUpdateManyMutationInput = {
  base?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rateOrFee?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransferredTaxUpdateManyWithoutInvoiceConceptInput = {
  connect?: Maybe<Array<TransferredTaxWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TransferredTaxCreateOrConnectWithoutInvoiceConceptInput>>;
  create?: Maybe<Array<TransferredTaxCreateWithoutInvoiceConceptInput>>;
  createMany?: Maybe<TransferredTaxCreateManyInvoiceConceptInputEnvelope>;
  delete?: Maybe<Array<TransferredTaxWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TransferredTaxScalarWhereInput>>;
  disconnect?: Maybe<Array<TransferredTaxWhereUniqueInput>>;
  set?: Maybe<Array<TransferredTaxWhereUniqueInput>>;
  update?: Maybe<Array<TransferredTaxUpdateWithWhereUniqueWithoutInvoiceConceptInput>>;
  updateMany?: Maybe<Array<TransferredTaxUpdateManyWithWhereWithoutInvoiceConceptInput>>;
  upsert?: Maybe<Array<TransferredTaxUpsertWithWhereUniqueWithoutInvoiceConceptInput>>;
};

export type TransferredTaxUpdateManyWithWhereWithoutInvoiceConceptInput = {
  data: TransferredTaxUpdateManyMutationInput;
  where: TransferredTaxScalarWhereInput;
};

export type TransferredTaxUpdateWithoutInvoiceConceptInput = {
  base?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  factorType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rateOrFee?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TransferredTaxUpdateWithWhereUniqueWithoutInvoiceConceptInput = {
  data: TransferredTaxUpdateWithoutInvoiceConceptInput;
  where: TransferredTaxWhereUniqueInput;
};

export type TransferredTaxUpsertWithWhereUniqueWithoutInvoiceConceptInput = {
  create: TransferredTaxCreateWithoutInvoiceConceptInput;
  update: TransferredTaxUpdateWithoutInvoiceConceptInput;
  where: TransferredTaxWhereUniqueInput;
};

export type TransferredTaxWhereInput = {
  AND?: Maybe<Array<TransferredTaxWhereInput>>;
  base?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  factorType?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  invoiceConcept?: Maybe<InvoiceConceptWhereInput>;
  invoiceConceptId?: Maybe<StringNullableFilter>;
  NOT?: Maybe<Array<TransferredTaxWhereInput>>;
  OR?: Maybe<Array<TransferredTaxWhereInput>>;
  rateOrFee?: Maybe<FloatFilter>;
  tax?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TransferredTaxWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum Treatment {
  INTEGRAL = 'INTEGRAL',
  ORTHODONTICS = 'ORTHODONTICS',
  SPECIALTY = 'SPECIALTY'
}

export type TreatmentIncome = {
  __typename?: 'TreatmentIncome';
  income?: Maybe<ComparisonField>;
  treatment?: Maybe<Treatment>;
};

export type TreatmentInfo = {
  __typename?: 'TreatmentInfo';
  estimatedEnd?: Maybe<Scalars['DateTime']>;
  remainingMonths?: Maybe<Scalars['Int']>;
  remainingToPay?: Maybe<Scalars['Float']>;
};

export type TreatmentsReports = {
  __typename?: 'TreatmentsReports';
  incomeOfTreatment?: Maybe<ComparisonField>;
  newColocations?: Maybe<NewColocations>;
  totalServicesByCategory?: Maybe<TotalServicesByCategory>;
};

export type TreatmentsReportsIncomeOfTreatmentArgs = {
  branchId: Scalars['String'];
  category: ServiceCategory;
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type TreatmentsReportsNewColocationsArgs = {
  branchIds: Array<Scalars['String']>;
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type TreatmentsReportsTotalServicesByCategoryArgs = {
  branchIds: Array<Scalars['String']>;
  category: ServiceCategory;
  year1?: Scalars['Int'];
  year2?: Scalars['Int'];
};

export type UploadFilePayload = {
  __typename?: 'UploadFilePayload';
  blurHash?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UserFeedback = {
  __typename?: 'UserFeedback';
  createdAt: Scalars['DateTime'];
  feedback: Scalars['String'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UserFeedbackCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFeedbackOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  feedback?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserFeedbackUpdateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFeedbackUpdateManyMutationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFeedbackWhereInput = {
  AND?: Maybe<Array<UserFeedbackWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  feedback?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  NOT?: Maybe<Array<UserFeedbackWhereInput>>;
  OR?: Maybe<Array<UserFeedbackWhereInput>>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserFeedbackWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type VerifyPayload = {
  __typename?: 'VerifyPayload';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type WaitingTimeChartPayload = {
  __typename?: 'WaitingTimeChartPayload';
  average?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};
