import { FunctionComponent } from 'react';
import RawSpinner from './RawSpinner';

export interface LoadingSpinnerProps {
  loading?: boolean;
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({ loading = true }) => {
  return (
    <>
      {loading && (
        <div className='flex justify-center items-center w-full p-8'>
          <RawSpinner width='30px' height='30px' />
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
