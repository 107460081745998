import { cloneDeep } from 'lodash';

export type ObjectWithCreatedAt<T> = T & { createdAt: string | Date };

export function sortByCreatedAt<T>(arr: ObjectWithCreatedAt<T>[]): T[] {
  const newArr = cloneDeep(arr);
  newArr.sort((item1, item2) => new Date(item2.createdAt).getTime() - new Date(item1.createdAt).getTime());
  return newArr;
}

export function sortByCreatedAtAsc<T>(arr: ObjectWithCreatedAt<T>[]): T[] {
  const newArr = cloneDeep(arr);
  newArr.sort((item1, item2) => new Date(item1.createdAt).getTime() - new Date(item2.createdAt).getTime());
  return newArr;
}
