import React, { createContext, FunctionComponent, ReactNode, useState } from 'react';
import ModalBackground from '../components/agenda/ModalBackground';

export const ModalContext = createContext<(component: ReactNode) => void>(null as never);

export const ModalProvider: FunctionComponent = ({ children }) => {
  const [dialog, setDialog] = useState<ReactNode>(null);

  return (
    <ModalContext.Provider value={setDialog}>
      {children}
      {dialog && <ModalBackground onBackgroundClick={() => setDialog(null)} dialog={dialog} />}
    </ModalContext.Provider>
  );
};
