import { FunctionComponent, ReactNode, useEffect } from 'react';

type ModalBackgroundProps = {
  onBackgroundClick: () => void;
  dialog?: ReactNode;
};

const ModalBackground: FunctionComponent<ModalBackgroundProps> = ({ onBackgroundClick, dialog, children }) => {
  useEffect(() => {
    const escFunction = (e: KeyboardEvent): void => {
      if (e.keyCode === 27) {
        onBackgroundClick();
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return (): void => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      <div className='dialog'>
        {dialog}
        {children}
      </div>

      <div
        className='modal-bg fixed w-full h-screen top-0 left-0 '
        style={{ backgroundColor: 'rgba(35, 35, 35, 0.3)', zIndex: 200 }}
        onClick={onBackgroundClick}
      />
      <style jsx>{`
        .dialog {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 300;
          animation: fade 0.4s;
        }
        .modal-bg {
          animation: fade 0.4s;
        }
        @keyframes fade {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

export default ModalBackground;
