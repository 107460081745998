export function mexicanDateFormat(date: string | number | Date): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const formatedDate = new Date(date).toLocaleDateString('es-MX', options);
  return formatedDate;
}

export function mexicanDateFormatNoYear(date: string | number | Date): string {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' };
  const formatedDate = new Date(date).toLocaleDateString('es-MX', options);
  return formatedDate;
}

export function hour(date: Date): string {
  let hours = date.getHours();
  const ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formatedHour =
    hours + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() + ampm : date.getMinutes() + ampm);
  return formatedHour;
}

export function timestamp(date: Date): string {
  return numericDateFormat(date) + ' ' + hour(date);
}

export function numericDateFormat(date: string | number | Date): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const formatedDate = new Date(date).toLocaleDateString('es-MX', options);
  return formatedDate;
}
